import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

const Link = React.forwardRef((props, ref) => (
  <MuiLink
    underline="none"
    {...props}
    ref={ref}
    component={RouterLink}
  />
));

export default Link;
