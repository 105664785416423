import { gql, useApolloClient } from '@apollo/client';
import { get, isEqual } from 'lodash';

const getErrorMessage = (testCriteria) => {
  const errors = [
    {
      criteria: { hasRelatedCustomers: true },
      message: 'Sales agent has no related customers',
    },
    {
      criteria: { hasValidRelatedCustomers: true },
      message: 'Sales agent has no valid related customers',
    }
  ];
  return errors.find((error) => isEqual(error.criteria, testCriteria))?.message;
};

const apolloClient = useApolloClient();

export const salesAgentValidator = (schema, jobName, sanaVersion, testCriteria) =>
  schema.test('validSalesAgent', (value, { createError }) => {
    if (!value || value === '-' || !sanaVersion.includes('SCC')) return true;

    const validationCriteria = { hasRelatedCustomers: true, hasValidRelatedCustomers: true, ...testCriteria };
    const queryFields = Object.keys(validationCriteria).join(" ");

    const getSalesAgentInfoQuery = gql`
    query getSalesAgentInfoQuery($jobName: String!, $salesAgentId: String!) {
      salesAgentInfo(jobName: $jobName, salesAgentId: $salesAgentId) {
        ${queryFields}   
      }      
    }`;

    return apolloClient
      .query({
        query: getSalesAgentInfoQuery,
        variables: {
          jobName,
          salesAgentId: value
        },
      })
      .then((result) => {
        var salesAgent = get(result, 'data.salesAgentInfo');
        if (salesAgent === null) {
          return createError({ message: 'Sales agent does not exist' });
        }

        for (const property in validationCriteria) {
          if (salesAgent[property] !== validationCriteria[property])
            return createError({
              message: getErrorMessage({ [property]: validationCriteria[property] }),
            });
        }
        return true;
      })
      .catch(() => true);
  });
