import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'components/form/builder/FormBuilder';
import get from 'lodash/get';
import FormContext from 'components/form/builder/FormBuilderContext';
import { schemaGenerator } from './schema';

function PaymentMethodsForm({
  jobName, configuration, handleSubmit,
}) {
  const parameters = get(configuration, 'parameters.Parameters.value') || {};

  const schema = useMemo(
    () => schemaGenerator(jobName, parameters),
    [jobName, parameters],
  );

  return (
    <FormContext.Provider value={parameters}>
      <FormBuilder
        schema={schema}
        onSubmit={handleSubmit}
      />
    </FormContext.Provider>
  );
}

PaymentMethodsForm.propTypes = {
  jobName: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    versionToken: PropTypes.string.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
};

export default PaymentMethodsForm;
