import { gql, useApolloClient } from '@apollo/client';
import { get } from 'lodash';

const getPaymentMethodQuery = gql`
  query getPaymentMethodQuery($jobName: String!, $name: String!, $providerId: String) {
    paymentMethod(jobName: $jobName, name: $name, providerId: $providerId)
      {
        name,
        moduleId,
        enabled
      }
    }
`;

const apolloClient = useApolloClient();

export const uniquePaymentMethodValidator = async (value, providerId, jobName, sanaVersion) => {
  if (!value || value === '-' || !sanaVersion.includes('SCC')) return true;
  return apolloClient.query({
    query: getPaymentMethodQuery,
    variables: {
      jobName,
      name: value,
      providerId,
    },
  })
    .then(result => get(result, 'data.paymentMethod'))
    .catch(() => true);
};
