import React, { memo, useState } from 'react';
import {
  Menu, MenuItem, Tooltip, IconButton, Link,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import ClickTrap from 'components/ClickTrap';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconLabel from 'components/IconLabel';
import PropTypes from 'prop-types';

function LogActions({ name }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleShowMoreClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e) => {
    if (e) e.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Actions">
        <IconButton size="small" onClick={e => handleShowMoreClick(e)}>
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <ClickTrap>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            className="download"
            component={Link}
            href={`/logs/${name}?download=true`}
            color="inherit"
          >
            <IconLabel icon={DownloadIcon}>
              Download
            </IconLabel>
          </MenuItem>
        </Menu>
      </ClickTrap>
    </>
  );
}

LogActions.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(LogActions);
