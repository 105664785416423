import { gql } from '@apollo/client';

const loadJobQuery = gql`
query loadUniqueJobName($jobName: String!) {
  job(name: $jobName) {
    name
  }
}`;

/**
 * validates job name against uniqueness
 * @param {import('yup').StringSchema} schema
 * @param {import('@apollo/client').ApolloClient} apollo
 */
// eslint-disable-next-line no-template-curly-in-string
export const createUniqueJobValidator = (schema, apollo) => schema.test('uniqueJob', 'The project with name "${value}" already exists',
  async (value) => {
    if (!value) return true;
    return apollo.query({
      query: loadJobQuery,
      variables: {
        jobName: value,
      },
      fetchPolicy: 'network-only',
    })
      .then(result => result && result.data && !result.data.job)
      .catch(() => false);
  });
