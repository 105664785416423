import React, {
  useCallback, useEffect, useMemo, useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Box, Toolbar } from '@material-ui/core';
import { countBy, includes } from 'lodash';
import ToggleChipGroup from 'components/ToogleChipGroup';
import TestStatus from './TestCaseStatus';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '5px',
    color: theme.palette.type === 'dark' ? 'white' : undefined,

    '& .MuiChip-deleteIconOutlinedColorPrimary': {
      color: theme.palette.type === 'dark' ? 'white' : undefined,
    },
  },
}));

const initialFilter = ['FAILED', 'SKIPPED', 'PASSED'];

function TestReportFilter({ tests, onFilterChanged }) {
  const classes = useStyles();

  const chipData = useMemo(() => [
    {
      label: 'Passed', value: 'PASSED', count: countBy(tests, v => v.status === 'PASSED').true,
    },
    {
      label: 'Skipped', value: 'SKIPPED', count: countBy(tests, v => v.status === 'SKIPPED').true,
    },
    {
      label: 'Failed', value: 'FAILED', count: countBy(tests, v => v.status === 'FAILED').true,
    },
  ], [tests]);
  const [selected, setSelected] = useState([]);

  const handleToggleChanged = useCallback((_, newSelected) => {
    setSelected(newSelected);

    if (onFilterChanged) {
      onFilterChanged((v) => {
        if (newSelected.length === 0) { return true; }
        return includes(newSelected, v.status);
      });
    }
  }, [setSelected, onFilterChanged]);

  useEffect(() => { handleToggleChanged(false, initialFilter); }, [tests, handleToggleChanged]);

  return (
    <Toolbar variant="dense">
      <ToggleChipGroup
        value={selected}
        onChange={handleToggleChanged}
      >
        {chipData.map(data => (
          <Chip
            key={data.value}
            icon={<TestStatus status={data.value} />}
            label={(
              <Box className={classes.box}>
                {data.label}
                {' - '}
                <span>{data.count || 0}</span>
              </Box>
          )}
            className={classes.chip}
            clickable
            value={data.value}
            color="primary"
          />
        ))}
      </ToggleChipGroup>
    </Toolbar>
  );
}

TestReportFilter.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string.isRequired,
  })),
  onFilterChanged: PropTypes.func,
};

export default memo(TestReportFilter);
