import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteTwoTone';
import MoreActionsButton from 'components/MoreActionsButton';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { DeleteJobDialog } from './dialogs';
import { RenameActionButton, DuplicateActionButton, EnableActionButton } from './actions';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '-5px',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

function JobActions({
  name, displayName, jobDisabled, lastBuild,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const handleDeleteButtonClick = () => {
    setDeleteJobDialogOpen(true);
  };
  const canModifyJob = !lastBuild || !lastBuild.building;

  return (
    <div className={classes.root}>
      <RenameActionButton
        name={name}
        displayName={displayName}
        disabled={!canModifyJob}
        className={classes.button}
        disabledReason={`Project cannot be renamed while run ${lastBuild && lastBuild.displayName} is in progress`}
      />
      <DuplicateActionButton
        name={name}
        displayName={displayName}
        className={classes.button}
      />
      <EnableActionButton
        name={name}
        disabled={!canModifyJob}
        className={classes.button}
        jobDisabled={jobDisabled}
        disabledReason={`Project cannot be disabled while run ${lastBuild && lastBuild.displayName} is in progress`}
      />
      <MoreActionsButton
        className={classes.button}
        items={[{
          key: 'delete',
          title: 'Delete',
          className: 'delete',
          onClick: handleDeleteButtonClick,
          icon: <DeleteIcon fontSize="small" />,
          disabled: !canModifyJob,
          disabledReason: `Project cannot be deleted while run ${lastBuild && lastBuild.displayName} is in progress`,
        }]}
      />
      <DeleteJobDialog
        name={name}
        displayName={displayName}
        open={deleteJobDialogOpen}
        setOpen={setDeleteJobDialogOpen}
        onDeleted={() => history.push('/projects')}
      />
    </div>
  );
}

export const jobActionsFragment = gql`
  fragment jobActionsFragment on Job {
    name
    displayName
    jobDisabled: disabled
    lastBuild {
      id
      building
      displayName
    }
  }
`;

JobActions.propTypes = {
  name: PropTypes.string.isRequired,
  jobDisabled: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
  lastBuild: PropTypes.shape({
    building: PropTypes.bool.isRequired,
    displayName: PropTypes.string,
  }),
};

export default memo(JobActions);
