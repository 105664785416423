import React from 'react';
import PropTypes from 'prop-types';
import { authProvider } from 'util/auth';
import { gql, useQuery } from '@apollo/client';
import { MsalProvider } from '@azure/msal-react';
import isNetworkError from 'util/apollo/isNetworkError';
import Page500 from 'pages/Page500';

const loadAppModelQuery = gql`
  query loadAppModel {
    app {
      id
      authentication {
        enabled
        clientId
        instance
        tenantId
        redirectUri
      }
    }
  }
`;

function AuthenticationProvider({ children }) {
  const { data, error, loading } = useQuery(loadAppModelQuery, {
    fetchPolicy: 'cache-first',
  });

  if (error && isNetworkError(error)) {
    return <Page500 error={error} />;
  }

  if (loading || !data) { return null; }

  const { app: { authentication } } = data;

  if (!authentication.enabled) { return children; }

  return (
    <MsalProvider instance={authProvider(data.app.authentication)}>
      {children}
    </MsalProvider>
  );
}

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticationProvider;
