import { gql } from '@apollo/client';

export const buildFragment = gql`
  fragment buildFragment on Build {
    id
    displayName
    result
    building
    duration,
    timestamp
    completed
    estimatedDuration
    number
    cancelling
  }
`;

export const getActiveBuildsQuery = gql`
  query getActiveBuilds {
    activeBuilds {
      id,
      number,
      displayName
    }
  }
`;

export const loadBuildQuery = gql`
  query loadBuild($jobName: String!, $buildNumber: Int!) {
    job(name: $jobName) {
      name
      build(buildNumber: $buildNumber) {
        ...buildFragment
        launchInfo {
          reportPortalId,
          reportPortalUrl
        },
        parameters(keys: ["TestListRerun", "StartedBy"]) {
          name,
          value
        }
      }    
    }
  }
  ${buildFragment}
`;
