import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlayCircleOutlineTwoToneIcon from '@material-ui/icons/PlayCircleOutlineTwoTone';
import { useStartBuildMutation } from 'behaviour/builds';
import { gql } from '@apollo/client';
import useBridgeAvailability from 'behaviour/jobs/useBridgeAvailability';
import Tooltip from 'components/Tooltip';
import useWebshopAvailability from 'behaviour/jobs/useWebshopAvailability';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
}));

function formatButtonText(inQueue = false, running = false) {
  if (inQueue) return 'In a queue';
  if (running) return 'In progress';

  return undefined;
}

export const buildJobButtonFragment = gql`
  fragment buildJobButtonFragment on Job {
    jobName: name,
    buildable,
    inQueue
    lastBuild {
      id
      building
    }
  }
`;

function BuildJobButton({
  jobName,
  packageVersion,
  buildable = true,
  inQueue = false,
  lastBuild = null,
  buttonText = 'Run',
  buttonIcon = PlayCircleOutlineTwoToneIcon,
}) {
  const classes = useStyles();
  const ButtonIcon = buttonIcon;
  const startBuildMutation = useStartBuildMutation();
  const startBuild = useCallback(
    () => startBuildMutation(jobName),
    [jobName, startBuildMutation],
  );
  const running = (lastBuild && lastBuild.building);

  const { isBridgeAvailable } = useBridgeAvailability({
    jobName,
    sanaVersion: packageVersion,
  });

  const { isWebshopAvailable } = useWebshopAvailability({
    jobName,
    sanaVersion: packageVersion
  });

  const getTooltipText = () => {
    if (!buildable) return "Project is not builable";
    if (running) return "Project is already running";
    if (inQueue) return "Project is in the run queue";
    if (isBridgeAvailable === false) return "Bridge is not available";
    if (isWebshopAvailable === false) return "Webshop is unavailable";
    return '';
  };

  return (
    <div className={classes.wrapper}>
      <Tooltip title={getTooltipText()}>
        <span>
          <Button
            onClick={startBuild}
            className="runBuild"
            disabled={!buildable || running || inQueue || isBridgeAvailable === false || isWebshopAvailable === false}
            startIcon={<ButtonIcon />}
            endIcon={inQueue && <CircularProgress size={24} />}
          >
            {formatButtonText(inQueue, running) || buttonText}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

BuildJobButton.propTypes = {
  jobName: PropTypes.string.isRequired,
  packageVersion: PropTypes.string,
  buildable: PropTypes.bool,
  inQueue: PropTypes.bool,
  lastBuild: PropTypes.shape({
    building: PropTypes.bool.isRequired,
  }),
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default memo(BuildJobButton);
