import tokenStorage from 'util/tokenStorage';
import { setContext } from 'apollo-link-context';

export const authLink = setContext((_, { headers }) => {
  const token = tokenStorage.read();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});
