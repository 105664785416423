import React, { useEffect, useState } from 'react';
import prettyMilliseconds from 'pretty-ms';
import PropTypes from 'prop-types';

function roundToSeconds(timestamp) {
  return Math.round(timestamp / 1000) * 1000;
}

function Duration({ duration, live = false, reversed = false }) {
  const [value, setValue] = useState(roundToSeconds(duration));
  const intervalMs = 1000;

  useEffect(() => {
    function progress() {
      if (reversed) {
        setValue(oldValue => oldValue - intervalMs);
      } else {
        setValue(oldValue => oldValue + intervalMs);
      }
    }

    if (live) {
      const timer = setInterval(progress, intervalMs);
      return () => {
        clearInterval(timer);
      };
    }

    return undefined;
  }, [duration, live, reversed]);

  const format = value
    ? prettyMilliseconds(value, {
      verbose: true,
    })
    : '0';

  return (
    <span>{format}</span>
  );
}

Duration.propTypes = {
  duration: PropTypes.number.isRequired,
  live: PropTypes.bool,
  reversed: PropTypes.bool,
};

export default Duration;
