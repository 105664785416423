import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useHover } from '../util/useHover';

function Tooltip({ children, className = null, ...props }) {
  const [buttonRef, isHovered] = useHover();

  return (
    <span ref={buttonRef} className={className}>
      {!isHovered && children}
      {isHovered
      && (
      <MuiTooltip {...props}>
        {children}
      </MuiTooltip>
      ) }
    </span>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Tooltip;
