import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  info: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
}));

function PageLayout({ header, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        {header}
      </div>
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </div>
  );
}

export default memo(PageLayout);

PageLayout.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};
