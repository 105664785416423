import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { closeSnackbar } from 'behaviour/notifications';

function FormAlert({ notifications, dirty, closeAlert }) {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    setAlerts(notifications.filter(n => get(n, 'options.type') === 'alert' && get(n, 'dismissed') !== true));
  }, [notifications]);

  useEffect(() => {
    if (dirty) closeAlert();
  }, [dirty]);

  if (alerts.length === 0) return null;

  return alerts.map(alert => (
    <Alert
      key={alert.key}
      severity={alert.options.variant}
      style={{ marginBottom: '24px' }}
      action={alert.options.action(alert.key)}
    >
      {alert.message}
    </Alert>
  ));
}

FormAlert.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    options: PropTypes.shape({
      variant: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
      type: PropTypes.oneOf(['alert']),
      action: PropTypes.func,
    }),
  })).isRequired,
};

const mapStateToProps = state => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps, { closeAlert: closeSnackbar })(FormAlert);
