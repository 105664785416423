import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/StarRounded';
import StarBorderIcon from '@material-ui/icons/StarBorderRounded';
import { makeStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import Tooltip from 'components/Tooltip';
import clsx from 'clsx';
import { useFavoritesBehavior } from 'behaviour/favorites';

const useClasses = makeStyles(() => ({
  button: {
    padding: 0,
  },
  icon: {
    color: yellow[800],
  },
}));

function FavoriteButton({ jobName, readonly = false, ...props }) {
  const classes = useClasses();
  const { addFavorite, removeFavorite, favorite } = useFavoritesBehavior(jobName);

  const onToggleClick = useCallback((e) => {
    e.preventDefault();
    if (readonly) { return; }

    if (favorite) {
      removeFavorite();
    } else {
      addFavorite();
    }
  }, [favorite, removeFavorite, addFavorite, readonly]);

  return (
    <Tooltip
      title={favorite ? 'Remove favorite' : 'Favorite'}
      {...props}
      disableFocusListener={readonly}
      disableHoverListener={readonly}
      disableTouchListener={readonly}
    >
      <IconButton
        className={classes.button}
        onClick={onToggleClick}
        disabled={readonly}
      >
        {favorite && <StarIcon className={clsx(classes.icon, 'removeFavorite')} />}
        {!favorite && <StarBorderIcon className={clsx(classes.icon, 'addFavorite')} />}
      </IconButton>
    </Tooltip>
  );
}

FavoriteButton.propTypes = {
  jobName: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
};

export default memo(FavoriteButton);
