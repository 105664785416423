import { useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { get } from 'lodash';
import { getJobId } from 'util/apollo';
import { indexingRunFragment, indexingTaskFragment } from './queries';

const runTestDataIndexingMutation = gql`
  mutation startTestDataIndexing($jobName:String!, $testDataToIndex: [String]!) {
    job(jobName: $jobName) {
      startTestDataIndexing(testDataToIndex: $testDataToIndex) {
        ...indexingRunFragment
      }
    }
  }
  ${indexingRunFragment}
`;

const useStartTestDataIndexingMutation = () => {
  const apolloClient = useApolloClient();

  return useCallback(
    (jobName, testDataFetchKeys) => {
      return apolloClient.mutate({
        mutation: runTestDataIndexingMutation,
        variables: {
          jobName,
          testDataToIndex: testDataFetchKeys
        },
        update: (cache, result) => {
          const run = get(result, 'data.job.startTestDataIndexing');
          apolloClient.writeFragment({
            id: getJobId(cache, jobName),
            fragment: indexingTaskFragment,
            fragmentName: 'indexingTaskFragment',
            data: {
              __typename: 'Job',
              name: jobName,
              indexingTask: {
                __typename: 'IndexingTask',
                isRunning: true,
                runs: [{ ...run }]
              },
            },
          });
        },
      })
    }, [apolloClient]);
};

export default useStartTestDataIndexingMutation;
