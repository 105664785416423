import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PageTitle from 'components/PageTitle';
import { Grid } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { getTraceIdentifier, isNetworkError } from 'util/apollo';
import { get } from 'lodash';
import { InternalServerErrorMessage } from 'components/errorStates';
import { IndexingRunList } from 'components/testDataIndexing';
import { indexingRunListFragment } from 'components/testDataIndexing/IndexingRunList';

const loadIndexingHistoryQuery = gql`
  query loadIndexingHistory($name: String!) {
    job (name: $name) {
      name
      indexingTask {
        isRunning
        runs {
          id
          ...indexingRunListFragment          
        }
      }   
    }
  }
  ${indexingRunListFragment}
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

function IndexingHistoryPage() {
  const classes = useStyles();
  const { jobName } = useParams();
  const { data, loading, error } = useQuery(loadIndexingHistoryQuery, {
    variables: {
      name: jobName,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const runs = get(data, 'job.indexingTask.runs', []);

  if (error && isNetworkError(error)) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  return (
    <>
      <PageTitle title="Test data indexing history" />
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <IndexingRunList
              runs={runs}
              loading={loading}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default IndexingHistoryPage;
