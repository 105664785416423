import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'components/PageHeader';
import IconLabel from 'components/IconLabel';
import MuiLink from '@material-ui/core/Link';
import { Tooltip } from '@material-ui/core';
import { gql } from '@apollo/client';
import { ReportPortalIcon } from 'components/icons';
import BuildSummary, { buildSummaryFragment } from './BuildSummary';
import CancelRunButton, { cancelRunButtonFragment } from './CancelRunButton';
import BuildBadges, { buildBadgesFragment } from './BuildBadges';

function BuildHeader({ build }) {
  return (
    <PageHeader
      title={`Run #${build.number}`}
      subTitle={
        build.launchInfo && (
          <IconLabel icon={ReportPortalIcon}>
            <Tooltip title={`Open launch #${build.launchInfo.reportPortalId} in Report Portal`}>
              <MuiLink
                color="inherit"
                href={`${build.launchInfo.reportPortalUrl}`}
                rel="noopener noreferrer"
                target="_blank"
                className="reportPortalLink"
              >
                View in Report Portal
              </MuiLink>
            </Tooltip>
          </IconLabel>
        )
      }
      tags={<BuildBadges build={build} />}
      extra={build && (
        <CancelRunButton {...build} />
      )
      }
    >
      <BuildSummary build={build} />
    </PageHeader>
  );
}

export const buildHeaderFragment = gql`
  fragment buildHeaderFragment on Build {
    result
    number
    building
    cancelling
    launchInfo {
      reportPortalId
      reportPortalUrl
    }
    job {
      name
    }
    ...buildBadgesFragment
    ...buildSummaryFragment
    ...cancelRunButtonFragment
  }
  ${buildBadgesFragment}
  ${buildSummaryFragment}
  ${cancelRunButtonFragment}
`;

function Skeleton(props) {
  return (
    <PageHeader.Skeleton {...props} />
  );
}

BuildHeader.propTypes = {
  build: PropTypes.shape({
    number: PropTypes.number.isRequired,
    result: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    building: PropTypes.bool.isRequired,
    estimatedDuration: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    job: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    launchInfo: PropTypes.shape({
      reportPortalUrl: PropTypes.string,
      reportPortalId: PropTypes.string,
    }),
    cancelling: PropTypes.bool,
  }).isRequired,
};

BuildHeader.Skeleton = Skeleton;

export default BuildHeader;
