import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { Switch, Route } from 'react-router';
import LoginPage from 'pages/LoginPage';
import Notifier from 'components/Notifier';
import { useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import createTheme from './theme';

function AppBase({ store }) {
  const darkMode = useSelector(s => s.app.darkMode);
  const appTheme = useMemo(() => createTheme(darkMode), [darkMode]);

  // eslint-disable-next-line global-require
  const App = require('./App').default;

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Notifier />
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route render={() => <App store={store} />} />
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

AppBase.propTypes = {
  store: PropTypes.shape({
    app: PropTypes.shape({
      darkMode: PropTypes.bool,
    }),
  }),
};

export default AppBase;
