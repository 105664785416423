import React from 'react';
import { get } from 'lodash';
import Page404 from 'pages/Page404';
import Page500 from 'pages/Page500';
import BuildDetails, { buildDetailsFragment } from 'components/jobBuild/BuildDetails';
import { useQuery, gql } from '@apollo/client';
import { isNetworkError } from 'util/apollo';

const loadBuildQuery = gql`
  query loadBuildDetails($jobName: String!, $buildNumber: Int!) {
    job(name: $jobName) {
      name
      build(buildNumber: $buildNumber) {
        id
        ...buildDetailsFragment
      }
    }
  }
  ${buildDetailsFragment}
`;

function JobRunPage(props) {
  const jobName = get(props, 'match.params.jobName');
  const buildNumber = parseInt(get(props, 'match.params.buildNumber'), 10);
  const { data, loading, error } = useQuery(loadBuildQuery, {
    variables: {
      jobName,
      buildNumber,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: Number.isNaN(buildNumber),
  });

  if (error && isNetworkError(error)) {
    return <Page500 error={error} />;
  }

  const build = get(data, 'job.build');
  if (!loading && (!build || Number.isNaN(buildNumber))) {
    return <Page404 />;
  }

  return (
    <BuildDetails
      jobName={jobName}
      build={build || { job: { name: jobName }, number: buildNumber }}
      buildNumber={buildNumber}
      loading={loading}
    />
  );
}

export default JobRunPage;
