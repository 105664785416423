import * as Yup from 'yup';
import { makeSchema } from 'components/form/builder';
import { getValue } from 'util/getValue';

export const schemaGenerator = parameters => makeSchema({
  sections: [{
    title: 'New customer account data',
    description: 'Data used for customer and prospect registration, guest checkout and custom shipping address.',
    inputs: [{
      name: 'newCustomerAccount_city',
      alias: 'NewCustomerAccount.City',
      label: 'City',
      defaultValue: getValue(parameters.newCustomerAccount_city, 'City'),
      validate: Yup.string().required(),
    },
    {
      name: 'newCustomerAccount_address',
      alias: 'NewCustomerAccount.Address',
      label: 'Address',
      defaultValue: getValue(parameters.newCustomerAccount_address, 'Address'),
      validate: Yup.string().required(),
    },
    {
      name: 'newCustomerAccount_address2',
      alias: 'NewCustomerAccount.Address2',
      label: 'Address 2',
      defaultValue: getValue(parameters.newCustomerAccount_address2, 'Address 2'),
      validate: Yup.string().required(),
    },
    {
      name: 'newCustomerAccount_phoneNumber',
      alias: 'NewCustomerAccount.PhoneNumber',
      label: 'Phone number',
      defaultValue: getValue(parameters.newCustomerAccount_phoneNumber, 'Phone no'),
      validate: Yup.string().required(),
    },
    {
      name: 'newCustomerAccount_zipCode',
      alias: 'NewCustomerAccount.ZipCode',
      label: 'Zip code',
      defaultValue: getValue(parameters.newCustomerAccount_zipCode, '12345'),
      validate: Yup.string().required(),
    },
    {
      name: 'newCustomerAccount_faxNo',
      alias: 'NewCustomerAccount.FaxNo',
      label: 'Fax no',
      defaultValue: getValue(parameters.newCustomerAccount_faxNo, '222222'),
      validate: Yup.string().required(),
    },
    {
      name: 'newCustomerAccount_taxNo',
      alias: 'NewCustomerAccount.TaxNo',
      label: 'Tax no',
      defaultValue: getValue(parameters.newCustomerAccount_taxNo, '3333aa'),
      validate: Yup.string().required(),
    }],
  }],
});
