import { gql } from "@apollo/client";

export const indexingRunFragment = gql`
  fragment indexingRunFragment on IndexingRun {
    id
    number
    startedAt
    duration
    completed
    exceptionDetails 
    status   
  }
`;

export const getIndexingRunQuery = gql`
  query getIndexingRun($jobName: String!, $runId: String!) {
    job(name: $jobName) {
      name,
      indexingTask {
        run(runId: $runId) {
          ...indexingRunFragment
        }
      }
    }
  }
  ${indexingRunFragment}
`;

export const indexingTaskFragment = gql`
  fragment indexingTaskFragment on Job {
    name,
    indexingTask {
      isRunning
      runs {
        ...indexingRunFragment   
      }
    }
  }
  ${indexingRunFragment}
`;
