import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

const calculatePercentage = (totalCountToPrefill, currentInputIndex) => (currentInputIndex / totalCountToPrefill) * 100;

function PrefillProgressIndicator({ totalCountToPrefill, currentInputIndex = 0 }) {
  const [completed, setCompleted] = useState(calculatePercentage(totalCountToPrefill, currentInputIndex));

  useEffect(() => {
    setCompleted(calculatePercentage(totalCountToPrefill, currentInputIndex));
  }, [currentInputIndex, totalCountToPrefill]);

  return <LinearProgress variant="determinate" value={completed} className="prefillProgress" />;
}

PrefillProgressIndicator.propTypes = {
  totalCountToPrefill: PropTypes.number.isRequired,
  currentInputIndex: PropTypes.number.isRequired
};

export default memo(PrefillProgressIndicator);
