import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PageTitle from 'components/PageTitle';
import { JobList, useJobListRoute } from 'components/jobList';
import { RecentBuilds } from 'components/recentBuildList';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
  container: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  mainContent: {
    width: '100%',
  },
  rightContent: {
    width: '100%',
  },
  title: {
    paddingRight: theme.spacing(1),
  },
  recentBuilds: {
    padding: theme.spacing(2),
    position: 'sticky',
    top: theme.spacing(2),
  },
}));

function JobListPage() {
  const classes = useStyles();
  const routeProps = useJobListRoute({});

  return (
    <>
      <PageTitle title="Projects" />
      <div className={classes.root}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item lg={9} className={classes.mainContent}>
            <JobList jobListProps={routeProps} />
          </Grid>
          <Grid item lg={3} className={classes.rightContent}>
            <RecentBuilds className={classes.recentBuilds} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withRouter(JobListPage);
