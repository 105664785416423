import { isNil } from 'lodash';

export const extendSchema = (schema, modifiers = []) => {
  let resultSchema = schema;
  modifiers.forEach((modify) => {
    resultSchema = modify(resultSchema);
    if (isNil(resultSchema)) {
      throw new Error(`Schema modification function '${modify.name}' does not return schema.`);
    }
  });
  return resultSchema;
};
