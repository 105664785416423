import React from 'react';
import Warning from 'components/Warning';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  }
}));

function WebshopUnavailableAlert() {
  const classes = useStyles();

  return (
    <Warning severity='error' className={classes.root}>
      Connection with Sana webshop cannot be established. Recheck website url.
    </Warning>
  );
}

export default WebshopUnavailableAlert;
