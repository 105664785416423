import { gql, useApolloClient } from '@apollo/client';
import { get, isEqual } from 'lodash';

const getErrorMessage = (testCriteria, product) => {
  const errors = [
    {
      criteria: { isVisible: true },
      message: 'Product is not visible',
    },
    {
      criteria: { isOrderable: true },
      message: 'Product is not orderable',
    },
    {
      criteria: { isBlocked: false },
      message: 'Product is blocked',
    },
    {
      criteria: { hasVariants: true },
      message: 'Product has no variants',
    },
    {
      criteria: { hasVariants: false },
      message: 'Product has variants, but expected product without variants',
    },
    {
      criteria: { hasAttachments: true },
      message: 'Product has no attachments in ERP',
    },
    {
      criteria: { hasWeight: true },
      message: `Product has weight ${product.weight}, but expected product with weight > 0 and < 100000`,
    },
    {
      criteria: { hasLength: true },
      message: 'Product has no length',
    },
    {
      criteria: { hasWidth: true },
      message: 'Product has no width',
    },
    {
      criteria: { hasHeight: true },
      message: 'Product has no height',
    },
    {
      criteria: { hasMaterialsFlag: true },
      message: 'Product has "HasMaterials" set to false in ERP, but expected to be true',
    },
    {
      criteria: { hasMaterials: true },
      message: 'Product has no materials',
    },
    {
      criteria: { hasStock: false },
      message: `Product has stock ${product.stock}, but expected product with 0 inventory`,
    },
    {
      criteria: { hasSimpleVariants: true },
      message: `Product has ${product.variantComponentsCount} variant components, but expected product with 1 variant component`,
    },
    {
      criteria: { hasComplexVariants: true },
      message: `Product has ${product.variantComponentsCount} variant component(s), but expected product with 2 variant components`,
    },
    {
      criteria: { hasMoreThanTwoVariantComponents: true },
      message: `Product has ${product.variantComponentsCount} variant component(s), but expected product with more than 2 variant components`,
    },
    {
      criteria: { hasUomSelection: true },
      message: `Product has ${product.uomsCount} Uom(s), but expected product with 2 or more Uoms`,
    }
  ];
  return errors.find((error) => isEqual(error.criteria, testCriteria))?.message;
};

const getProductInfoQuery = gql`
  query getProductInfoQuery($jobName: String!, $productId: String!) {
    productInfo(jobName: $jobName, productId: $productId) {
      isVisible
      isOrderable
      isBlocked                        
      hasVariants
      hasSimpleVariants
      hasComplexVariants
      hasMoreThanTwoVariantComponents
      variantComponentsCount
      hasUomSelection
      uomsCount
      hasWeight
      weight
      hasWidth
      hasLength
      hasHeight
      hasMaterialsFlag
      hasMaterials
      hasAttachments                  
      hasStock
      stock
    }
  }
`;

const apolloClient = useApolloClient();

export const productValidator = (schema, jobName, sanaVersion, testCriteria) =>
  schema.test('validProduct', (value, { createError }) => {
    if (!value || value === '-' || !sanaVersion.includes('SCC')) return true;

    const validationCriteria = { isVisible: true, isBlocked: false, isOrderable: true, ...testCriteria };

    return apolloClient
      .query({
        query: getProductInfoQuery,
        variables: {
          jobName,
          productId: value,
        },
      })
      .then((result) => {
        var product = get(result, 'data.productInfo');
        if (product === null) {
          return createError({ message: 'Product does not exist' });
        }

        for (const property in validationCriteria) {
          if (product[property] !== validationCriteria[property])
            return createError({
              message: getErrorMessage({ [property]: validationCriteria[property] }, product),
            });
        }
        return true;
      })
      .catch(() => true);
  });
