import React from 'react';
import { Field } from 'formik';
import { Box } from '@material-ui/core';
import { Switch } from 'components/form';

function SwitchEditor(props) {
  return (
    <Box paddingBottom={1}>
      <Field
        {...props}
        component={Switch}
      />
    </Box>
  );
}

export default SwitchEditor;
