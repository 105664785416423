import type { AppState } from './types';
import { SIGNALR_CONNECTION_STATUS, LOADING, SWITCH_DARK_MODE, AppAction } from './actions';

const initialState: AppState = {
  liveUpdates: false,
  loading: false,
  darkMode: false,
};

export default function reducer(state = initialState, action: AppAction): AppState {
  switch (action.type) {
    case SIGNALR_CONNECTION_STATUS:
      return {
        ...state,
        liveUpdates: action.payload.connected,
      };
    case LOADING: return {
      ...state,
      loading: action.payload.loading,
    };
    case SWITCH_DARK_MODE: return {
      ...state,
      darkMode: action.payload.darkMode,
    };
    default:
      return state;
  }
}
