import { gql, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useNotifications } from 'behaviour/notifications';
import { loadingChanged } from 'behaviour/app/actions';
import { useDispatch } from 'react-redux';

const enableJobMutation = gql`
  mutation enableJob($jobName: String!, $enable: Boolean!) {
    job(jobName: $jobName) {
      enable(enable: $enable) {
        name,
        disabled,
        buildable,
        configuration {
          versionToken
        }
      }
    }
  }
`;

export default function useEnableJobMutation() {
  const apolloClient = useApolloClient();
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();

  return useCallback(
    (jobName, enable) => {
      dispatch(loadingChanged(true));
      return apolloClient.mutate({
        mutation: enableJobMutation,
        variables: {
          jobName,
          enable,
        },
      }).catch(() => {
        addNotification({
          message: `Could not ${enable ? 'enable' : 'disable'} project ${jobName}`,
          options: {
            variant: 'error',
          },
        });
      }).finally(() => {
        dispatch(loadingChanged(false));
      });
    },
    [apolloClient, addNotification, dispatch],
  );
}
