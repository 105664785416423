import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';

function TableRowSkeleton({ columns, cellStyle = {} }) {
  const template = Array.from(Array(columns), (_, i) => i);

  return (
    <TableRow>
      {template.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableCell key={index} style={cellStyle}>
          <Skeleton variant="text" width="60%" />
        </TableCell>
      ))}
    </TableRow>
  );
}

TableRowSkeleton.propTypes = {
  columns: PropTypes.number.isRequired,
  cellStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default TableRowSkeleton;
