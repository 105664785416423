import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { find } from 'lodash';
import { gql } from '@apollo/client';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
  },
}));

function BuildBadges({ build, ...props }) {
  const classes = useStyles();
  const { parametersWithTestList } = build;
  const testListRerunParameter = parametersWithTestList && find(parametersWithTestList, p => p.name === 'TestListRerun' && p.value !== '{}');

  return (
    <>
      { testListRerunParameter && (
      <Box className={classes.root}>
        <Chip size="small" label="Replay" color="secondary" className="labelReplay" {...props} />
      </Box>
      )}
    </>
  );
}

export const buildBadgesFragment = gql`
fragment buildBadgesFragment on Build {
  parametersWithTestList: parameters(keys: ["TestListRerun"]) {
    name
    value
  }
}
`;

BuildBadges.propTypes = {
  build: PropTypes.shape({
    parametersWithTestList: PropTypes.arrayOf(
      PropTypes.shape().isRequired,
    ).isRequired,
  }),
};

export default BuildBadges;
