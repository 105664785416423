import { useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { getJobId } from 'util/apollo';
import { useNotifications } from 'behaviour/notifications';
import { get } from 'lodash';

const replayBuildMutation = gql`
  mutation replayBuild($jobName:String!, $buildNumber:Int!, $testIds:[String!]!) {
    job(jobName: $jobName) {
      build(buildNumber: $buildNumber) {
        replay(testIds:$testIds) {
          queueItem {
            id
          }
        }
      }
    }
  }
`;

export const useReplayBuildMutation = () => {
  const client = useApolloClient();
  const { addNotification } = useNotifications();
  return useCallback(({ jobName, buildNumber, testIds }) => client.mutate({
    mutation: replayBuildMutation,
    variables: {
      jobName,
      buildNumber,
      testIds,
    },
    update: (cache, result) => {
      const queueId = get(result, 'data.job.build.replay.queueItem.id');
      if (queueId) {
        cache.modify({
          id: getJobId(cache, jobName),
          fields: {
            inQueue() {
              return true;
            },
          },
        });
        addNotification({
          message: `The project ${jobName} is added to queue.`,
        });
      }
    },
  }).catch(() => {
    addNotification({
      message: `The project ${jobName} cannot be started because of server error.`,
      options: {
        variant: 'error',
      },
    });
  }), [client, addNotification]);
};
