/* eslint-disable max-len */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { useLocation } from 'react-router';
import { find } from 'lodash';
import { isActiveLocation } from 'util/routes';
import Tabs from './Tabs';
import Tab from './Tab';

function LinkTab(tabProps) {
  const { exact, ...other } = tabProps;
  return (
    <Tab
      component={Link}
      underline="none"
      {...other}
    />
  );
}

export default function RoutingTabs({ tabs, className = '', ...props }) {
  const location = useLocation();

  const isActiveTab = useCallback(
    (to, exact) => isActiveLocation(to, location, {
      exact: exact === undefined ? true : exact,
    }), [location],
  );

  const selectedTab = find(tabs, t => isActiveTab(t.to, t.exact)) || tabs[0];

  return (
    <Tabs value={selectedTab && selectedTab.value} {...props}>
      {tabs.map(tab => <LinkTab {...tab} key={tab.value} disabled={tab.disabled || false} className={className} />) }
    </Tabs>
  );
}

RoutingTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    disabled: PropTypes.bool,
  })),
  className: PropTypes.string,
};
