import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'components/markdown';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PageTitle from 'components/PageTitle';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  article: {
    padding: theme.spacing(3),
  },
}));

function Article({ title, content, loading = false }) {
  const classes = useStyles();

  if (loading) {
    return (
      <Paper component="article" className={classes.article}>
        <Skeleton variant="text" width={150} height={44} />
        {[200, 240, 160, 50, 180, 245].map(width => <Skeleton key={width} variant="text" width={width} height={20} />)}
      </Paper>
    );
  }

  return (
    <>
      <PageTitle title={`Knowledge base - ${title}`} />
      <Paper component="article" className={classes.article}>
        <Markdown>{content}</Markdown>
      </Paper>
    </>
  );
}

Article.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  loading: PropTypes.bool,
};

export default Article;
