import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withAuthentication } from 'components/authentication';
import { Helmet } from 'react-helmet';
import AppBar from './components/AppBar';
import CreateJobPage from './pages/CreateJobPage/page';
import KnowledgeBasePage from './pages/KnowledgeBasePage';
import JobListPage from './pages/JobListPage';
import JobPage from './pages/JobPage';
import JobRunPage from './pages/JobRunPage';
import Page404 from './pages/Page404';
import BreadCrumbs from './components/BreadCrumbs';
import AppLoader from './components/AppLoader';
import ScrollToTop from './components/ScrollToTop';
import MaintenanceAlert from './components/MaintenanceAlert';
import LogListPage from './pages/LogListPage';
import IndexingHistoryPage from 'pages/IndexingHistoryPage';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  pageContent: {
    marginTop: '15px',
  },
}));

function App() {
  const classes = useStyles();

  return (
    <>
      <Helmet titleTemplate="%s - Automation Hub" defaultTitle="Automation Hub" />
      <div className={classes.root}>
        <ScrollToTop />
        <AppBar />
        <AppLoader />
        <MaintenanceAlert />
        <Container maxWidth="lg" className={classes.pageContent}>
          <BreadCrumbs homeTitle="Home" />
          <Switch>
            <Route path="/createProject" exact component={CreateJobPage} />
            <Route path="/projects" exact component={JobListPage} />
            <Route
              path={[
                '/projects/:jobName',
                '/projects/:jobName/settings',
                '/projects/:jobName/scheduling',
              ]}
              component={JobPage}
              exact
            />
            <Route path="/projects/:jobName/testData/indexing" component={IndexingHistoryPage} />
            <Route path="/projects/:jobName/testData" component={JobPage} />
            <Route path="/projects/:jobName/:buildNumber" component={JobRunPage} />
            <Route path="/logs" exact component={LogListPage} />
            <Redirect from="/" to="/projects" exact />
            <Route component={Page404} />
          </Switch>
        </Container>
      </div>
    </>
  );
}

export default withAuthentication(App);
