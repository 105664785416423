import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import BuildStatus from 'components/jobBuild/BuildStatus';
import Link from 'components/Link';
import FavoriteButton from 'components/FavoriteButton';
import JobBadges, { jobBadgesFragment } from 'components/job/JobBadges';
import clsx from 'clsx';
import { gql } from '@apollo/client';
import TimeAgo from '../TimeAgo';
import JobActions, { jobListActionsFragment } from './JobActions';

const useStyles = makeStyles(theme => ({
  title: {
    flex: '0 0 auto',
  },
  tableRow: {
    display: 'table-row',
    outline: 'none',
    textDecoration: 'none',
    transition: 'background 80ms linear',
    verticalAlign: 'middle',
  },
  titleColumn: {
    [theme.breakpoints.up('md')]: {
      minWidth: '35em',
    },
  },
  lastRunColumn: {
    [theme.breakpoints.up('md')]: {
      minWidth: '8em',
    },
    paddingRight: theme.spacing(1),
  },
  listMenuIcon: {
    minWidth: '30px',
  },
  favoriteButton: {
    marginLeft: theme.spacing(1),
  },
}));

function formatDate(value) {
  if (!value) { return null; }

  const date = new Date(value);
  return <TimeAgo date={date} />;
}

function JobItem({ job }) {
  const classes = useStyles();
  const {
    name, displayName, lastBuild, packageVersion,
  } = job;

  return (
    <Link
      color="inherit"
      to={`/projects/${displayName}`}
      key={name}
      className={`MuiTableRow-root MuiTableRow-hover ${classes.tableRow}`}
      underline="none"
      role="row"
      tabIndex={-1}
    >
      <TableCell padding="normal" className="status">
        <BuildStatus {...lastBuild} />
      </TableCell>
      <TableCell padding="normal" className={clsx(classes.titleColumn, 'name')}>
        <span>{displayName}</span>
        <FavoriteButton
          className={classes.favoriteButton}
          jobName={displayName}
        />
        <JobBadges {...job} size="small" />
      </TableCell>
      <TableCell align="left" padding="none" className={clsx(classes.lastRunColumn, 'lastRunTime')}>
        {formatDate(lastBuild && lastBuild.timestamp)}
      </TableCell>
      <TableCell align="left" padding="none" className="version">{packageVersion}</TableCell>
      <TableCell align="left" padding="none" className="actions">
        <JobActions {...job} />
      </TableCell>
    </Link>
  );
}

export const jobListItemFragment = gql`
  fragment jobListItemFragment on Job {
    name
    displayName 
    packageVersion
    lastBuild {
      id
      result
      timestamp
    }
    ...jobBadgesFragment
    ...jobListActionsFragment
  }
  ${jobBadgesFragment}
  ${jobListActionsFragment}
`;

JobItem.propTypes = {
  job: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    lastBuild: PropTypes.shape({
      result: PropTypes.string,
      timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    packageVersion: PropTypes.string,
  }),
};

export default memo(JobItem);
