import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({
  typographer: true,
  breaks: true,
});

export default function useMarkdown(text) {
  if (!text) { return null; }

  return md.render(text);
}
