import { find, orderBy as sortBy, get } from 'lodash';

function defaultSortable(items, orderBy, ascending) {
  return sortBy(items, [item => get(item, orderBy)], [ascending ? 'asc' : 'desc']);
}

export default function fetchMore({
  items,
  page,
  count,
  orderBy,
  ascending,
  columns,
}) {
  const column = find(columns, c => c.id === orderBy);
  let sortable = (column && column.sortable);
  if (typeof sortable !== 'function') {
    sortable = defaultSortable;
  }

  return {
    items: sortable(items, orderBy, ascending)
      .slice(page * count, page * count + count),
    totalCount: items.length,
  };
}
