import {
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import tokenStorage from 'util/tokenStorage';

export default function createHubConnection() {
  const protocol = new JsonHubProtocol();

  // let transport to fall back to to LongPolling if it needs to
  const transport = HttpTransportType.WebSockets;

  const options = {
    transport,
    accessTokenFactory: () => tokenStorage.read(),
  };

  // the url is hard coded because otherwise
  // webpack websocket connection conflicting with signalR websocket.
  const connectionHub = '/api/notification/hub';

  return new HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .withHubProtocol(protocol)
    .build();
}
