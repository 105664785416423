import * as Yup from 'yup';
import { makeSchema } from 'components/form/builder';
import { getValue } from 'util/getValue';
import { uniqueShippingMethodValidator, shippingAddressFieldsValidator, isCategoryExcluded } from 'behaviour/jobs/validators';
import { SwitchEditor } from 'components/form/editors';
import { isValueExists } from 'util/isValueExists';
import { extendSchema } from 'util/yup';
import { ShippingMethodValidationMessage, ShippingCalculationMethod, NotFilledProductWithDimensionsValidationMessage } from './constants';
import { TestCategory } from '../../general';

export const schemaGenerator = (jobName, parameters) => makeSchema({
  sections: [{
    title: 'Shipping methods',
    description: 'If the field for name is empty or its value is `-`, then tests for this shipping method will be skipped.',
    inputs: [{
      name: 'shippingMethod_fixedPerCountry',
      alias: 'ShippingMethod.FixedPerCountry.Name',
      label: 'Fixed per country shipping method name',
      defaultValue: getValue(parameters.shippingMethod_fixedPerCountry, 'Autotest fixed per country'),
      validate: Yup.string(),
    },
    {
      name: 'shippingMethod_external',
      alias: 'ShippingMethod.External.Name',
      label: 'External shipping method name',
      defaultValue: getValue(parameters.shippingMethod_external, 'Autotest external'),
      validate: Yup.string(),
    },
    {
      name: 'shippingMethod_byCountryAndWeight',
      alias: 'ShippingMethod.ByCountryAndWeight.Name',
      label: 'By country and weight shipping method name',
      defaultValue: getValue(parameters.shippingMethod_byCountryAndWeight, 'Autotest by country and weight'),
      validate: Yup.string(),
    },
    {
      name: 'shippingMethod_byCountryAndOrderTotal',
      alias: 'ShippingMethod.ByCountryAndOrderTotal.Name',
      label: 'By country and order total shipping method name',
      defaultValue: getValue(parameters.shippingMethod_byCountryAndOrderTotal, 'Autotest by country and order total'),
      validate: Yup.string(),
    },
    {
      name: 'shippingMethod_location',
      disabled: isCategoryExcluded(parameters, [TestCategory.Locations]),
      disabledReason: "Not applicable since the 'Locations' category is turned off",
      alias: 'ShippingMethod.Location.Name',
      label: 'Store location name',
      defaultValue: getValue(parameters.shippingMethod_location, 'Autotest location'),
      validate: Yup.string(),
    }],
    addonPanels: [
      {
        visible: parameters.sanaVersion !== 930,
        name: 'Consignor',
        title: 'nShift (Formerly Consignor)',
        errorKey: 'shippingMethod_Consignor',
        checkbox: {
          name: 'shippingMethod_Consignor_checkbox',
          defaultValue: isValueExists(parameters.shippingMethod_Consignor),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'shippingMethod_Consignor',
          alias: 'ShippingMethod.Consignor.Name',
          label: 'Name',
          defaultValue: getValue(parameters.shippingMethod_Consignor, ''),
          validate: Yup.string().when('shippingMethod_Consignor_checkbox', {
            is: true,
            then: Yup.string()
              .required()
              .test('filledProductWithDimensions', NotFilledProductWithDimensionsValidationMessage, () => !!parameters.product_withDimensionsId)
              .test(
                'existingShippingMethod',
                ShippingMethodValidationMessage,
                async value => uniqueShippingMethodValidator(
                  value,
                  ShippingCalculationMethod.Consignor,
                  jobName,
                  parameters.sanaVersion,
                ),
              ),
          }),
        },
        useDefaults: {
          name: 'shippingMethod_Consignor_useDefaults',
          label:
            'Use default shipping address data (Consignor shipping will be checked with customer address)',
          defaultValue: getValue(parameters.shippingMethod_Consignor_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [{
          description: 'Shipping address:',
          inputs: [{
            name: 'shippingMethod_Consignor_ShippingAddress_CountryId',
            alias: 'ShippingMethod.Consignor.ShippingAddress.CountryId',
            label: 'Country ID',
            defaultValue: getValue(parameters.shippingMethod_Consignor_ShippingAddress_CountryId, ''),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'Consignor'),
              ]),
          },
          {
            name: 'shippingMethod_Consignor_ShippingAddress_StateId',
            alias: 'ShippingMethod.Consignor.ShippingAddress.State.Id',
            label: 'State ID',
            defaultValue: getValue(parameters.shippingMethod_Consignor_ShippingAddress_StateId, ''),
            validate: Yup.string(),
          },
          {
            name: 'shippingMethod_Consignor_ShippingAddress_Address',
            alias: 'ShippingMethod.Consignor.ShippingAddress.Address',
            label: 'Address',
            defaultValue: getValue(parameters.shippingMethod_Consignor_ShippingAddress_Address, ''),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'Consignor'),
              ]),
          },
          {
            name: 'shippingMethod_Consignor_ShippingAddress_City',
            alias: 'ShippingMethod.Consignor.ShippingAddress.City',
            label: 'City',
            defaultValue: getValue(parameters.shippingMethod_Consignor_ShippingAddress_City, ''),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'Consignor'),
              ]),
          },
          {
            name: 'shippingMethod_Consignor_ShippingAddress_ZipCode',
            alias: 'ShippingMethod.Consignor.ShippingAddress.ZipCode',
            label: 'Zip code',
            defaultValue: getValue(parameters.shippingMethod_Consignor_ShippingAddress_ZipCode, ''),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'Consignor'),
              ]),
          }],
        }],
      },
      {
        name: 'FEDEX',
        title: 'FedEx',
        errorKey: 'shippingMethod_FEDEX',
        checkbox: {
          name: 'shippingMethod_FEDEX_checkbox',
          defaultValue: isValueExists(parameters.shippingMethod_FEDEX),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'shippingMethod_FEDEX',
          alias: 'ShippingMethod.FEDEX.Name',
          label: 'Name',
          defaultValue: getValue(parameters.shippingMethod_FEDEX, ''),
          validate: Yup.string().when('shippingMethod_FEDEX_checkbox', {
            is: true,
            then: Yup.string()
              .required()
              .test('filledProductWithDimensions', NotFilledProductWithDimensionsValidationMessage, () => !!parameters.product_withDimensionsId)
              .test(
                'existingShippingMethod',
                ShippingMethodValidationMessage,
                async value => uniqueShippingMethodValidator(
                  value,
                  ShippingCalculationMethod.FEDEX,
                  jobName,
                  parameters.sanaVersion,
                ),
              ),
          }),
        },
        useDefaults: {
          name: 'shippingMethod_FEDEX_useDefaults',
          label: 'Use default shipping address data',
          defaultValue: getValue(parameters.shippingMethod_FEDEX_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [{
          description: 'Shipping address:',
          inputs: [{
            name: 'shippingMethod_FEDEX_ShippingAddress_CountryId',
            alias: 'ShippingMethod.FEDEX.ShippingAddress.CountryId',
            label: 'Country ID',
            defaultValue: getValue(parameters.shippingMethod_FEDEX_ShippingAddress_CountryId, 'NL'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'FEDEX'),
              ]),
          },
          {
            name: 'shippingMethod_FEDEX_ShippingAddress_Address',
            alias: 'ShippingMethod.FEDEX.ShippingAddress.Address',
            label: 'Address',
            defaultValue: getValue(parameters.shippingMethod_FEDEX_ShippingAddress_Address, 'Van Nelleweg 1'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'FEDEX'),
              ]),
          },
          {
            name: 'shippingMethod_FEDEX_ShippingAddress_City',
            alias: 'ShippingMethod.FEDEX.ShippingAddress.City',
            label: 'City',
            defaultValue: getValue(parameters.shippingMethod_FEDEX_ShippingAddress_City, 'Rotterdam'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'FEDEX'),
              ]),
          },
          {
            name: 'shippingMethod_FEDEX_ShippingAddress_ZipCode',
            alias: 'ShippingMethod.FEDEX.ShippingAddress.ZipCode',
            label: 'Zip code',
            defaultValue: getValue(parameters.shippingMethod_FEDEX_ShippingAddress_ZipCode, '3044 BC'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'FEDEX'),
              ]),
          }],
        }],
      },
      {
        name: 'UPS',
        errorKey: 'shippingMethod_UPS',
        checkbox: {
          name: 'shippingMethod_UPS_checkbox',
          defaultValue: isValueExists(parameters.shippingMethod_UPS),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'shippingMethod_UPS',
          alias: 'ShippingMethod.UPS.Name',
          label: 'Name',
          defaultValue: getValue(parameters.shippingMethod_UPS, ''),
          validate: Yup.string().when('shippingMethod_UPS_checkbox', {
            is: true,
            then: Yup.string()
              .required()
              .test('filledProductWithDimensions', NotFilledProductWithDimensionsValidationMessage, () => !!parameters.product_withDimensionsId)
              .test(
                'existingShippingMethod',
                ShippingMethodValidationMessage,
                async value => uniqueShippingMethodValidator(
                  value,
                  ShippingCalculationMethod.UPS,
                  jobName,
                  parameters.sanaVersion,
                ),
              ),
          }),
        },
        useDefaults: {
          name: 'shippingMethod_UPS_useDefaults',
          label: 'Use default shipping address data',
          defaultValue: getValue(parameters.shippingMethod_UPS_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [{
          description: 'Shipping address:',
          inputs: [{
            name: 'shippingMethod_UPS_ShippingAddress_CountryId',
            alias: 'ShippingMethod.UPS.ShippingAddress.CountryId',
            label: 'Country ID',
            defaultValue: getValue(parameters.shippingMethod_UPS_ShippingAddress_CountryId, 'US'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPS'),
              ]),
          },
          {
            name: 'shippingMethod_UPS_ShippingAddress_StateId',
            alias: 'ShippingMethod.UPS.ShippingAddress.State.Id',
            label: 'State ID',
            defaultValue: getValue(parameters.shippingMethod_UPS_ShippingAddress_StateId, 'GA'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPS'),
              ]),
          },
          {
            name: 'shippingMethod_UPS_ShippingAddress_Address',
            alias: 'ShippingMethod.UPS.ShippingAddress.Address',
            label: 'Address',
            defaultValue: getValue(parameters.shippingMethod_UPS_ShippingAddress_Address, '5555 Main 4 Case Cour'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPS'),
              ]),
          },
          {
            name: 'shippingMethod_UPS_ShippingAddress_Address2',
            alias: 'ShippingMethod.UPS.ShippingAddress.Address2',
            label: 'Address 2',
            defaultValue: getValue(parameters.shippingMethod_UPS_ShippingAddress_Address2, 'Apt 3B'),
            validate: Yup.string(),
          },
          {
            name: 'shippingMethod_UPS_ShippingAddress_City',
            alias: 'ShippingMethod.UPS.ShippingAddress.City',
            label: 'City',
            defaultValue: getValue(parameters.shippingMethod_UPS_ShippingAddress_City, 'Roswell'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPS'),
              ]),
          },
          {
            name: 'shippingMethod_UPS_ShippingAddress_ZipCode',
            alias: 'ShippingMethod.UPS.ShippingAddress.ZipCode',
            label: 'Zip code',
            defaultValue: getValue(parameters.shippingMethod_UPS_ShippingAddress_ZipCode, '30076'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPS'),
              ]),
          }],
        }],
      },
      {
        visible: parameters.sanaVersion.includes('SCC'),
        name: 'UPS Metric',
        errorKey: 'shippingMethod_UPSMetric',
        checkbox: {
          name: 'shippingMethod_UPSMetric_checkbox',
          defaultValue: isValueExists(parameters.shippingMethod_UPSMetric),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'shippingMethod_UPSMetric',
          alias: 'ShippingMethod.UPSMetric.Name',
          label: 'Name',
          defaultValue: getValue(parameters.shippingMethod_UPSMetric, ''),
          validate: Yup.string().when('shippingMethod_UPSMetric_checkbox', {
            is: true,
            then: Yup.string()
              .required()
              .test('filledProductWithDimensions', NotFilledProductWithDimensionsValidationMessage, () => !!parameters.product_withDimensionsId)
              .test(
                'existingShippingMethod',
                ShippingMethodValidationMessage,
                async value => uniqueShippingMethodValidator(
                  value,
                  ShippingCalculationMethod.UPSMetric,
                  jobName,
                  parameters.sanaVersion,
                ),
              ),
          }),
        },
        useDefaults: {
          name: 'shippingMethod_UPSMetric_useDefaults',
          label: 'Use default shipping address data',
          defaultValue: getValue(parameters.shippingMethod_UPSMetric_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [{
          description: 'Shipping address:',
          inputs: [{
            name: 'shippingMethod_UPSMetric_ShippingAddress_CountryId',
            alias: 'ShippingMethod.UPSMetric.ShippingAddress.CountryId',
            label: 'Country ID',
            defaultValue: getValue(parameters.shippingMethod_UPSMetric_ShippingAddress_CountryId, 'NL'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSMetric'),
              ]),
          },
          {
            name: 'shippingMethod_UPSMetric_ShippingAddress_Address',
            alias: 'ShippingMethod.UPSMetric.ShippingAddress.Address',
            label: 'Address',
            defaultValue: getValue(parameters.shippingMethod_UPSMetric_ShippingAddress_Address, 'Van Nelleweg 1'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSMetric'),
              ]),
          },
          {
            name: 'shippingMethod_UPSMetric_ShippingAddress_City',
            alias: 'ShippingMethod.UPSMetric.ShippingAddress.City',
            label: 'City',
            defaultValue: getValue(parameters.shippingMethod_UPSMetric_ShippingAddress_City, 'Rotterdam'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSMetric'),
              ]),
          },
          {
            name: 'shippingMethod_UPSMetric_ShippingAddress_ZipCode',
            alias: 'ShippingMethod.UPSMetric.ShippingAddress.ZipCode',
            label: 'Zip code',
            defaultValue: getValue(parameters.shippingMethod_UPSMetric_ShippingAddress_ZipCode, '3044 BC'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSMetric'),
              ]),
          }],
        }],
      },
      {
        visible: parameters.sanaVersion.includes('SCC'),
        name: 'UPS Imperial',
        errorKey: 'shippingMethod_UPSImperial',
        checkbox: {
          name: 'shippingMethod_UPSImperial_checkbox',
          defaultValue: isValueExists(parameters.shippingMethod_UPSImperial),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'shippingMethod_UPSImperial',
          alias: 'ShippingMethod.UPSImperial.Name',
          label: 'Name',
          defaultValue: getValue(parameters.shippingMethod_UPSImperial, ''),
          validate: Yup.string().when(
            'shippingMethod_UPSImperial_checkbox',
            {
              is: true,
              then: Yup.string()
                .required()
                .test('filledProductWithDimensions', NotFilledProductWithDimensionsValidationMessage, () => !!parameters.product_withDimensionsId)
                .test(
                  'existingShippingMethod',
                  ShippingMethodValidationMessage,
                  async value => uniqueShippingMethodValidator(
                    value,
                    ShippingCalculationMethod.UPSImperial,
                    jobName,
                    parameters.sanaVersion,
                  ),
                ),
            },
          ),
        },
        useDefaults: {
          name: 'shippingMethod_UPSImperial_useDefaults',
          label: 'Use default shipping address data',
          defaultValue: getValue(parameters.shippingMethod_UPSImperial_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [{
          description: 'Shipping address:',
          inputs: [{
            name: 'shippingMethod_UPSImperial_ShippingAddress_CountryId',
            alias: 'ShippingMethod.UPSImperial.ShippingAddress.CountryId',
            label: 'Country ID',
            defaultValue: getValue(parameters.shippingMethod_UPSImperial_ShippingAddress_CountryId, 'US'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSImperial'),
              ]),
          },
          {
            name: 'shippingMethod_UPSImperial_ShippingAddress_StateId',
            alias: 'ShippingMethod.UPSImperial.ShippingAddress.State.Id',
            label: 'State ID',
            defaultValue: getValue(parameters.shippingMethod_UPSImperial_ShippingAddress_StateId, 'GA'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSImperial'),
              ]),
          },
          {
            name: 'shippingMethod_UPSImperial_ShippingAddress_Address',
            alias: 'ShippingMethod.UPSImperial.ShippingAddress.Address',
            label: 'Address',
            defaultValue: getValue(parameters.shippingMethod_UPSImperial_ShippingAddress_Address, '5555 Main 4 Case'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSImperial'),
              ]),
          },
          {
            name: 'shippingMethod_UPSImperial_ShippingAddress_Address2',
            alias: 'ShippingMethod.UPSImperial.ShippingAddress.Address2',
            label: 'Address 2',
            defaultValue: getValue(parameters.shippingMethod_UPSImperial_ShippingAddress_Address2, 'Apt 3B'),
            validate: Yup.string(),
          },
          {
            name: 'shippingMethod_UPSImperial_ShippingAddress_City',
            alias: 'ShippingMethod.UPSImperial.ShippingAddress.City',
            label: 'City',
            defaultValue: getValue(parameters.shippingMethod_UPSImperial_ShippingAddress_City, 'Roswell'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSImperial'),
              ]),
          },
          {
            name: 'shippingMethod_UPSImperial_ShippingAddress_ZipCode',
            alias: 'ShippingMethod.UPSImperial.ShippingAddress.ZipCode',
            label: 'Zip code',
            defaultValue: getValue(parameters.shippingMethod_UPSImperial_ShippingAddress_ZipCode, '30963'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'UPSImperial'),
              ]),
          }],
        }],
      },
      {
        visible: parameters.sanaVersion.includes('SCC'),
        name: 'USPS International',
        errorKey: 'shippingMethod_USPSInternational',
        checkbox: {
          name: 'shippingMethod_USPSInternational_checkbox',
          defaultValue: isValueExists(parameters.shippingMethod_USPSInternational),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'shippingMethod_USPSInternational',
          alias: 'ShippingMethod.USPSInternational.Name',
          label: 'Name',
          defaultValue: getValue(parameters.shippingMethod_USPSInternational, ''),
          validate: Yup.string().when(
            'shippingMethod_USPSInternational_checkbox',
            {
              is: true,
              then: Yup.string()
                .required()
                .test('filledProductWithDimensions', NotFilledProductWithDimensionsValidationMessage, () => !!parameters.product_withDimensionsId)
                .test(
                  'existingShippingMethod',
                  ShippingMethodValidationMessage,
                  async value => uniqueShippingMethodValidator(
                    value,
                    ShippingCalculationMethod.USPSInternational,
                    jobName,
                    parameters.sanaVersion,
                  ),
                ),
            },
          ),
        },
        useDefaults: {
          name: 'shippingMethod_USPSInternational_useDefaults',
          label: 'Use default shipping address data',
          defaultValue: getValue(parameters.shippingMethod_USPSInternational_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [{
          description: 'Shipping address:',
          inputs: [{
            name: 'shippingMethod_USPSInternational_ShippingAddress_CountryId',
            alias: 'ShippingMethod.USPSInternational.ShippingAddress.CountryId',
            label: 'Country ID',
            defaultValue: getValue(parameters.shippingMethod_USPSInternational_ShippingAddress_CountryId, 'US'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'USPSInternational'),
              ]),
          },
          {
            name: 'shippingMethod_USPSInternational_ShippingAddress_StateId',
            alias: 'ShippingMethod.USPSInternational.ShippingAddress.State.Id',
            label: 'State ID',
            defaultValue: getValue(parameters.shippingMethod_USPSInternational_ShippingAddress_StateId, 'GA'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'USPSInternational'),
              ]),
          },
          {
            name: 'shippingMethod_USPSInternational_ShippingAddress_Address',
            alias: 'ShippingMethod.USPSInternational.ShippingAddress.Address',
            label: 'Address',
            defaultValue: getValue(parameters.shippingMethod_USPSInternational_ShippingAddress_Address, '5555 Main 4 Case Cour'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'USPSInternational'),
              ]),
          },
          {
            name: 'shippingMethod_USPSInternational_ShippingAddress_Address2',
            alias: 'ShippingMethod.USPSInternational.ShippingAddress.Address2',
            label: 'Address 2',
            defaultValue: getValue(parameters.shippingMethod_USPSInternational_ShippingAddress_Address2, 'Apt 3B'),
            validate: Yup.string(),
          },
          {
            name: 'shippingMethod_USPSInternational_ShippingAddress_City',
            alias: 'ShippingMethod.USPSInternational.ShippingAddress.City',
            label: 'City',
            defaultValue: getValue(parameters.shippingMethod_USPSInternational_ShippingAddress_City, 'Roswell'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'USPSInternational'),
              ]),
          },
          {
            name: 'shippingMethod_USPSInternational_ShippingAddress_ZipCode',
            alias: 'ShippingMethod.USPSInternational.ShippingAddress.ZipCode',
            label: 'Zip code',
            defaultValue: getValue(parameters.shippingMethod_USPSInternational_ShippingAddress_ZipCode, '30076'),
            validate: extendSchema(Yup.string(),
              [
                schema => shippingAddressFieldsValidator(schema, 'USPSInternational'),
              ]),
          }],
        }],
      },
      {
      visible: parameters.sanaVersion.includes('SCC'),
      name: 'USPS Domestic',
      errorKey: 'shippingMethod_USPSDomestic',
      checkbox: {
        name: 'shippingMethod_USPSDomestic_checkbox',
        defaultValue: isValueExists(parameters.shippingMethod_USPSDomestic),
        validate: Yup.bool(),
      },
      nameInput: {
        name: 'shippingMethod_USPSDomestic',
        alias: 'ShippingMethod.USPSDomestic.Name',
        label: 'Name',
        defaultValue: getValue(parameters.shippingMethod_USPSDomestic, ''),
        validate: Yup.string().when(
          'shippingMethod_USPSDomestic_checkbox',
          {
            is: true,
            then: Yup.string()
              .required()
              .test('filledProductWithDimensions', NotFilledProductWithDimensionsValidationMessage, () => !!parameters.product_withDimensionsId)
              .test(
                'existingShippingMethod',
                ShippingMethodValidationMessage,
                async value => uniqueShippingMethodValidator(
                  value,
                  ShippingCalculationMethod.USPSDomestic,
                  jobName,
                  parameters.sanaVersion,
                ),
              ),
          },
        ),
      },
      useDefaults: {
        name: 'shippingMethod_USPSDomestic_useDefaults',
        label: 'Use default shipping address data',
        defaultValue: getValue(parameters.shippingMethod_USPSDomestic_useDefaults, true),
        validate: Yup.bool(),
        input: SwitchEditor,
      },
      sections: [{
        description: 'Shipping address:',
        inputs: [{
          name: 'shippingMethod_USPSDomestic_ShippingAddress_CountryId',
          alias: 'ShippingMethod.USPSDomestic.ShippingAddress.CountryId',
          label: 'Country ID',
          defaultValue: getValue(parameters.shippingMethod_USPSDomestic_ShippingAddress_CountryId, 'US'),
          validate: extendSchema(Yup.string(),
            [
              schema => shippingAddressFieldsValidator(schema, 'USPSDomestic'),
            ]),
        },
        {
          name: 'shippingMethod_USPSDomestic_ShippingAddress_StateId',
          alias: 'ShippingMethod.USPSDomestic.ShippingAddress.State.Id',
          label: 'State ID',
          defaultValue: getValue(parameters.shippingMethod_USPSDomestic_ShippingAddress_StateId, 'GA'),
          validate: extendSchema(Yup.string(),
            [
              schema => shippingAddressFieldsValidator(schema, 'USPSDomestic'),
            ]),
        },
        {
          name: 'shippingMethod_USPSDomestic_ShippingAddress_Address',
          alias: 'ShippingMethod.USPSDomestic.ShippingAddress.Address',
          label: 'Address',
          defaultValue: getValue(parameters.shippingMethod_USPSDomestic_ShippingAddress_Address, '5555 Main 4 Case Cour'),
          validate: extendSchema(Yup.string(),
            [
              schema => shippingAddressFieldsValidator(schema, 'USPSDomestic'),
            ]),
        },
        {
          name: 'shippingMethod_USPSDomestic_ShippingAddress_Address2',
          alias: 'ShippingMethod.USPSDomestic.ShippingAddress.Address2',
          label: 'Address 2',
          defaultValue: getValue(parameters.shippingMethod_USPSDomestic_ShippingAddress_Address2, 'Apt 3B'),
          validate: Yup.string(),
        },
        {
          name: 'shippingMethod_USPSDomestic_ShippingAddress_City',
          alias: 'ShippingMethod.USPSDomestic.ShippingAddress.City',
          label: 'City',
          defaultValue: getValue(parameters.shippingMethod_USPSDomestic_ShippingAddress_City, 'Roswell'),
          validate: extendSchema(Yup.string(),
            [
              schema => shippingAddressFieldsValidator(schema, 'USPSDomestic'),
            ]),
        },
        {
          name: 'shippingMethod_USPSDomestic_ShippingAddress_ZipCode',
          alias: 'ShippingMethod.USPSDomestic.ShippingAddress.ZipCode',
          label: 'Zip code',
          defaultValue: getValue(parameters.shippingMethod_USPSDomestic_ShippingAddress_ZipCode, '30076'),
          validate: extendSchema(Yup.string(),
            [
              schema => shippingAddressFieldsValidator(schema, 'USPSDomestic'),
            ]),
        }],
      }],
      },
    ],
  }],
});
