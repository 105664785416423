import {
  switchMap, map, pluck, tap,
} from 'rxjs/operators';
import { merge, from } from 'rxjs';
import { gql } from '@apollo/client';
import { enqueueSnackbar } from 'behaviour/notifications/actions';
import { ofType } from 'redux-observable';
import {
  getActiveBuildsQuery, loadBuildQuery, buildFragment,
} from './queries';
import {
  BUILD_FINISHED,
  BUILD_STARTED,
} from './actions';

const updateQueueFragment = gql`
  fragment updateQueueFragment on Job {
    name,
    inQueue
  }
`;

const lastBuildFragment = gql`
  fragment lastBuildFragment on Job {
    lastBuild {
      ...buildFragment,
      launchInfo {
        reportPortalId,
        reportPortalUrl
      }
    }
  }
${buildFragment}`;

const mergeBuildListFragment = gql`
  fragment mergeBuildListFragment on Job {
    name,
    builds (count: $count, offset: $offset) {
      launchInfo {
        reportPortalId
        reportPortalUrl
      }
      parametersWithTestList: parameters(keys: ["TestListRerun"]) {
        name
        value
      }
      ...buildFragment
    }
  }
${buildFragment}`;

/**
 * @typedef {Object} EpicContext
 * @property {import('@apollo/client').ApolloClient} apollo
 *
 * @param {import("rxjs").Observable} action$
 * @param {import("redux-observable").StateObservable} state$
 * @param {EpicContext} context
 */
const buildEpics = (action$, state$, context) => {
  const { apollo } = context;
  const getJobId = jobName => apollo.cache.identify({
    __typename: 'Job',
    name: jobName,
  });

  const updateJobQueueStatus = (jobName, inQueue) => {
    apollo.writeFragment({
      id: getJobId(jobName),
      fragment: updateQueueFragment,
      data: {
        __typename: 'Job',
        inQueue,
        name: jobName,
      },
    });
  };

  const updateLastBuild = ({ jobName, build }) => {
    apollo.writeFragment({
      id: getJobId(jobName),
      fragment: lastBuildFragment,
      fragmentName: 'lastBuildFragment',
      data: {
        __typename: 'Job',
        name: jobName,
        lastBuild: {
          __typename: 'Build',
          ...build,
        },
      },
    });
  };

  const mergeBuilds = ({ jobName, build }) => {
    apollo.writeFragment({
      id: getJobId(jobName),
      fragment: mergeBuildListFragment,
      fragmentName: 'mergeBuildListFragment',
      variables: {
        offset: 1,
        count: 10,
      },
      data: {
        __typename: 'Job',
        name: jobName,
        builds: [
          {
            ...build,
            __typename: 'Build',
          },
        ],
      },
    });
  };

  const mergeActiveBuilds = ({ build }) => {
    apollo.writeQuery({
      query: getActiveBuildsQuery,
      data: {
        activeBuilds: [build],
      },
    });
  };

  const markBuildAsCompleted = ({ build }) => {
    apollo.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        activeBuilds(existingBuildRefs, { readField }) {
          return existingBuildRefs.filter(
            buildRef => build.id !== readField('id', buildRef),
          );
        },
      },
    });
  };

  const buildStarted$ = action$.pipe(
    ofType(BUILD_STARTED),
    pluck('payload'),
    switchMap(({ jobName, buildNumber }) => from(apollo.query({
      query: loadBuildQuery,
      variables: {
        jobName,
        buildNumber,
      },
      fetchPolicy: 'network-only',
    })).pipe(
      pluck('data', 'job', 'build'),
      tap(build => updateLastBuild({ jobName, build })),
      tap(build => mergeBuilds({ jobName, build })),
      tap(() => updateJobQueueStatus(jobName, false)),
      tap(build => mergeActiveBuilds({ build })),
      map(() => enqueueSnackbar({
        message: `Project ${jobName} #${buildNumber} was started`,
      })),
    )),
  );

  const buildFinished$ = action$.pipe(
    ofType(BUILD_FINISHED),
    pluck('payload'),
    switchMap(({ jobName, buildNumber }) => from(apollo.query({
      query: loadBuildQuery,
      variables: {
        jobName,
        buildNumber,
      },
      fetchPolicy: 'network-only',
    })).pipe(
      pluck('data', 'job', 'build'),
      tap(build => markBuildAsCompleted({ build })),
      map(() => enqueueSnackbar({
        message: `Project ${jobName} #${buildNumber} was finished`,
      })),
    )),
  );

  return merge(
    buildStarted$,
    buildFinished$,
  );
};

export default buildEpics;
