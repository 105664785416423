import React from 'react';
import PropTypes from 'prop-types';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ColumnProps } from './makeHeader';

const useStyles = makeStyles(theme => ({
  row: {
    padding: theme.spacing(1),
  },
  wideColumn: {
    [theme.breakpoints.up('md')]: {
      minWidth: '35em',
    },
  },
  contentFitColumn: {
    width: '35em',
  },
  cell: {
  },
  skeleton: {
    display: 'inline-flex',
    maxWidth: '20em',
    width: '100%',
  },
  alignRight: {

  },
}));

function getSkeletonProps(column, classes) {
  const skeletonProps = {
    variant: 'text',
    className: classes.skeleton,
  };

  if (column.id === 'selection') {
    skeletonProps.width = '100%';
    skeletonProps.variant = 'rect';
  }

  if (column.contentFit) {
    skeletonProps.width = '100%';
  }

  if (column.numeric) {
    skeletonProps.className = classNames(
      skeletonProps.className, classes.alignRight,
    );
  }

  return skeletonProps;
}

function DataRowSkeleton({ columns, cellStyle = {} }) {
  const classes = useStyles();

  return (
    <TableRow>
      {columns.map(column => (
        <MuiTableCell
          key={column.id}
          style={cellStyle}
          align={column.numeric ? 'right' : 'left'}
          padding={column.disablePadding ? 'none' : 'normal'}
          className={classNames({
            [classes.wideColumn]: column.wide === true,
          }, classes.cell)}
        >
          <Skeleton {...getSkeletonProps(column, classes)} />
        </MuiTableCell>
      ))}
    </TableRow>
  );
}

DataRowSkeleton.propTypes = {
  columns: PropTypes.arrayOf(ColumnProps).isRequired,
  cellStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default DataRowSkeleton;
