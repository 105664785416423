/* eslint-disable func-names */
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { makeSchema } from 'components/form/builder';
import { getValue } from 'util/getValue';
import { AutocompleteEditor } from 'components/form/editors';
import {
  isCategoryExcluded,
  customerValidator,
  contactValidator,
  orderValidator,
  salesAgentValidator
} from 'behaviour/jobs/validators';
import { TestCategory } from '../../general';
import { extendSchema } from 'util/yup';

export const schemaGenerator = (jobName, parameters) => makeSchema({
  sections: [{
    title: 'Customers',
    description: 'If the field is empty or its value is `-`, then tests related to this test data will be skipped.',
    expansionPanels: [{
      name: 'B2B',
      disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer]),
      disabledReason: "Not applicable since the 'B2B customer' category is turned off",
      errorKey: 'shopAccount_b2b',
      sections: [{
        inputs: [{
          name: 'shopAccount_b2b_customerId',
          alias: 'ShopAccount.B2B.Regular.Id',
          label: 'Regular customer ID',
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer]),
          defaultValue: getValue(parameters.shopAccount_b2b_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion, { hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.b2BCustomers',
          },
        },
        {
          name: 'shopAccount_b2b_accountManagerId',
          alias: 'ShopAccount.AccountManager.Id',
          label: 'Account manager customer ID',
          defaultValue: getValue(parameters.shopAccount_b2b_accountManagerId, ''),
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer, TestCategory.ShopAccountRoles]),
          disabledReason: "Not applicable since the 'Shop account roles' category is turned off",
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion)
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.b2BCustomers',
          },
        },
        {
          name: 'shopAccount_b2b_withCreditLimit_customerId',
          alias: 'ShopAccount.B2B.WithCreditLimit.Id',
          label: 'Customer ID with credit limit',
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer, TestCategory.CreditLimit]),
          disabledReason: "Not applicable since the 'Credit limit' category is turned off",
          defaultValue: getValue(parameters.shopAccount_b2b_withCreditLimit_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion, { hasCreditLimit: true, hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.b2BCustomersWithCreditLimit',
          },
        },
        {
          name: 'shopAccount_b2b_withSalesAgreements_customerId',
          alias: 'ShopAccount.B2B.WithSalesAgreements.Id',
          label: 'Customer ID with sales agreements',
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer, TestCategory.SalesAgreements]),
          disabledReason: "Not applicable since the 'Sales agreements' category is turned off",
          defaultValue: getValue(parameters.shopAccount_b2b_withSalesAgreements_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion, { hasSalesAgreements: true, hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.b2BCustomersWithSalesAgreements',
          },
        },
        {
          name: 'shopAccount_b2b_withSeveralShippingAddresses_customerId',
          alias: 'ShopAccount.B2B.WithSeveralShippingAddresses.Id',
          label: 'Customer ID with several shipping addresses',
          defaultValue: getValue(parameters.shopAccount_b2b_withSeveralShippingAddresses_customerId, ''),
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer]),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion, { hasSeveralShippingAddresses: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.b2BCustomersWithSeveralShippingAddresses',
          },
        }],
      },
      {
        inputs: [{
          name: 'shopAccount_b2b_withErpAttachment_orderIdWithErpAttachment',
          alias: 'ShopAccount.B2B.WithErpAttachment.OrderIdWithErpAttachment',
          label: 'Order ID with ERP attachment',
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer, TestCategory.OrderAttachments]),
          disabledReason: "Not applicable since the 'Order attachments' category is turned off",
          defaultValue: getValue(parameters.shopAccount_b2b_withErpAttachment_orderIdWithErpAttachment, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => orderValidator(schema, jobName, parameters.sanaVersion, { hasErpAttachment: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'orders.b2BOrdersWithERPAttachments',
            onValueChange: ({ relatedData, setFieldValue }) => setFieldValue('shopAccount_b2b_withErpAttachment_customerId', relatedData['B2BCustomerId'])
          },
        },
        {
          name: 'shopAccount_b2b_withErpAttachment_customerId',
          alias: 'ShopAccount.B2B.WithErpAttachment.Id',
          label: 'Customer ID with ERP attachment',
          helperText: "This field is filled in automatically after selecting the 'Order ID with ERP attachment' value",
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer, TestCategory.OrderAttachments]),
          disabledReason: "Not applicable since the 'Order attachments' category is turned off",
          defaultValue: getValue(parameters.shopAccount_b2b_withErpAttachment_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion)
            ]),
        }],
      },
      {
        inputs: [{
          name: 'shopAccount_b2b_withSalesReport_orderIdWithSalesReport',
          alias: 'ShopAccount.B2B.WithSalesReport.OrderIdWithSalesReport',
          label: 'Order ID with sales report',
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer, TestCategory.SalesReports]),
          disabledReason: "Not applicable since the 'Sales reports' category is turned off",
          defaultValue: getValue(parameters.shopAccount_b2b_withSalesReport_orderIdWithSalesReport, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => orderValidator(schema, jobName, parameters.sanaVersion, { hasSalesReport: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'orders.b2BOrdersWithSalesReports',
            onValueChange: ({ relatedData, setFieldValue }) => setFieldValue('shopAccount_b2b_withSalesReport_customerId', relatedData['B2BCustomerId'])
          },
        },
        {
          name: 'shopAccount_b2b_withSalesReport_customerId',
          alias: 'ShopAccount.B2B.WithSalesReport.Id',
          label: 'Customer ID with sales report',
          helperText: "This field is filled in automatically after selecting the 'Order ID with sales report' value",
          disabled: isCategoryExcluded(parameters, [TestCategory.B2BCustomer, TestCategory.SalesReports]),
          disabledReason: "Not applicable since the 'Sales reports' category is turned off",
          defaultValue: getValue(parameters.shopAccount_b2b_withSalesReport_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion)
            ]),
        }],
      }],
    },
    {
      name: 'Contact',
      disabled: isCategoryExcluded(parameters, [TestCategory.Contact]),
      disabledReason: "Not applicable since the 'Contact' category is turned off",
      errorKey: 'shopAccount_contact',
      sections: [{
        inputs: [{
          name: 'shopAccount_contact_customerId',
          alias: 'ShopAccount.Contact.Regular.Id',
          label: 'Regular contact ID',
          defaultValue: getValue(parameters.shopAccount_contact_customerId, ''),
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact]),
          validate: extendSchema(Yup.string(),
            [
              schema => contactValidator(schema, jobName, parameters.sanaVersion, { hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.contacts',
          },
        },
        {
          name: 'shopAccount_contact_withCreditLimit_customerId',
          alias: 'ShopAccount.Contact.WithCreditLimit.Id',
          label: 'Contact ID with credit limit',
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact, TestCategory.CreditLimit]),
          disabledReason: "Not applicable since the 'Credit limit' category is turned off",
          defaultValue: getValue(parameters.shopAccount_contact_withCreditLimit_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => contactValidator(schema, jobName, parameters.sanaVersion, { hasCreditLimit: true, hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.contactsWithCreditLimit',
          },
        },
        {
          name: 'shopAccount_contact_withSalesAgreements_customerId',
          alias: 'ShopAccount.Contact.WithSalesAgreements.Id',
          label: 'Contact ID with sales agreements',
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact, TestCategory.SalesAgreements]),
          disabledReason: "Not applicable since the 'Sales agreements' category is turned off",
          defaultValue: getValue(parameters.shopAccount_contact_withSalesAgreements_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => contactValidator(schema, jobName, parameters.sanaVersion, { hasSalesAgreements: true, hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.contactsWithSalesAgreements',
          },
        },
        {
          name: 'shopAccount_contact_withSeveralShippingAddresses_customerId',
          alias: 'ShopAccount.Contact.WithSeveralShippingAddresses.Id',
          label: 'Contact ID with several shipping addresses',
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact]),
          defaultValue: getValue(parameters.shopAccount_contact_withSeveralShippingAddresses_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => contactValidator(schema, jobName, parameters.sanaVersion, { hasSeveralShippingAddresses: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.contactsWithSeveralShippingAddresses',
          },
        }],
      },
      {
        inputs: [{
          name: 'shopAccount_contact_withErpAttachment_orderIdWithErpAttachment',
          alias: 'ShopAccount.Contact.WithErpAttachment.OrderIdWithErpAttachment',
          label: 'Order ID with ERP attachment',
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact, TestCategory.OrderAttachments]),
          disabledReason: "Not applicable since the 'Order attachments' category is turned off",
          defaultValue: getValue(parameters.shopAccount_contact_withErpAttachment_orderIdWithErpAttachment, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => orderValidator(schema, jobName, parameters.sanaVersion, { hasErpAttachment: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'orders.contactOrdersWithERPAttachments',
            onValueChange: ({ relatedData, setFieldValue }) => setFieldValue('shopAccount_contact_withErpAttachment_customerId', relatedData['ContactId'])
          },
        },
        {
          name: 'shopAccount_contact_withErpAttachment_customerId',
          alias: 'ShopAccount.Contact.WithErpAttachment.Id',
          label: 'Contact ID with ERP attachment',
          helperText: "This field is filled in automatically after selecting the 'Order ID with ERP attachment' value",
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact, TestCategory.OrderAttachments]),
          disabledReason: "Not applicable since the 'Order attachments' category is turned off",
          defaultValue: getValue(parameters.shopAccount_contact_withErpAttachment_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => contactValidator(schema, jobName, parameters.sanaVersion)
            ]),
        }],
      },
      {
        inputs: [{
          name: 'shopAccount_contact_withSalesReport_orderIdWithSalesReport',
          alias: 'ShopAccount.Contact.WithSalesReport.OrderIdWithSalesReport',
          label: 'Order ID with sales report',
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact, TestCategory.SalesReports]),
          disabledReason: "Not applicable since the 'Sales reports' category is turned off",
          defaultValue: getValue(parameters.shopAccount_contact_withSalesReport_orderIdWithSalesReport, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => orderValidator(schema, jobName, parameters.sanaVersion, { hasSalesReport: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'orders.contactOrdersWithSalesReports',
            onValueChange: ({ relatedData, setFieldValue }) => setFieldValue('shopAccount_contact_withSalesReport_customerId', relatedData['ContactId'])
          },
        },
        {
          name: 'shopAccount_contact_withSalesReport_customerId',
          alias: 'ShopAccount.Contact.WithSalesReport.Id',
          label: 'Contact ID with sales report',
          helperText: "This field is filled in automatically after selecting the 'Order ID with sales report' value",
          disabled: isCategoryExcluded(parameters, [TestCategory.Contact, TestCategory.SalesReports]),
          disabledReason: "Not applicable since the 'Sales reports' category is turned off",
          defaultValue: getValue(parameters.shopAccount_contact_withSalesReport_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => contactValidator(schema, jobName, parameters.sanaVersion)
            ]),
        }],
      }],
    },
    {
      name: 'SalesAgent',
      title: 'Sales agent',
      disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent]),
      disabledReason: "Not applicable since the 'Sales agent' category is turned off",
      errorKey: 'shopAccount_salesAgent',
      sections: [{
        inputs: [{
          name: 'shopAccount_salesAgent_customerId',
          alias: 'ShopAccount.SalesAgent.Regular.Id',
          label: 'Regular sales agent ID',
          disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent]),
          defaultValue: getValue(parameters.shopAccount_salesAgent_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => salesAgentValidator(schema, jobName, parameters.sanaVersion)
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.salesAgents',
            onValueChange: ({ setFieldValue }) => {
              setFieldValue('shopAccount_salesAgent_representCustomerId', '')
              setFieldValue('shopAccount_salesAgent_superUser_notLinkedCustomerId', '')
            }
          },
        },
        {
          name: 'shopAccount_salesAgent_representCustomerId',
          alias: 'ShopAccount.SalesAgent.Regular.RepresentCustomer.Id',
          label: 'Represent customer ID',
          helperText: 'If represent customer ID has empty or \'-\' value, First customer in the list will be represented. If represent customer ID has specified value, this customer will be represented',
          disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent]),
          defaultValue: getValue(parameters.shopAccount_salesAgent_representCustomerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion, { hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.salesAgentRepresentCustomer',
            relatedField: 'shopAccount_salesAgent_customerId',
          },
        },
        {
          name: 'shopAccount_salesAgent_superUser_notLinkedCustomerId',
          alias: 'ShopAccount.SalesAgent.SuperUser.NotLinkedCustomerId',
          label: 'Not linked customer ID',
          helperText: 'ID of the customer who is not linked to the regular sales agent ID',
          disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent]),
          defaultValue: getValue(parameters.shopAccount_salesAgent_superUser_notLinkedCustomerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion)
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.salesAgentNotLinkedCustomer',
            relatedField: 'shopAccount_salesAgent_customerId',
          },
        }],
      },
      {
        inputs: [{
          name: 'shopAccount_salesAgent_withSalesAgreements_customerId',
          alias: 'ShopAccount.SalesAgent.WithSalesAgreements.Id',
          label: 'Sales agent ID with sales agreements',
          disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent, TestCategory.SalesAgreements]),
          disabledReason: "Not applicable since the 'Sales agreements' category is turned off",
          defaultValue: getValue(parameters.shopAccount_salesAgent_withSalesAgreements_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => salesAgentValidator(schema, jobName, parameters.sanaVersion)
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.salesAgentsWithSalesAgreements',
            onValueChange: ({ setFieldValue }) => setFieldValue('shopAccount_salesAgent_withSalesAgreements_representCustomerId', '')
          },
        },
        {
          name: 'shopAccount_salesAgent_withSalesAgreements_representCustomerId',
          alias: 'ShopAccount.SalesAgent.WithSalesAgreements.RepresentCustomer.Id',
          label: 'Represent customer ID',
          helperText: 'If represent customer ID has empty or \'-\' value, First customer in the list will be represented. If represent customer ID has specified value, this customer will be represented',
          disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent, TestCategory.SalesAgreements]),
          disabledReason: "Not applicable since the 'Sales agreements' category is turned off",
          defaultValue: getValue(parameters.shopAccount_salesAgent_withSalesAgreements_representCustomerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => customerValidator(schema, jobName, parameters.sanaVersion, { hasSalesAgreements: true, hasBillingAddress: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'users.salesAgentsWithSalesAgreementsRepresentCustomer',
            relatedField: 'shopAccount_salesAgent_withSalesAgreements_customerId',
          },
        }],
      },
      {
        inputs: [{
          name: 'shopAccount_salesAgent_withSalesReport_orderIdWithSalesReport',
          alias: 'ShopAccount.SalesAgent.WithSalesReport.OrderIdWithSalesReport',
          label: 'Order ID with sales report',
          disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent, TestCategory.SalesReports]),
          disabledReason: "Not applicable since the 'Sales reports' category is turned off",
          defaultValue: getValue(parameters.shopAccount_salesAgent_withSalesReport_orderIdWithSalesReport, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => orderValidator(schema, jobName, parameters.sanaVersion, { hasSalesReport: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'orders.salesAgentOrdersWithSalesReports',
            onValueChange: ({ relatedData, setFieldValue }) => setFieldValue('shopAccount_salesAgent_withSalesReport_customerId', relatedData['SalesAgentId'])
          },
        },
        {
          name: 'shopAccount_salesAgent_withSalesReport_customerId',
          alias: 'ShopAccount.SalesAgent.WithSalesReport.Id',
          label: 'Sales agent ID with sales report',
          helperText: "This field is filled in automatically after selecting the 'Order ID with sales report' value",
          disabled: isCategoryExcluded(parameters, [TestCategory.SalesAgent, TestCategory.SalesReports]),
          disabledReason: "Not applicable since the 'Sales reports' category is turned off",
          defaultValue: getValue(parameters.shopAccount_salesAgent_withSalesReport_customerId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => salesAgentValidator(schema, jobName, parameters.sanaVersion)
            ]),
        }],
      }],
    }],
  }],
});
