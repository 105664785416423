import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

function DataTableBody({
  rows, renderRow, rowsPerPage, columnsCount, totalCount,
}) {
  const emptyRows = totalCount < rowsPerPage ? 0 : rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <TableBody>
      {rows.map(data => renderRow({
        data,
      }))}
      {emptyRows > 0 && (
      <TableRow style={{ height: 33 * emptyRows }}>
        <TableCell colSpan={columnsCount} />
      </TableRow>
      )}
    </TableBody>
  );
}

DataTableBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  columnsCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default memo(DataTableBody);
