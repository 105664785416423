import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import MuiCheckbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  wideColumn: {
    [theme.breakpoints.up('md')]: {
      minWidth: '35em',
    },
  },
  contentFitColumn: {
    width: '1%',
  },
}));

function DataGridHead({
  columns,
  order,
  orderBy,
  onRequestSort,
  allowSelection = false,
  onSelectAll = null,
  selectionState = 'unselected',
}) {
  const classes = useStyles();
  const createSortHandler = property => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <MuiTableRow>
        {allowSelection && (
          <MuiTableCell padding="checkbox">
            <MuiCheckbox
              color="primary"
              indeterminate={selectionState === 'selected'}
              checked={selectionState === 'selectedAll'}
              onChange={e => onSelectAll(e.target.checked)}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </MuiTableCell>
        )}
        {columns.map(col => (
          <MuiTableCell
            key={col.id}
            align={col.numeric ? 'right' : 'left'}
            padding={col.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === col.id ? order : false}
            className={classNames({
              [classes.wideColumn]: col.wide === true,
              [classes.contentFitColumn]: col.contentFit === true,
            })}
          >
            {col.id && col.sortable && (
              <MuiTableSortLabel
                active={orderBy === col.id}
                direction={order}
                onClick={onRequestSort && createSortHandler(col.id)}
              >
                {col.label || col.id}
              </MuiTableSortLabel>
            )}
            {col.id && !col.sortable && (col.label || col.id)}
          </MuiTableCell>
        ))}
      </MuiTableRow>
    </MuiTableHead>
  );
}

DataGridHead.propTypes = {
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    wide: PropTypes.bool,
    contentFit: PropTypes.bool,
    sortable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  })).isRequired,
  allowSelection: PropTypes.bool,
  onSelectAll: PropTypes.func,
  selectionState: PropTypes.oneOf(['unselected', 'selected', 'selectedAll']),
};

export default memo(DataGridHead);
