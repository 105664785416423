import React, {
  useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NotesIcon from '@material-ui/icons/NotesTwoTone';
import { Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RenameJobDialog } from '../dialogs';

function RenameActionButton({
  name, displayName = name, className = '', disabled = false, disabledReason = '',
}) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const history = useHistory();

  const handleRenameButtonClick = (e) => {
    e.preventDefault();
    setDialogOpen(true);
  };

  return (
    <>
      <RenameJobDialog
        name={name}
        displayName={displayName}
        open={isDialogOpen}
        setOpen={setDialogOpen}
        onRenamed={(_, newName) => history.push(`/projects/${newName}`)}
      />
      <Tooltip title={(disabled && disabledReason) || ''}>
        <span>
          <Button
            variant="text"
            disabled={disabled}
            className={clsx(className, 'rename')}
            onClick={handleRenameButtonClick}
            startIcon={<NotesIcon fontSize="small" />}
          >
            Rename
          </Button>
        </span>
      </Tooltip>
    </>
  );
}

RenameActionButton.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
};

export default memo(RenameActionButton);
