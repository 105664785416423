import { getIn } from 'formik';

export const regex = (errorKey) =>
  new RegExp(`^${errorKey}($|_.*)`, "g");

export const showError = (errors, errorKey, touched) => {
  return Object.keys(errors)
    .filter(key => regex(errorKey).test(key))
    .some(field => getIn(touched, field));
};

function getSectionInputs(section, inputs) {
  if (section.inputs) {
    section.inputs.forEach((input) => {
      inputs.push(input);
    });
  }

  return inputs;
}

export const getInputs = (schema) => {
  const inputs = [];

  if (schema.sections) {
    schema.sections.forEach((section) => {
      getSectionInputs(section, inputs);
      if (section.expansionPanels) {
        section.expansionPanels.forEach((expansionPanel) => {
          if (expansionPanel.sections) {
            expansionPanel.sections.forEach((innerSection) => {
              getSectionInputs(innerSection, inputs);
            });
          }
        });
      }
      if (section.addonPanels) {
        section.addonPanels.forEach((addonPanel) => {
          [addonPanel.checkbox, addonPanel.nameInput, addonPanel.useDefaults].forEach((input) => {
            if (input) inputs.push(input);
          });
          if (addonPanel.sections) {
            addonPanel.sections.forEach((innerSection) => {
              getSectionInputs(innerSection, inputs);
            });
          }
        });
      }
    });
  }

  if (schema.tabs) {
    schema.tabs.forEach((tab) => {
      if (tab.sections) {
        tab.sections.forEach((section) => {
          getSectionInputs(section, inputs);
          if (section.expansionPanels) {
            section.expansionPanels.forEach((expansionPanel) => {
              if (expansionPanel.sections) {
                expansionPanel.sections.forEach((innerSection) => {
                  getSectionInputs(innerSection, inputs);
                });
              }
            });
          }
        });
      }
    });
  }

  return inputs;
}
