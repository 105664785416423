import { get } from 'lodash';

export function flatTestCases(testReport) {
  if (!testReport || testReport.length === 0) { return []; }

  const suites = get(testReport, 'suites') || [];
  const testCases = [];
  if (suites.length !== 0) {
    suites.forEach((suite) => {
      suite.cases.forEach((testCase) => {
        testCases.push(testCase);
      });
    });
  }

  return testCases;
}
