import React from 'react';
import MuiLink from '@material-ui/core/Link';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

const NavLink = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <MuiLink
      underline="none"
      {...props}
      ref={ref}
      component={RouteNavLink}
      activeClassName={classes.selected}
    />
  );
});

export default NavLink;
