import React from 'react';
import { PropTypes } from 'prop-types';
import * as Yup from 'yup';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router';
import { useCopyJobMutation } from 'behaviour/jobs';
import { TextEditor } from 'components/form/editors';
import { Modal } from 'components/modal';
import { createUniqueJobValidator, createValidJobNameValidator } from 'behaviour/jobs/validators';
import { extendSchema } from 'util/yup';

function CopyJobDialog({
  name,
  open,
  setOpen,
  onCopied,
}) {
  const apollo = useApolloClient();
  const history = useHistory();
  const copyJob = useCopyJobMutation();

  const validationSchema = Yup.object().shape({
    newName: extendSchema(
      Yup.string()
        .required('Project name is a required field'),
      [
        createValidJobNameValidator,
        schema => createUniqueJobValidator(schema, apollo),
      ],
    ),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    copyJob(values.newName, name)
      .then((result) => {
        if (result && result.data) {
          history.push(`/projects/${values.newName}/settings`);
          setOpen(false);
          if (onCopied) { onCopied(values.newName, name); }
        }
      }).finally(() => setSubmitting(false));
  };

  return (
    <>
      <Modal
        heading="Duplicate project"
        open={open}
        onClose={() => setOpen(false)}
        actionsProps={[{
          className: 'cancelButton',
          onClick: () => setOpen(false),
          text: 'Cancel',
        }, {
          className: 'copyButton',
          type: 'submit',
          text: 'Duplicate',
        }]}
        formProps={{
          initialValues: { newName: name },
          validationSchema,
          validateOnChange: false,
          onSubmit: handleSubmit,
        }}
      >
        <TextEditor
          name="newName"
          label="Project name"
          fullWidth
          autoFocus
        />
      </Modal>
    </>
  );
}

export default CopyJobDialog;

CopyJobDialog.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onCopied: PropTypes.func,
};
