import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

const getBridgeAvailabilityQuery = gql`
  query getBridgeAvailabilityQuery($jobName: String!) {
    bridgeConnectionAvailability(jobName: $jobName)
  }
`;

const useBridgeAvailability = ({ jobName, sanaVersion }) => {
  const { data } = useQuery(getBridgeAvailabilityQuery, {
    variables: {
      jobName
    },
    skip: !sanaVersion?.includes('SCC'),
    fetchPolicy: 'cache-and-network',
  });

  const isBridgeAvailable = get(data, 'bridgeConnectionAvailability');

  return { isBridgeAvailable };
};

export default useBridgeAvailability;
