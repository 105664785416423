import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from 'components/PageTitle';
import { gql } from '@apollo/client';
import JobContent, { jobContentFragment } from './JobContent';
import JobHeader, { jobHeaderFragment } from './JobHeader';
import JobContext from './JobContext';

function JobDetails({
  job, jobName, loading = false,
}) {
  return (
    <JobContext.Provider value={jobName}>
      {!loading && <PageTitle title={job && job.displayName} />}
      <div>
        <div>
          {loading && <JobHeader.Skeleton />}
          {!loading && <JobHeader job={job} />}
        </div>
        <JobContent
          jobName={jobName}
          job={job}
          loading={loading}
        />
      </div>
    </JobContext.Provider>
  );
}

export const jobDetailsFragment = gql`
  fragment jobDetailsFragment on Job {
    name
    displayName
    ...jobContentFragment
    ...jobHeaderFragment
  }
  ${jobHeaderFragment}
  ${jobContentFragment}
`;

JobDetails.propTypes = {
  jobName: PropTypes.string.isRequired,
  job: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
};

export default JobDetails;
