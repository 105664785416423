import React, { useEffect } from 'react';
import { getIn } from 'formik';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiOutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import CheckIcon from '@material-ui/icons/CheckCircleTwoTone';
import BlockIcon from '@material-ui/icons/BlockOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '16px 0 8px',
    width: '100%',
    '&:first-child': {
      marginTop: 0,
    },
  },
  label: {
    margin: 0,
    alignItems: 'flex-start',
    '&$error': {
      color: theme.palette.error.main,
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
  helperText: {
    margin: '0',
    fontSize: '0.85rem',
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  smallInput: {
    width: '35%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mediumInput: {
    width: '70%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  largeInput: {
    width: '100%',
  },
}));

const fieldToTextField = ({
  field,
  form,
  disabled,
  ...props
}) => {
  const { name } = field;
  const { touched, errors, isSubmitting, setFieldTouched } = form;

  const fieldError = getIn(errors, name);
  const hasTouched = getIn(touched, name);
  const showError = hasTouched && !!fieldError;

  return {
    ...props,
    ...field,
    hasTouched,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: disabled || isSubmitting,
    isSubmitting,
    setFieldTouched
  };
};

function TextField({
  label = '', size = 'medium', children = null, ...props
}) {
  const classes = useStyles();
  const {
    error, helperText, InputProps, fullWidth, disabled, disabledReason,
    hasTouched, isSubmitting, setFieldTouched, name, ...fieldProps
  } = fieldToTextField(props);
  const endAdornment = InputProps && InputProps.endAdornment;

  useEffect(() => {
    if (!isSubmitting && disabled) {
      setFieldTouched(name, false);
    }
  }, [disabled, isSubmitting, name, setFieldTouched]);

  return (
    <MuiFormControl variant="outlined" fullWidth className={classes.root}>
      <MuiFormControlLabel
        label={label}
        labelPlacement="top"
        disabled={disabled}
        className={clsx(
          classes.label,
          {
            [classes.error]: error,
            [classes.smallInput]: size === 'small',
            [classes.mediumInput]: size === 'medium',
            [classes.largeInput]: size === 'large' || fullWidth,
          },
        )}
        control={(
          <Tooltip
            title={!disabled || disabledReason == null ? '' : disabledReason}
          >
            <MuiOutlinedInput
              fullWidth
              name={name}
              {...InputProps}
              {...fieldProps}
              endAdornment={(
                <>
                  {hasTouched && error && <ErrorIcon className={classes.error} />}
                  {hasTouched && !error && <CheckIcon className={classes.success} />}
                  {disabled && !isSubmitting && <BlockIcon />}
                  {endAdornment && [endAdornment]}
                </>
              )}
              error={error}
            >
              {children}
            </MuiOutlinedInput>
          </Tooltip>
        )}
      />
      {helperText
        && (
          <MuiFormHelperText className={clsx(
            classes.helperText,
            {
              [classes.error]: error,
              errorMessage: error,
            },
          )}
          >
            {helperText}
          </MuiFormHelperText>
        )
      }
    </MuiFormControl>
  );
}

TextField.propTypes = {
  label: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node,
};

export default TextField;
