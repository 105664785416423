import React, {
  useCallback, useEffect, useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Box, Toolbar } from '@material-ui/core';
import { countBy, includes } from 'lodash';
import ToggleChipGroup from 'components/ToogleChipGroup';
import { connect } from 'react-redux';
import persistedStorage from 'util/persistedStorage';

function formatLabel(name, count) {
  if (Number.isNaN(count)) { return name; }

  return `${name} - ${count}`;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '5px',
    color: theme.palette.type === 'dark' ? 'white' : undefined,

    '& .MuiChip-deleteIconOutlinedColorPrimary': {
      color: theme.palette.type === 'dark' ? 'white' : undefined,
    },
  },
}));

const JobFilters = Object.freeze({
  Active: 'ACTIVE',
  Runnable: 'RUNNABLE',
  Favorites: 'FAVORITES',
});

function JobListFilter({ items, onFilterChanged, favorites = [] }) {
  const classes = useStyles();
  const [selected, setSelected] = useState(() => {
    const initialFilters = persistedStorage.readJson('jobList:filter');
    if (initialFilters) {
      return initialFilters;
    }

    return [
      JobFilters.Active,
      JobFilters.Runnable,
      favorites.length > 0 ? JobFilters.Favorites : null,
    ];
  });

  const toggles = [{
    label: 'Favorites', value: JobFilters.Favorites, count: countBy(items, job => includes(favorites, job.name)).true || 0,
  },
  {
    label: 'Active', value: JobFilters.Active, count: countBy(items, v => v.disabled === false).true || 0,
  }, {
    label: 'Runnable', value: JobFilters.Runnable, count: countBy(items, v => v.buildable === true).true || 0,
  }];

  const handleToggleChanged = useCallback((_, newSelected) => {
    setSelected(newSelected);

    persistedStorage.writeJson('jobList:filter', newSelected);

    if (onFilterChanged) {
      onFilterChanged((item) => {
        let applied = true;

        if (includes(newSelected, JobFilters.Active)) {
          applied = item.disabled === false;
        }

        if (includes(newSelected, JobFilters.Runnable)) {
          applied = applied && item.buildable === true;
        }

        if (includes(newSelected, JobFilters.Favorites)) {
          applied = applied && favorites.includes(item.name);
        }

        return applied;
      });
    }
  }, [setSelected, onFilterChanged, favorites]);

  useEffect(() => {
    handleToggleChanged(false, selected);
  }, [items, favorites, handleToggleChanged, selected]);

  return (
    <Toolbar variant="dense">
      <ToggleChipGroup
        value={selected}
        onChange={handleToggleChanged}
      >
        {toggles.map(data => (
          <Chip
            key={data.value}
            label={(
              <Box className={classes.box}>
                {formatLabel(data.label, data.count)}
              </Box>
          )}
            className={classes.chip}
            clickable
            value={data.value}
            color="primary"
          />
        ))}
      </ToggleChipGroup>
    </Toolbar>
  );
}

JobListFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    buildable: PropTypes.bool.isRequired,
  })),
  onFilterChanged: PropTypes.func,
  favorites: PropTypes.arrayOf(PropTypes.string),
};

export default memo(connect(({ favorites }) => ({ favorites }))(JobListFilter));
