import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { BuildListContent } from 'components/jobBuildList';
import { BuildJobButton, buildJobButtonFragment } from 'components/jobBuild';
import { ButtonSkeleton } from 'components/form';
import Toolbar from '@material-ui/core/Toolbar';
import { RoutingTabs } from 'components/tabs';
import { Route, Switch } from 'react-router-dom';
import { JobConfiguration } from 'components/job/configuration';
import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
}));

const createTabs = jobName => [
  {
    to: `/projects/${jobName}/`,
    label: 'Runs',
    value: 'runs',
  }, {
    to: `/projects/${jobName}/settings`,
    label: 'Settings',
    value: 'settings',
  }, {
    to: `/projects/${jobName}/testData`,
    label: 'Test data',
    value: 'testData',
    exact: false,
  }, {
    to: `/projects/${jobName}/scheduling`,
    label: 'Scheduling',
    value: 'scheduling',
  },
];

function JobContent({
  job, jobName, loading = false,
}) {
  const classes = useStyles();
  const tabs = useMemo(() => createTabs(job && job.displayName), [job]);

  return (
    <>
      {!loading && <RoutingTabs tabs={tabs} />}
      <Paper className={classes.paper}>
        <Switch>
          <Route
            path="/projects/:jobName"
            exact
            render={() => (
              <>
                <Toolbar variant="dense">
                  {loading && <ButtonSkeleton width={120} disableGutters />}
                  {!loading && (
                    <BuildJobButton {...job} />
                  )}
                </Toolbar>
                <BuildListContent
                  jobName={jobName}
                />
              </>
            )}
          />
          <JobConfiguration jobName={jobName} />
        </Switch>
      </Paper>
    </>
  );
}

export const jobContentFragment = gql`
  fragment jobContentFragment on Job {
    name
    displayName
    inQueue
    buildable
    disabled
    lastBuild {
      id
      building
    }
    ...buildJobButtonFragment
  }
  ${buildJobButtonFragment}
`;

JobContent.propTypes = {
  jobName: PropTypes.string.isRequired,
  job: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    inQueue: PropTypes.bool.isRequired,
    buildable: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    lastBuild: PropTypes.shape({
      building: PropTypes.bool.isRequired,
    }),
  }),
  loading: PropTypes.bool,
};

export default JobContent;
