import { matchPath } from 'react-router';
import { createLocation } from 'history';

const resolveToLocation = (to, currentLocation) => (typeof to === 'function' ? to(currentLocation) : to);

const normalizeToLocation = (to, currentLocation) => (typeof to === 'string'
  ? createLocation(to, null, null, currentLocation)
  : to);

export function isActiveLocation(to, currentLocation, options) {
  const toLocation = normalizeToLocation(
    resolveToLocation(to, currentLocation),
    currentLocation,
  );

  const { pathname: path } = toLocation;
  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

  const match = escapedPath
    ? matchPath(currentLocation.pathname, {
      path: escapedPath,
      ...options,
    })
    : null;

  return !!match;
}
