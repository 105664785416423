import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import { useBridgeAvailability, useErpConnection, useWebshopAvailability } from 'behaviour/jobs';
import FormContext from 'components/form/builder/FormBuilderContext';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1)
  }
}));

function PrefillButton({ jobName, isPrefilling, onClick }) {
  const { parameters } = useContext(FormContext);
  const { sanaVersion } = parameters;
  const { isBridgeAvailable } = useBridgeAvailability({ jobName, sanaVersion });
  const { isErpConnectionAvailable } = useErpConnection({ jobName, sanaVersion });
  const { isWebshopAvailable } = useWebshopAvailability({ jobName, sanaVersion });
  const classes = useStyles();

  const getTooltipText = () => {
    if (isPrefilling) return 'Test data prefilling is in progress';
    if (isBridgeAvailable === false) return 'Bridge is not available';
    if (isErpConnectionAvailable === false) return 'ERP connection is not available';
    if (isWebshopAvailable === false) return 'Webshop is unavailable';
    return '';
  };

  return (
    <div className={classes.root}>
      <Tooltip title={getTooltipText()}>
        <span>
          <Button
            onClick={onClick}
            className="prefillInputs"
            disabled={
              isPrefilling ||
              isBridgeAvailable === false ||
              isErpConnectionAvailable === false ||
              isWebshopAvailable === false
            }
            startIcon={<LowPriorityIcon />}
            endIcon={isPrefilling && <CircularProgress size={24} />}
          >
            {isPrefilling ? 'Prefilling...' : 'Prefill all test data'}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

PrefillButton.propTypes = {
  jobName: PropTypes.string.isRequired,
  isPrefilling: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(PrefillButton);
