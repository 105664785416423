export const TestCategory = {
  AnonymousCustomer: 'Anonymous_Customer',
  B2BCustomer: 'B2B_Customer',
  B2BRegistration: 'B2B_Registration',
  B2CCustomer: 'B2C_Customer',
  CategoryImport: 'CategoryImport',
  Contact: 'Contact',
  CreditLimit: 'CreditLimit',
  GuestCheckout: 'GuestCheckout',
  Locations: 'Locations',
  OnlineInvoicePayments: 'OnlineInvoicePayments',
  Order: 'Order',
  OrderAttachments: 'OrderAttachments',
  PaymentMethod: 'PaymentMethod',
  ProductAttachments: 'ProductAttachments',
  ProductReturns: 'ProductReturns',
  Promotions: 'Promotions',
  Prospect: 'Prospect',
  Quote: 'Quote',
  QuoteToOrderConversion: 'QuoteToOrderConversion',
  SalesAgent: 'SalesAgent',
  SalesAgreements: 'SalesAgreements',
  SalesReports: 'SalesReports',
  ScheduledTasksCheck: 'ScheduledTasksCheck',
  ShippingMethod: 'ShippingMethod',
  ShopAccountRoles: 'ShopAccountRoles',
  SimpleProducts: 'SimpleProducts',
  VariantProducts: 'VariantProducts'
};

export const ShopType = {
  Closed: 'closed',
  Private: 'private',
  Public: 'public'
}
