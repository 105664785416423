/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { getIn } from 'formik';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import CheckIcon from '@material-ui/icons/CheckCircleTwoTone';
import BlockIcon from '@material-ui/icons/BlockOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '16px 0 0',
    '&:first-child': {
      marginTop: 0,
    },
  },
  label: {
    alignItems: 'flex-start',
    width: '100%',
    margin: '0 0 8px',
    '& .MuiFormHelperText-root': {
      margin: '0',
    },
    '&$error': {
      color: theme.palette.error.main,
    },
  },
  input: {
    paddingRight: 0,
  },
  helper: {
    margin: '0',
    '&$error': {
      color: theme.palette.error.main,
    },
    fontSize: '0.85rem',
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  smallInput: {
    width: '35%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mediumInput: {
    width: '70%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  largeInput: {
    width: '100%',
  },
  selectIcon: {
    position: 'absolute',
    right: '40px',
    pointerEvents: 'none',
  },
}));

function Select({
  field,
  form: {
    touched, errors, isSubmitting, setFieldValue, setFieldTouched
  },
  disabled = false,
  disabledReason,
  label = '',
  helperText = '',
  multiple = false,
  native = false,
  size = 'small',
  ...props
}) {
  const classes = useStyles();

  const { name } = field;

  const fieldError = getIn(errors, name);
  const hasTouched = getIn(touched, name);
  const showError = hasTouched && !!fieldError;


  const onChange = React.useCallback(
    (event) => {
      // Special case for multiple and native
      if (multiple && native) {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }

        setFieldValue(field.name, value);
      } else {
        field.onChange(event);
      }
    },
    [field, multiple, native, setFieldValue],
  );

  useEffect(() => {
    if (!isSubmitting && disabled) {
      setFieldTouched(name, false);
    }
  }, [disabled, isSubmitting, name, setFieldTouched]);

  return (
    <FormControl variant="outlined" fullWidth className={classes.root}>
      <FormControlLabel
        label={label}
        labelPlacement="top"
        className={clsx(
          classes.label,
          {
            [classes.error]: showError,
          },
        )}
        control={(
          <Tooltip
            title={!disabled || disabledReason == null ? '' : disabledReason}
          >
            <MuiSelect
              disabled={disabled || isSubmitting}
              className={clsx(classes.input, {
                [classes.smallInput]: size === 'small',
                [classes.mediumInput]: size === 'medium',
                [classes.largeInput]: size === 'large',
              })}
              endAdornment={(
                <>
                  {hasTouched && showError && <ErrorIcon className={clsx(classes.selectIcon, classes.error)} />}
                  {hasTouched && !showError && <CheckIcon className={clsx(classes.selectIcon, classes.success)} />}
                  {disabled && !isSubmitting && <BlockIcon className={classes.selectIcon} />}
                </>
              )}
              onChange={onChange}
              {...field}
              {...props}
            />
          </Tooltip>
        )}
      />
      {(fieldError || helperText)
        && (
          <FormHelperText className={clsx(classes.helper, { [classes.error]: showError, errorMessage: showError })}>
            {showError ? fieldError : helperText}
          </FormHelperText>
        )
      }
    </FormControl>
  );
}

Select.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  multiple: PropTypes.bool,
  native: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Select;
