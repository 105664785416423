import React from 'react';
import PropTypes from 'prop-types';
import IconLabel from 'components/IconLabel';
import Duration from 'components/Duration';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { find } from 'lodash';
import { gql } from '@apollo/client';
import TimeAgo from '../TimeAgo';
import BuildStatus, { buildStatusFragment } from './BuildStatus';

function formatDate(value) {
  if (!value) { return null; }

  const date = new Date(value);
  return <TimeAgo date={date} />;
}

function BuildSummary({ build }) {
  const {
    building, timestamp, completed, estimatedDuration, duration, parametersWithStartedBy,
  } = build;
  const startedByParameter = parametersWithStartedBy && find(parametersWithStartedBy, p => p.name === 'StartedBy');

  return (
    <>
      {building && (
        <>
          <IconLabel icon={<BuildStatus {...build} />} className="started">
            Started
            {' '}
            {formatDate(timestamp)}
          </IconLabel>
          {estimatedDuration !== 0 && (estimatedDuration >= duration) && (
            <IconLabel icon={TimerRoundedIcon} className="estimatedDuration">
              About
              {' '}
              <Duration duration={estimatedDuration - duration} live reversed />
              &nbsp;
              left
            </IconLabel>
          )}
          {estimatedDuration !== 0 && (estimatedDuration < duration) && (
            <IconLabel icon={TimerRoundedIcon} className="estimatedDuration">
              It takes longer than expected by
              {' '}
              <Duration duration={duration - estimatedDuration} live />
            </IconLabel>
          )}
        </>
      )}
      {!building && (
        <>
          <IconLabel icon={<BuildStatus {...build} />} className="lastRun">
            Last run
            {' '}
            {formatDate(completed)}
          </IconLabel>
          {duration > 0
            && (
              <IconLabel icon={TimerRoundedIcon} className="duration">
                Took
                {' '}
                <Duration duration={duration} />
              </IconLabel>
            )}
        </>
      )}
      {startedByParameter && startedByParameter.value === '{}' && (
      <IconLabel icon={PersonOutlinedIcon} className="startedBy">
        Started by scheduling
      </IconLabel>
      )}
      {startedByParameter && startedByParameter.value !== '{}' && (
      <IconLabel icon={PersonOutlinedIcon} className="startedBy">
        Started by
        {' '}
        {startedByParameter.value}
      </IconLabel>
      )}
    </>
  );
}

export const buildSummaryFragment = gql`
  fragment buildSummaryFragment on Build {
    building
    result
    timestamp
    estimatedDuration
    duration
    completed
    parametersWithStartedBy: parameters(keys: "StartedBy") {
      name
      value
    }
    ...buildStatusFragment
  }
  ${buildStatusFragment}
`;

BuildSummary.propTypes = {
  build: PropTypes.shape({
    building: PropTypes.bool.isRequired,
    result: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    estimatedDuration: PropTypes.number,
    duration: PropTypes.number.isRequired,
    completed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    parametersWithStartedBy: PropTypes.arrayOf(
      PropTypes.shape().isRequired,
    ).isRequired,
  }).isRequired,
};

export default BuildSummary;
