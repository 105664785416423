import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow, TableCell, makeStyles, Button,
} from '@material-ui/core';
import clsx from 'clsx';
import { gql } from '@apollo/client';
import { BuildStatus } from 'components/jobBuild';
import Link from 'components/Link';

const useStyles = makeStyles(() => ({
  cell: {
    padding: '5px 0',
    borderBottomWidth: 0,
  },
  link: {
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 'inherit',
    padding: '2px 8px',
    color: 'inherit',
  },
}));

function RecentBuildRow({ build, jobName }) {
  const classes = useStyles();
  const { number, result } = build;

  return (
    <>
      <TableRow className={clsx('lastBuildLine', { active: result === 'RUNNING' })}>
        <TableCell className={clsx(classes.cell, 'status')}>
          <BuildStatus {...build} />
        </TableCell>
        <TableCell className={clsx(classes.cell, 'jobName')}>
          <Button className={classes.link} component={Link} to={`/projects/${jobName}`} variant="text">
            {jobName}
          </Button>
        </TableCell>
        <TableCell className={clsx(classes.cell, 'buildNumber')}>
          <Button className={classes.link} component={Link} to={`/projects/${jobName}/${number}`} variant="text">
            {`#${number}`}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export const recentBuildRowFragment = gql`
  fragment recentBuildRowFragment on Build {
    result
    number
  }
`;

export default RecentBuildRow;

RecentBuildRow.propTypes = {
  build: PropTypes.shape({
    result: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  }),
  jobName: PropTypes.string.isRequired,
};
