import React, { memo } from 'react';
import PageTitle from 'components/PageTitle';
import { NotFoundMessage } from 'components/errorStates';

function Page404() {
  return (
    <>
      <PageTitle title="Page not found" />
      <NotFoundMessage />
    </>
  );
}

export default memo(Page404);
