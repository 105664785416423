import { useState, useEffect, useCallback } from 'react';
import find from 'lodash/find';

export default function useContentPages(root) {
  const [article, setArticle] = useState(null);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${root}/index.json`).then((response) => {
      if (response.ok) return response.text();
      return null;
    }).then((data) => {
      const dataJson = JSON.parse(data);
      if (dataJson.content) setArticles(dataJson.content);
      setLoading(false);
    });
  }, [root]);

  const loadArticle = useCallback((url) => {
    setLoading(true);
    fetch(`/${root}/${url}`, {
      cache: 'no-cache',
    }).then((response) => {
      if (response.ok) return response.text();
      return null;
    }).then((data) => {
      setLoading(false);
      const theArticle = find(articles, a => a.url === url);
      if (!theArticle) { return; }
      setArticle({
        ...theArticle,
        content: data,
      });
    });
  }, [articles, root]);

  return {
    loading,
    articles,
    article,
    loadArticle,
  };
}
