import React from 'react';
import PropTypes from 'prop-types';
import IconLabel from 'components/IconLabel';
import TimeAgo from 'components/TimeAgo';
import RunStatus from './IndexingRunStatus';
import Link from 'components/Link';
import { makeStyles } from '@material-ui/core';

function formatDate(value) {
  if (!value) { return null; }

  const date = new Date(value);
  return <TimeAgo date={date} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

function IndexingSummary({ jobName, lastRun }) {
  const classes = useStyles();
  const { completed } = lastRun;

  return (
    <div className={classes.root}>
      {completed && <IconLabel icon={<RunStatus {...lastRun} />} className="lastRun">
        Indexing completed
        {' '}
        {formatDate(completed)}
      </IconLabel>}
      <Link to={`/projects/${jobName}/testData/indexing`} underline="hover">
        <span>View history</span>
      </Link>
    </div>
  );
}

IndexingSummary.propTypes = {
  jobName: PropTypes.string.isRequired,
  lastRun: PropTypes.shape({
    completed: PropTypes.string
  })
};

export default IndexingSummary;
