import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { closeSnackbar } from 'behaviour/notifications';
import { Button } from 'components/form';
import { useUpdateJobConfigurationMutation } from 'behaviour/jobs';

function OverwriteConfiguration({ notificationKey, jobName, configuration }) {
  const dispatch = useDispatch();
  const update = useUpdateJobConfigurationMutation();

  const handleOverwriteClick = () => {
    update({
      jobName,
      configuration,
      overwrite: true,
    });
    dispatch(closeSnackbar(notificationKey));
  };

  const handleCancelClick = () => dispatch(closeSnackbar(notificationKey));

  return (
    <>
      <Button
        variant="text"
        title="Overwrite with my own"
        onClick={handleOverwriteClick}
      />
      {' | '}
      <Button
        variant="text"
        title="Cancel"
        onClick={handleCancelClick}
      />
    </>
  );
}

OverwriteConfiguration.propTypes = {
  notificationKey: PropTypes.number.isRequired,
  jobName: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    versionToken: PropTypes.string.isRequired,
  }),
};

export default OverwriteConfiguration;
