import React, {
  useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  ButtonGroup, Button, Box, Link, Popper, Grow, ClickAwayListener, MenuList, MenuItem,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DownloadIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNewTwoTone';
import IconLabel from 'components/IconLabel';

const useStyles = makeStyles(() => ({
  buttonGroup: {
    position: 'relative',
    zIndex: '1',
    display: 'inline-flex',
  },
}));

const consoleTextUrl = (jobName, buildNumber, inline = true) => `/consoleText/${encodeURIComponent(jobName)}/${buildNumber}${!inline ? '?download=true' : ''}`;

function DownloadButtonGroup({ jobName, buildNumber }) {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box className={classes.buttonGroup}>
      <ButtonGroup ref={anchorRef} variant="contained">
        <Button startIcon={<DownloadIcon />}>
          <Link
            href={consoleTextUrl(jobName, buildNumber, false)}
            color="inherit"
            underline="none"
            className="downloadButton"
          >
            Download
          </Link>
        </Button>
        <Button size="small" onClick={handleToggle} className="opener">
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    onClick={() => setOpen(false)}
                    component={Link}
                    href={consoleTextUrl(jobName, buildNumber)}
                    target="_blank"
                    color="inherit"
                    underline="none"
                    className="viewInANewTab"
                  >
                    <IconLabel icon={OpenInNewIcon}>
                      View in a new tab
                    </IconLabel>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

DownloadButtonGroup.propTypes = {
  jobName: PropTypes.string.isRequired,
  buildNumber: PropTypes.number.isRequired,
};

export default DownloadButtonGroup;
