import { useApolloClient, gql } from '@apollo/client';
import { useCallback } from 'react';
import { useNotifications } from 'behaviour/notifications';
import { loadingChanged } from 'behaviour/app/actions';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

const jobFragment = gql`
  fragment jobFragment on Job {
    name,
    displayName,
    configuration {
      versionToken
    }
  }
`;

const renameJobMutation = gql`
  mutation renameJob($jobName: String!, $newName: String!) {
    job(jobName: $jobName) {
      rename(newName: $newName) {
        ...jobFragment
      }
    }
  }
  ${jobFragment}
`;

export default function useRenameJobMutation() {
  const apolloClient = useApolloClient();
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();

  return useCallback(
    (jobName, newName) => {
      dispatch(loadingChanged(true));
      return apolloClient
        .mutate({
          mutation: renameJobMutation,
          variables: {
            jobName,
            newName,
          },
          update: (proxy, result) => {
            const updatedJob = get(result, 'data.job.rename');
            if (!updatedJob) { return; }

            const getJobId = name => proxy.identify({
              __typename: 'Job',
              name,
            });

            proxy.modify({
              id: 'ROOT_QUERY',
              fields: {
                allJobs(allJobs, { readField }) {
                  return allJobs.filter(
                    job => jobName !== readField('name', job),
                  );
                },
              },
            });

            proxy.writeFragment({
              id: getJobId(jobName),
              fragment: jobFragment,
              data: {
                __typename: 'Job',
                ...updatedJob,
              },
            });

            proxy.evict({
              id: getJobId(jobName),
            });
          },
        })
        .catch(() => {
          addNotification({
            message: 'Could not rename the project',
            options: {
              variant: 'error',
            },
          });
        }).finally(() => dispatch(loadingChanged(false)));
    },
    [apolloClient, addNotification, dispatch],
  );
}
