import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';

const Tabs = withStyles(theme => ({
  root: {
    fontSize: '2rem',
    marginBottom: '17px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(props => <MuiTabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export default Tabs;
