import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import DataGridHead from './DataGridHead';
import DataTableBody from './DataTableBody';
import DataTableBodySkeleton from './DataTableBodySkeleton';
import { ColumnProps } from './makeHeader';

const useStyles = makeStyles(theme => ({
  cell: {
    padding: theme.spacing(0.5, 1),
    '&:first-child': {
      paddingLeft: theme.spacing(2),
    },
    '&:last-child': {
      paddingRight: theme.spacing(2),
    },
  },
}));

const emptySelected = () => false;

function DataGrid({
  items = [],
  totalCount = 0,
  columns,
  fetchMore,
  allowSelection = false,
  isSelected = null,
  handleSelectAll = null,
  handleSelected = null,
  selectionState = 'unselected',
  defaultRowsPerPage = 50,
  defaultSortProperty = null,
  defaultSortAscending = true,
  defaultPage = 0,
  renderRow,
  loading = false,
  className = '',
  pagination = true,
  dense = true,
  onPageChange = null,
  onRowsPerPageChange = null,
  onSortChange = null,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState(defaultSortAscending ? 'asc' : 'desc');
  const [orderBy, setOrderBy] = useState(defaultSortProperty || columns[0].id);

  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const dataColumns = useMemo(() => (!allowSelection
    ? columns
    : [{
      id: 'selection',
      label: '',
    }, ...columns]), [columns, allowSelection]);

  useEffect(() => {
    setPage(defaultPage);
  }, [totalCount]);

  useEffect(() => {
    fetchMore({
      page,
      count: rowsPerPage,
      orderBy,
      ascending: order === 'asc',
      columns,
    });
  }, [fetchMore, page, rowsPerPage, order, orderBy, columns, totalCount]);

  if (allowSelection) {
    if (!isSelected || !handleSelected || !handleSelectAll) {
      throw new Error('isSelected, handleSelected and handleSelectAll are required when allowSelection == true');
    }
  }

  const handleSortChange = useCallback((_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (onSortChange) {
      onSortChange(property, isAsc ? 'desc' : 'asc');
    }
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [orderBy, order]);

  const handlePageChange = useCallback((_, newPage) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
    setPage(newPage);
  }, [setPage]);

  const handleRowsPerPageChange = useCallback((event) => {
    const count = parseInt(event.target.value, 10);
    if (onRowsPerPageChange) {
      onRowsPerPageChange(count);
    }
    setRowsPerPage(count);
    setPage(defaultPage);
  }, [setPage, setRowsPerPage]);

  const loaded = !loading;

  const renderRowInternal = useCallback(({ data }) => renderRow({
    data,
    allowSelection,
    onSelected: allowSelection ? handleSelected : emptySelected,
    selected: allowSelection ? isSelected(data) : emptySelected,
    columns: dataColumns,
  }), [allowSelection, isSelected, dataColumns, renderRow, handleSelected]);

  return (
    <>
      <TableContainer className={className}>
        <Table
          className={classes.table}
          size={dense ? 'small' : 'medium'}
        >
          <DataGridHead
            columns={columns}
            order={order}
            orderBy={orderBy}
            onSelectAll={handleSelectAll}
            onRequestSort={handleSortChange}
            allowSelection={allowSelection}
            selectionState={selectionState}
          />
          {loaded && (
            <DataTableBody
              rows={items}
              columnsCount={columns.length + (allowSelection ? 1 : 0)}
              rowsPerPage={rowsPerPage}
              renderRow={renderRowInternal}
              totalCount={totalCount}
            />
          )}
          {loading && (
            <DataTableBodySkeleton
              rowsCount={rowsPerPage}
              columns={dataColumns}
            />
          )}
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          className="tablePagination"
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={(page > 0 && items.length < rowsPerPage) ? 0 : page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          SelectProps={{
            variant: 'outlined',
            fullWidth: false,
          }}
        />
      )
      }
    </>
  );
}

DataGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(ColumnProps).isRequired,
  allowSelection: PropTypes.bool,
  defaultRowsPerPage: PropTypes.number,
  defaultSortProperty: PropTypes.string,
  defaultSortAscending: PropTypes.bool,
  defaultPage: PropTypes.number,
  renderRow: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fetchMore: PropTypes.func,
  className: PropTypes.string,
  pagination: PropTypes.bool,
  isSelected: PropTypes.func,
  handleSelected: PropTypes.func,
  handleSelectAll: PropTypes.func,
  selectionState: PropTypes.oneOf(['unselected', 'selected', 'selectedAll']),
  dense: PropTypes.bool,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};

export default DataGrid;
