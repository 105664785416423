import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR, NotificationsAction } from './actions';
import type { Notification } from './types';

export default (state = [] as Notification[], action: NotificationsAction): Notification[] => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return [
        ...state,
        { ...action.notification },
      ];

    case CLOSE_SNACKBAR:
      return [
        ...state.map(notification => (
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      ];

    case REMOVE_SNACKBAR:
      return [
        ...state.filter(notification => notification.key !== action.key),
      ];

    default:
      return state;
  }
};
