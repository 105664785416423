import React from 'react';
import Warning from 'components/Warning';

function ErpConnectionAlert() {
  return (
    <Warning severity='error'>
      ERP connection is not available. Autocomplete functionality and data validation will not work properly.
    </Warning>
  );
}

export default ErpConnectionAlert;
