import React from 'react';
import PropTypes from 'prop-types';
import MuiSwitch from '@material-ui/core/Switch';
import { FormControlLabel } from '@material-ui/core';

function Switch({
  field, form, label = '', disabled = false, ...props
}) {
  const { value } = field;
  const { isSubmitting } = form;

  return (
    <FormControlLabel
      control={(
        <MuiSwitch
          checked={value}
          {...props}
          {...field}
        />
      )}
      label={label}
      labelPlacement="end"
      disabled={disabled || isSubmitting}
    />
  );
}

Switch.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.bool.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool,
  }),
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Switch;
