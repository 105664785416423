import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'components/form/builder/FormBuilder';
import get from 'lodash/get';
import { schemaGenerator } from './schema';
import { TestCategory } from './constants';

function GeneralForm({
  configuration, updateConfiguration,
}) {
  const parameters = get(configuration, 'parameters.Parameters.value') || {};
  const schema = useMemo(
    () => schemaGenerator(parameters),
    [parameters],
  );

  const handleSubmit = useCallback(({ values, aliases }, { setSubmitting, resetForm }) => {
    const mergedValues = { ...parameters, ...values };

    const defaultConfig = {
      '@import': 'Integration.sanaunit.json',
      tests: {
        excludes: Object.values(TestCategory).filter(
          item => !mergedValues.categories.includes(item),
        ) || [],
      },
    };

    const mergedConfig = {
      ...defaultConfig,
      general: {
        ...defaultConfig.general,
        ...aliases.general,
      },
      tests: {
        ...defaultConfig.tests,
        ...aliases.tests,
      },
      selenium: {
        ...aliases.selenium,
      },
      reportPortal: {
        ...aliases.reportPortal,
      },
    };

    updateConfiguration({
      versionToken: configuration.versionToken,
      parameters: [{
        name: 'Sanaunit',
        value: JSON.stringify(mergedConfig),
        type: 'MULTILINE',
      },
      {
        name: 'Parameters',
        value: JSON.stringify(mergedValues),
        type: 'MULTILINE',
      }],
    }).then(() => {
      setSubmitting(false);
      resetForm({ values });
    });
  }, [parameters, configuration, updateConfiguration]);

  return (
    <FormBuilder
      schema={schema}
      onSubmit={handleSubmit}
    />
  );
}

GeneralForm.propTypes = {
  configuration: PropTypes.shape({
    versionToken: PropTypes.string.isRequired,
  }),
  updateConfiguration: PropTypes.func.isRequired,
};

export default GeneralForm;
