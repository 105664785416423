import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';

const fieldToCheckbox = ({
  field, form: { isSubmitting }, disabled, ...props
}) => ({
  disabled: disabled || isSubmitting,
  ...props,
  ...field,
});

function Checkbox(props) {
  return (
    <MuiCheckbox {...fieldToCheckbox(props)} />
  );
}

export default Checkbox;
