import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import Duration from 'components/Duration';
import Markdown from 'components/markdown';
import classNames from 'classnames';
import clsx from 'clsx';
import { gql } from '@apollo/client';
import TestCaseStatus from './TestCaseStatus';

const useStyles = makeStyles(theme => ({
  row: {
    cursor: 'pointer',
  },
  rowFailed: {
    verticalAlign: 'top',
  },
  checkboxCell: {
    width: '1%',
    paddingLeft: theme.spacing(1),
  },
  statusCell: {
    width: '1%',
  },
  status: {
    padding: '9px',
  },
  errorDetails: {
    fontSize: '0.875rem',
    borderLeft: `3px solid ${theme.palette.grey[400]}`,
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5, 0.5, 0.5, 3),
  },
}));

function TestReportItem({
  name, status, duration, errorDetails, onSelected, selected, allowSelection,
}) {
  const classes = useStyles();

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={name}
      onClick={e => onSelected(e, name)}
      selected={selected}
      role="checkbox"
      className={classNames(classes.row, {
        [classes.rowFailed]: errorDetails,
      })}
    >
      {allowSelection ? (
        <TableCell className={clsx(classes.checkboxCell, 'selection')}>
          <Checkbox
            color="primary"
            checked={selected}
          />
        </TableCell>
      ) : null}
      <TableCell align="center" className={clsx(classes.statusCell, 'status')}>
        <div className={classes.status}>
          <TestCaseStatus status={status} />
        </div>
      </TableCell>
      <TableCell padding="normal" className="testName">
        <span className={classes.title}>{name}</span>
        <br />
        {errorDetails && (
          <Markdown className={classes.errorDetails}>
            {errorDetails}
          </Markdown>
        )}
      </TableCell>
      <TableCell align="right" className="duration">{duration >= 1000 ? <Duration duration={duration} /> : '< 1 second'}</TableCell>
    </TableRow>
  );
}

export const testReportItemFragment = gql`
  fragment testReportItemFragment on TestCase {
    name
    status
    duration
    errorDetails
  }
`;

TestReportItem.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  errorDetails: PropTypes.string,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  allowSelection: PropTypes.bool,
};

export default memo(TestReportItem);
