export const JOB_UPDATED = 'SET_JOB_UPDATED';
export const INDEXING_COMPLETED = 'INDEXING_COMPLETED';

export function jobUpdated(jobName) {
  return {
    type: JOB_UPDATED,
    payload: {
      jobName,
    },
  };
}

export function indexingTaskCompleted(jobName, runId) {
  return {
    type: INDEXING_COMPLETED,
    payload: {
      jobName,
      runId
    },
  };
}
