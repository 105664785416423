import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@material-ui/core';
import StopIcon from '@material-ui/icons/StopTwoTone';
import { useStopBuildMutation } from 'behaviour/builds';
import { gql } from '@apollo/client';

export const cancelRunButtonFragment = gql`
  fragment cancelRunButtonFragment on Build {
    number,
    building
    cancelling
    job {
      name
    }
  }
`;

function CancelRunButton({
  number, building, cancelling, job,
}) {
  const stopBuildMutation = useStopBuildMutation();
  const stopBuild = useCallback(() => {
    stopBuildMutation({ jobName: job.name, buildNumber: number });
  }, [number, job, stopBuildMutation]);

  return (
    <Tooltip title={building ? '' : 'Run has already been completed'}>
      <span>
        <MuiButton
          startIcon={<StopIcon />}
          endIcon={cancelling && <CircularProgress size={20} />}
          disabled={!building || cancelling}
          onClick={stopBuild}
          className="cancelButton"
        >
          {cancelling ? 'Cancelling' : 'Cancel'}
        </MuiButton>
      </span>
    </Tooltip>
  );
}

CancelRunButton.propTypes = {
  number: PropTypes.number.isRequired,
  building: PropTypes.bool.isRequired,
  cancelling: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(CancelRunButton);
