export const offsetCountPagination = (keyArgs = []) => ({
  keyArgs,
  read(existing, { args: { offset = 0, count } }) {
    // A read function should always return undefined if existing is
    // undefined. Returning undefined signals that the field is
    // missing from the cache, which instructs Apollo Client to
    // fetch its value from your GraphQL server.
    return existing && existing.slice(offset, offset + count);
  },
  merge(existing, incoming, { args }) {
    const merged = existing ? existing.slice(0) : [];
    if (args) {
      // Assume an offset of 0 if args.offset omitted.
      const { offset = 0 } = args;
      for (let i = 0; i < incoming.length; ++i) {
        merged[offset + i] = incoming[i];
      }
      return merged;
    }
    throw new Error('Offset argument is not provided.');
  },
});
