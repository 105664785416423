import { gql, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useNotifications } from 'behaviour/notifications';
import { loadingChanged } from 'behaviour/app/actions';
import { useDispatch } from 'react-redux';

const deleteJobMutation = gql`
  mutation deleteJob($jobName: String!) {
    job(jobName: $jobName) {
      delete
    }
  }
`;

export default function useDeleteJobMutation() {
  const apolloClient = useApolloClient();
  const { addNotification } = useNotifications();
  const dispatch = useDispatch();

  return useCallback(
    (jobName) => {
      dispatch(loadingChanged(true));
      return apolloClient.mutate({
        mutation: deleteJobMutation,
        variables: {
          jobName,
        },
        update: (proxy) => {
          const getJobId = name => proxy.identify({
            __typename: 'Job',
            name,
          });

          proxy.modify({
            id: 'ROOT_QUERY',
            fields: {
              allJobs(allJobs, { readField }) {
                return allJobs.filter(
                  job => jobName !== readField('name', job),
                );
              },
            },
          });

          proxy.evict({
            id: getJobId(jobName),
          });
        },
      }).catch(() => {
        addNotification({
          message: `Could not delete the project ${jobName}`,
          options: {
            variant: 'error',
          },
        });
      }).finally(() => dispatch(loadingChanged(false)));
    },
    [apolloClient, addNotification, dispatch],
  );
}
