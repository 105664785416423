import { gql, useApolloClient } from '@apollo/client';
import { get, isEqual } from 'lodash';

const getErrorMessage = (testCriteria, customer) => {
  const errors = [
    {
      criteria: { isValid: true },
      message: 'Customer is not valid',
    },
    {
      criteria: { isB2C: false },
      message: 'Customer is B2C customer, but expected to be B2B',
    },
    {
      criteria: { hasCreditLimit: true },
      message: 'Customer has no credit limit',
    },
    {
      criteria: { hasSalesAgreements: true },
      message: 'Customer has no sales agreements',
    },
    {
      criteria: { hasSeveralShippingAddresses: true },
      message: `Customer has ${customer.shippingAddressesCount} shipping address but expected 2 or more`,
    },
    {
      criteria: { hasBillingAddress: true },
      message: 'Customer billing address is not available as shipping address on checkout.'
    }
  ];
  return errors.find((error) => isEqual(error.criteria, testCriteria))?.message;
};

const apolloClient = useApolloClient();

export const customerValidator = (schema, jobName, sanaVersion, testCriteria) =>
  schema.test('validCustomer', (value, { createError }) => {
    if (!value || value === '-' || !sanaVersion.includes('SCC')) return true;

    const validationCriteria = { isValid: true, isB2C: false, ...testCriteria };
    let queryFields = Object.keys(validationCriteria).join(" ");
    if (queryFields.includes('hasSeveralShippingAddresses'))
      queryFields = `${queryFields} shippingAddressesCount`;

    const getCustomerInfoQuery = gql`
    query getCustomerInfoQuery($jobName: String!, $customerId: String!) {
      customerInfo(jobName: $jobName, customerId: $customerId) {
        ${queryFields}        
      }      
    }`;

    return apolloClient
      .query({
        query: getCustomerInfoQuery,
        variables: {
          jobName,
          customerId: value
        },
      })
      .then((result) => {
        var customer = get(result, 'data.customerInfo');
        if (customer === null) {
          return createError({ message: 'Customer does not exist' });
        }

        for (const property in validationCriteria) {
          if (customer[property] !== validationCriteria[property])
            return createError({
              message: getErrorMessage({ [property]: validationCriteria[property] }, customer),
            });
        }
        return true;
      })
      .catch(() => true);
  });
