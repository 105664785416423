import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTab from '@material-ui/core/Tab';

const Tab = withStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
    marginRight: theme.spacing(1),
    minWidth: 0,
    '&:focus': {
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {},
}))(props => <MuiTab disableRipple {...props} />);

export default Tab;
