import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

export const subscriptionTypes = {
  Essential: 'essential',
  Pro: 'pro',
  Advanced: 'advanced',
};

const getSubscriptionQuery = gql`
  query getSubscriptionQuery($jobName: String!) {
    subscription(jobName: $jobName)
  }
`;

const useSubscription = ({ jobName }) => {
  const { data, loading } = useQuery(getSubscriptionQuery, {
    variables: {
      jobName
    },
    skip: !jobName,
    fetchPolicy: 'cache-and-network',
  });

  const subscriptionType = get(data, 'subscription');

  return { subscriptionType, loading };
};

export default useSubscription;
