import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { getReturnUrl } from 'util/routes';

export function useLogin() {
  const { instance } = useMsal();
  const history = useHistory();

  return useCallback(() => instance.loginRedirect().then(() => {
    const returnUrl = getReturnUrl();
    if (returnUrl) {
      history.push(returnUrl);
    } else {
      history.push('/');
    }
  }), [instance, history]);
}
