import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToFavorites, removeFromFavorites } from './actions';
import type { FavoritesState } from './types';

export default function useFavoritesBehavior(jobName: string) {
  const dispatch = useDispatch();
  const favorites = useSelector((state: FavoritesState) => state.favorites);

  const addFavorite = useCallback(() => dispatch(addToFavorites(jobName)),
    [dispatch, jobName]);

  const removeFavorite = useCallback(() => dispatch(removeFromFavorites(jobName)),
    [dispatch, jobName]);

  const favorite = favorites.includes(jobName);

  return {
    favorite,
    addFavorite,
    removeFavorite,
  };
}
