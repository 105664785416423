import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(1),
  },
}));

function Warning({ children, severity = 'warning', className = '' }) {
  const classes = useStyles();
  return (
    <Alert
      severity={severity}
      className={clsx(classes.alert, 'warningAlert', className)}
    >
      {children}
    </Alert>
  );
}

export default Warning;

Warning.propTypes = {
  children: PropTypes.node.isRequired,
};
