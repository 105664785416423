import { Button, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import IllustratedMessage from 'components/IllustratedMessage';
import { useAuthentication } from 'behaviour/authentication';
import PageTitle from 'components/PageTitle';
import loginImg from '../asserts/undraw_stars_re_6je7.svg';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '800px',
    margin: '0 auto',
    paddingTop: theme.spacing(20),
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const { enabled, login } = useAuthentication();

  return (
    <>
      <PageTitle title="Log in" />
      <Grid container className={`${classes.root} loginPage`}>
        <IllustratedMessage
          imageSrc={loginImg}
          imageTitle="Login"
          title="Automation Hub"
          subTitle={enabled ? '' : ('Login is disabled')}
          actionBox={<Button color="primary" onClick={() => login()}>Login with Azure AD</Button>}
        />
      </Grid>
    </>
  );
}
