import Cron from 'cronstrue';
import { makeSchema } from 'components/form/builder';
import { SwitchEditor, CronEditor } from 'components/form/editors';

export const schemaGenerator = jobConfig => makeSchema({
  sections: [{
    inputs: [{
      name: 'buildPeriodically',
      label: 'Run periodically',
      defaultValue: jobConfig.buildPeriodically || false,
      input: SwitchEditor,
    },
    {
      name: 'timerTrigger',
      defaultValue: jobConfig.timerTrigger || 'H 3 * * 6',
      helperText: value => `Project will run periodically ${Cron.toString(value).replace(', only', ' UTC, only')}`,
      disabled: values => values.buildPeriodically === false,
      input: CronEditor,
    }],
  }],
});
