import React, { useCallback } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import get from 'lodash/get';
import { useNotifications } from 'behaviour/notifications';
import { OverwriteConfiguration } from 'components/job';
import { getSanaVersionQuery } from './useSanaVersion';

export const jobConfigurationFragment = gql`
  fragment jobConfigurationFragment on JobConfiguration {
    versionToken
    parameters {
      name,
      type,
      value
    },
    isParameterized,
    nonBuildableReason,
    buildPeriodically,
    timerTrigger
  }
`;

const updateJobConfigurationMutation = gql`
  mutation updateJobConfiguration($jobName:String!, $configuration:JobConfigurationInput!, $overwrite:Boolean = false) {
    job (jobName: $jobName) {
      updateConfiguration(configuration:$configuration, overwrite:$overwrite) {
        ...jobConfigurationFragment
      }
    }
  }
  ${jobConfigurationFragment}
`;

export const configurationFragment = gql`
  fragment configurationFragment on Job {
    configuration {
      ...jobConfigurationFragment
    }
  }
  ${jobConfigurationFragment}
`;

const useUpdateJobConfigurationMutation = () => {
  const apolloClient = useApolloClient();
  const { addNotification } = useNotifications();

  const isSanaCloudVersion = (configuration) =>
    JSON.parse(
      configuration.parameters.find((p) => p.name === 'Parameters').value
    ).sanaVersion.includes('SCC');

  const mutate = useCallback(({
    jobName,
    configuration,
    overwrite = false,
  }) => apolloClient.mutate({
    mutation: updateJobConfigurationMutation,
    variables: {
      jobName,
      configuration,
      overwrite,
    },
    update: (proxy, result) => {
      const updatedConfiguration = get(result, 'data.job.updateConfiguration');
      if (!updatedConfiguration) { return; }

      proxy.writeFragment({
        fragment: configurationFragment,
        fragmentName: 'configurationFragment',
        id: proxy.identify({
          __typename: 'Job',
          name: jobName,
        }),
        data: {
          __typename: 'Job',
          name: jobName,
          configuration: {
            __typename: 'JobConfiguration',
            ...updatedConfiguration,
          },
        },
      });

      addNotification({
        message: 'Project configuration updated successfully',
        options: {
          variant: 'success',
        },
      });
    },
    refetchQueries: isSanaCloudVersion(configuration) ? [getSanaVersionQuery] : [],
  }).catch((error) => {
    const networkErrors = error.networkError.result.errors;
    networkErrors.map((err) => {
      switch (err.extensions.code) {
        case 'OPTIMISTIC_CONCURRENCY':
          return addNotification({
            message: 'The project configuration you are working on has been modified by another user. Changes you have made have not been saved',
            options: {
              variant: 'error',
              type: 'alert',
              action: key => (
                <OverwriteConfiguration
                  notificationKey={key}
                  jobName={jobName}
                  configuration={configuration}
                />
              ),
            },
          });

        default:
          return addNotification({
            message: 'Cannot update a project configuration',
            options: {
              variant: 'error',
            },
          });
      }
    });
  }), [apolloClient, addNotification]);

  return mutate;
};

export default useUpdateJobConfigurationMutation;
