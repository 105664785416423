export const ShippingMethodValidationMessage = 'Shipping method with specified name and calculation method does not exist or disabled in Admin';
export const NotFilledProductWithDimensionsValidationMessage = 'Please fill product with length, width, height and weight first.';

export const ShippingCalculationMethod = {
  USPS: 'USPS',
  USPSDomestic: 'UspsDomestic',
  USPSInternational: 'UspsInternational',
  UPS: 'UPS',
  UPSImperial: 'UpsImperial',
  UPSMetric: 'UpsMetric',
  FEDEX: 'FedEx',
  Consignor: 'Consignor',
};
