import { gql, useApolloClient } from '@apollo/client';
import { get } from 'lodash';

const testWebsiteUrlQuery = gql`
  query testWebsiteUrlQuery(
    $websiteUrl: String!
    $email: String!
    $password: String!
  ) {
    testWebsiteUrl(websiteUrl: $websiteUrl, email: $email, password: $password)
  }
`;

const apolloClient = useApolloClient();

export const websiteUrlValidator = (schema, sanaVersion) =>
  schema.test(
    'validWebsiteUrl',
    'The hub cannot connect to the Sana webshop. Make sure the website url is correct.',
    async function (value, context) {
      const {
        websiteUrl,
        admin_email: email,
        admin_password: password,
        sanaVersion: contextSanaVersion
      } = context.parent;

      const version = sanaVersion || contextSanaVersion;

      if (!websiteUrl || !email || !password || !version?.includes('SCC')) return true;

      return apolloClient
        .query({
          query: testWebsiteUrlQuery,
          variables: {
            websiteUrl,
            email,
            password,
          },
        })
        .then((result) => get(result, 'data.testWebsiteUrl'))
        .catch(() => true);
    }
  );
