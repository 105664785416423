import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core';
import { TextField } from 'components/form';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  input: {
  },
  required: {
    color: theme.palette.error.dark,
    paddingLeft: '2px',
  },
}));

function InputLabel(label, required) {
  const classes = useStyles();

  if (required) {
    return (
      <>
        {label}
        <span className={classes.required}>*</span>
      </>
    );
  }

  return (
    <>
      {label}
    </>
  );
}

function TextEditor({
  name, label, required = false, helperText = '', ...props
}) {
  const classes = useStyles();

  return (
    <Field
      name={name}
      {...props}
      label={InputLabel(label, required)}
      helperText={helperText}
      component={TextField}
      className={clsx(classes.input, { required })}
    />
  );
}

TextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default memo(TextEditor);
