import { pluck, tap, ignoreElements } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { AppAction, SWITCH_DARK_MODE } from './actions';

/**
* @param {import("rxjs").Observable} action$
*/
const appEpics: Epic<AppAction> = (action$) => {
  const darkModeSwitch$ = action$.pipe(
    ofType(SWITCH_DARK_MODE),
    pluck('payload'),
    tap(({ darkMode }) => {
      localStorage.setItem('dark-mode', darkMode ? 'true' : 'false');
    }),
    ignoreElements(),
  );

  return darkModeSwitch$;
};

export default appEpics;
