import { combineEpics } from 'util/rxjs';
import buildEpics from './builds/epics';
import jobEpics from './jobs/epics';
import appEpics from './app/epics';

export default combineEpics(
  buildEpics,
  jobEpics,
  appEpics,
);
