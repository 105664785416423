import { createContext } from 'react';
import type { AuthenticationContextType } from './types';

const AuthenticationContext = createContext<AuthenticationContextType>({
  enabled: false,
  username: '',
  isAuthenticated: false,
});

export default AuthenticationContext;
