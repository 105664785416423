import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'components/form/builder/FormBuilder';
import { get } from 'lodash';
import { schemaGenerator } from './schema';

function BuildTriggerForm({
  configuration, updateConfiguration,
}) {
  const parameters = get(configuration, 'parameters.Parameters.value') || {};
  const schema = useMemo(
    () => schemaGenerator(configuration),
    [configuration],
  );

  const handleSubmit = useCallback(({ values }, { setSubmitting, resetForm }) => {
    const mergedValues = { ...parameters, ...values };

    updateConfiguration({
      versionToken: configuration.versionToken,
      buildPeriodically: values.buildPeriodically,
      timerTrigger: values.timerTrigger,
      parameters: [{
        name: 'Parameters',
        value: JSON.stringify(mergedValues),
        type: 'MULTILINE',
      }],
    }).then(() => {
      setSubmitting(false);
      resetForm({ values });
    });
  }, [parameters, configuration, updateConfiguration]);

  return (
    <FormBuilder
      schema={schema}
      onSubmit={handleSubmit}
    />
  );
}

BuildTriggerForm.propTypes = {
  configuration: PropTypes.shape({
    versionToken: PropTypes.string.isRequired,
  }),
  updateConfiguration: PropTypes.func.isRequired,
};

export default BuildTriggerForm;
