import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  List, CellMeasurerCache, CellMeasurer, WindowScroller,
} from 'react-virtualized';
import Markdown from 'components/markdown';
import { gql } from '@apollo/client';

const useStyles = makeStyles(() => ({
  list: {
    outline: 'none',
  },
}));

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 20,
});

function BuildOutputViewer({ output = {}, width }) {
  const classes = useStyles();
  const { content = [] } = output;

  return (
    <WindowScroller>
      {({
        height, isScrolling, onChildScroll, scrollTop,
      }) => (
        <List
          autoHeight
          height={height}
          isScrolling={isScrolling}
          onScroll={onChildScroll}
          scrollTop={scrollTop}
          className={classes.list}
          width={width}
          rowCount={content.length}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={({ index, parent, style }) => (
            <CellMeasurer
              key={index}
              cache={cache}
              parent={parent}
              columnIndex={0}
              rowIndex={index}
            >
              <Markdown variant="body2" style={style}>
                {content[index]}
              </Markdown>
            </CellMeasurer>
          )
          }
        />
      )}
    </WindowScroller>
  );
}

export const buildOutputViewerFragment = gql`
  fragment buildOutputViewerFragment on BuildOutputResult {
    content
    hasMoreData
    nextOffset
  }
`;

BuildOutputViewer.propTypes = {
  output: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasMoreData: PropTypes.bool.isRequired,
    nextOffset: PropTypes.number.isRequired,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default BuildOutputViewer;
