import { merge, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const ERROR_PRIORITIES = Object.freeze({
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
});

const getEmpty = () => EMPTY;
export const handleError = (priority = ERROR_PRIORITIES.MEDIUM, epicName = '<anonymous>', continueWith = getEmpty) => catchError((e, o) => {
  const continueObservable = continueWith(e, o);

  if (priority === ERROR_PRIORITIES.LOW) { return continueObservable; }

  console.error(`The following error occurred in "${epicName}" epic:`, e);

  return continueObservable;
});

export function combineWithErrorCatch(...epics) {
  function combineEpics(...args) {
    return merge(
      ...epics.map((epic) => {
        let output$ = epic(...args);
        if (!output$) {
          throw new TypeError(`combineEpics: one of the provided Epics "${epic.name || '<anonymous>'}" does not return a stream.
Double check you're not missing a return statement!`);
        }

        output$ = output$.pipe(
          handleError(ERROR_PRIORITIES.HIGH, epic.displayName || epic.name, () => output$),
        );

        return output$;
      }),
    );
  }

  return combineEpics;
}
