import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

export const getSanaVersionQuery = gql`
query getSanaVersionQuery($jobName: String!) {
  sanaVersion(jobName: $jobName)
}
`;

const useSanaVersion = ({ jobName, packageVersion }) => {
  const { data, loading } = useQuery(getSanaVersionQuery, {
    variables: {
      jobName,
    },
    skip: !packageVersion.includes('SCC'),
    fetchPolicy: 'cache-and-network',
  });

  const sanaVersion = get(data, 'sanaVersion') || null;

  return { sanaVersion, loading };
};

export default useSanaVersion;
