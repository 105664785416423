import { TagsEditor } from 'components/form/editors';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

function ReportPortalTagsEditor({
  name, label = '', disabled = false, ...props
}) {
  const { values, setFieldValue } = useFormikContext();
  const { sanaVersion, reportPortal_Tags } = values;

  useEffect(() => {
    const versionTag = reportPortal_Tags.find(tag => tag.includes("SCC"));
    if (versionTag && versionTag === `v${sanaVersion}`)
      return;

    const updatedTags = reportPortal_Tags.map(tag => tag.replace(/^vSCC.*/, `v${sanaVersion}`));
    setFieldValue(name, updatedTags);
  }, [sanaVersion, name, setFieldValue]);

  return (
    <TagsEditor
      name={name}
      label={label}
      disabled={disabled}
      {...props}
    />
  );
}

ReportPortalTagsEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(ReportPortalTagsEditor);
