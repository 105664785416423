import { makeStyles, TableCell } from '@material-ui/core';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import Link from 'components/Link';
import LogActions from './LogActions';

const useClasses = makeStyles(() => ({
  tableRow: {
    display: 'table-row',
    outline: 'none',
    textDecoration: 'none',
    transition: 'background 80ms linear',
    verticalAlign: 'middle',
  },
}));

const formatDateTime = (date) => {
  if (date == null) { return null; }

  return new Date(date).toLocaleString();
};

function LogItem({ file }) {
  const classes = useClasses();

  return (
    <Link
      color="inherit"
      to={`/logs/${file.name}`}
      key={file.name}
      className={`MuiTableRow-root MuiTableRow-hover ${classes.tableRow}`}
      underline="none"
      role="row"
      target="_blank"
      tabIndex={-1}
    >
      <TableCell className="name">{file.name}</TableCell>
      <TableCell className="size">{file.length}</TableCell>
      <TableCell className="lastModified">{formatDateTime(file.lastModified)}</TableCell>
      <TableCell align="right" className="actions">
        <LogActions {...file} />
      </TableCell>
    </Link>
  );
}

export const logListItemFragment = gql`
  fragment logListItemFragment on Logs {
    name,
    length,
    lastModified
  }
`;

LogItem.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
  }).isRequired,
};

export default memo(LogItem);
