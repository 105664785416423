import React, { memo } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import DataGrid, { useFetchMore, makeColumns, useFilter } from 'components/dataGrid';
import makerLaunch from 'asserts/maker_launch.svg';
import { gql } from '@apollo/client';
import IllustratedMessage from 'components/IllustratedMessage';
import JobItem, { jobListItemFragment } from './JobItem';
import JobListFilter from './JobListFilter';

const ignoreCasePropertySorter = (items, sortProperty, ascending) => orderBy(
  items,
  [item => item[sortProperty] && item[sortProperty].toLowerCase()],
  [ascending ? 'asc' : 'desc'],
);

const columns = makeColumns([
  {
    id: 'lastBuild.result', label: 'Status', contentFit: true, sortable: true,
  },
  {
    id: 'displayName', label: 'Name', wide: true, sortable: ignoreCasePropertySorter,
  },
  {
    id: 'lastBuild.timestamp', disablePadding: true, label: 'Last run time', sortable: true,
  },
  {
    id: 'packageVersion', disablePadding: true, label: 'Version', sortable: true,
  },
  { id: '' },
]);

const Row = ({ data, ...rowProps }) => <JobItem job={data} key={data.name} {...rowProps} />;

function JobListContent({
  jobs = [],
  loading = false,
  currentPage = 0,
  onPageChange,
  currentRowsPerPage = 50,
  onRowsPerPageChange,
  onFetchMore = null,
  currentSort = 'displayName',
  currentOrder = 'asc',
  onSortChange,
}) {
  const { filteredItems, onFilterChanged } = useFilter(jobs);
  const { pagedItems, totalCount, fetchMore } = useFetchMore(filteredItems, onFetchMore);

  return (
    <>
      <JobListFilter items={jobs} onFilterChanged={onFilterChanged} />
      {jobs.length > 0 && !loading && filteredItems.length === 0
        ? (
          <IllustratedMessage
            imageSrc={makerLaunch}
            title="There is no item available for selected filters"
          />
        )
        : (
          <DataGrid
            className="jobsTable"
            columns={columns}
            fetchMore={fetchMore}
            totalCount={totalCount}
            items={pagedItems}
            loading={loading || (jobs.length > 0 && pagedItems.length === 0)}
            renderRow={Row}
            defaultSortProperty={currentSort}
            defaultSortAscending={currentOrder === 'asc'}
            defaultPage={currentPage}
            defaultRowsPerPage={currentRowsPerPage}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            onSortChange={onSortChange}
          />
        )
      }
    </>
  );
}

export const jobListFragment = gql`
  fragment jobListFragment on Job {
    ...jobListItemFragment
  }
  ${jobListItemFragment}
`;

Row.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

JobListContent.propTypes = {
  onFetchMore: PropTypes.func,
  loading: PropTypes.bool,
  jobs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  currentRowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  currentSort: PropTypes.string,
  currentOrder: PropTypes.oneOf(['asc', 'desc']),
  onSortChange: PropTypes.func,
};

export default memo(JobListContent);
