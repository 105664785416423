import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { InternalServerErrorMessage } from 'components/errorStates';
import { getTraceIdentifier } from 'util/apollo';
import BuildOutputContent, { buildOutputContentFragment } from './BuildOutputContent';

const loadBuildLogsQuery = gql`
  query loadBuildLogs($jobName: String!, $buildNumber: Int!) {
    job(name: $jobName) {
      name
      build(buildNumber: $buildNumber) {
        id
        building
        ...buildOutputContentFragment
      }
    }
  }
  ${buildOutputContentFragment}
`;

function BuildOutput({ jobName, buildNumber }) {
  const {
    data, loading, error, startPolling, stopPolling,
  } = useQuery(loadBuildLogsQuery, {
    variables: {
      jobName,
      buildNumber,
    },
    fetchPolicy: 'cache-and-network',
  });

  const build = get(data, 'job.build') || { number: buildNumber };

  useEffect(() => {
    if (build && build.building) {
      startPolling(5000);
      return () => stopPolling();
    }

    return undefined;
  }, [build, startPolling, stopPolling]);

  if (error) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  return (
    <BuildOutputContent
      jobName={jobName}
      build={build}
      loading={loading}
    />
  );
}

BuildOutput.propTypes = {
  jobName: PropTypes.string.isRequired,
  buildNumber: PropTypes.number.isRequired,
};

export default BuildOutput;
