import { useCallback, useState } from 'react';
import { filter } from 'lodash';

export function useFilter(items = []) {
  const [filteredItems, setFilteredItems] = useState(items);

  const handleFilterChanged = useCallback((predicate) => {
    setFilteredItems(filter(items, v => predicate(v)));
  }, [items]);

  return {
    filteredItems,
    onFilterChanged: handleFilterChanged,
  };
}
