import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { red, green, grey } from '@material-ui/core/colors';
import Tooltip from 'components/Tooltip';
import CheckIcon from '@material-ui/icons/CheckCircleTwoTone';
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import CancelIcon from '@material-ui/icons/CancelTwoTone';
import { CircularProgress } from '@material-ui/core';
import { gql } from '@apollo/client';

export const indexingRunStatusFragment = gql`
  fragment indexingRunStatusFragment on IndexingRun {
    status
  }
`;

export const RunStatus = 
{
  Enqueued: 'Enqueued',
  Processing: 'Processing',
  Succeeded: 'Succeeded',
  Failed: 'Failed'
}

const useStyles = makeStyles(() => ({
  success: {
    color: green[500],
  },
  failed: {
    color: red[500],
  },
  cancel: {
    color: grey[500],
  },
}));

function IndexingRunStatus({ status, className = '' }) {
  const fontSize = 'small';
  const classes = useStyles();

  if (status === RunStatus.Processing || status === RunStatus.Enqueued) {
    return (
      <Tooltip title="Task is in progress">
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="running"
          size={20}
          thickness={4}
        />
      </Tooltip>
    );
  }

  if (status === RunStatus.Succeeded) {
    return (
      <Tooltip title="Finished successfully" className={className}>
        <CheckIcon className={classes.success} fontSize={fontSize} />
      </Tooltip>
    );
  }

  if (status === RunStatus.Failed) {
    return (
      <Tooltip title="Finished with errors" className={className}>
        <ErrorIcon className={classes.failed} fontSize={fontSize} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Aborted" className={className}>
      <CancelIcon className={classes.cancel} fontSize={fontSize} />
    </Tooltip>
  );
}

IndexingRunStatus.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default memo(IndexingRunStatus);
