import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import { gql } from '@apollo/client';
import BuildOutputViewer, { buildOutputViewerFragment } from './BuildOutputViewer';
import DownloadButtonGroup from './DownloadButtonGroup';
import Resizer from './Resizer';
import BuildLogsLoading from './BuildLogsLoading';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  info: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  logs: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

function BuildOutputContent({
  jobName, build, loading = false,
}) {
  const classes = useStyles();
  const { number: buildNumber } = build;

  return (
    <>
      <Toolbar variant="dense">
        <DownloadButtonGroup jobName={jobName} buildNumber={buildNumber} />
      </Toolbar>
      <Resizer className={classes.logs}>
        {({ width }) => (
          <>
            <BuildLogsLoading loading={loading}>
              {build.output && <BuildOutputViewer output={build.output} width={width} />}
            </BuildLogsLoading>
          </>
        )}
      </Resizer>
    </>
  );
}

export const buildOutputContentFragment = gql`
  fragment buildOutputContentFragment on Build {
    number
    output {
      ...buildOutputViewerFragment
    }
  }
  ${buildOutputViewerFragment}
`;

BuildOutputContent.propTypes = {
  jobName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  build: PropTypes.shape({
    number: PropTypes.number.isRequired,
    output: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.string),
      hasMoreData: PropTypes.bool,
      nextOffset: PropTypes.number,
    }),
  }),
};

export default BuildOutputContent;
