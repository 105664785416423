import { makeStyles } from '@material-ui/core/styles';
import { useNotifications } from 'behaviour/notifications';
import FormContext from 'components/form/builder/FormBuilderContext';
import { useFormikContext } from 'formik';
import { set } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useContext, useState } from 'react';
import { handleErrors } from 'util/apollo';
import PrefillButton from './PrefillButton';
import PrefillProgressIndicator from './PrefillProgressIndicator';
import useLoadTestData from './useLoadTestData';
import { getInputsToPrefill } from './utils';

const useClasses = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5),
    width: '100%'
  }
}));

function PrefillContent({ jobName, schema }) {
  const classes = useClasses();
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext();
  const [isPrefilling, setIsPrefilling] = useState(false);
  const [currentInput, setCurrentInput] = useState(0);
  const { expansionPanelsState } = useContext(FormContext);
  const { setExpandAllSections } = expansionPanelsState;
  const loadTestData = useLoadTestData();
  const { addNotification } = useNotifications();

  const inputsToPrefill = getInputsToPrefill(schema);

  const prefillInputs = async () => {
    setIsPrefilling(true);
    setExpandAllSections(true);

    let formValues = {};
    let completedSuccessfully = true;
    for (const [index, input] of inputsToPrefill.entries()) {
      setCurrentInput(index);
      const { name, prefill } = input;
      setFieldTouched(name, true, false);

      await loadTestData(jobName, prefill, formValues)
        .then(loadedData => {
          const item = loadedData.data.testData.items[0];
          const id = item?.id ?? '';
          setFieldValue(name, id);
          set(formValues, name, id);

          //prefill related data (Customer with ERP attachment and Sales report)
          const { onValueChange } = prefill;
          if (onValueChange) {
            var relatedData = {};
            if (item?.relatedData) item.relatedData.forEach(({ key, value }) => set(relatedData, key, value));
            onValueChange({ relatedData, setFieldValue });
          }
        })
        .catch(error => {
          completedSuccessfully = false;
          handleErrors(name, error, setFieldError);
        });
    }

    setIsPrefilling(false);

    if (completedSuccessfully)
      addNotification({
        message: 'The test data has been successfully prefilled! Please review the entries and click "Save changes" button.',
        options: {
          variant: 'success',
        }, addNotification
      });
    else
      addNotification({
        message: 'The test data prefill encountered errors. Please review the highlighted fields for corrections.',
        options: {
          variant: 'error',
        }, addNotification
      });
  };

  return (
    <div className={classes.root}>
      <PrefillButton jobName={jobName} schema={schema} isPrefilling={isPrefilling} onClick={prefillInputs} />
      {isPrefilling && (
        <PrefillProgressIndicator totalCountToPrefill={inputsToPrefill.length} currentInputIndex={currentInput} />
      )}
    </div>
  );
}

PrefillContent.propTypes = {
  jobName: PropTypes.string.isRequired,
  schema: PropTypes.shape().isRequired
};

export default memo(PrefillContent);
