import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useEventListener } from 'util/react/hooks';

const useStyles = makeStyles(() => ({
  alert: {
    margin: '0 auto',
  },
}));

const applicationQuery = gql`
  query loadMaintenanceAlert{
    app {
      id
      maintenance {
        enabled
      }
    }
  }
`;

function MaintenanceAlert() {
  const classes = useStyles();
  const {
    data, loading, error, refetch, previousData,
  } = useQuery(applicationQuery, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
  });
  const location = useLocation();
  const isMaintenance = get(data, 'app.maintenance.enabled') || false;
  const previousMaintenance = get(previousData, 'app.maintenance.enabled') || false;

  useEffect(() => {
    refetch();
  }, [location, isMaintenance, refetch]);

  useEventListener('visibilitychange', () => refetch(), document);

  if (error) return null;
  if (!(loading && previousMaintenance) && !isMaintenance) {
    return null;
  }

  return (
    <div className="MuiAlert-standardWarning">
      <Container maxWidth="lg">
        <Alert
          severity="warning"
          className={classes.alert}
        >
          <AlertTitle>Maintenance mode is activated</AlertTitle>
          The application is working in viewing only mode.
        </Alert>
      </Container>
    </div>
  );
}

export default MaintenanceAlert;
