import React from 'react';
import PropTypes from 'prop-types';

const handleClick = e => e.stopPropagation();

function ClickTrap({ children }) {
  return (
    <div role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleClick}>
      {children}
    </div>
  );
}

export default ClickTrap;

ClickTrap.propTypes = {
  children: PropTypes.node.isRequired,
};
