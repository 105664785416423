import React, {
  Fragment, memo, useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core';
import FormPanel, { isRequired } from 'components/form/builder/FormPanel';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, ExpansionPanelSummary } from 'components/form/builder';
import clsx from 'clsx';
import MuiDivider from '@material-ui/core/Divider';
import TextEditor from './TextEditor';
import FormHeader from '../builder/FormHeader';
import { showError } from '../builder/utils';

const useStyles = makeStyles(theme => ({
  grid: {
    margin: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

function AddonEditor({
  addon, values, errors, validationSchema,
}) {
  const classes = useStyles();
  const { setFieldValue, touched } = useFormikContext();

  const checkboxKey = get(addon, 'checkbox.name');
  const nameInputKey = get(addon, 'nameInput.name');
  const useDefaultsKey = get(addon, 'useDefaults.name');
  const {
    title, name, description, errorKey, nameInput, useDefaults, sections,
  } = addon;
  const addonName = title || name;
  const error = errorKey && showError(errors, errorKey, touched);

  const [checked, setChecked] = useState(values[checkboxKey]);
  const [expended, setExpanded] = useState(checked);

  const handleChange = useCallback(
    (event) => {
      setChecked(event.target.checked);
      setFieldValue(checkboxKey, event.target.checked);
      // to expand when clicking the label
      setExpanded(event.target.checked);
      if (!event.target.checked) {
        setFieldValue(nameInputKey, '');
        setFieldValue(useDefaultsKey, true);
      }
    },
    [nameInputKey, setFieldValue, useDefaultsKey, checkboxKey],
  );

  return (
    <ExpansionPanel name={addonName} expanded={expended} onChange={() => setExpanded(!expended)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <FormControlLabel
          control={<Checkbox name={checkboxKey} checked={checked} onChange={handleChange} />}
          label={addonName}
          className={clsx({ [classes.error]: error })}
        />
      </ExpansionPanelSummary>
      <Grid item xs={12} className={classes.grid}>
        {description && (
          <FormHeader variant="subtitle1">
            {typeof description === 'function' ? description() : description}
          </FormHeader>
        )}

        {[nameInput, useDefaults].map((inputProps) => {
          if (!inputProps) return null;
          const {
            // eslint-disable-next-line no-shadow
            name,
            input: Input = TextEditor,
            defaultValue,
            visible = true,
            ...options
          } = inputProps;
          if (!(typeof visible === 'function' ? visible(values) : visible)) { return null; }
          return (
            <Input
              key={name}
              name={name}
              required={isRequired(validationSchema, name)}
              disabled={!checked}
              {...options}
            />
          );
        })}

        {!values[useDefaultsKey]
          && sections?.map((section, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <MuiDivider className={classes.divider} />
              <FormPanel
                {...section}
                values={values}
                validationSchema={validationSchema}
              />
            </Fragment>
          ))}
      </Grid>
    </ExpansionPanel>
  );
}

AddonEditor.propTypes = {
  addon: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    nameInput: PropTypes.shape().isRequired,
    useDefaults: PropTypes.shape(),
    sections: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validationSchema: PropTypes.shape().isRequired,
};

export default memo(AddonEditor);
