import { createTheme as createMuiTheme } from '@material-ui/core/styles';

const createTheme = (darkMode = false) => {
  const theme = createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          backgroundColor: () => theme.palette.background.default,

          '&.Mui-focused': {
            backgroundColor: 'unset',
          },
        },
        notchedOutline: {
          borderWidth: '2px',
        },
      },
      MuiDialogTitle: {
        root: {
          padding: () => theme.spacing(1, 3),
        },
      },
      MuiDialogContent: {
        root: {
          padding: () => theme.spacing(1, 3),

          '&:first-child': {
            paddingTop: 'inherit',
          },
        },
      },
      MuiChip: {
        outlined: {
          '& .MuiChip-avatar': {
            marginLeft: '5px',
          },
        },
      },
      MuiDialogActions: {
        root: {
          padding: () => theme.spacing(1, 3),
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(9, 30, 66, 0.54)',
          background: 'rgba(9, 30, 66, 0.54) none repeat scroll 0% 0%;',
        },
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: () => theme.spacing(1),
          paddingRight: () => theme.spacing(1),
        },
        dense: {
          minHeight: 'unset',
          marginTop: () => theme.spacing(0.5),
          marginBottom: () => theme.spacing(0.5),
        },
      },
      MuiButton: {
        root: {
          textTransform: 'unset',
        },
      },
    },
    props: {
      MuiButton: {
        variant: 'contained',
      },
      MuiTextField: {
        variant: 'outlined',
        fullWidth: true,
      },
      MuiCheckbox: {
        color: 'primary',
      },
      MuiSelect: {
        fullWidth: true,
      },
      MuiSwitch: {
        color: 'primary',
      },
      MuiTable: {
        component: 'div',
        style: { minWidth: 750 },
      },
      MuiTableHead: {
        component: 'div',
      },
      MuiTableBody: {
        component: 'div',
      },
      MuiTableRow: {
        component: 'div',
      },
      MuiTableCell: {
        component: 'div',
      },
      MuiMenuItem: {
        dense: true,
      },
      MuiTooltip: {
        arrow: true,
        enterDelay: 200,
      },
      MuiChip: {
        style: {
          borderRadius: '4px',
        },
      },
      MuiTab: {
        style: {
          fontSize: '1rem',
          textTransform: 'unset',
        },
      },
    },
  });
  return theme;
};

export default createTheme;
