import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MoreIcon from '@material-ui/icons/MoreVert';
import MuiLink from '@material-ui/core/Link';
import { useAuthentication } from 'behaviour/authentication';
import logoImage from 'asserts/logo3.svg';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { Tooltip } from '@material-ui/core';
import IconLabel from 'components/IconLabel';
import OpenInNewIcon from '@material-ui/icons/OpenInNewTwoTone';
import ExitToAppIcon from '@material-ui/icons/ExitToAppTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import Link from './Link';
import OmniSearchBox from './omniSearchBox';
import DarkModeSwitcher from './DarkModeSwitcher';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 0.5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: '48px',
    marginRight: theme.spacing(1),
    padding: '4px',

    [theme.breakpoints.up('sm')]: {
      marginRight: '1px',
    },
  },
  title: {
    display: 'none',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
  titleLink: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  searchWrapper: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  breadCrumbs: {
    color: theme.palette.text.primary,
  },
  helpLink: {
    lineHeight: 0,
  },
  menuItemButton: {
    padding: theme.spacing(0.5),
  },
}));

export default function SAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [feedbackEl, setFeedbackEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { name, isAuthenticated, instance } = useAuthentication();
  const theme = useTheme();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isFeedbackMenuOpen = Boolean(feedbackEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleFeedbackMenuOpen(event) {
    setFeedbackEl(event.currentTarget);
  }

  function handleFeedbackMenuClose() {
    setFeedbackEl(null);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <IconLabel icon={AccountCircleTwoToneIcon}>
          {name}
        </IconLabel>
      </MenuItem>
      {isAuthenticated && (
        <MenuItem onClick={() => instance.logout()}>
          <IconLabel icon={ExitToAppIcon}>
            Logout
          </IconLabel>
        </MenuItem>
      )}
    </Menu>
  );

  const feedbackMenuId = 'feedback-menu';
  const renderFeedbackMenu = (
    <Menu
      id={feedbackMenuId}
      anchorEl={feedbackEl}
      keepMounted
      open={isFeedbackMenuOpen}
      onClose={handleFeedbackMenuClose}
    >
      <MenuItem
        onClick={handleFeedbackMenuClose}
        component={MuiLink}
        color="inherit"
        href="https://sanacommerce.visualstudio.com/Sana%20StarterSite%20WebTests/_workitems/create/User%20Story?templateId=00ea8b84-de9b-4564-86d1-0b2835a136c5&ownerId=41d8b142-d53c-4524-bbda-ee5aef75f626"
        rel="noopener noreferrer"
        target="_blank"
        underline="none"
      >
        <IconLabel icon={OpenInNewIcon}>
          Give feedback on Web Tests
        </IconLabel>
      </MenuItem>
      <MenuItem
        onClick={handleFeedbackMenuClose}
        component={MuiLink}
        color="inherit"
        href="https://sanacommerce.visualstudio.com/Sana%20StarterSite%20WebTests/_workitems/create/User%20Story?templateId=54f69997-2bea-4ea6-979d-431cc95b7575&ownerId=41d8b142-d53c-4524-bbda-ee5aef75f626"
        rel="noopener noreferrer"
        target="_blank"
        underline="none"
      >
        <IconLabel icon={OpenInNewIcon}>
          Give feedback on Automation Hub
        </IconLabel>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleFeedbackMenuOpen}>
        <IconButton
          aria-label="give feedback"
          aria-controls="feedback-menu"
          aria-haspopup="true"
          color="inherit"
          className={classes.menuItemButton}
        >
          <FeedbackIcon />
        </IconButton>
        <span>Feedback</span>
      </MenuItem>
      {isAuthenticated && (
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label="Account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            className={classes.menuItemButton}
          >
            <AccountCircle />
          </IconButton>
          <span>Profile</span>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="relative" color={theme.palette.type === 'dark' ? 'inherit' : undefined}>
        <Toolbar variant="dense">
          <Link to="/" color="inherit" underline="none" className={classes.titleLink}>
            <img src={logoImage} alt="Logo" className={classes.logo} />
            <Typography className={classes.title} variant="h6" noWrap>
              Automation Hub
            </Typography>
          </Link>

          <div className={classes.searchWrapper}>
            <OmniSearchBox />
          </div>
          <div className={classes.sectionDesktop}>
            <Tooltip title="Give feedback">
              <IconButton
                aria-label="give feedback"
                aria-controls={feedbackMenuId}
                aria-haspopup="true"
                onClick={handleFeedbackMenuOpen}
                color="inherit"
              >
                <FeedbackIcon />
              </IconButton>
            </Tooltip>
            <DarkModeSwitcher />
            {isAuthenticated && (
              <IconButton
                aria-label="Account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
      {renderFeedbackMenu}
    </div>
  );
}
