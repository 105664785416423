import { Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { enqueueSnackbar } from '../../behaviour/notifications';

export const temporaryNotAvailableError = onError(({
  networkError, operation, response,
}) => {
  const isTemporaryUnavailableError = (networkError && networkError.statusCode === 503)
    || (response && !!response.errors.find(e => e.extensions && e.extensions.code === 'TEMPORTARY_NOT_AVAILABLE'));
  if (isTemporaryUnavailableError) {
    const context = operation.getContext();
    const store = context.getStore();

    store.dispatch(enqueueSnackbar({
      key: 'temporaryNotAvailable',
      message: 'The service is currently unavailable. Please try again later.',
      options: {
        variant: 'error',
      },
    }));

    return new Observable((o) => {
      o.next({ data: null });
      o.complete();
    });
  }

  return null;
});
