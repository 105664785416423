import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import DataRowSkeleton from './DataRowSkeleton';
import { ColumnProps } from './makeHeader';

function DataTableSkeleton({ rowsCount, columns }) {
  const template = Array.from(Array(rowsCount), (d, i) => i);

  return (
    <TableBody>
      { template.map(key => (
        <DataRowSkeleton key={key} columns={columns} />
      ))}
    </TableBody>
  );
}

DataTableSkeleton.propTypes = {
  rowsCount: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(ColumnProps).isRequired,
};

export default DataTableSkeleton;
