import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { getTraceIdentifier, isNetworkError } from 'util/apollo';
import { InternalServerErrorMessage } from 'components/errorStates';
import get from 'lodash/get';
import DataGrid, { makeColumns, useFetchMore } from 'components/dataGrid';
import IllustratedMessage from 'components/IllustratedMessage';
import makerLaunch from 'asserts/maker_launch.svg';
import LogItem, { logListItemFragment } from './LogItem';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
}));

export const loadLogListQuery = gql`
  query loadLogList{
    logs{     
      ...logListItemFragment
    }
  }
  ${logListItemFragment}
`;

const columns = makeColumns([
  { id: 'name', label: 'File name', sortable: true },
  { id: 'size', label: 'File size (KB)' },
  { id: 'lastModified', label: 'Last modified', sortable: true },
  { id: '' },
]);

function LogList() {
  const classes = useStyles();
  const { data, error, loading } = useQuery(loadLogListQuery, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
  });

  const logs = get(data, 'logs', []);
  const { pagedItems, totalCount, fetchMore } = useFetchMore(logs);

  if (error && isNetworkError(error)) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  return (
    <Paper className={`${classes.root}`}>
      {!loading && logs.length === 0
        ? (
          <IllustratedMessage
            imageSrc={makerLaunch}
            title="There are no logs available"
          />
        )
        : (
          <DataGrid
            className="logsTable"
            columns={columns}
            fetchMore={fetchMore}
            totalCount={totalCount}
            items={pagedItems}
            loading={loading}
            renderRow={({ data: file }) => (
              <LogItem
                key={file.name}
                file={file}
              />
            )}
            defaultSortProperty="lastModified"
            defaultSortAscending={false}
            pagination={false}
          />
        )}
    </Paper>
  );
}

export default LogList;
