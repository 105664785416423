import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PageTitle from 'components/PageTitle';
import { InternalServerErrorMessage } from 'components/errorStates';
import { getTraceIdentifier } from 'util/apollo';

function Page500({ error }) {
  const traceIdentifier = getTraceIdentifier(error);

  return (
    <>
      <PageTitle title="Internal server error" />
      <InternalServerErrorMessage traceIdentifier={traceIdentifier} />
    </>
  );
}

Page500.propTypes = {
  error: PropTypes.shape({
    networkError: PropTypes.shape({
      result: PropTypes.shape({
        traceIdentifier: PropTypes.string,
      }),
    }),
  }),
};

export default memo(Page500);
