import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { getTraceIdentifier, isNetworkError } from 'util/apollo';
import { InternalServerErrorMessage } from 'components/errorStates';
import { get } from 'lodash';
import JobConfigurationForm, { jobConfigurationFormFragment } from './JobConfigurationForm';
import JobConfigurationSkeleton from './JobConfigurationSkeleton';

export const loadJobConfigurationQuery = gql`
  query loadJobConfiguration($name:String!) {
    job(name:$name) {
      name,
      configuration {
        ...jobConfigurationFormFragment
      }
    }
  }
  ${jobConfigurationFormFragment}
`;

export function normilizeConfiguration(oldConfiguration) {
  if (!oldConfiguration) { return oldConfiguration; }

  const { parameters = {}, ...rest } = oldConfiguration;
  const newConfiguration = {
    ...rest,
    parameters: {},
  };

  parameters.forEach((p) => {
    newConfiguration.parameters[p.name] = {
      name: p.name,
      value: JSON.parse(p.value),
      type: p.type,
    };
  });

  return newConfiguration;
}

function JobConfiguration({ jobName }) {
  const { data, error, loading } = useQuery(loadJobConfigurationQuery, {
    variables: {
      name: jobName,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const configuration = useMemo(() => normilizeConfiguration(get(data, 'job.configuration')),
    [data]);

  if (error && isNetworkError(error)) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  if (loading) {
    return <JobConfigurationSkeleton />;
  }

  return (
    <JobConfigurationForm
      jobName={jobName}
      configuration={configuration}
      loading={loading}
    />
  );
}


JobConfiguration.propTypes = {
  jobName: PropTypes.string.isRequired,
};

export default JobConfiguration;
