import React from 'react';
import Warning from 'components/Warning';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  }
}));

function BridgeNotInstalledAlert() {
  const classes = useStyles();

  return (
    <Warning severity='error' className={classes.root}>
      Bridge is not installed. Automated tests cannot be run. Please install the Bridge and refresh the page.
    </Warning>
  );
}

export default BridgeNotInstalledAlert;
