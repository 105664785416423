import React from 'react';
import PropTypes from 'prop-types';
import IllustratedMessage from 'components/IllustratedMessage';
import noDataImage from 'asserts/empty_report.svg';
import pendingDataImage from 'asserts/pending_report.svg';
import cancelBuildImage from 'asserts/aborted_report.svg';

function TestReportStatus({
  build, isEmpty, loading, children,
}) {
  const inProgress = build && build.building === true;
  const isCancelled = build && build.result && build.result.toLowerCase() === 'aborted';
  const loaded = !loading;

  if (loaded && inProgress) {
    return (
      <IllustratedMessage
        imageSrc={pendingDataImage}
        className="testReportPending"
        title="Run is currently in progress"
        subTitle="You will see the test report as soon as the launch is completed."
      />
    );
  }

  if (loaded && isCancelled) {
    return (
      <IllustratedMessage
        imageSrc={cancelBuildImage}
        className="testReportCancelled"
        title="The test report is not available"
        subTitle="The run has cancelled before the test report has collected."
      />
    );
  }

  if (loaded && isEmpty) {
    return (
      <IllustratedMessage
        imageSrc={noDataImage}
        className="testReportEmpty"
        title="There are no tests archived for this run"
        subTitle="It may happen when project is not configured to report test progress."
      />
    );
  }

  return children;
}

TestReportStatus.propTypes = {
  build: PropTypes.shape({
    building: PropTypes.bool.isRequired,
    result: PropTypes.string,
  }),
  testCases: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default TestReportStatus;
