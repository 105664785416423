import { ADD_FAVORITE, FavoritesAction, REMOVE_FAVORITE } from './actions';

const loadFromLocalStorage = (): string[] => {
  try {
    const favorites = localStorage.getItem('favorites');
    if (favorites === null) {
      return [];
    }
    return JSON.parse(favorites);
  } catch (err) {
    return [];
  }
};

const initialState = loadFromLocalStorage();

export default function reducer(state = initialState, action: FavoritesAction): string[] {
  switch (action.type) {
    case ADD_FAVORITE:
      if (state.some(item => item === action.payload)) return state;
      return [
        ...state,
        action.payload,
      ];

    case REMOVE_FAVORITE:
      if (state.some(item => item === action.payload)) {
        return [
          ...state.filter(item => item !== action.payload),
        ];
      }
      return state;

    default:
      return state;
  }
}
