import React from 'react';
import PropTypes from 'prop-types';
import InputSkeleton from './InputSkeleton';
import ButtonSkeleton from './ButtonSkeleton';

function FormSkeleton({ inputs }) {
  const template = Array.from(Array(inputs), (d, i) => i);

  return (
    <div>
      {template.map(key => (
        <InputSkeleton key={key} />
      ))}
      <ButtonSkeleton />
    </div>
  );
}

FormSkeleton.propTypes = {
  inputs: PropTypes.number.isRequired,
};

export default FormSkeleton;
