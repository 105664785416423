import React, {
  useState, memo, Fragment, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  Menu, MenuItem, ListItemIcon, ListItemText, Tooltip,
} from '@material-ui/core';
import StopIcon from '@material-ui/icons/StopTwoTone';
import MuiLink from '@material-ui/core/Link';
import ClickTrap from 'components/ClickTrap';
import { ReportPortalIcon } from 'components/icons';
import { useStopBuildMutation } from 'behaviour/builds';

import { gql } from '@apollo/client';

const useStyles = makeStyles(() => ({
  listMenuIcon: {
    minWidth: '30px',
  },
}));

function BuildActions({
  number, building, cancelling, jobName, launchInfo,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const stopBuildMutation = useStopBuildMutation();
  const cancelRun = useCallback((e) => {
    // eslint-disable-next-line no-use-before-define
    handleMenuClose(e);
    stopBuildMutation({ jobName, buildNumber: number });
  }, [jobName, number, stopBuildMutation]);

  const handleShowMoreClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e) => {
    if (e) e.preventDefault();
    setAnchorEl(null);
  };

  const renderShowMoreMenu = (
    <ClickTrap>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="actionsMenu"
      >
        <MenuItem
          disabled={!launchInfo}
          component={MuiLink}
          color="inherit"
          href={`${launchInfo && launchInfo.reportPortalUrl}`}
          rel="noopener noreferrer"
          target="_blank"
          className="reportPortalLink"
        >
          <ListItemIcon className={classes.listMenuIcon}>
            <ReportPortalIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View in Report Portal" />
        </MenuItem>
        <MenuItem
          disabled={!building || cancelling}
          onClick={cancelRun}
          className="cancelRun"
        >
          <ListItemIcon className={classes.listMenuIcon}>
            <StopIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={cancelling ? 'Cancelling' : 'Cancel'} />
        </MenuItem>
      </Menu>
    </ClickTrap>
  );

  return (
    <Fragment>
      <Tooltip title="Actions">
        <IconButton size="small" onClick={e => handleShowMoreClick(e)}>
          <MoreIcon />
        </IconButton>
      </Tooltip>
      {renderShowMoreMenu}
    </Fragment>
  );
}

export const buildListActionsFragment = gql`
  fragment buildListActionsFragment on Build {
    building
    number
    cancelling
    launchInfo {
      reportPortalId
      reportPortalUrl
    }
  }
`;

BuildActions.propTypes = {
  number: PropTypes.number.isRequired,
  building: PropTypes.bool.isRequired,
  launchInfo: PropTypes.shape({
    reportPortalUrl: PropTypes.string,
    reportPortalId: PropTypes.string,
  }),
  jobName: PropTypes.string.isRequired,
  cancelling: PropTypes.bool,
};

export default memo(BuildActions);
