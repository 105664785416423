import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  red, green, orange, grey,
} from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/CheckCircleTwoTone';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import Brightness1TwoToneIcon from '@material-ui/icons/Brightness1TwoTone';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  success: {
    color: green[500],
  },
  warning: {
    color: orange[500],
  },
  failed: {
    color: red[500],
  },
  running: {
    animationDuration: '750ms',
    marginLeft: '2px',
  },
  cancel: {
    color: grey[500],
  },
}));

function TestCaseStatus({ status, className }) {
  const classes = useStyles();
  const statusNormalized = status && status.toLowerCase();

  if (statusNormalized === 'passed') {
    return (
      <CheckIcon className={clsx(classes.success, className, 'passed')} />
    );
  }

  if (statusNormalized === 'skipped') {
    return (<WarningIcon className={clsx(classes.warning, className, 'skipped')} />);
  }

  if (statusNormalized === 'failed') {
    return (
      <ErrorIcon className={clsx(classes.failed, className, 'failed')} />
    );
  }

  return (
    <Brightness1TwoToneIcon className={clsx(classes.cancel, className)} />
  );
}

TestCaseStatus.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default React.memo(TestCaseStatus);
