import { Accordion, withStyles } from '@material-ui/core';

const ExpansionPanel = withStyles({
  root: {
    border: '2px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

export default ExpansionPanel;
