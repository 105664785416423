import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { get } from 'lodash';
import { InternalServerErrorMessage } from 'components/errorStates';
import { getTraceIdentifier, isNetworkError } from 'util/apollo';
import BuildList, { buildListFragment } from './BuildList';

const loadJobBuildListQuery = gql`
  query loadJobBuildList($name: String!, $count: Int!) {
    job (name: $name) {
      name
      builds(count: $count) {
        id
        ...buildListFragment
      }
    }
  }
  ${buildListFragment}
`;

function BuildListContent({ jobName }) {
  const { data, loading, error } = useQuery(loadJobBuildListQuery, {
    variables: {
      name: jobName,
      count: 20,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const builds = get(data, 'job.builds') || [];

  if (error && isNetworkError(error)) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  return (
    <BuildList
      jobName={jobName}
      builds={builds}
      loading={loading}
    />
  );
}

BuildListContent.propTypes = {
  jobName: PropTypes.string.isRequired,
};

export default memo(BuildListContent);
