import * as React from 'react';
import { isFragment } from 'react-is';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import BlockIcon from '@material-ui/icons/BlockOutlined';

// Determine if the toggle button value matches, or is contained in, the
// candidate group value.
function isValueSelected(value, candidate) {
  if (candidate === undefined || value === undefined) {
    return false;
  }

  if (Array.isArray(candidate)) {
    return candidate.indexOf(value) >= 0;
  }

  return value === candidate;
}

const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    borderRadius: theme.shape.borderRadius,
  },
  /* Styles applied to the root element if `orientation="vertical"`. */
  vertical: {
    flexDirection: 'column',
  },
  /* Styles applied to the children. */
  grouped: {
    margin: theme.spacing(0.5),
    display: 'inline-flex',
    border: '2px solid',
    marginLeft: 0,
  },
  chipPrimary: {
    borderColor: theme.palette.primary.main,
  },
  chipDisabled: {
    borderStyle: 'dashed',
  },
});

const ToggleChipGroup = React.forwardRef((props, ref) => {
  const {
    children,
    classes,
    className,
    exclusive = false,
    onChange,
    size = 'medium',
    value,
    color,
    ...other
  } = props;

  const handleChange = (event, buttonValue) => {
    if (!onChange) {
      return;
    }

    const index = value && value.indexOf(buttonValue);
    let newValue;

    if (value && index >= 0) {
      newValue = value.slice();
      newValue.splice(index, 1);
    } else {
      newValue = value ? value.concat(buttonValue) : [buttonValue];
    }

    onChange(event, newValue);
  };

  const handleExclusiveChange = (event, buttonValue) => {
    if (!onChange) {
      return;
    }

    onChange(event, value === buttonValue ? null : buttonValue);
  };

  return (
    <div
      role="group"
      className={clsx(
        classes.root,
        className,
      )}
      ref={ref}
      {...other}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        if (process.env.NODE_ENV !== 'production') {
          if (isFragment(child)) {
            console.error(
              [
                "Material-UI: The ToggleButtonGroup component doesn't accept a Fragment as a child.",
                'Consider providing an array instead.',
              ].join('\n'),
            );
          }
        }

        const selected = child.props.selected === undefined
          ? isValueSelected(child.props.value, value)
          : child.props.selected;

        const disabled = child.props.disabled === true;
        let deleteIcon = !selected ? <AddIcon /> : null;
        if (disabled) { deleteIcon = <BlockIcon />; }

        return React.cloneElement(child, {
          className: clsx(
            classes.grouped,
            child.props.className, {
              [classes.chipPrimary]: color === 'primary',
              [classes.chipDisabled]: disabled,
            },
          ),
          onClick: (e) => {
            if (exclusive) handleExclusiveChange(e, child.props.value);
            else { handleChange(e, child.props.value); }
          },
          selected,
          variant: selected ? 'default' : 'outlined',
          size: child.props.size || size,
          color: child.props.color || color,
          deleteIcon,
          onDelete: (e) => {
            if (exclusive) handleExclusiveChange(e, child.props.value);
            else { handleChange(e, child.props.value); }
          },
        });
      })}
    </div>
  );
});

ToggleChipGroup.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape(),
  className: PropTypes.string,
  exclusive: PropTypes.bool,
  /**
   * Callback fired when the value changes.
   *
   * @param {object} event The event source of the callback.
   * @param {any} value of the selected buttons. When `exclusive` is true
   * this is a single value; when false an array of selected values. If no value
   * is selected and `exclusive` is true the value is null; when false an empty array.
   */
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
};

export default withStyles(styles, { name: 'MuiToggleChipGroup' })(ToggleChipGroup);
