import {
  InMemoryCache,
} from '@apollo/client';
import mergeArrayByField from './mergeArrayByField';
import { offsetCountPagination } from './offsetCountPagination';

export const cache = new InMemoryCache({
  freezeResults: true,
  resultCaching: true,
  addTypename: true,
  typePolicies: {
    Job: {
      keyFields: ['name'],
      fields: {
        builds: offsetCountPagination(),
        build: {
          merge: false,
        },
        configuration: {
          merge: true,
        },
        indexingTask: {
          merge(existing, incoming) {
            return {
              ...existing,
              ...incoming,
              runs: {
                ...existing?.runs,
                ...incoming?.runs,
              },
            }
          },
        }
      },
    },
    Build: {
      fields: {
        launchInfo: {
          merge: true,
        },
        testReport: {
          merge: true,
        },
        parameters: {
          keyArgs: false,
          merge: mergeArrayByField('name'),
        },
      },
    },
    TestReport: {
      fields: {
        suites: true,
      },
    },
    App: {
      fields: {
        authentication: {
          merge: true,
        },
        maintenance: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        activeBuilds: {
          merge: mergeArrayByField('id'),
        },
        allJobs: {
          merge: mergeArrayByField('name'),
        },
      },
    },
    JobMutation: {
      merge: false,
    },
  },
});
