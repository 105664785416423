import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

const getWebshopAvailabilityQuery = gql`
query getWebshopAvailabilityQuery($jobName: String!) {
  webshopAvailability(jobName: $jobName)
}
`;

const useWebshopAvailability = ({ jobName, sanaVersion }) => {
  const { data } = useQuery(getWebshopAvailabilityQuery, {
    variables: {
      jobName
    },
    skip: !sanaVersion?.includes('SCC'),
    fetchPolicy: 'cache-and-network'
  });

  const isWebshopAvailable = get(data, 'webshopAvailability');

  return { isWebshopAvailable };
};

export default useWebshopAvailability;
