export const BUILD_FINISHED = 'BUILD_FINISHED';
export const BUILD_STARTED = 'BUILD_STARTED';

export function buildStarted(jobName, buildNumber) {
  return {
    type: BUILD_STARTED,
    payload: {
      jobName,
      buildNumber,
    },
  };
}

export const buildFinished = (jobName, buildNumber) => ({
  type: BUILD_FINISHED,
  payload: {
    jobName,
    buildNumber,
  },
});
