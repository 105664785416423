import React, { memo } from 'react';
import {
  Grid, Button, makeStyles,
} from '@material-ui/core';
import Link from 'components/Link';
import IllustratedMessage from 'components/IllustratedMessage';
import notFoundImage from 'asserts/not_found.svg';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '800px',
    margin: '0 auto',
  },
}));

function NotFoundMessage() {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.root} pageNotFound`}>
      <IllustratedMessage
        imageSrc={notFoundImage}
        imageTitle="404 Page not found"
        title="404 - Page not found"
        subTitle="Sorry, we can’t find the page you were looking for."
        actionBox={(
          <Link to="/" color="inherit" underline="none">
            <Button color="primary" variant="text">Go to home page</Button>
          </Link>
        )}
      />
    </Grid>
  );
}

export default memo(NotFoundMessage);
