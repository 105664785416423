import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PageTitle from 'components/PageTitle';
import { gql } from '@apollo/client';
import BuildHeader, { buildHeaderFragment } from './BuildHeader';
import BuildContent from './BuildContent';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  info: {
    width: '100%',
  },
}));


function BuildDetails({
  jobName, build, buildNumber, loading = false,
}) {
  const classes = useStyles();

  return (
    <>
      <PageTitle title={`${jobName} - #${buildNumber.toString()}`} />
      <div className={classes.root}>
        <div className={classes.info}>
          {loading && <BuildHeader.Skeleton />}
          {!loading && <BuildHeader build={build} />}
        </div>
        <BuildContent jobName={jobName} buildNumber={buildNumber} />
      </div>
    </>
  );
}

export const buildDetailsFragment = gql`
  fragment buildDetailsFragment on Build {
    number
    ...buildHeaderFragment
  }
  ${buildHeaderFragment}
`;

BuildDetails.propTypes = {
  jobName: PropTypes.string.isRequired,
  build: PropTypes.shape({
    building: PropTypes.bool,
    estimatedDuration: PropTypes.number,
    duration: PropTypes.number,
    launchInfo: PropTypes.shape(),
  }),
  buildNumber: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default BuildDetails;
