import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PageTitle from 'components/PageTitle';
import { Grid } from '@material-ui/core';
import { LogList } from 'components/logsList';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

function LogListPage() {
  const classes = useStyles();

  return (
    <>
      <PageTitle title="Logs" />
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item lg={9}>
            <LogList />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default LogListPage;
