/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import get from 'lodash/get';
import { gql, useQuery } from '@apollo/client';
import { getTraceIdentifier, isNetworkError } from 'util/apollo';
import { InternalServerErrorMessage } from 'components/errorStates';
import JobListContent, { jobListFragment } from './JobListContent';
import JobListToolbar from './JobListToolbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
}));

export const loadJobListQuery = gql`
  query loadJobList($offset: Int!) {
    allJobs (offset: $offset) {
      name
      description
      ...jobListFragment
    }
  }
  ${jobListFragment}
`;

function JobList({ jobListProps, className = '' }) {
  const classes = useStyles();
  const { data, error, loading } = useQuery(loadJobListQuery, {
    variables: {
      offset: 0,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error && isNetworkError(error)) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  const allJobs = get(data, 'allJobs', []);

  return (
    <>
      <Paper className={`${classes.root} ${className}`}>
        <JobListToolbar />
        <JobListContent
          {...jobListProps}
          jobs={allJobs}
          loading={loading}
        />
      </Paper>
    </>
  );
}

JobList.propTypes = {
  className: PropTypes.string,
  jobListProps: PropTypes.shape(),
};

export default JobList;
