import React, {
  useState, Fragment, memo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEnableJobMutation } from 'behaviour/jobs';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  Menu, MenuItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import Tooltip from 'components/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DeleteJobDialog, CopyJobDialog, RenameJobDialog } from 'components/job/dialogs';
import NotesIcon from '@material-ui/icons/Notes';
import BlockIcon from '@material-ui/icons/BlockTwoTone';
import CheckCircleIcon from '@material-ui/icons/CheckCircleTwoTone';
import ClickTrap from 'components/ClickTrap';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';

const useStyles = makeStyles(() => ({
  listMenuIcon: {
    minWidth: '30px',
  },
}));

function JobActions({ name, displayName, disabled }) {
  const classes = useStyles();

  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [copyJobDialogOpen, setCopyJobDialogOpen] = useState(false);
  const [renameJobDialogOpen, setRenameJobDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isShowMoreOpened = Boolean(anchorEl);

  const enableJob = useEnableJobMutation();

  const handleShowMoreClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleRenameButtonClick = (e) => {
    handleMenuClose(e);
    setRenameJobDialogOpen(true);
  };

  const handleDeleteButtonClick = (e) => {
    e.preventDefault();
    handleMenuClose(e);
    setDeleteJobDialogOpen(true);
  };

  const handleCopyButtonClick = (e) => {
    e.preventDefault();
    handleMenuClose(e);
    setCopyJobDialogOpen(true);
  };

  const handleEnableButtonClick = (e) => {
    e.preventDefault();
    handleMenuClose(e);
    enableJob(name, true);
  };

  const handleDisableButtonClick = (e) => {
    e.preventDefault();
    handleMenuClose(e);
    enableJob(name, false);
  };

  const renderShowMoreMenu = (
    <Menu
      className="actionsMenu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isShowMoreOpened}
      onClose={handleMenuClose}
    >
      <MenuItem className="rename" onClick={handleRenameButtonClick}>
        <ListItemIcon className={classes.listMenuIcon}>
          <NotesIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Rename project" />
      </MenuItem>
      <MenuItem className="copy" onClick={e => handleCopyButtonClick(e)}>
        <ListItemIcon className={classes.listMenuIcon}>
          <FileCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Duplicate project" />
      </MenuItem>
      {disabled
        ? (
          <MenuItem className="enable" onClick={e => handleEnableButtonClick(e)}>
            <ListItemIcon className={classes.listMenuIcon}>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Enable project" />
          </MenuItem>
        ) : (
          <MenuItem className="disable" onClick={e => handleDisableButtonClick(e)}>
            <ListItemIcon className={classes.listMenuIcon}>
              <BlockIcon />
            </ListItemIcon>
            <ListItemText primary="Disable project" />
          </MenuItem>
        )}
      <MenuItem className="delete" onClick={e => handleDeleteButtonClick(e)}>
        <ListItemIcon className={classes.listMenuIcon}>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Delete project" />
      </MenuItem>
    </Menu>
  );

  return (
    <Fragment>
      <Tooltip title="Actions">
        <IconButton size="small" onClick={e => handleShowMoreClick(e)} className="jobActions">
          <MoreIcon />
        </IconButton>
      </Tooltip>
      {renderShowMoreMenu}
      <ClickTrap>
        <Fragment>
          {deleteJobDialogOpen && (
            <DeleteJobDialog
              name={name}
              displayName={displayName}
              open={deleteJobDialogOpen}
              setOpen={setDeleteJobDialogOpen}
            />
          )}
          {copyJobDialogOpen && (
            <CopyJobDialog
              name={name}
              displayName={displayName}
              open={copyJobDialogOpen}
              setOpen={setCopyJobDialogOpen}
            />
          ) }
          {renameJobDialogOpen && (
            <RenameJobDialog
              name={name}
              displayName={displayName}
              open={renameJobDialogOpen}
              setOpen={setRenameJobDialogOpen}
            />
          ) }
        </Fragment>
      </ClickTrap>
    </Fragment>
  );
}

export const jobListActionsFragment = gql`
  fragment jobListActionsFragment on Job {
    name
    displayName
    disabled
  }
`;

JobActions.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default memo(JobActions);
