import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    margin: '16px 0 8px',
  },
}));

function InputSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton height={24} width={100} />
      <Skeleton variant="rect" height={56} />
      <Skeleton height={20} width={500} />
    </div>
  );
}

export default InputSkeleton;
