import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: '0',
    zIndex: theme.zIndex.modal + 1,
  },
  spacer: {
    height: '4px',
  },
}));

export default function AppLoader() {
  const classes = useStyles();
  const loading = useSelector(state => state.app.loading);

  return (
    <>
      {!loading && <div className={classes.spacer} />}
      {loading && <LinearProgress color="secondary" className={classes.root} />}
    </>
  );
}
