import React, { memo } from 'react';
import {
  Grid, Typography, makeStyles,
} from '@material-ui/core';
import IllustratedMessage from 'components/IllustratedMessage';
import maintenanceImage from 'asserts/maintenance.svg';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '800px',
    margin: '0 auto',
  },
}));

function ServiceUnavailableMessage() {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.root} serviceUnavailable`}>
      <IllustratedMessage
        imageSrc={maintenanceImage}
        imageTitle="503 Service Unavailable"
        title="We&rsquo;ll be back soon!"
        actionBox={(
          <Typography>
            Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.
          </Typography>
        )}
      />
    </Grid>
  );
}

export default memo(ServiceUnavailableMessage);
