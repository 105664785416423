import { gql, useApolloClient } from '@apollo/client';

const getTestDataQuery = gql`
  query getTestDataQuery($jobName: String!, $field: String!, $relatedField: String, $take: Int!, $skip: Int!) {
    testData(jobName: $jobName, field: $field, relatedField: $relatedField, take: $take, skip: $skip) {
      items {
        id
        label
        extra
        relatedData {
          key
          value
        }
      }
    }
  }
`;

const useLoadTestData = () => {
  const apolloClient = useApolloClient();
  const take = 1;
  const skip = 0;

  return (jobName, prefill, formValues) => {
    const relatedField = prefill.relatedField;
    const relatedFieldValue = relatedField ? formValues[relatedField] : '';

    //skip retrieving test data when related field is blank
    if (relatedField && !relatedFieldValue) return Promise.resolve();

    return apolloClient.query({
      query: getTestDataQuery,
      variables: {
        jobName,
        field: prefill.fetchKey,
        relatedField: relatedFieldValue,
        take,
        skip
      },
      fetchPolicy: 'no-cache'
    });
  };
};

export default useLoadTestData;
