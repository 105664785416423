import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { Select } from 'components/form';

function ExpiryYearEditor({ lastTwoDigits = false, ...props }) {
  return (
    <Field {...props} component={Select}>
      <MuiMenuItem value={lastTwoDigits ? '22' : '2022'}>2022</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '23' : '2023'}>2023</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '24' : '2024'}>2024</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '25' : '2025'}>2025</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '26' : '2026'}>2026</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '27' : '2027'}>2027</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '28' : '2028'}>2028</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '29' : '2029'}>2029</MuiMenuItem>
      <MuiMenuItem value={lastTwoDigits ? '30' : '2030'}>2030</MuiMenuItem>
    </Field>
  );
}

ExpiryYearEditor.propTypes = {
  lastTwoDigits: PropTypes.bool,
};
export default memo(ExpiryYearEditor);
