import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useReplayBuildMutation } from 'behaviour/builds';
import { gql } from '@apollo/client';
import ReplayIcon from '@material-ui/icons/Replay';
import { useBridgeAvailability } from 'behaviour/jobs';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
}));

function formatButtonText(inQueue = false, running = false) {
  if (inQueue) return 'In a queue';
  if (running) return 'In progress';

  return undefined;
}

export const replayBuildButtonFragment = gql`
  fragment replayBuildButtonFragment on Job {
    jobName: name,
    packageVersion,
    buildable,
    inQueue
    lastBuild {
      id
      building
    }
  }
`;

function ReplayBuildButton({
  jobName,
  packageVersion,
  buildNumber,
  testIds = [],
  buildable = true,
  inQueue = false,
  lastBuild = null,
  buttonText = 'Replay',
  buttonIcon = ReplayIcon,
}) {
  const classes = useStyles();
  const ButtonIcon = buttonIcon;
  const replayBuildMutation = useReplayBuildMutation();
  const replayBuild = useCallback(
    () => replayBuildMutation({ jobName, buildNumber, testIds }),
    [jobName, replayBuildMutation, buildNumber, testIds],
  );
  const running = (lastBuild && lastBuild.building);

  const { isBridgeAvailable } = useBridgeAvailability({
    jobName,
    sanaVersion: packageVersion,
  });

  const getTooltipText = () => {
    if (!buildable) return "Project is not builable";
    if (running) return "Project is already running";
    if (inQueue) return "Project is in the run queue";
    if (testIds.length === 0) return "No tests selected to replay";
    if (isBridgeAvailable === false) return "Bridge is not available";
    return '';
  };

  return (
    <div className={classes.wrapper}>
      <Tooltip title={getTooltipText()}>
        <span>
          <Button
            className="replayBuildButton"
            onClick={replayBuild}
            disabled={!buildable || running || inQueue || testIds.length === 0 || isBridgeAvailable === false}
            startIcon={<ButtonIcon />}
            endIcon={inQueue && <CircularProgress size={24} />}
          >
            {formatButtonText(inQueue, running) || buttonText}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

ReplayBuildButton.propTypes = {
  jobName: PropTypes.string.isRequired,
  packageVersion: PropTypes.string,
  buildNumber: PropTypes.number.isRequired,
  testIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  buildable: PropTypes.bool,
  inQueue: PropTypes.bool,
  lastBuild: PropTypes.shape({
    building: PropTypes.bool.isRequired,
  }),
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default memo(ReplayBuildButton);
