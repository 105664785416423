import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'components/form/builder/FormBuilder';
import get from 'lodash/get';
import FormContext from 'components/form/builder/FormBuilderContext';
import { schemaGenerator } from './schema';
import { useErpConnection } from 'behaviour/jobs';
import { AutocompleteAlert, ErpConnectionAlert, TestDataIndexingAlert } from 'components/alerts';
import { IndexingSummary, RunIndexingButton } from 'components/testDataIndexing';
import { PrefillContent } from 'components/testDataPrefill';

function ProductsForm({
  jobName, configuration, handleSubmit,
}) {
  const parameters = get(configuration, 'parameters.Parameters.value') || {};
  const schema = useMemo(
    () => schemaGenerator(jobName, parameters),
    [jobName, parameters],
  );
  const sanaVersion = parameters.sanaVersion;
  const indexingRuns = get(configuration, 'job.indexingTask.runs');
  const { isErpConnectionAvailable } = useErpConnection({ jobName, sanaVersion });
  const showErpConnectionAlert = isErpConnectionAvailable === false && sanaVersion?.includes('SCC');
  const [expandAllSections, setExpandAllSections] = useState(false);
  const expansionPanelsState = { expandAllSections, setExpandAllSections };

  return (
    <>
      {!sanaVersion?.includes('SCC') && <AutocompleteAlert />}
      {showErpConnectionAlert && <ErpConnectionAlert />}
      {sanaVersion?.includes('SCC') && indexingRuns?.length === 0 && <TestDataIndexingAlert />}
      <FormContext.Provider value={{ parameters, expansionPanelsState }}>
        <FormBuilder
          schema={schema}
          onSubmit={handleSubmit}
          header={sanaVersion?.includes('SCC') && <PrefillContent jobName={jobName} schema={schema} />}
          additionalButtons={sanaVersion?.includes('SCC') && <RunIndexingButton jobName={jobName} configuration={configuration} />}
          footer={indexingRuns?.length > 0 && <IndexingSummary jobName={jobName} lastRun={indexingRuns[0]} />}
        />
      </FormContext.Provider>
    </>
  );
}

ProductsForm.propTypes = {
  jobName: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    versionToken: PropTypes.string.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
};

export default ProductsForm;
