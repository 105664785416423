import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { addToRecentViewed as addToRecentViewedAction } from './actions';

export function useRecentViewed() {
  const dispatch = useDispatch();
  const recentViewed = useSelector((state: { recentViewed: string[] }) => state.recentViewed);

  const addToRecentViewed = useCallback((jobName: string) => {
    dispatch(addToRecentViewedAction(jobName));
  }, [dispatch]);

  return {
    addToRecentViewed,
    recentViewed,
  };
}
