import React, { memo, isValidElement, cloneElement } from 'react';
import {
  makeStyles,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
  },
  icon: {
    display: 'inline',
    marginRight: theme.spacing(1),
    height: '20px',
  },
}));

function IconLabel({
  icon, component = 'div', children, className = '', ...props
}) {
  const SvgIcon = icon;
  const classes = useStyles();
  const Component = component;
  const isElement = isValidElement(icon);

  return (
    <Component {...props} className={clsx(classes.root, className)}>
      {isElement && cloneElement(icon, {
        className: classes.icon,
        fontSize: 'small',
      })}
      {!isElement && SvgIcon && <SvgIcon fontSize="small" className={classes.icon} />}
      {children}
    </Component>
  );
}

IconLabel.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

export default memo(IconLabel);
