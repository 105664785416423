import appReducer from './app/reducer';
import notificationsReducer from './notifications/reducers';
import favoritesReducer from './favorites/reducer';
import recentViewedReducer from './recentViewed/reducer';

export default {
  app: appReducer,
  notifications: notificationsReducer,
  favorites: favoritesReducer,
  recentViewed: recentViewedReducer,
};
