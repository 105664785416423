import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import IconLabel from 'components/IconLabel';
import CronParser from 'cron-parser';
import { gql } from '@apollo/client';
import { BuildStatus, buildStatusFragment } from 'components/jobBuild';
import { useSanaVersion } from 'behaviour/jobs';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import TimeAgo from '../TimeAgo';

const useStyles = makeStyles(theme => ({
  errorIcon: {
    color: theme.palette.error.main,
  },
}));

function formatDate(value) {
  if (!value) { return null; }

  const date = new Date(value);
  return <TimeAgo date={date} />;
}

function formatNextRunDate(value) {
  if (!value) { return null; }

  const interval = CronParser.parseExpression(value.replace('H', '*'), { utc: true });
  const date = new Date(interval.next().toISOString());
  return <TimeAgo date={date} future />;
}

function JobSummary({
  name, lastBuild, configuration, packageVersion,
}) {
  const { timerTrigger } = configuration;

  const { sanaVersion, loading } = useSanaVersion({
    jobName: name,
    packageVersion,
  });

  const classes = useStyles();

  return (
    <Fragment>
      {lastBuild && lastBuild.building && (
        <Link
          color="inherit"
          to={`/projects/${name}/${lastBuild.number}`}
        >
          <IconLabel icon={<BuildStatus {...lastBuild} />} className="started">
            Started
            {' '}
            {formatDate(lastBuild.timestamp)}
            {!lastBuild.building && `Last run ${formatDate(lastBuild.completed)}`}
          </IconLabel>
        </Link>
      )}
      {lastBuild && !lastBuild.building && (
        <Link
          color="inherit"
          to={`/projects/${name}/${lastBuild.number}`}
        >
          <IconLabel icon={<BuildStatus {...lastBuild} />} className="lastRun">
            Last run was
            {' '}
            {formatDate(lastBuild.completed)}
          </IconLabel>
        </Link>
      )}
      <Link
        color="inherit"
        to={`/projects/${name}/scheduling`}
      >
        <IconLabel icon={EventTwoToneIcon} className="nextRun">
          Next run
          {' '}
          {timerTrigger && 'will take place in '}
          {timerTrigger ? formatNextRunDate(timerTrigger) : 'is not scheduled yet'}
        </IconLabel>
      </Link>
      {packageVersion && loading && (<Skeleton variant="text" height={24} width={200} />)}
      {packageVersion && !loading && (
        <IconLabel icon={InfoTwoToneIcon} className="version">
          Version
          {' '}
          {packageVersion}
          {' '}
          {packageVersion.includes('SCC') && (sanaVersion === null ? (
            <Tooltip title="Sana version cannot be retrieved" placement="right">
              <ErrorIcon className={clsx(classes.errorIcon, 'errorIcon')} />
            </Tooltip>
          ) : `(${sanaVersion})`)}
        </IconLabel>
      )}
    </Fragment>
  );
}

export const jobSummaryFragment = gql`
  fragment jobSummaryFragment on Job {
    name
    lastBuild {
      id
      completed
      timestamp
      building
      number
      ...buildStatusFragment
    }
    configuration {
      timerTrigger,
      parameters {
        name,
        type,
        value
      }
   }
    packageVersion
  }
  ${buildStatusFragment}
`;

JobSummary.propTypes = {
  name: PropTypes.string.isRequired,
  lastBuild: PropTypes.shape({
    completed: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    building: PropTypes.bool,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  configuration: PropTypes.shape({
    timerTrigger: PropTypes.string,
    parameters: PropTypes.arrayOf(
      PropTypes.shape().isRequired,
    ).isRequired,
  }),
  packageVersion: PropTypes.string,
};

export default memo(JobSummary);
