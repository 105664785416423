import { gql, useApolloClient } from '@apollo/client';
import { get, isEqual } from 'lodash';

const getErrorMessage = (testCriteria, contact) => {
  const errors = [
    {
      criteria: { hasCustomerId: true },
      message: 'Contact is not linked to any customer',
    },
    {
      criteria: { hasValidCustomerId: true },
      message: 'Contact is linked to not valid customer',
    },
    {
      criteria: { hasCreditLimit: true },
      message: 'Contact has no credit limit',
    },
    {
      criteria: { hasSalesAgreements: true },
      message: 'Contact has no sales agreements',
    },
    {
      criteria: { hasSeveralShippingAddresses: true },
      message: `Contact has ${contact.shippingAddressesCount} shipping address but expected 2 or more`,
    },
    {
      criteria: { hasBillingAddress: true },
      message: 'Contact billing address is not available as shipping address on checkout.'
    }
  ];
  return errors.find((error) => isEqual(error.criteria, testCriteria))?.message;
};

const apolloClient = useApolloClient();

export const contactValidator = (schema, jobName, sanaVersion, testCriteria) =>
  schema.test('validContact', (value, { createError }) => {
    if (!value || value === '-' || !sanaVersion.includes('SCC')) return true;

    const validationCriteria = { hasCustomerId: true, hasValidCustomerId: true, ...testCriteria };
    let queryFields = Object.keys(validationCriteria).join(" ");
    if (queryFields.includes('hasSeveralShippingAddresses'))
      queryFields = `${queryFields} shippingAddressesCount`;

    const getContactInfoQuery = gql`
    query getContactInfoQuery($jobName: String!, $contactId: String!) {
      contactInfo(jobName: $jobName, contactId: $contactId) {
        ${queryFields}        
      }      
    }`;

    return apolloClient
      .query({
        query: getContactInfoQuery,
        variables: {
          jobName,
          contactId: value
        },
      })
      .then((result) => {
        var contact = get(result, 'data.contactInfo');
        if (contact === null) {
          return createError({ message: 'Contact does not exist' });
        }

        for (const property in validationCriteria) {
          if (contact[property] !== validationCriteria[property])
            return createError({
              message: getErrorMessage({ [property]: validationCriteria[property] }, contact),
            });
        }
        return true;
      })
      .catch(() => true);
  });
