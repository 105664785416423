import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, {
  makeColumns, useFetchMore, useFilter, useSelection,
} from 'components/dataGrid';
import { gql } from '@apollo/client';
import TestReportItem, { testReportItemFragment } from './TestReportItem';
import TestReportFilter from './TestReportFilter';

const columns = makeColumns([{
  id: 'status', label: 'Status', sortable: true,
},
{
  id: 'name', label: 'Test', sortable: true, contentFit: true,
},
{
  id: 'duration', label: 'Duration', numeric: true,
}]);

const Row = ({
  data, ...renderProps
}) => (
  <TestReportItem
    key={data.name}
    {...data}
    {...renderProps}
  />
);

function TestReportList({
  items = [], onItemsSelected, loading, ...props
}) {
  const { filteredItems, onFilterChanged } = useFilter(items);
  const {
    isSelected, handleSelectAll, handleSelected, selectionState,
  } = useSelection(filteredItems, 'name', onItemsSelected);
  const { pagedItems, totalCount, fetchMore } = useFetchMore(filteredItems);

  return (
    <>
      <TestReportFilter tests={items} onFilterChanged={onFilterChanged} />
      <DataGrid
        {...props}
        loading={loading || (items.length > 0 && pagedItems.length === 0)}
        columns={columns}
        items={pagedItems}
        totalCount={totalCount}
        fetchMore={fetchMore}
        isSelected={isSelected}
        handleSelectAll={handleSelectAll}
        handleSelected={handleSelected}
        selectionState={selectionState}
        defaultRowsPerPage={50}
        renderRow={Row}
      />
    </>
  );
}

export const testReportListFragment = gql`
  fragment testReportListFragment on TestCase {
    ...testReportItemFragment
  }
  ${testReportItemFragment}
`;

Row.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

TestReportList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    errorDetails: PropTypes.string,
  })),
  onItemsSelected: PropTypes.func,
  loading: PropTypes.bool,
};

export default TestReportList;
