import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';

/**
 * Creates authentication configuration
 * @param {string} tenantId
 * @param {string} clientId
 * @param {string} redirectUri
 * @returns {import("@azure/msal-browser").Configuration}
 */
const createAuthConfig = ({ tenantId, clientId, redirectUri }) => ({
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}/`,
    clientId,
    redirectUri,
    postLogoutRedirectUri: `${redirectUri}/login`,
    protocolMode: 'AAD',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
  system: {
    loadFrameTimeout: 1,
    tokenRenewalOffsetSeconds: 1,
  },
});

export const authProvider = options => new PublicClientApplication(createAuthConfig(options));
