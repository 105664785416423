/* eslint-disable func-names */
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { makeSchema } from 'components/form/builder';
import { AutocompleteEditor } from 'components/form/editors';
import { getValue } from 'util/getValue';
import { notEqualTestDataValidator, isCategoryExcluded, productValidator } from 'behaviour/jobs/validators';
import { TestCategory } from '../../general';
import { extendSchema } from 'util/yup';

export const schemaGenerator = (jobName, parameters) => makeSchema({
  sections: [{
    title: 'Product list',
    description: 'If specified list is not found it will be created with all products from test data.',
    inputs: [{
      name: 'productListUrl',
      alias: 'ProductList.Url',
      label: 'URL',
      helperText: 'You can provide existing product list URL from Sana admin. Go to Web pages -> Product list pages.',
      defaultValue: getValue(parameters.productListUrl, 'autotest_productlist'),
      validate: Yup.string().required()
        .matches('^((?!https).)*$', 'The specified value is not a valid url. The url must start with "https://" and be one of the existing product listing page urls.')
        .matches('^((?!search?q=*).)*$', 'You have provided the url of a search page but a product list page url is expected.'),
    }],
  },
  {
    title: 'Products',
    inputs: [{
      name: 'product_orderableId',
      alias: 'Product.Orderable.Id',
      label: 'Orderable ID',
      helperText: 'Any product which can be added to basket. It will be used for multiple order submit, so recommended to choose product with great stock',
      defaultValue: getValue(parameters.product_orderableId, ''),
      validate: extendSchema(Yup.string()
        .test('notEqual', '${path} is the same as Orderable 2 ID', function (value) {
          return notEqualTestDataValidator(value, this.resolve(Yup.ref('product_orderable2Id')));
        }),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion)
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.orderableProducts',
      },
    },
    {
      name: 'product_orderable2Id',
      alias: 'Product.Orderable2.Id',
      label: 'Orderable 2 ID',
      defaultValue: getValue(parameters.product_orderable2Id, ''),
      validate: extendSchema(Yup.string()
        .test('notEqual', '${path} is the same as Orderable ID', function (value) {
          return notEqualTestDataValidator(value, this.resolve(Yup.ref('product_orderableId')));
        }),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion)
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.orderableProducts',
      },
    },
    {
      name: 'product_simpleId',
      alias: 'Product.Simple.Id',
      label: 'Simple ID',
      helperText: 'Product without variants',
      disabled: isCategoryExcluded(parameters, [TestCategory.SimpleProducts]),
      disabledReason: "Not applicable since the 'Simple products' category is turned off",
      defaultValue: getValue(parameters.product_simpleId, ''),
      validate: extendSchema(Yup.string(),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion, { hasVariants: false })
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.simpleProducts',
      },
    },
    {
      name: 'product_withAttachmentId',
      alias: 'Product.WithAttachment.Id',
      label: 'With attachment ID',
      helperText: 'Product with attachment from ERP',
      disabled: isCategoryExcluded(parameters, [TestCategory.ProductAttachments]),
      disabledReason: "Not applicable since the 'Product attachments' category is turned off",
      defaultValue: getValue(parameters.product_withAttachmentId, ''),
      validate: extendSchema(Yup.string(),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion, { hasAttachments: true })
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.productsWithAttachment',
      },
    },
    {
      name: 'product_withGrossWeightId',
      alias: 'Product.WithGrossWeight.Id',
      label: 'With gross weight ID',
      helperText: 'Product with weight less than 100000 weight unit',
      defaultValue: getValue(parameters.product_withGrossWeightId, ''),
      validate: extendSchema(Yup.string(),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion, { hasWeight: true })
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.productsWithGrossWeight',
      },
    },
    {
      name: 'product_withDimensionsId',
      alias: 'Product.WithDimensions.Id',
      label: 'With Length, Width, Height and Weight ID',
      helperText: 'This product is used for testing UPS, UPS Imperial, USPS and Consignor shipping methods',
      defaultValue: getValue(parameters.product_withDimensionsId, ''),
      validate: extendSchema(Yup.string(),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion, { hasLength: true, hasWidth: true, hasHeight: true })
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.productsWithDimensions',
      },
    },
    {
      name: 'product_bomId',
      alias: 'Product.BOM.Id',
      label: 'BOM ID',
      helperText: 'Bill of materials product which has components',
      defaultValue: getValue(parameters.product_bomId, ''),
      validate: extendSchema(Yup.string(),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion, { hasMaterialsFlag: true, hasMaterials: true })
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.bomProducts',
      },
    },
    {
      name: 'product_outOfStockProductSimpleId',
      alias: 'Product.OutOfStockProduct.Simple.Id',
      label: 'Out of stock product ID',
      helperText: 'Product with 0 inventory. Be sure that product does not have substitution',
      disabled: isCategoryExcluded(parameters, [TestCategory.SimpleProducts]),
      disabledReason: "Not applicable since the 'Simple products' category is turned off",
      defaultValue: getValue(parameters.product_outOfStockProductSimpleId, ''),
      validate: extendSchema(Yup.string(),
        [
          schema => productValidator(schema, jobName, parameters.sanaVersion, { hasStock: false })
        ]),
      input: AutocompleteEditor,
      prefill: {
        fetchKey: 'products.outOfStockProducts',
      },
    },
    ],
    expansionPanels: [{
      name: 'WithVariants',
      title: 'With variants',
      disabled: isCategoryExcluded(parameters, [TestCategory.VariantProducts]),
      disabledReason: "Not applicable since the 'Variant products' category is turned off",
      errorKey: 'product_withVariants',
      sections: [{
        inputs: [{
          name: 'product_withVariants_orderableId',
          alias: 'Product.WithVariants.Orderable.Id',
          label: 'Orderable ID',
          helperText: 'Any product with variants which can be added to basket',
          disabled: isCategoryExcluded(parameters, [TestCategory.VariantProducts]),
          defaultValue: getValue(parameters.product_withVariants_orderableId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => productValidator(schema, jobName, parameters.sanaVersion, { hasVariants: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'products.productsWithVariants',
          },
        },
        {
          name: 'product_withVariants_simpleId',
          alias: 'Product.WithVariants.SimpleVariant.Id',
          label: 'Simple variant ID',
          helperText: 'Product with 1 variant component (dropdown)',
          disabled: isCategoryExcluded(parameters, [TestCategory.VariantProducts]),
          defaultValue: getValue(parameters.product_withVariants_simpleId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => productValidator(schema, jobName, parameters.sanaVersion, { hasVariants: true, hasSimpleVariants: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'products.productsWithSimpleVariants',
          },
        },
        {
          name: 'product_withVariants_complexId',
          alias: 'Product.WithVariants.ComplexVariant.Id',
          label: 'Complex variant ID',
          helperText: 'Product with 2 variant components (dropdowns)',
          disabled: isCategoryExcluded(parameters, [TestCategory.VariantProducts]),
          defaultValue: getValue(parameters.product_withVariants_complexId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => productValidator(schema, jobName, parameters.sanaVersion, { hasVariants: true, hasComplexVariants: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'products.productsWithComplexVariants',
          },
        },
        {
          name: 'product_withVariants_moreThanTwoComponentsId',
          alias: 'Product.WithVariants.VariantWithMoreThanTwoComponents.Id',
          label: 'Variant with more than two components ID',
          helperText: 'Product with more than 2 variant components (dropdowns)',
          disabled: isCategoryExcluded(parameters, [TestCategory.VariantProducts]),
          defaultValue: getValue(parameters.product_withVariants_moreThanTwoComponentsId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => productValidator(schema, jobName, parameters.sanaVersion, { hasVariants: true, hasMoreThanTwoVariantComponents: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'products.productsWithMoreThanTwoVariantComponents',
          },
        }],
      }],
    },
    {
      name: 'WithUOMSelection',
      title: 'With UOM selection',
      errorKey: 'product_withUOMSelection',
      sections: [{
        inputs: [{
          name: 'product_withUOMSelection_simpleId',
          alias: 'Product.WithUOMSelection.Simple.Id',
          label: 'Simple ID',
          helperText: 'Product without variants with multiple UOMs',
          disabled: isCategoryExcluded(parameters, [TestCategory.SimpleProducts]),
          disabledReason: "Not applicable since the 'Simple products' category is turned off",
          defaultValue: getValue(parameters.product_withUOMSelection_simpleId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => productValidator(schema, jobName, parameters.sanaVersion, { hasVariants: false, hasUomSelection: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'products.simpleProductsWithUOMSelection',
          },
        },
        {
          name: 'product_withUOMSelection_withVariantsId',
          alias: 'Product.WithUOMSelection.WithVariants.Id',
          label: 'With variants ID',
          helperText: 'Product with variants with multiple UOMs',
          disabled: isCategoryExcluded(parameters, [TestCategory.VariantProducts]),
          disabledReason: "Not applicable since the 'Variant products' category is turned off",
          defaultValue: getValue(parameters.product_withUOMSelection_withVariantsId, ''),
          validate: extendSchema(Yup.string(),
            [
              schema => productValidator(schema, jobName, parameters.sanaVersion, { hasVariants: true, hasUomSelection: true })
            ]),
          input: AutocompleteEditor,
          prefill: {
            fetchKey: 'products.productsWithVariantsAndUOMSelection',
          },
        }],
      }],
    }],
  }],
});
