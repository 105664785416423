import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  },
}));

function FormHeader({ error = false, className = '', ...rest }) {
  const classes = useStyles();
  return <MuiTypography className={clsx(className, { [classes.error]: error })} {...rest} />;
}

FormHeader.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
};

export default FormHeader;
