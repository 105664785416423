// action for user authentication and receiving the access_token
import { APP_INIT, signalRConnected } from 'behaviour/app/actions';
import { buildStarted, buildFinished } from 'behaviour/builds/actions';
import { jobUpdated, indexingTaskCompleted } from 'behaviour/jobs/actions';
import createHubConnection from 'util/createHubConnection';

const signalRMiddleware = ({ dispatch }) => next => async (action) => {
  if (action.type === APP_INIT) {
    const startSignalRConnection = connection => connection.start()
      .then(() => dispatch(signalRConnected(true)))
      .catch(err => console.error('SignalR Connection Error: ', err));

    const connection = createHubConnection();

    connection.on('BuildStarted', (jobName, buildNumber) => {
      dispatch(buildStarted(jobName, buildNumber));
    });

    connection.on('BuildCompleted', (jobName, buildNumber) => {
      dispatch(buildFinished(jobName, buildNumber));
    });

    connection.on('JobUpdated', (jobName) => {
      dispatch(jobUpdated(jobName));
    });

    connection.on('IndexingTaskCompleted', (jobName, runId) => {
      dispatch(indexingTaskCompleted(jobName, runId));
    });

    // re-establish the connection if connection dropped
    connection.onclose(() => {
      dispatch(signalRConnected(false));
      setTimeout(startSignalRConnection(connection), 5000);
    });

    startSignalRConnection(connection);
  }

  return next(action);
};

export default signalRMiddleware;
