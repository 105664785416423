import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Duration from 'components/Duration';
import { BuildStatus, buildStatusFragment } from 'components/jobBuild';
import JobRunProgress from 'components/jobBuild/BuildProgressIndicator';
import Link from 'components/Link';
import { gql } from '@apollo/client';
import BuildBadges, { buildBadgesFragment } from 'components/jobBuild/BuildBadges';
import TimeAgo from '../TimeAgo';
import BuildActions, { buildListActionsFragment } from './BuildActions';

const useClasses = makeStyles(theme => ({
  button: {
    padding: 0,
    margin: 0,
  },
  tableRow: {
    display: 'table-row',
    outline: 'none',
    textDecoration: 'none',
    transition: 'background 80ms linear',
    verticalAlign: 'middle',
  },
  launchCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  fullWidthCell: {
    position: 'absolute',
    width: '100%',
  },
  progressRow: {
    height: '4px',
  },
  replayBadge: {
    marginLeft: theme.spacing(1),
  },
}));

function formatDate(value) {
  if (!value) { return null; }

  const date = new Date(value);
  return <TimeAgo date={date} />;
}

function completedDate(run) {
  if (run.building) {
    return (<span> - </span>);
  }

  return formatDate(run.completed);
}

function BuildItem({ jobName, run }) {
  const classes = useClasses();
  const duration = run.building ? new Date() - new Date(run.timestamp) : run.duration;

  return (
    <Fragment>
      {(run.building) && (
        <TableRow className={classes.progressRow}>
          <TableCell padding="none" className={classes.fullWidthCell}>
            <JobRunProgress
              estimatedDuration={run.estimatedDuration}
              currentDuration={duration}
            />
          </TableCell>
        </TableRow>
      )}
      <Link
        color="inherit"
        to={`/projects/${jobName}/${run.number}`}
        key={run.number}
        className={`MuiTableRow-root MuiTableRow-hover ${classes.tableRow}`}
        underline="none"
        role="row"
        tabIndex={-1}
      >
        <TableCell padding="normal" className="status">
          <BuildStatus {...run} />
        </TableCell>
        <TableCell className="buildNumber">
          <span>{run.displayName}</span>
          <BuildBadges
            build={run}
            className={classes.replayBadge}
          />
        </TableCell>
        <TableCell align="left" className="duration">
          <Duration duration={duration} live={run.building} />
        </TableCell>
        <TableCell align="left" className="completed">{completedDate(run)}</TableCell>
        <TableCell align="right" className="actions">
          <BuildActions {...run} jobName={jobName} />
        </TableCell>
      </Link>
    </Fragment>
  );
}

export const buildItemFragment = gql`
  fragment buildItemFragment on Build {
    displayName
    result
    building
    duration
    timestamp
    completed
    estimatedDuration
    number
    cancelling
    ...buildListActionsFragment
    ...buildBadgesFragment
    ...buildStatusFragment
  }
  ${buildListActionsFragment}
  ${buildBadgesFragment}
  ${buildStatusFragment}
`;


BuildItem.propTypes = {
  run: PropTypes.shape({
    number: PropTypes.number.isRequired,
    result: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    duration: PropTypes.number,
    building: PropTypes.bool.isRequired,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    completed: PropTypes.string,
    estimatedDuration: PropTypes.number.isRequired,
  }).isRequired,
  jobName: PropTypes.string.isRequired,
};

export default memo(BuildItem);
