export const NumberWithSpacesRegex = /^[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/;
export const NumberWithoutSpacesRegex = /^[0-9]+$/;
export const PaymentMethodValidationMessage = 'Payment method with specified name and payment provider does not exist or disabled in Admin';

export const PaymentProvider = {
  AuthorizeNetAcceptHosted: 'AuthorizeNet',
  ChargeLogic: 'ChargeLogic',
  DocData: 'Docdata',
  Ingenico: 'Ingenico',
  Buckaroo: 'Buckaroo',
  PayPalCheckout: 'PayPalCheckout',
  SagePay: 'SagePay',
  PayFabric: 'PayFabric',
  WorldPay: 'WorldPay',
  KbcPaypage: 'KbcPaypage',
  MercadoPago: 'MercadoPago',
  Mollie: 'Mollie',
  Eway: 'eWay',
  AmazonPay: 'AmazonPay',
  AmazonPayEU: 'AmazonPayEU',
  EBizCharge: 'EBizCharge',
  SanaPay: 'SanaPay',
};
