import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import useMarkdown from './useMarkdown';

const useStyles = makeStyles(() => ({
  content: {
    '& p': {
      margin: 0,
    },

    '& h1, h2, h3, h4, h5, h6': {
      fontWeight: 400,
      lineHeight: '1.25',
    },

    '& h1:first-child, h2:first-child, h3:first-child': {
      marginTop: 0,
      marginBottom: '1rem',
    },

    '& h1': {
      fontSize: '2.2rem',
    },

    '& h2': {
      fontSize: '1.65rem',
      paddingBottom: '0.3rem',
      borderBottom: '1px solid #eaecef',
    },

    '& h3': {
      fontSize: '1.35rem',
    },

    '& ol, ul, p': {
      lineHeight: '1.7',
    },

    '& ol, ul': {
      paddingLeft: '1.2em',
    },

    '& pre': {
      fontSize: '.875em',
      color: '#ccc',
      backgroundColor: '#2d2d2d',
      padding: '1em',
      margin: '0.5em 0',
      overflow: 'auto',
      fontFamily: 'Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace',
      textAlign: 'left',
      whiteSpace: 'pre',
      wordSpacing: 'normal',
      wordBreak: 'normal',
      wordWrap: 'normal',
      lineHeight: '1.5',
      hyphens: 'none',
    },

    '& pre code': {
      backgroundColor: 'transparent',
      color: 'currentColor',
      fontSize: '1em',
      padding: 0,
      fontWeight: 400,
    },
  },
}));

function Markdown({
  children, component = Typography, className = '', ...props
}) {
  const classes = useStyles();
  const markdown = useMarkdown(children);
  const Component = component;

  return (
    <Component
      {...props}
      className={`${classes.content} ${className}`}
      dangerouslySetInnerHTML={{ __html: markdown }}
    />
  );
}

export default memo(Markdown);

Markdown.propTypes = {
  children: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
};
