import * as Yup from 'yup';
import { makeSchema } from 'components/form/builder';
import { SelectEditor } from 'components/form/editors';
import { createUniqueJobValidator, createValidJobNameValidator, adminCredentialsValidator, websiteUrlValidator } from 'behaviour/jobs/validators';
import { extendSchema } from 'util/yup';
import { urlRegEx } from 'components/job/configuration/general/schema';

const sanaVersions = ['SCC', 'SCC LTS', 'Sana 9.3.5', 'Sana 9.3.4', 'Sana 9.3.3', 'Sana 9.3.2', 'Sana 9.3.1', 'Sana 9.3.0'];

export const schemaGenerator = apollo => makeSchema({
  sections: [{
    inputs: [{
      name: 'jobName',
      label: 'Project name',
      defaultValue: '',
      size: 'large',
      validate: extendSchema(Yup.string()
        .required(),
        [
          createValidJobNameValidator,
          schema => createUniqueJobValidator(schema, apollo),
        ]),
    },
    {
      name: 'sanaVersion',
      label: 'Sana version',
      defaultValue: 'SCC',
      validate: Yup.string().required().oneOf(sanaVersions),
      input: SelectEditor,
      options: [
        { value: 'SCC' },
        { value: 'SCC LTS' },
        { value: 'Sana 9.3.5' },
        { value: 'Sana 9.3.4' },
        { value: 'Sana 9.3.3' },
        { value: 'Sana 9.3.2' },
        { value: 'Sana 9.3.1' },
        { value: 'Sana 9.3.0' },
      ],
    },
    {
      name: 'websiteUrl',
      alias: 'general.websiteUrl',
      label: 'Website url',
      defaultValue: '',
      helperText: 'The website url. E.g. https://demo.sana-commerce.com/',
      validate: extendSchema(Yup.string()
        .required()
        .matches(urlRegEx, 'Please provide a valid website url. E.g. https://demo.sana-commerce.com/')
        .matches('^((?!/admin).)*$', 'Looks like you have specified the url to Sana admin. '
          + 'Please use the url to the frontend instead.E.g. https://demo.sana-commerce.com/'),
        [
          schema => websiteUrlValidator(schema),
        ]),
    },
    {
      name: 'admin_email',
      alias: 'general.adminUser.email',
      label: 'Admin email',
      defaultValue: '',
      validate: extendSchema(Yup.string().required().email(),
        [
          schema => adminCredentialsValidator(schema),
        ]),
    },
    {
      name: 'admin_password',
      alias: 'general.adminUser.password',
      label: 'Admin password',
      defaultValue: '',
      validate: extendSchema(Yup.string().required(),
        [
          schema => adminCredentialsValidator(schema),
        ]),
      InputProps: {
        type: 'password',
      },
    },
    {
      name: 'basicAuthentication_username',
      alias: 'general.basicAuthentication.username',
      label: 'NT Popup username',
      defaultValue: '',
      size: 'small',
      visible: values => !values.sanaVersion.includes('SCC'),
    },
    {
      name: 'basicAuthentication_password',
      alias: 'general.basicAuthentication.password',
      label: 'NT Popup password',
      defaultValue: '',
      size: 'small',
      visible: values => !values.sanaVersion.includes('SCC'),
      InputProps: {
        type: 'password',
      },
    }],
  }],
});
