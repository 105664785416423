import { switchMap, pluck, tap, map } from 'rxjs/operators';
import { merge } from 'lodash';
import { EMPTY, from } from 'rxjs';
import { ofType } from 'redux-observable';
import { JOB_UPDATED, INDEXING_COMPLETED } from './actions';
import { enqueueSnackbar } from 'behaviour/notifications';
import { getIndexingRunQuery, indexingTaskFragment } from './queries';

/**
   * @typedef {Object} EpicContext
   * @property {import('@apollo/client').ApolloClient} apollo
   *
   * @param {import("rxjs").Observable} action$
   * @param {import("redux-observable").StateObservable} state$
   * @param {EpicContext} context
   */

const jobEpics = (action$, state$, context) => {
  const { apollo } = context;

  const getJobId = jobName => apollo.cache.identify({
    __typename: 'Job',
    name: jobName,
  });

  const markJobAsUpdated = ({ jobName }) => {
    apollo.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        allJobs(allJobs, { readField }) {
          return allJobs.filter(
            job => jobName !== readField('name', job),
          );
        },
      },
    });
    apollo.cache.evict(getJobId(jobName));
    apollo.cache.gc();
  };

  const jobRenamed$ = action$.pipe(
    ofType(JOB_UPDATED),
    pluck('payload'),
    tap(payload => markJobAsUpdated(payload)),
    switchMap(() => EMPTY),
  );

  const updateIndexingTask = ({ jobName, run }) => {
    apollo.writeFragment({
      id: getJobId(jobName),
      fragment: indexingTaskFragment,
      fragmentName: 'indexingTaskFragment',
      data: {
        __typename: 'Job',
        name: jobName,
        indexingTask: {
          __typename: 'IndexingTask',
          isRunning: false,
          runs: [{ ...run }]
        },
      },
    });
  };

  const testDataIndexingCompleted$ = action$.pipe(
    ofType(INDEXING_COMPLETED),
    pluck('payload'),
    switchMap(({ jobName, runId }) => from(apollo.query({
      query: getIndexingRunQuery,
      variables: {
        jobName,
        runId,
      },
      fetchPolicy: 'network-only',
    })).pipe(
      pluck('data', 'job', 'indexingTask', 'run'),
      tap(run => updateIndexingTask({ jobName, run })),
      map(() => enqueueSnackbar({
        message: `Test data indexing for job '${jobName}' finished`,
      })),
    ))
  );

  return merge(jobRenamed$, testDataIndexingCompleted$);
};

export default jobEpics;
