import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Tooltip } from '@material-ui/core';
import { useEnableJobMutation } from 'behaviour/jobs';
import BlockIcon from '@material-ui/icons/BlockTwoTone';
import CheckCircleIcon from '@material-ui/icons/CheckCircleTwoTone';

function EnableActionButton({
  name, jobDisabled, className = '', disabled = false, disabledReason = '',
}) {
  const enableJob = useEnableJobMutation();
  const handleEnableButtonClick = (e) => {
    e.preventDefault();
    enableJob(name, true);
  };

  const handleDisableButtonClick = (e) => {
    e.preventDefault();
    enableJob(name, false);
  };

  return (
    <Tooltip title={(disabled && disabledReason) || ''}>
      <span>
        {jobDisabled
          ? (
            <Button
              variant="text"
              disabled={disabled}
              className={clsx(className, 'enable')}
              onClick={e => handleEnableButtonClick(e)}
              startIcon={<CheckCircleIcon />}
            >
              Enable
            </Button>
          ) : (
            <Button
              variant="text"
              disabled={disabled}
              className={clsx(className, 'disable')}
              onClick={e => handleDisableButtonClick(e)}
              startIcon={<BlockIcon />}
            >
              Disable
            </Button>
          )}
      </span>
    </Tooltip>
  );
}

EnableActionButton.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  jobDisabled: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string,
};

export default memo(EnableActionButton);
