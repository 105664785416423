import { useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { getJobId } from 'util/apollo';
import { get } from 'lodash';
import { useNotifications } from 'behaviour/notifications';

const startBuildMutation = gql`
  mutation startBuild($jobName:String!) {
    job(jobName: $jobName) {
      startBuild {
        queueItem {
          id
        }
      }
    }
  }
`;

export const useStartBuildMutation = () => {
  const client = useApolloClient();
  const { addNotification } = useNotifications();
  return useCallback(jobName => client.mutate({
    mutation: startBuildMutation,
    variables: {
      jobName,
    },
    update: (cache, result) => {
      const queueId = get(result, 'data.job.startBuild.queueItem.id');
      if (queueId) {
        cache.modify({
          id: getJobId(cache, jobName),
          fields: {
            inQueue() {
              return true;
            },
          },
        });
      }
    },
  }).catch(() => {
    addNotification({
      message: `The project ${jobName} cannot be started because of server error.`,
      options: {
        variant: 'error',
      },
    });
  }), [client, addNotification]);
};
