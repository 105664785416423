import queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function generateNewUrl({
  location, page, count, sort, order,
}) {
  const typedLocation = queryString.parse(location.search);

  const searchString = queryString.stringify({
    page: page === 0 ? undefined : (page + 1 || typedLocation.page),
    count: count || typedLocation.count,
    sort: sort || typedLocation.sort,
    order: order || typedLocation.order,
  });

  return searchString ? `?${searchString}` : '';
}

export default function useJobListRoute({
  currentPage = 0, currentRowsPerPage = 50, currentSort = 'displayName', currentOrder = 'asc',
}) {
  const location = useLocation();
  const history = useHistory();
  const typedLocation = queryString.parse(location.search);

  const onSortChange = useCallback((sort, order) => {
    const newUrl = generateNewUrl({
      location, sort, order,
    });
    history.push(newUrl);
  }, [location, history]);

  const onRowsPerPageChange = useCallback((count) => {
    const newUrl = generateNewUrl({
      location, count,
    });
    history.push(newUrl);
  }, [location, history]);

  const onPageChange = useCallback((newPage) => {
    const newUrl = generateNewUrl({
      location, page: newPage,
    });
    history.push(newUrl);
  }, [location, history]);

  return {
    currentPage: parseInt(typedLocation.page, 10) - 1 || currentPage,
    currentRowsPerPage: parseInt(typedLocation.count, 10) || currentRowsPerPage,
    currentSort: typedLocation.sort || currentSort,
    currentOrder: typedLocation.order || currentOrder,
    onSortChange,
    onRowsPerPageChange,
    onPageChange,
  };
}
