import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { switchDarkMode } from 'behaviour/app/actions';
import { IconButton, Tooltip } from '@material-ui/core';
import MoonIcon from '@material-ui/icons/Brightness2TwoTone';
import SunIcon from '@material-ui/icons/Brightness5TwoTone';
import { makeStyles } from '@material-ui/core/styles';
import { yellow, blue } from '@material-ui/core/colors';


const useStyles = makeStyles(() => ({
  darkIcon: {
    color: blue[500],
  },
  lightIcon: {
    color: yellow[800],
  },
}));


function DarkModeSwitcher() {
  const darkMode = useSelector(store => store.app.darkMode);
  const dispatch = useDispatch();
  const classes = useStyles();
  const switchToDarkMode = useCallback(() => dispatch(switchDarkMode(true)), [dispatch]);
  const switchToLightMode = useCallback(() => dispatch(switchDarkMode(false)), [dispatch]);

  return (
    <Tooltip title={darkMode ? 'Toggle dark mode' : 'Toggle light mode'}>
      <span>
        {darkMode && (
        <IconButton onClick={switchToLightMode}>
          <MoonIcon className={classes.darkIcon} />
        </IconButton>
        )}
        {!darkMode && (
        <IconButton onClick={switchToDarkMode} color="inherit">
          <SunIcon className={classes.lightIcon} />
        </IconButton>
        )}
      </span>
    </Tooltip>
  );
}

export default memo(DarkModeSwitcher);
