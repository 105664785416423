import { gql, useApolloClient } from '@apollo/client';
import { get, isEqual } from 'lodash';

const getErrorMessage = (testCriteria, order) => {
  const errors = [
    {
      criteria: { hasErpAttachment: true },
      message: 'Order has no ERP attachments',
    },
    {
      criteria: { hasSalesReport: true },
      message: 'Order has no sales report',
    }
  ];
  return errors.find((error) => isEqual(error.criteria, testCriteria))?.message;
};

const apolloClient = useApolloClient();

export const orderValidator = (schema, jobName, sanaVersion, testCriteria) =>
  schema.test('validOrder', (value, { createError }) => {
    if (!value || value === '-' || !sanaVersion.includes('SCC')) return true;

    const validationCriteria = { ...testCriteria };
    const queryFields = Object.keys(validationCriteria).join(" ");

    const getOrderInfoQuery = gql`
    query getOrderInfoQuery($jobName: String!, $orderId: String!) {
      orderInfo(jobName: $jobName, orderId: $orderId) {
        ${queryFields}   
      }      
    }`;

    return apolloClient
      .query({
        query: getOrderInfoQuery,
        variables: {
          jobName,
          orderId: value
        },
      })
      .then((result) => {
        var order = get(result, 'data.orderInfo');
        if (order === null) {
          return createError({ message: 'Order does not exist' });
        }

        for (const property in validationCriteria) {
          if (order[property] !== validationCriteria[property])
            return createError({
              message: getErrorMessage({ [property]: validationCriteria[property] }, order),
            });
        }
        return true;
      })
      .catch(() => true);
  });
