export const APP_INIT = 'APP_INIT' as const;
export const initApp = () => ({
  type: APP_INIT
});

export const SIGNALR_CONNECTION_STATUS = 'SIGNALR_CONNECTED' as const;
export const signalRConnected = (connected: boolean) => ({
    type: SIGNALR_CONNECTION_STATUS,
    payload: { connected },
});

export const LOADING = 'LOADING' as const;
export const loadingChanged = (loading: boolean) => ({
  type: LOADING,
  payload: { loading },
});

export const SWITCH_DARK_MODE = 'SWITCH_DARK_MODE' as const;
export const switchDarkMode = (darkMode: boolean) => ({
  type: SWITCH_DARK_MODE,
  payload: { darkMode },
});

export type AppAction = ReturnType<
  | typeof initApp
  | typeof signalRConnected
  | typeof loadingChanged
  | typeof switchDarkMode
>;
