import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';

const getErpConnectionAvailabilityQuery = gql`
  query getErpConnectionAvailabilityQuery($jobName: String!) {
    erpConnectionAvailability(jobName: $jobName)
  }
`;

const useErpConnection = ({ jobName, sanaVersion }) => {
  const { data } = useQuery(getErpConnectionAvailabilityQuery, {
    variables: {
      jobName
    },
    skip: !sanaVersion?.includes('SCC'),
    fetchPolicy: 'cache-and-network',
  });

  const isErpConnectionAvailable = get(data, 'erpConnectionAvailability');

  return { isErpConnectionAvailable };
};

export default useErpConnection;
