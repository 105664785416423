export const ADD_FAVORITE = 'ADD_FAVORITE' as const;
export const addToFavorites = (jobName: string) => ({
  type: ADD_FAVORITE,
  payload: jobName,
});

export const REMOVE_FAVORITE = 'REMOVE_FAVORITE' as const;
export const removeFromFavorites = (jobName: string) => ({
  type: REMOVE_FAVORITE,
  payload: jobName,
});

export type FavoritesAction = ReturnType<
  | typeof addToFavorites
  | typeof removeFromFavorites
>;
