import React, { useState, useEffect } from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { useApolloClient, gql } from '@apollo/client';
import { alpha, makeStyles } from '@material-ui/core/styles';
import MuiInputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import FavoriteButton from 'components/FavoriteButton';
import { useSelector } from 'react-redux';
import { useRecentViewed } from 'behaviour/recentViewed';
import { BuildStatus, buildStatusFragment } from 'components/jobBuild';
import ListboxComponent from './ListboxComponent';

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: '500px',
      margin: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 5, 1, 5),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  option: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  optionIcon: {
    marginRight: '4px',
  },
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  title: {
    display: 'inline-block',
  },
  favoriteButton: {
    marginLeft: theme.spacing(1),
  },
}));

const GET_ALL_JOBS = gql`
  query getAllJobs($offset: Int!) {
    allJobs (offset: $offset) {
      name, 
      displayName,
      lastBuild {
        id,
        completed
        ...buildStatusFragment
      }
    }
  }
  ${buildStatusFragment}
`;

export default function OmniSearchBox() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const client = useApolloClient();
  const history = useHistory();
  const favorites = useSelector(state => state.favorites);
  const loading = open && options.length === 0;
  const { recentViewed } = useRecentViewed();

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data } = await client.query({
        query: GET_ALL_JOBS,
        variables: {
          offset: 0,
        },
        fetchPolicy: 'network-only',
      }).catch(() => ({}));

      if ((!data || !data.allJobs) && active) {
        setOptions([]);
        setOpen(false);
        return;
      }

      if (active) {
        setOptions(Object.keys(data.allJobs).map(key => ({
          category: favorites.includes(data.allJobs[key].name) ? 1 : 1000,
          ...data.allJobs[key],
        })));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, client, favorites, recentViewed]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const optionComparer = (left, right) => {
    const leftViewedIndex = [...recentViewed].findIndex(v => v === left.name);
    const rightViewedIndex = [...recentViewed].findIndex(v => v === right.name);

    if (leftViewedIndex - rightViewedIndex !== 0) {
      return leftViewedIndex - rightViewedIndex;
    }

    const leftFavorites = favorites.includes(left.name);
    const rightFavorites = favorites.includes(right.name);

    if (leftFavorites === rightFavorites) {
      return -left.name.localeCompare(right.name);
    } if (leftFavorites) return 1;
    if (rightFavorites) return -1;

    return -left.name.localeCompare(right.name);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <MuiAutocomplete
        classes={{
          option: classes.option,
          listbox: classes.listbox,
        }}
        open={open}
        freeSolo
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(_, value) => {
          if (value) {
            history.push(`/projects/${value.name || value}`);
          }
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.displayName || option.name}
        options={options.sort((a, b) => -optionComparer(a, b))}
        loading={loading}
        ListboxComponent={ListboxComponent}
        renderInput={params => (
          <MuiInputBase
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            placeholder="Type to search.."
            fullWidth
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        )}
        renderOption={option => (
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="center"
          >
            <Grid item className={classes.optionIcon}>
              <BuildStatus {...option.lastBuild} />
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.title}>
                {option.displayName}
              </Typography>
              <FavoriteButton
                className={classes.favoriteButton}
                jobName={option.name}
                readonly
              />
            </Grid>
          </Grid>
        )}
      />
      <div className={classes.loadingIcon}>
        {loading
          ? <MuiCircularProgress color="inherit" size={20} />
          : null}
      </div>
    </div>
  );
}
