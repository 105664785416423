import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { TestReport } from 'components/testReport';
import { RoutingTabs } from 'components/tabs';
import {
  Route,
  Switch,
} from 'react-router-dom';
import BuildOutput from './buildOutput';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
  logs: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const createTabs = (jobName, buildNumber) => [{
  to: `/projects/${jobName}/${buildNumber}`,
  label: 'Test report',
  value: 'testReport',
}, {
  to: `/projects/${jobName}/${buildNumber}/output`,
  label: 'Logs',
  value: 'logs',
},
];

function BuildContent({ jobName, buildNumber }) {
  const tabs = createTabs(jobName, buildNumber);
  const classes = useStyles();

  return (
    <>
      <RoutingTabs tabs={tabs} />
      <Paper className={classes.paper}>
        <Switch>
          <Route
            path="/projects/:jobName/:buildNumber"
            render={() => (
              <TestReport jobName={jobName} buildNumber={buildNumber} />
            )}
            exact
          />
          <Route
            path="/projects/:jobName/:buildNumber/testReport"
            render={() => (
              <TestReport jobName={jobName} buildNumber={buildNumber} />
            )}
            exact
          />
          <Route
            path="/projects/:jobName/:buildNumber/output"
            render={() => (
              <BuildOutput jobName={jobName} buildNumber={buildNumber} />
            )}
          />
        </Switch>
      </Paper>
    </>
  );
}

BuildContent.propTypes = {
  jobName: PropTypes.string.isRequired,
  buildNumber: PropTypes.number.isRequired,
};

export default BuildContent;
