import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import Duration from 'components/Duration';
import TimeAgo from 'components/TimeAgo';
import IndexingRunStatus, { RunStatus, indexingRunStatusFragment } from './IndexingRunStatus';
import { gql } from '@apollo/client';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { xcode } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export const indexingRunFragment = gql`
  fragment indexingRunFragment on IndexingRun {
    id
    number
    startedAt
    duration
    completed
    exceptionDetails
    ...indexingRunStatusFragment
  }
  ${indexingRunStatusFragment}
`;

const useClasses = makeStyles(() => ({
  tableRow: {
    verticalAlign: 'top',
  }
}));

function formatDate(value) {
  if (!value) { return (<span> - </span>); }

  const date = new Date(value);
  return <TimeAgo date={date} />;
}

function IndexingRun({ run }) {
  const classes = useClasses();
  const { status, number, duration: runDuration, startedAt, completed, exceptionDetails } = run;
  const running = status === RunStatus.Processing;
  const duration = running ? new Date() - new Date(startedAt) : runDuration;

  return (
    <Fragment>
      <TableRow className={classes.tableRow}>
        <TableCell className="status">
          <IndexingRunStatus {...run} />
        </TableCell>
        <TableCell className="number">#{number}</TableCell>
        <TableCell className="duration">
          <Duration duration={duration} live={running} />
        </TableCell>
        <TableCell className="completed">
          {formatDate(completed)}
        </TableCell>
        <TableCell className="exceptionDetails">
          <SyntaxHighlighter
            language="csharp"
            wrapLongLines={true}
            style={xcode}
            customStyle={{
              padding: 0,
              margin: 0
            }}
          >
            {exceptionDetails}
          </SyntaxHighlighter>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

IndexingRun.propTypes = {
  run: PropTypes.shape({
    status: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    duration: PropTypes.number,
    startedAt: PropTypes.string.isRequired,
    completed: PropTypes.string,
    exceptionDetails: PropTypes.string,
  })
};

export default memo(IndexingRun);
