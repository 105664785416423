import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';

function Button({ title, ...props }) {
  return (
    <MuiButton {...props}>
      {title}
    </MuiButton>
  );
}

export default Button;

Button.propTypes = {
  title: PropTypes.string.isRequired,
};
