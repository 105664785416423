import {
  applyMiddleware, compose, createStore, combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import reducers from './behaviour/rootReducer';
import rootEpic from './behaviour/rootEpic';
import signalRMiddleware from './middlewares/signalRMiddleware';

export default function configureStore(history, initialState, dependencies) {
  const epicMiddleware = createEpicMiddleware({ dependencies });

  const middleware = [
    thunk,
    signalRMiddleware,
    epicMiddleware,
    routerMiddleware(history),
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  // eslint-disable-next-line no-underscore-dangle
  window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );

  const saveToLocalStorage = (favorites) => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  };

  store.subscribe(() => {
    saveToLocalStorage(
      store.getState().favorites,
    );
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./behaviour/rootReducer', () => {
      store.replaceReducer(combineReducers(reducers));
    });
  }

  epicMiddleware.run(rootEpic);

  return store;
}
