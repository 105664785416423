import { useContext } from 'react';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLogin } from 'util/auth';
import context from './context';

function useAuthentication() {
  const authContext = useContext(context);
  const { accounts, inProgress, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const login = useLogin();

  return {
    ...authContext,
    username: account && account.username,
    name: account && account.name,
    loading: inProgress,
    isAuthenticated,
    enabled: instance != null,
    instance,
    account,
    login,
  };
}

export default useAuthentication;
