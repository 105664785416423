import {
  useState, useCallback, useEffect,
} from 'react';
import fetchMore from './fetchMore';

const EmptyArray = [];

export function useFetchMore(items = EmptyArray, onFetchMore = null) {
  const internalItems = items.length === 0 ? EmptyArray : items;

  const [pagedItems, setPagedItems] = useState([]);
  const [totalCount, setTotalCount] = useState(internalItems.length);

  useEffect(() => {
    setTotalCount(internalItems.length);
  }, [internalItems.length]);

  const loadMore = useCallback((pagingOptions) => {
    const page = fetchMore({
      ...pagingOptions,
      items: internalItems,
    });

    setPagedItems(page.items);
    setTotalCount(page.totalCount);

    if (onFetchMore) {
      onFetchMore({
        ...pagingOptions,
      });
    }
  }, [internalItems, onFetchMore]);

  return {
    pagedItems,
    totalCount,
    fetchMore: loadMore,
  };
}
