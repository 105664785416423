import { gql, useApolloClient } from '@apollo/client';
import { get } from 'lodash';

const getShippingMethodQuery = gql`
  query getShippingMethodQuery($jobName: String!, $name: String!, $calculationMethod: String) {
    shippingMethod(jobName: $jobName, name: $name, calculationMethod: $calculationMethod)
      {
        name,
        providerType,
        enabled
      }
    }
`;

const apolloClient = useApolloClient();

export const uniqueShippingMethodValidator = async (value, calculationMethod, jobName, sanaVersion) => {
  if (!value || value === '-' || !sanaVersion.includes('SCC')) return true;
  return apolloClient.query({
    query: getShippingMethodQuery,
    variables: {
      jobName,
      name: value,
      calculationMethod,
    },
  })
    .then(result => get(result, 'data.shippingMethod'))
    .catch(() => true);
};
