import * as Yup from 'yup';
import { makeSchema } from 'components/form/builder';
import { getValue } from 'util/getValue';

export const schemaGenerator = (parameters) =>
  makeSchema({
    sections: [
      {
        title: 'Discount codes',
        description:
          'If the field for name is empty or its value is `-`, then tests for this discount code will be skipped.',
        inputs: [
          {
            name: 'discountCode_fixedAmount',
            alias: 'DiscountCode.FixedAmount.Name',
            label: 'Fixed amount discount code name',
            defaultValue: getValue(
              parameters.discountCode_fixedAmount,
              'Autotest fixed amount'
            ),
            validate: Yup.string(),
          },
          {
            name: 'discountCode_percentageDiscount',
            alias: 'DiscountCode.PercentageDiscount.Name',
            label: 'Percentage discount code name',
            defaultValue: getValue(
              parameters.discountCode_percentageDiscount,
              'Autotest percentage'
            ),
            validate: Yup.string(),
          },
          {
            name: 'discountCode_freeShipping',
            alias: 'DiscountCode.FreeShipping.Name',
            label: 'Free shipping discount code name',
            defaultValue: getValue(
              parameters.discountCode_freeShipping,
              'Autotest free shipping'
            ),
            validate: Yup.string(),
          },
        ],
      },
    ],
  });
