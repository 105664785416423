import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import IllustratedMessage from 'components/IllustratedMessage';
import serverDownImage from 'asserts/undraw_meditation_re_gll0.svg';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '800px',
    margin: '0 auto',
  },
}));

function InternalServerErrorMessage({ traceIdentifier }) {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.root} internalServerError`}>
      <IllustratedMessage
        imageSrc={serverDownImage}
        imageTitle="500 Internal server error"
        title="500 - Internal server error"
        subTitle="Oops, something went wrong."
        actionBox={(
          <>
            <Typography>
              Please try again later or feel free to contact us if the problem persists
              {traceIdentifier && (
                <>
                  {' including'}
                  <Typography variant="subtitle1" className="traceIdentifier">
                    {traceIdentifier}
                  </Typography>
                  in your message
                </>
              )}
            </Typography>
          </>
        )
        }
      />
    </Grid>
  );
}

InternalServerErrorMessage.propTypes = {
  traceIdentifier: PropTypes.string,
};

export default memo(InternalServerErrorMessage);
