// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 *
 * @callback FormHelperTextCallback
 * @param {string} value
 *
 * @callback FormInputDisabledCallback
 * @param {Object} values
 */

/**
 * @typedef {Object} FormSchema
 * @property {Array<FormSection>} sections
 * @property {Array<FormTab>} tabs
 *
 * @typedef {Object} FormSection
 * @property {String} title
 * @property {String|React.ReactElement} description
 * @property {Array<FormInput>} inputs
 *
 * @typedef {Object} FormExpansionPanel
 * @property {String} name
 * @property {String} title
 * @property {Array<FormSection>} sections
 * @property {string} disabledReason
 *
 * @typedef {Object} FormTab
 * @property {string} name
 * @property {string} title
 * @property {boolean} disabled
 * @property {string} disabledReason
 * @property {Array<FormSection>} sections
 *
 * @typedef {Object} FormInput
 * @property {string} name
 * @property {string} alias
 * @property {string} label
 * @property {string} placeholder
 * @property {string|FormHelperTextCallback} helperText
 * @property {function} input
 * @property {boolean|FormInputDisabledCallback} disabled
 * @property {string} disabledReason
 * @property {Object} defaultValue
 * @property {function} validate Yup validator
 * @property {boolean} visible
 * @property {('small'|'medium'|'large')} size
 * @property {Object} InputProps
 *
*/

/**
 * This function doesn't really "do anything" at runtime, it's just the identity
 * function. Its only purpose is to defeat TypeScript's type widening when providing
 * schema to schema generator function.
 * @param {FormSchema} schema
 */
export default function makeSchema(schema) {
  return schema;
}
