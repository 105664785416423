import React from 'react';
import {
  Typography, TableBody, Table, Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { InternalServerErrorMessage } from 'components/errorStates';
import { get } from 'lodash';
import TableRowSkeleton from 'components/TableRowSkeleton';
import { isNetworkError, getTraceIdentifier } from 'util/apollo';
import RecentBuildRow, { recentBuildRowFragment } from './RecentBuildRow';

const loadRecentBuildsQuery = gql`
  query loadRecentBuilds($offset: Int!) {
    allJobs (offset: $offset) {
      name
      description
      lastBuild {
        id
        timestamp
        ...recentBuildRowFragment
      }
    }
  }
  ${recentBuildRowFragment}
`;

function RecentBuilds({ className = '' }) {
  const { data, error, loading } = useQuery(loadRecentBuildsQuery, {
    variables: {
      offset: 0,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error && isNetworkError(error)) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  const allJobs = get(data, 'allJobs', []);
  const jobs = allJobs.filter(job => job.lastBuild && job.description && job.description.match(/__Release (\d+|SCC)\.\d+(\.\d+)?__$/) && !job.name.includes('Automation Hub Template'));
  const lastBuildsCount = jobs.length < 5 ? jobs.length : 5;

  const getLastBuilds = () => jobs.sort((a, b) => {
    if ((a.lastBuild.timestamp === '' && b.lastBuild.timestamp !== '') || (new Date(a.lastBuild.timestamp) < new Date(b.lastBuild.timestamp))) return 1;
    if ((a.lastBuild.timestamp !== '' && b.lastBuild.timestamp === '') || (new Date(a.lastBuild.timestamp) > new Date(b.lastBuild.timestamp))) return -1;
    return 0;
  }).slice(0, lastBuildsCount).map(job => ({
    jobName: job.name,
    build: job.lastBuild,
  }));

  const lastBuilds = getLastBuilds();

  return (
    <Paper className={className}>
      <Typography variant="h6">Recent runs</Typography>
      <Table style={{ minWidth: 260 }}>
        <TableBody>
          {lastBuilds.length === 0
            && <span>Your recent runs will be displayed here</span>}
          {lastBuilds.length > 0 && [...Array(lastBuildsCount).keys()].map(value => (
            loading ? (
              <TableRowSkeleton
                key={value}
                columns={4}
                cellStyle={{ padding: '5px 0', borderBottomWidth: 0 }}
              />
            ) : (
              <RecentBuildRow
                key={value}
                {...lastBuilds[value]}
              />
            )
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

RecentBuilds.propTypes = {
  className: PropTypes.string,
};

export default RecentBuilds;
