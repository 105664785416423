import { useCallback, useState } from 'react';
import { get } from 'lodash';

function getSelectionState(allItems, selected) {
  if (selected.length === 0) { return 'unselected'; }

  if (allItems.length > selected.length) { return 'selected'; }

  return 'selectedAll';
}

export function useSelection(items, keyField, onItemsSelected = null) {
  const [selected, setSelected] = useState([]);

  const isSelected = useCallback((data) => {
    const key = get(data, keyField);
    return selected.indexOf(key) !== -1;
  }, [selected, keyField]);

  const handleSelectAll = useCallback((checked) => {
    if (checked) {
      const newSelecteds = items.map(n => get(n, keyField));
      setSelected(newSelecteds);
      if (onItemsSelected) {
        onItemsSelected(newSelecteds);
      }
      return;
    }
    setSelected([]);
    if (onItemsSelected) {
      onItemsSelected([]);
    }
  }, [items, keyField, onItemsSelected]);

  const handleSelected = useCallback((e, key) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, [key]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (onItemsSelected) {
      onItemsSelected(newSelected);
    }
  }, [selected, onItemsSelected]);

  return {
    selected,
    isSelected,
    handleSelected,
    handleSelectAll,
    selectionState: getSelectionState(items, selected),
  };
}
