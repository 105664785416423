import React from 'react';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Warning from 'components/Warning';

function TestDataIndexingAlert() {
  return (
    <Warning>
      <AlertTitle>The test data indexing task was not run yet.</AlertTitle>
      <span>
        Autocomplete functionality will work slowly due to real-time Bridge calls.
        <br />
        Press Run test data indexing task button below to index test data into database.
      </span>
    </Warning>
  );
}

export default TestDataIndexingAlert;
