import {
  ApolloClient, from,
} from '@apollo/client';
import { authLink } from './authLink';
import { httpLink } from './httpLink';
import { cache } from './cache';
import { temporaryNotAvailableError } from './temporaryNotAvailableError';

export function createApolloClient(sharedContext) {
  const link = from([
    temporaryNotAvailableError,
    authLink,
    httpLink,
  ]);

  return new ApolloClient({
    cache,
    link,
    defaultOptions: {
      mutate: {
        context: {
          ...sharedContext,
        },
      },
      query: {
        context: {
          ...sharedContext,
        },
      },
      watchQuery: {
        context: {
          ...sharedContext,
        },
      },
    },
  });
}
