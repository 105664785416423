import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiTab from '@material-ui/core/Tab';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tab: {
    textAlign: 'right',

    '& .MuiTab-wrapper': {
      [theme.breakpoints.up('md')]: {
        alignItems: 'unset',
      },
    },
  },
  error: {
    color: theme.palette.error.main,
    '&.Mui-selected': {
      color: theme.palette.error.main,
    },
  },
}));

function FormTab({
  error = false, disabled = false, disabledReason, ...rest
}) {
  const classes = useStyles();
  return (
    <Tooltip
      title={!disabled || disabledReason == null ? '' : disabledReason}
    >
      <>
        <MuiTab
          className={clsx({ [classes.error]: error }, classes.tab)}
          disabled={disabled}
          {...rest}
        />
      </>
    </Tooltip>
  );
}

FormTab.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
};

export default FormTab;
