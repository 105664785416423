import { AccordionSummary, withStyles } from '@material-ui/core';

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderBottom: '2px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(AccordionSummary);

export default ExpansionPanelSummary;
