import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '@material-ui/core';
import { gql } from '@apollo/client';
import { ButtonSkeleton } from 'components/form';
import TestReportList, { testReportListFragment } from './TestReportList';
import TestReportStatus from './TestReportStatus';
import ReplayButton, { replayBuildButtonFragment } from './ReplayButton';
import { flatTestCases } from './utils';

export default function TestReportContent({
  job, build, testReport, loading,
}) {
  const [testIds, setTestIds] = useState([]);
  const testCases = useMemo(() => flatTestCases(testReport), [testReport]);

  return (
    <TestReportStatus
      build={build}
      isEmpty={!testReport || testReport.isEmpty}
      loading={loading}
    >
      <>
        <Toolbar variant="dense">
          {loading && <ButtonSkeleton />}
          {!loading && <ReplayButton {...job} buildNumber={build.number} testIds={testIds} />}
        </Toolbar>
        <TestReportList
          items={testCases}
          loading={loading}
          onItemsSelected={(items) => {
            setTestIds(items);
          }}
          allowSelection={(job && job.buildable) || loading}
        />
      </>
    </TestReportStatus>
  );
}

export const testReportContentFragment = gql`
  fragment testReportContentFragment on Job {
    name
    buildable
    ...replayBuildButtonFragment
    build(buildNumber: $buildNumber) {
      id
      building
      result
      number
      testReport {
        isEmpty
        suites {
          cases {
            ...testReportListFragment
          }
        }
      }
    }
  }
  ${replayBuildButtonFragment}
  ${testReportListFragment}
`;

TestReportContent.propTypes = {
  job: PropTypes.shape({
    name: PropTypes.string.isRequired,
    buildable: PropTypes.bool.isRequired,
  }),
  build: PropTypes.shape({
    number: PropTypes.number.isRequired,
  }),
  testReport: PropTypes.shape({
    isEmpty: PropTypes.bool.isRequired,
  }),
  loading: PropTypes.bool.isRequired,
};
