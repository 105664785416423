import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from './actions';
import type { Notification } from './types';

const useNotifications = () => {
  const dispatch = useDispatch();

  const addNotification = useCallback((notification: Notification) => dispatch(enqueueSnackbar(notification)),
    [dispatch]);

  return {
    addNotification,
  };
};

export default useNotifications;
