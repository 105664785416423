import { Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

function hasMutation(query) {
  return query.definitions.findIndex(def => def.operation === 'mutation') >= 0;
}

export const createAuthError = (getToken, handleError) => onError(({
  networkError, operation, forward,
}) => {
  if (networkError && networkError.statusCode === 401) {
    const loginPath = `/login?returnUrl=${window.location.pathname}`;

    return new Observable((observer) => {
      function retry(token) {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            Authorization: token ? `Bearer ${token}` : '',
          },
        });

        const response$ = forward(operation);

        response$.subscribe({
          next: value => observer.next(value),
          complete: () => observer.complete(),
          error: err => observer.error(err),
        });
      }

      getToken().then(retry).catch(async (error) => {
        const anyMutation = hasMutation(operation.query);
        if (handleError) {
          await handleError({
            error,
            anyMutation,
            loginPath,
          });
        }
      });

      return () => {};
    });
  }
  return null;
});
