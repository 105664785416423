import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import makerLaunch from 'asserts/maker_launch.svg';
import clsx from 'clsx';
import DataGrid, { useFetchMore, makeColumns } from 'components/dataGrid';
import { gql } from '@apollo/client';
import IllustratedMessage from '../IllustratedMessage';
import BuildItem, { buildItemFragment } from './BuildItem';

const columns = makeColumns([
  { id: 'result', label: 'Status', contentFit: true },
  { id: 'number', label: 'Run', sortable: true },
  { id: 'duration', label: 'Duration' },
  { id: 'timestamp', label: 'Completed' },
  { id: '' },
]);

const useStyles = makeStyles(() => ({
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  table: {
    position: 'relative',
  },
}));

function BuildList({ jobName, builds = [], loading = false }) {
  const { pagedItems, totalCount, fetchMore } = useFetchMore(builds);
  const classes = useStyles();

  if (!loading && builds.length === 0) {
    return (
      <IllustratedMessage
        imageSrc={makerLaunch}
        title="This project has no run activity yet"
        subTitle="Press Run button and start your first launch"
      />
    );
  }

  return (
    <DataGrid
      className={clsx(classes.table, 'buildsTable')}
      columns={columns}
      fetchMore={fetchMore}
      totalCount={totalCount}
      items={pagedItems}
      loading={loading}
      renderRow={({ data: rowData }) => (
        <BuildItem
          key={rowData.id}
          jobName={jobName}
          run={rowData}
        />
      )}
      defaultSortProperty="number"
      defaultSortAscending={false}
      defaultRowsPerPage={20}
      pagination={false}
    />
  );
}

export const buildListFragment = gql`
  fragment buildListFragment on Build {
    id,
    ...buildItemFragment
  },
  ${buildItemFragment}
`;

BuildList.propTypes = {
  jobName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  builds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
};

export default memo(BuildList);
