import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  red, green, orange, grey,
} from '@material-ui/core/colors';
import Tooltip from 'components/Tooltip';
import CheckIcon from '@material-ui/icons/CheckCircleTwoTone';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import CancelIcon from '@material-ui/icons/CancelTwoTone';
import NotBuiltIcon from '@material-ui/icons/Brightness1TwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { gql } from '@apollo/client';

const useStyles = makeStyles(() => ({
  success: {
    color: green[500],
  },
  warning: {
    color: orange[500],
  },
  failed: {
    color: red[500],
  },
  running: {
    animationDuration: '750ms',
    marginLeft: '2px',
  },
  cancel: {
    color: grey[500],
  },
}));

function BuildStatus({ result, className = '', fontSize = 'medium' }) {
  const classes = useStyles();
  const statusNormalized = result && result.toLowerCase();

  if (statusNormalized === 'running') {
    return (
      <Tooltip title="Run is in progress" className={className}>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="running"
          size={20}
          thickness={4}
        />
      </Tooltip>
    );
  }

  if (statusNormalized === 'success') {
    return (
      <Tooltip title="Finished successfully" className={className}>
        <CheckIcon className={classes.success} fontSize={fontSize} />
      </Tooltip>
    );
  }

  if (statusNormalized === 'aborted') {
    return (
      <Tooltip title="Aborted" className={className}>
        <CancelIcon className={classes.cancel} fontSize={fontSize} />
      </Tooltip>
    );
  }

  if (statusNormalized === 'warning') {
    return (
      <Tooltip title="Attention required" className={className}>
        <WarningIcon className={classes.warning} fontSize={fontSize} />
      </Tooltip>
    );
  }

  if (statusNormalized === 'failure') {
    return (
      <Tooltip title="Finished with errors" className={className}>
        <ErrorIcon className={classes.failed} fontSize={fontSize} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Not built" className={className}>
      <NotBuiltIcon className={classes.cancel} fontSize={fontSize} />
    </Tooltip>
  );
}

export const buildStatusFragment = gql`
  fragment buildStatusFragment on Build {
    result
  }
`;

BuildStatus.propTypes = {
  result: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.oneOf(['inherit', 'medium', 'small', 'large']),
};

export default memo(BuildStatus);
