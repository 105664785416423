import React from 'react';
import { PropTypes } from 'prop-types';
import { useDeleteJobMutation } from 'behaviour/jobs';
import { Modal } from 'components/modal';

function DeleteJobDialog({
  name,
  displayName,
  open,
  setOpen,
  onDeleted,
}) {
  const deleteJob = useDeleteJobMutation();

  const handleConfirm = () => {
    deleteJob(name).then((result) => {
      if (result && result.data && onDeleted) {
        setOpen(false);
        onDeleted(name);
      }
    });
  };

  return (
    <>
      <Modal
        heading={`You're about to delete the ${displayName || name} project`}
        open={open}
        onClose={() => setOpen(false)}
        variant="danger"
        actionsProps={[
          {
            className: 'cancelButton',
            text: 'No, keep it',
            onClick: () => setOpen(false),
          }, {
            className: 'deleteButton',
            text: 'Trash it',
            color: 'secondary',
            onClick: handleConfirm,
          },
        ]}
      >
        This project will be permanently deleted. This is destructive operation.
      </Modal>
    </>
  );
}

export default DeleteJobDialog;

DeleteJobDialog.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onDeleted: PropTypes.func,
};
