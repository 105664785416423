import React, { forwardRef, memo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import clsx from 'clsx';
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import IconLabel from 'components/IconLabel';

const ModalTransition = forwardRef((props, ref) => <Grow direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  warningIcon: {
    color: theme.palette.warning.main,
  },
  dangerIcon: {
    color: theme.palette.error.main,
  },
}));

function Modal({
  heading, variant = 'default', actionsProps, formProps, children, ...props
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      {...props}
      aria-labelledby="job-dialog"
      TransitionComponent={ModalTransition}
    >
      <DialogTitle id="job-dialog">
        <IconLabel icon={(({ className }) => (
          <>
            {variant === 'warning' && <WarningIcon className={clsx([classes.warningIcon, className])} />}
            {variant === 'danger' && <ErrorIcon className={clsx([classes.dangerIcon, className])} />}
          </>
        )
        )}
        >
          {heading}
        </IconLabel>
      </DialogTitle>

      <Formik {...formProps}>
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              {children}
            </DialogContent>
            <DialogActions>
              {actionsProps.map(({ text, disabled, ...buttonProps }, index) => (
                <Button
                  key={text}
                  variant={index === actionsProps.length - 1 ? 'contained' : 'text'}
                  {...buttonProps}
                  disabled={isSubmitting || disabled}
                >
                  {text}
                </Button>
              ))}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

Modal.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.oneOf(['default', 'warning', 'danger']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionsProps: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
  })),
  formProps: PropTypes.shape(),
};

export default memo(Modal);
