import React, {
  memo, useContext, useState, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import {
  Chip, CircularProgress, makeStyles, Tooltip,
} from '@material-ui/core';
import { TextField } from 'components/form';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormContext from 'components/form/builder/FormBuilderContext';
import { JobContext } from 'components/job';
import CloudOffIcon from '@material-ui/icons/CloudOffTwoTone';
import { useTestData } from 'behaviour/jobs';
import { set } from 'lodash';

const useStyles = makeStyles(theme => ({
  input: {
  },
  required: {
    color: theme.palette.error.dark,
    paddingLeft: '2px',
  },
  smallInput: {
    width: '35%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mediumInput: {
    width: '70%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  largeInput: {
    width: '100%',
  },
  chip: {
    marginRight: theme.spacing(1),
    borderRadius: '4px',
  },
  loadMore: {
    margin: 'auto',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

function InputLabel(label, required) {
  const classes = useStyles();

  if (required) {
    return (
      <>
        {label}
        <span className={classes.required}>*</span>
      </>
    );
  }

  return (<>{label}</>);
}

function Extra({ extra }) {
  const classes = useStyles();

  if (extra && extra.length > 0) {
    return (
      <div>
        {extra.map(item => item && (
          <Chip
            key={item}
            className={clsx(classes.chip, 'extra')}
            color="secondary"
            size="small"
            label={item}
          />
        ))}
      </div>
    );
  }

  return null;
}

Extra.propTypes = {
  extra: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function formatVersion(input) {
  return (`${input}`).split('').join('.');
}

function AutocompleteEditor({
  name, label, size = 'medium', required = false, helperText = '', prefill = null, disabledReason, ...props
}) {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();
  const jobName = useContext(JobContext);
  const { parameters } = useContext(FormContext);
  const [open, setOpen] = useState(false);

  const relatedField = prefill.relatedField || '';
  const relatedFieldValue = relatedField ? values[relatedField] : '';
  const relatedFieldPath = relatedField.split('_').join(' -> ').replace(/([A-Z])/g, ' $1').toLowerCase();
  const { onValueChange } = prefill;

  const { testData, loadMore, loading } = useTestData({
    jobName,
    field: prefill.fetchKey,
    name,
    relatedField: {
      id: relatedField,
      value: relatedFieldValue,
      path: relatedFieldPath,
    },
    parameters,
  });

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={testData}
      renderOption={(option) => {
        switch (option.id) {
          case 'loadMore':
          case 'loading':
            return (
              <div id={option.id} className={classes.loadMore}>
                {option.label}
              </div>
            );
          case 'noResults':
            return (<div id={option.id}>{option.label}</div>);
          default:
            return (
              <div id={option.id}>
                {option.id}
                {option.label && ` - ${option.label}`}
                {option.extra && <Extra extra={option.extra} />}
              </div>
            );
        }
      }}
      getOptionLabel={option => (option.id === 'loadMore' ? '' : option.id || option)}
      getOptionDisabled={option => option.id === 'noResults' || option.id === 'loading'}
      onFocus={() => loadMore()}
      value={values[name]}
      onChange={(_, newValue) => {
        if (newValue.id !== 'loadMore') {
          setFieldValue(name, newValue.id);
          if (onValueChange) {
            var relatedData = {};
            if (newValue.relatedData)
              newValue.relatedData.forEach(({ key, value }) => set(relatedData, key, value));
            onValueChange({ relatedData, setFieldValue });
          }
          setOpen(false);
        } else {
          loadMore(testData.length - 1);
        }
      }}
      disableCloseOnSelect
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      className={clsx(
        {
          [classes.smallInput]: size === 'small',
          [classes.mediumInput]: size === 'medium',
          [classes.largeInput]: size === 'large',
        },
      )}
      {...props}
      renderInput={({ InputLabelProps, ...params }) => (
        <Field
          name={name}
          {...props}
          {...params}
          label={InputLabel(label, required)}
          helperText={helperText}
          disabledReason={disabledReason}
          component={TextField}
          className={clsx(classes.input, { required })}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment key={name}>
                {loading ? <CircularProgress color="inherit" size={20} className={clsx(classes.icon, 'loadingIndicator')} /> : null}
                {!parameters.sanaVersion?.includes('SCC') && (
                  <Tooltip title={`Autocomplete functionality is not available for Sana ${formatVersion(parameters.sanaVersion)}`}>
                    <CloudOffIcon className={classes.icon} />
                  </Tooltip>
                )}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

AutocompleteEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  required: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabledReason: PropTypes.string,
  disabled: PropTypes.bool,
  prefill: PropTypes.shape({
    fetchKey: PropTypes.string.isRequired,
    relatedField: PropTypes.string,
  }),
};

export default memo(AutocompleteEditor);
