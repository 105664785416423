import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

function calculatePercentage(current, total) {
  return current / total * 100;
}

export default function BuildProgressIndicator({ currentDuration, estimatedDuration = 1 }) {
  const [completed, setCompleted] = useState(
    calculatePercentage(+currentDuration, +estimatedDuration),
  );
  const intervalMs = 1000;
  const diff = calculatePercentage(intervalMs, +estimatedDuration);

  useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 100;
        }

        const newValue = oldCompleted + diff;
        return Math.min(newValue, 100);
      });
    }

    const timer = setInterval(progress, intervalMs);
    return () => {
      clearInterval(timer);
    };
  }, [currentDuration, estimatedDuration, diff]);

  return (
    <LinearProgress variant="determinate" value={completed} className="buildProgress" />
  );
}

BuildProgressIndicator.propTypes = {
  estimatedDuration: PropTypes.number,
  currentDuration: PropTypes.number.isRequired,
};
