import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { InternalServerErrorMessage, NotFoundMessage } from 'components/errorStates';
import { getTraceIdentifier, isNetworkError } from 'util/apollo';
import TestReportContent, { testReportContentFragment } from './TestReportContent';

const loadTestReport = gql`
  query getTestReport($jobName: String!, $buildNumber: Int!) {
    job(name: $jobName) {
      ...testReportContentFragment
    }
  }
  ${testReportContentFragment}
`;

function TestReport({
  jobName, buildNumber,
}) {
  const { data, loading, error } = useQuery(loadTestReport, {
    variables: {
      jobName,
      buildNumber,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const testReport = get(data, 'job.build.testReport');
  const job = get(data, 'job');
  const build = get(data, 'job.build');

  if (error && isNetworkError(error)) {
    const traceIdentifier = getTraceIdentifier(error);
    return (<InternalServerErrorMessage traceIdentifier={traceIdentifier} />);
  }

  if (!loading && !build) { return <NotFoundMessage />; }

  return (
    <TestReportContent
      job={job}
      build={build}
      testReport={testReport}
      loading={loading}
    />
  );
}

export default withRouter(TestReport);

TestReport.propTypes = {
  jobName: PropTypes.string.isRequired,
  buildNumber: PropTypes.number.isRequired,
};
