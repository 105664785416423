const getSectionInputs = (section, inputs) => {
  if (section.inputs) {
    section.inputs.forEach(({ name, disabled, prefill }) => {
      if (!disabled && prefill) inputs.push({ name, prefill });
    });
  }

  return inputs;
};

export const getInputsToPrefill = schema => {
  const inputs = [];

  if (schema.sections) {
    schema.sections.forEach(section => {
      getSectionInputs(section, inputs);
      if (section.expansionPanels) {
        section.expansionPanels.forEach(expansionPanel => {
          if (!expansionPanel.disabled && expansionPanel.sections) {
            expansionPanel.sections.forEach(innerSection => {
              getSectionInputs(innerSection, inputs);
            });
          }
        });
      }
    });
  }

  return inputs;
};
