import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { gql } from '@apollo/client';
import BlockIcon from '@material-ui/icons/BlockTwoTone';
import ReportIcon from '@material-ui/icons/ReportTwoTone';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  chip: {
    marginRight: theme.spacing(1),
    borderRadius: '4px',
  },
}));

function JobBadges({ disabled, buildable }) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {disabled && <Chip size="small" icon={<BlockIcon />} label="Disabled" color="secondary" className={clsx(classes.chip, 'labelDisabled')} />}
      {!disabled && !buildable && <Chip size="small" icon={<ReportIcon />} label="Non-runnable" color="secondary" className={classes.chip} />}
    </Box>
  );
}

export const jobBadgesFragment = gql`
  fragment jobBadgesFragment on Job {
    buildable
    disabled
  }
`;

JobBadges.propTypes = {
  disabled: PropTypes.bool.isRequired,
  buildable: PropTypes.bool.isRequired,
};

export default JobBadges;
