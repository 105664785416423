import React, { memo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  makeStyles, FormControl, FormControlLabel, InputAdornment, CircularProgress,
} from '@material-ui/core';
import { subscriptionTypes, useSubscription } from 'behaviour/jobs';
import MuiSelect from '@material-ui/core/Select';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { JobContext } from 'components/job';
import { useNotifications } from 'behaviour/notifications';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '16px 0 0',
  },
  label: {
    alignItems: 'flex-start',
    width: '100%',
    margin: '0 0 8px',
  },
  input: {
    width: '35%',
  },
  loadingIcon: {
    marginRight: theme.spacing(3),
  },
}));

function SubscriptionEditor({
  name, label, disabled = false
}) {
  const classes = useStyles();
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const jobName = useContext(JobContext);
  const { subscriptionType, loading } = useSubscription({ jobName });
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (subscriptionType && subscriptionType !== values.subscriptionType) {
      setFieldValue(name, subscriptionType);
      setTimeout(() => addNotification({
        message: 'The subscription type is changed. Please save changes.',
        options: {
          variant: 'info',
          autoHideDuration: 5000,
        },
      }), 500);
    }
  }, [subscriptionType, name, setFieldValue, values.subscriptionType, addNotification]);

  return (
    <FormControl variant="outlined" fullWidth className={classes.root}>
      <FormControlLabel
        label={label}
        labelPlacement="top"
        className={classes.label}
        control={(
          <MuiSelect
            value={subscriptionType || values.subscriptionType}
            disabled={disabled || isSubmitting}
            className={clsx(classes.input, 'subscriptionType')}
            endAdornment={loading && (
              <InputAdornment className={classes.loadingIcon} position="end">
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            )}
          >
            <MuiMenuItem value={subscriptionTypes.Essential} disabled>Essential</MuiMenuItem>
            <MuiMenuItem value={subscriptionTypes.Pro} disabled>Pro</MuiMenuItem>
            <MuiMenuItem value={subscriptionTypes.Advanced} disabled>Advanced</MuiMenuItem>
          </MuiSelect>
        )}
      />
    </FormControl>
  );
}

SubscriptionEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default memo(SubscriptionEditor);
