import React, {
  useState, memo, Fragment, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Menu, MenuItem, ListItemIcon, ListItemText, Button, Tooltip,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownTwoTone';

const useStyles = makeStyles(() => ({
  menuItem: {
    minWidth: '150px',
  },
  listMenuIcon: {
    minWidth: '30px',
  },
}));

function MoreActionsButton({ buttonText = 'More actions', items = [], className = '' }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const handleMenuOpen = (e) => {
    e.preventDefault();
    setAnchorEl(buttonRef.current);
  };

  const handleMenuClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const renderShowMoreMenu = (
    <Menu
      className="actionsMenu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      variant="selectedMenu"
    >
      {items.map(item => (
        <Tooltip
          title={(item.disabled && item.disabledReason) || ''}
          key={item.key}
        >
          <span>
            <MenuItem
              component="span"
              className={`${item.className} ${classes.menuItem}`}
              onClick={(e) => {
                handleMenuClose(e);
                if (item.onClick) { item.onClick(); }
              }}
              disabled={item.disabled}
              dense
            >
              <ListItemIcon className={classes.listMenuIcon}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </MenuItem>
          </span>
        </Tooltip>
      ))}
    </Menu>
  );

  return (
    <Fragment>
      <Button
        onClick={e => handleMenuOpen(e)}
        className={`${className} moreActions`}
        variant="text"
        ref={buttonRef}
        endIcon={<ArrowDropDownIcon />}
      >
        {buttonText}
      </Button>
      {renderShowMoreMenu}
    </Fragment>
  );
}

MoreActionsButton.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
  })),
};

export default memo(MoreActionsButton);
