import { useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useNotifications } from 'behaviour/notifications';

const stopBuildMutation = gql`
  mutation stopBuild($jobName:String!, $buildNumber:Int!) {
    job(jobName: $jobName) {
      build(buildNumber: $buildNumber) {
        stop {
          id,
          cancelling,
          number
        }
      }
    }
  }
`;

export const useStopBuildMutation = () => {
  const client = useApolloClient();
  const { addNotification } = useNotifications();
  return useCallback(({ jobName, buildNumber }) => client.mutate({
    mutation: stopBuildMutation,
    variables: {
      jobName,
      buildNumber,
    },
    optimisticResponse: {
      job: {
        __typename: 'JobMutation',
        build: {
          __typename: 'BuildMutation',
          stop: {
            __typename: 'Build',
            id: `${jobName}-${buildNumber}`,
            cancelling: true,
            jobName,
            number: buildNumber,
          },
        },
      },
    },
  }).catch(() => {
    addNotification({
      message: `The run ${jobName} #${buildNumber} cannot be cancelled because of server error.`,
      options: {
        variant: 'error',
      },
    });
  }), [client, addNotification]);
};
