const mergeArrayByField = keyName => (existing, incoming, { readField, mergeObjects }) => {
  const merged = existing ? existing.slice(0) : [];
  const entityNameToIndex = Object.create(null);
  if (existing) {
    existing.forEach((entity, index) => {
      entityNameToIndex[readField(keyName, entity)] = index;
    });
  }
  incoming.forEach((entity) => {
    const name = readField(keyName, entity);
    const index = entityNameToIndex[name];
    if (typeof index === 'number') {
      // Merge the new entity data with the existing entity data.
      merged[index] = mergeObjects(merged[index], entity);
    } else {
      // First time we've seen this entity in this array.
      entityNameToIndex[name] = merged.length;
      merged.push(entity);
    }
  });
  return merged;
};

export default mergeArrayByField;
