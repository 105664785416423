import { gql, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { loadingChanged } from 'behaviour/app/actions';
import { useDispatch } from 'react-redux';
import { useNotifications } from 'behaviour/notifications';

const copyJobMutation = gql`
  mutation copyJob($jobName:String!, $copyFromJobName:String!) {
    job(jobName: $copyFromJobName) {
      copy(newName: $jobName) {
        name
      }
    }
  }
`;

export default function useCopyJobMutation() {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();

  return useCallback((jobName, sourceJobName) => {
    dispatch(loadingChanged(true));
    return apolloClient.mutate({
      mutation: copyJobMutation,
      variables: {
        jobName,
        copyFromJobName: sourceJobName,
      },
      update: (proxy) => {
        proxy.modify({
          id: 'ROOT_QUERY',
          fields: {
            allJobs(_, { INVALIDATE }) {
              return INVALIDATE;
            },
          },
        });
      },
    })
      .catch(() => {
        addNotification({
          message: `Could not create the project ${jobName}`,
          options: {
            variant: 'error',
          },
        });
      })
      .finally(() => dispatch(loadingChanged(false)));
  }, [apolloClient, dispatch, addNotification]);
}
