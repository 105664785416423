import PropTypes from 'prop-types';
import React, {
  Fragment, memo, useMemo, useState,
} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Prompt } from 'react-router';
import { Button } from 'components/form';
import { set, isUndefined } from 'lodash';
import {
  Grid, AppBar, Tabs, Box, makeStyles, alpha,
} from '@material-ui/core';
import { TabPanel } from 'components/tabs';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { isValueExists } from 'util/isValueExists';
import FormTab from './FormTab';
import FormPanel from './FormPanel';
import FormAlert from './FormAlert';
import { getInputs, showError } from './utils';

// eslint-disable-next-line no-unused-vars
const example = {
  sections: [{
    title: 'Title',
    description: 'Description',
    inputs: [{
      name: 'name',
      path: 'name.path',
      label: 'label',
      helperText: '',
      input: 'TextInput', // 'SelectInput'
      disabled: values => values.shopType === 'private',
      defaultValue: '',
      validate: null, // Yup
      options: [{
        title: '',
        value: '',
      }],
    }],
    expansionPanels: [{
      name: '',
      title: '',
      sections: [],
    }],
    addonPanels: [{
      name: '',
      title: '',
      description: '',
      errorKey: '',
      checkbox: {
        name: '',
        defaultValue: '',
      },
      nameInput: {
        name: '',
        alias: '',
        label: '',
        defaultValue: '',
        validate: null, // Yup
      },
      useDefaultsSwitch: {
        name: '',
        label: '',
        defaultValue: '',
        validate: null, // Yup
        input: 'SwitchEditor',
      },
      sections: [],
    }],
  }],
  tabs: [{
    name: '',
    title: '',
    errorKey: '',
    disabled: false,
    sections: [],
  }],
};

function getInitialValues(schema) {
  const initialValues = {};
  const inputs = getInputs(schema);

  inputs.forEach(({ name, defaultValue }) => {
    set(initialValues, name, isUndefined(defaultValue) ? null : defaultValue);
  });

  return initialValues;
}

function mapValuesToAliases(schema, values) {
  const aliases = {};
  const inputs = getInputs(schema);

  inputs.forEach(({ name, alias }) => {
    if (isValueExists(values[name]) && alias !== undefined) set(aliases, alias, values[name]);
  });

  return aliases;
}

function createValidationSchema(schema) {
  const validationSchema = {};
  const inputs = getInputs(schema);

  inputs.forEach(({ name, label, disabled, validate }) => {
    if (!disabled && validate) {
      set(validationSchema, name, validate.label(label));
    }
  });

  return Yup.object().shape(validationSchema);
}

const useStyles = makeStyles(theme => ({
  stickyPanel: {
    padding: theme.spacing(1),
    position: 'sticky',
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    bottom: 0,
    zIndex: 2,
    display: 'inline-flex'
  },
  tabPanel: {
    padding: theme.spacing(1),
  },
  leftContent: {
    maxWidth: '100%',
  },
  mainContent: {
    width: '100%',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const defaultButtonProps = {
  title: 'Save changes',
  startIcon: <SaveIcon />,
};

function FormBuilder({
  schema,
  onSubmit,
  buttonProps = {},
  allowUnsavedChanges = false,
  additionalButtons,
  header,
  footer,
  ...options
}) {
  const classes = useStyles();
  const initialValues = useMemo(() => getInitialValues(schema), [schema]);
  const validationSchema = useMemo(() => createValidationSchema(schema), [schema]);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [tabValue, setTabValue] = useState(() => {
    if (schema.tabs) return schema.tabs[0].name;
    return '';
  });
  const handleTabChange = (_, newTab) => {
    setTabValue(newTab);
  };

  return (
    <Formik
      {...options}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={((values, formikHelpers) => {
        const aliases = mapValuesToAliases(schema, values);
        onSubmit({
          values,
          aliases,
        }, formikHelpers);
        window.scrollTo(0, 0);
      })}
    >
      {(formikBag) => {
        const {
          values, isSubmitting, dirty, errors, touched
        } = formikBag;

        return (
          <Fragment>
            {!allowUnsavedChanges && (
              <Prompt
                when={dirty}
                message="You have unsaved changes, are you sure you want to leave?"
              />
            )}
            <FormAlert dirty={dirty} />
            <Form>
              <Grid container spacing={1}>
                {schema.tabs && (
                  <>
                    <Grid item md={2} className={classes.leftContent}>
                      <AppBar position="sticky" color="transparent" style={{ top: '1%' }} elevation={0}>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          orientation={smallScreen ? 'horizontal' : 'vertical'}
                        >
                          {schema.tabs.map(tab => tab.visible !== false && (
                                <FormTab
                                  value={tab.name}
                                  key={tab.name}
                                  label={tab.title || tab.name}
                                  error={tab.errorKey && showError(errors, tab.errorKey, touched)}
                                  disabled={tab.disabled}
                                  disabledReason={tab.disabledReason}
                                />
                              ))}
                        </Tabs>
                      </AppBar>
                    </Grid>
                    <Grid item md={10} className={classes.mainContent}>
                      {schema.tabs.map(tab => (
                        <TabPanel
                          value={tabValue}
                          key={tab.name}
                          index={tab.name}
                          className={classes.tabPanel}
                        >
                          {tab.sections && tab.sections.map((section, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Fragment key={index}>
                                <FormPanel
                                  {...section}
                                  values={values}
                                  errors={errors}
                                  validationSchema={validationSchema}
                                />
                                {index !== tab.sections.length - 1
                                && <Divider className={classes.divider} />}
                              </Fragment>
                            ))}
                        </TabPanel>
                      ))}
                      <Box className={classes.stickyPanel}>
                        <Button
                          {...defaultButtonProps}
                          {...buttonProps}
                          type="submit"
                          disabled={isSubmitting}
                          endIcon={isSubmitting && <CircularProgress size={24} />}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
                {!schema.tabs && schema.sections && (
                  <>
                    {header}
                    {schema.sections.map((section, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Fragment key={index}>
                        <FormPanel
                          {...section}
                          values={values}
                          errors={errors}
                          validationSchema={validationSchema}
                        />
                        {index !== schema.sections.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                    <Box width={1} className={classes.stickyPanel}>
                      <Button
                        {...defaultButtonProps}
                        {...buttonProps}
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={isSubmitting && <CircularProgress size={24} />}
                      />
                      {additionalButtons}
                    </Box>
                    {footer}
                  </>
                )}
              </Grid>
            </Form>
          </Fragment>
        );
      }}
    </Formik>
  );
}

FormBuilder.propTypes = {
  schema: PropTypes.shape({
    sections: PropTypes.arrayOf(PropTypes.shape()),
    tabs: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      errorKey: PropTypes.string,
      disabled: PropTypes.bool,
    })),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  allowUnsavedChanges: PropTypes.bool,
  buttonProps: PropTypes.shape({
    title: PropTypes.string,
  }),
  additionalButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  footer: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default memo(FormBuilder);
