import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { Select } from 'components/form';

function ExpiryMonthEditor({ numberWithZero = true, ...props }) {
  return (
    <Field {...props} component={Select}>
      <MuiMenuItem value={numberWithZero ? '01' : '1'}>01 - January</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '02' : '2'}>02 - February</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '03' : '3'}>03 - March</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '04' : '4'}>04 - April</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '05' : '5'}>05 - May</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '06' : '6'}>06 - June</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '07' : '7'}>07 - July</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '08' : '8'}>08 - August</MuiMenuItem>
      <MuiMenuItem value={numberWithZero ? '09' : '9'}>09 - September</MuiMenuItem>
      <MuiMenuItem value="10">10 - October</MuiMenuItem>
      <MuiMenuItem value="11">11 - November</MuiMenuItem>
      <MuiMenuItem value="12">12 - December</MuiMenuItem>
    </Field>
  );
}

ExpiryMonthEditor.propTypes = {
  numberWithZero: PropTypes.bool,
};

export default memo(ExpiryMonthEditor);
