import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from 'util/apollo';
import { AuthenticationProvider } from 'components/authentication';
import { get } from 'lodash';
import configureStore from './configureStore';
import './index.css';
import { initApp, switchDarkMode } from './behaviour/app/actions';
import AppBase from './AppBase';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

const reHydrateStore = () => {
  // Get the application-wide store instance,
  // pre populating with state from the server where available.
  const initialState = window.initialReduxState || {};

  return {
    ...initialState,
    app: {
      ...initialState.app,
      darkMode: localStorage.getItem('dark-mode') === 'true',
    },
  };
};

const apolloClient = createApolloClient({ getStore: () => store });
const store = configureStore(history, reHydrateStore(), { apollo: apolloClient });

async function render() {
  ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ConnectedRouter history={history}>
          <AuthenticationProvider>
            <AppBase store={store} />
          </AuthenticationProvider>
        </ConnectedRouter>
      </ApolloProvider>
    </Provider>,
    document.getElementById('root'),
  );
}

document.addEventListener('visibilitychange', () => {
  const state = store.getState();
  const darkModeInStore = get(state, 'app.darkMode');
  const darkModeInLocalStorage = localStorage.getItem('dark-mode') === 'true';
  if (darkModeInLocalStorage !== darkModeInStore) {
    store.dispatch(switchDarkMode(darkModeInLocalStorage));
  }
});

if (module.hot) {
  module.hot.accept('./AppBase', () => render());
}

store.dispatch(initApp());

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
