import React, { memo, Fragment, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiDivider from '@material-ui/core/Divider';
import { getIn, useFormikContext } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import ExpansionPanelSummary from './ExpansionPanelSummary';
import ExpansionPanel from './ExpansionPanel';
import FormHeader from './FormHeader';
import TextEditor from '../editors/TextEditor';
import { AddonEditor } from '../editors';
import { showError } from './utils';
import FormContext from 'components/form/builder/FormBuilderContext';

const useStyles = makeStyles(theme => ({
  info: {
    marginBottom: theme.spacing(1),
  },
  grid: {
    margin: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

export const isRequired = (validationSchema, name) => {
  const field = getIn(validationSchema.fields, name);
  if (field !== undefined) {
    const { tests } = field;
    return tests ? !!tests.find(test => test.OPTIONS.name === 'required') : false;
  }
  return false;
};

function FormPanel({
  title = '',
  description = '',
  visible = true,
  inputs = [],
  expansionPanels = [],
  addonPanels = [],
  values = {},
  errors = {},
  validationSchema
}) {
  const classes = useStyles();
  const { isSubmitting, touched } = useFormikContext();
  const { expansionPanelsState } = useContext(FormContext);
  const expandAllSections = expansionPanelsState?.expandAllSections ?? false;
  const [expandedPanels, setExpandedPanels] = useState([]);
  const expanded = (index) => expandedPanels.includes(index);

  const refs = expansionPanels.reduce((acc, value) => {
    acc[value.name] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (isSubmitting) {
      const errorKeys = Object.keys(errors);
      const visibleElements = [];
      errorKeys.forEach((errorKey) => {
        const element = global.window.document.getElementsByName(errorKey)[0];
        if (element) {
          visibleElements.push(element);
        }
      });
      if (visibleElements.length > 0) {
        visibleElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errors, isSubmitting]);

  useEffect(() => {
    if (expandAllSections) {
      const allSections = [];
      expansionPanels.forEach((panel, index) => {
        if (!panel.disabled)
          allSections.push(index);
      })
      setExpandedPanels(allSections);
    }
  }, [expandAllSections])

  if (!visible) return null;

  const expansionPanelClicked = (index) => {
    if (expandedPanels.includes(index)) {
      setExpandedPanels(expandedPanels.filter((number) => number !== index));
    } else setExpandedPanels([...expandedPanels, index]);
  };

  return (
    <Grid item xs={12}>
      {title && (
        <FormHeader
          variant="h5"
          className={classes.title}
        >
          {title}
        </FormHeader>
      )}
      {description && (
        <FormHeader variant="subtitle1" className={classes.info}>
          {typeof description === 'function' ? description() : description}
        </FormHeader>
      )}
      <div>
        {inputs && inputs.map((inputProps) => {
          const {
            name,
            input: Input = TextEditor,
            defaultValue,
            disabled = false,
            // eslint-disable-next-line no-shadow
            visible = true,
            ...options
          } = inputProps;
          if (!(typeof visible === 'function' ? visible(values) : visible)) return null;
          return (
            <Input
              key={name}
              name={name}
              required={isRequired(validationSchema, name)}
              disabled={typeof disabled === 'function' ? disabled(values) : disabled}
              {...options}
            />
          );
        })}
        {expansionPanels && expansionPanels.map((expansionPanel, index) => expansionPanel.visible !== false
          && (
            <Tooltip
              key={expansionPanel.name}
              title={expansionPanel.disabled && expansionPanel.disabledReason ? expansionPanel.disabledReason : ''}
            >
              <ExpansionPanel expanded={expanded(index)}
                ref={refs[expansionPanel.name]}
                onChange={() => {
                  expansionPanelClicked(index);
                  setTimeout(() => {
                    (refs[expansionPanel.name].current && refs[expansionPanel.name].current.scrollIntoView({ behavior: 'smooth', block: 'start' }));
                  }, 280);
                }}
                disabled={expansionPanel.disabled}
                TransitionProps={{ unmountOnExit: true }}
                className="expansionPanel"
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <FormHeader variant="subtitle1" error={expansionPanel.errorKey && showError(errors, expansionPanel.errorKey, touched)}>
                    {expansionPanel.title || expansionPanel.name}
                  </FormHeader>
                </ExpansionPanelSummary>
                <Grid item xs={12} className={classes.grid}>
                  {expansionPanel.sections && expansionPanel.sections.map((section, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                      <FormPanel {...section} values={values} validationSchema={validationSchema} />
                      {index !== expansionPanel.sections.length - 1
                        && <MuiDivider className={classes.divider} />}
                    </Fragment>
                  ))}
                </Grid>
              </ExpansionPanel>
            </Tooltip>
          ))}
        {addonPanels?.length > 0 && (
          <>
            <MuiDivider className={classes.divider} />
            <FormHeader variant="h5" className={classes.title}>Add-ons</FormHeader>
            <FormHeader variant="subtitle1" className={classes.info}>Should be created and configured manually in Admin</FormHeader>
          </>
        )}
        {addonPanels?.map(addonPanel => addonPanel.visible !== false && (
          <AddonEditor
            key={addonPanel.name}
            addon={addonPanel}
            values={values}
            errors={errors}
            validationSchema={validationSchema}
          />
        ))}

      </div>
    </Grid>
  );
}

FormPanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  visible: PropTypes.bool,
  inputs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any.isRequired,
    input: PropTypes.elementType,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  })),
  expansionPanels: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    sections: PropTypes.arrayOf(PropTypes.shape()),
  })),
  addonPanels: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    sections: PropTypes.arrayOf(PropTypes.shape()),
  })),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validationSchema: PropTypes.shape().isRequired,
};

export default memo(FormPanel);
