import PropTypes from 'prop-types';

/**
* @typedef {Object} TableHeader
* @property {string} id
* @property {string} label
* @property {boolean} numeric
* @property {boolean} disablePadding
* @property {boolean} wide
* @property {boolean} contentFit
* @property {boolean|function} sortable
*/

/**
 * This function doesn't really "do anything" at runtime, it's just the identity
 * function
 * @param {Array<TableHeader>} headers
 */
export default function makeHeaders(headers) {
  return headers;
}

export const ColumnProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  numeric: PropTypes.bool,
  disablePadding: PropTypes.bool,
  wide: PropTypes.bool,
  contentFit: PropTypes.bool,
  sortable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
});
