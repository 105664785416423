import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import Tooltip from 'components/Tooltip';
import PlayCircleOutlineTwoToneIcon from '@material-ui/icons/PlayCircleOutlineTwoTone';
import useStartTestDataIndexingMutation from 'behaviour/jobs/useStartTestDataIndexingMutation';
import { useBridgeAvailability, useErpConnection, useWebshopAvailability } from 'behaviour/jobs';
import { get, merge } from 'lodash';
import { schemaGenerator as customersSchemaGenerator } from 'components/job/configuration/testData/customers/schema';
import { schemaGenerator as productsSchemaGenerator } from 'components/job/configuration/testData/products/schema';
import { getInputs } from 'components/form/builder/utils';
import { gql } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(1),
  }
}));

export const runIndexingButtonFragment = gql`
  fragment runIndexingButtonFragment on IndexingTask {
    isRunning
  }
`;

const getTestDataForIndexing = (jobName, parameters) => {
  const customersSchema = customersSchemaGenerator(jobName, parameters);
  const productsSchmema = productsSchemaGenerator(jobName, parameters);
  const schema = merge(customersSchema, productsSchmema);
  const inputs = getInputs(schema);

  let testDataToFetch = [];
  inputs.forEach(({ disabled, prefill }) => {
    if (!disabled && prefill && !prefill.relatedField)
      testDataToFetch.push(prefill.fetchKey);
  });
  const distinctValues = [...new Set(testDataToFetch)];
  return distinctValues;
}

function RunIndexingButton({
  jobName,
  configuration
}) {
  const parameters = get(configuration, 'parameters.Parameters.value') || {};
  const { sanaVersion } = parameters;
  const isIndexingTaskRunning = get(configuration, 'job.indexingTask.isRunning');
  const startIndexing = useStartTestDataIndexingMutation();
  const [disabled, setDisabled] = useState(false);

  const runTestDataIndexingTask = useCallback(() => {
    setDisabled(true);
    startIndexing(jobName, getTestDataForIndexing(jobName, parameters))
  }, [jobName, startIndexing, parameters]);

  useEffect(() => {
    setDisabled(isIndexingTaskRunning);
  }, [isIndexingTaskRunning]);

  const { isBridgeAvailable } = useBridgeAvailability({ jobName, sanaVersion });
  const { isErpConnectionAvailable } = useErpConnection({ jobName, sanaVersion });
  const { isWebshopAvailable } = useWebshopAvailability({ jobName, sanaVersion });
  const classes = useStyles();

  const getTooltipText = () => {
    if (isIndexingTaskRunning) return "Test data indexing is already running";
    if (isBridgeAvailable === false) return "Bridge is not available";
    if (isErpConnectionAvailable === false) return "ERP connection is not available";
    if (isWebshopAvailable === false) return "Webshop is unavailable";
    return '';
  };

  
const getButtonText = () => {
  if (!isIndexingTaskRunning && disabled) return "Starting test data indexing";
  if (isIndexingTaskRunning) return "Test data indexing is running";
  if (!isIndexingTaskRunning) return "Run test data indexing";
}

  return (
    <div className={classes.root}>
      <Tooltip title={getTooltipText()}>
        <span>
          <Button
            onClick={runTestDataIndexingTask}
            className='runTestDataIndexing'
            disabled={disabled || isBridgeAvailable === false || isErpConnectionAvailable === false || isWebshopAvailable === false}
            startIcon={<PlayCircleOutlineTwoToneIcon />}
            endIcon={isIndexingTaskRunning && <CircularProgress size={24} />}
          >
            {getButtonText()}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

RunIndexingButton.propTypes = {
  jobName: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    job: PropTypes.shape({
      indexingTask: PropTypes.shape({
        isRunning: PropTypes.bool.isRequired,
      })
    })
  })
};

export default memo(RunIndexingButton);
