import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavLink from './NavLink';

const useStyles = makeStyles(theme => ({
  nav: {
    margin: theme.spacing(0, 1),
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  link: {
    padding: theme.spacing(1, 2),
    display: 'block',
    borderRadius: '4px',
    color: theme.palette.type === 'dark' ? theme.palette.text.primary : undefined,
  },
}));

function AsideMenu({ links }) {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <ul className={classes.ul}>
        {links.map(link => (
          <li className={classes.li} key={link.to}>
            <NavLink to={link.to} className={classes.link}>{link.title}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

AsideMenu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
};

export default AsideMenu;
