import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from '@material-ui/core';

function BuildLogsLoading({ children, loading = true }) {
  if (loading) {
    return (
      <>
        <Typography variant="body2">Loading logs data. This will take a while.</Typography>
        {[200, 240, 160, 400, 320, 350].map(width => <Skeleton key={width} variant="text" width={width} height={20} />)}
      </>
    );
  }

  return children;
}

BuildLogsLoading.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};

export default BuildLogsLoading;
