import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import FormBuilder from 'components/form/builder/FormBuilder';
import PageLayout from 'components/PageLayout';
import { useCopyJobMutation, useLoadJobConfiguration, useUpdateJobConfigurationMutation } from 'behaviour/jobs';
import { useApolloClient } from '@apollo/client';
import blankCanvas from 'asserts/blank_canvas.svg';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import PageTitle from 'components/PageTitle';
import { get } from 'lodash';
import { normilizeConfiguration } from 'components/job/configuration/JobConfiguration';
import { schemaGenerator } from './schema';

const useStyles = makeStyles(theme => ({
  box: {
    padding: theme.spacing(3),
  },
  formContent: {
    width: '100%',
  },
  image: {
    width: '100%',
  },
}));

function CreateJobPage() {
  const classes = useStyles();
  const history = useHistory();
  const copyJob = useCopyJobMutation();
  const loadJobConfiguration = useLoadJobConfiguration();
  const updateConfiguration = useUpdateJobConfigurationMutation();
  const apolloClient = useApolloClient();

  const handleSubmit = ({ values, aliases }, { setSubmitting }) => {
    const { jobName, sanaVersion } = values;

    copyJob(jobName, `Automation Hub Template ${sanaVersion}`)
      .then((result) => {
        if (result?.data) {
          loadJobConfiguration(jobName).then((response) => {
            if (response?.data) {
              const configuration = normilizeConfiguration(get(response.data, 'job.configuration'));
              const parameters = get(configuration, 'parameters.Parameters.value') || {};

              const mergedValues = { ...parameters, ...values };
              const mergedConfig = {
                '@import': 'Integration.sanaunit.json',
                general: {
                  ...aliases.general,
                },
              };

              updateConfiguration({
                jobName,
                configuration: {
                  versionToken: configuration.versionToken,
                  parameters: [{
                    name: 'Sanaunit',
                    value: JSON.stringify(mergedConfig),
                    type: 'MULTILINE',
                  },
                  {
                    name: 'Parameters',
                    value: JSON.stringify(mergedValues),
                    type: 'MULTILINE',
                  }],
                },
              }).then(() => history.push(`/projects/${jobName}/settings`));
            }
          });
        }
      }).finally(() => setSubmitting(false));
  };

  return (
    <>
      <PageTitle title="Create a project" />
      <PageLayout>
        <div className={classes.box}>
          <Typography variant="h4">Create a new project</Typography>
          <Grid container>
            <Grid container item md>
              <Grid className={classes.formContent}>
                <FormBuilder
                  schema={schemaGenerator(apolloClient)}
                  onSubmit={handleSubmit}
                  buttonProps={{
                    title: 'Create',
                    startIcon: <AddCircleIcon />,
                  }}
                  allowUnsavedChanges
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="flex-end" md className={classes.imageBox}>
              <img src={blankCanvas} alt="Create a new project" className={classes.image} />
            </Grid>
          </Grid>
        </div>
      </PageLayout>
    </>
  );
}

export default withRouter(CreateJobPage);
