/* eslint-disable max-len */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { FormGroup, Chip } from '@material-ui/core';
import { orderBy, find } from 'lodash';
import ToogleChipGroup from 'components/ToogleChipGroup';
import { makeStyles } from '@material-ui/core/styles';
import { subscriptionTypes } from 'behaviour/jobs';
import { ShopType, TestCategory } from '../constants';

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: '1rem',
    color: theme.palette.type === 'dark' ? 'white' : undefined,

    '& .MuiChip-deleteIconOutlinedColorPrimary': {
      color: theme.palette.type === 'dark' ? 'white' : undefined,
    },
  },
}));

const excludedCategoriesForPrivateShop = [
  TestCategory.AnonymousCustomer,
  TestCategory.B2CCustomer,
  TestCategory.GuestCheckout
];

const excludedCategoriesForClosedShop = [
  ...excludedCategoriesForPrivateShop,
  TestCategory.B2BRegistration
];

const excludedCategoriesForEssentialSubscription = [
  TestCategory.SalesAgent,
  TestCategory.Prospect,
  TestCategory.ShopAccountRoles,
  TestCategory.ProductReturns,
  TestCategory.QuoteToOrderConversion,
  TestCategory.SalesAgreements,
  TestCategory.Locations
];

const excludedCategoriesForProSubscription = [TestCategory.ShopAccountRoles];

const categoriesDependencies = [
  {
    categoryId: TestCategory.AnonymousCustomer,
    dependantCategories: [TestCategory.GuestCheckout]
  },
  {
    categoryId: TestCategory.B2BCustomer,
    dependantCategories: [TestCategory.ShopAccountRoles, TestCategory.B2BRegistration]
  },
  {
    categoryId: TestCategory.SalesAgent,
    dependantCategories: [TestCategory.Prospect]
  },
  {
    categoryId: TestCategory.Quote,
    dependantCategories: [TestCategory.QuoteToOrderConversion]
  }
];

const categoryBelongsToCollection = (categoryId, categoriesArray) => !!find(categoriesArray, value => value === categoryId);

function CategoryListEditor({ name, options, disabled = true }) {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const { shopType, subscriptionType, categories } = values;
  const classes = useStyles();

  const isExcludedForShopType = categoryId => {
    switch (shopType) {
      case ShopType.Private: return categoryBelongsToCollection(categoryId, excludedCategoriesForPrivateShop);
      case ShopType.Closed: return categoryBelongsToCollection(categoryId, excludedCategoriesForClosedShop);
      default: return false;
    }
  }

  const isExcludedForSubscription = categoryId => {
    switch (subscriptionType) {
      case subscriptionTypes.Essential: return categoryBelongsToCollection(categoryId, excludedCategoriesForEssentialSubscription);
      case subscriptionTypes.Pro: return categoryBelongsToCollection(categoryId, excludedCategoriesForProSubscription);
      default: return false;
    }
  }

  useEffect(() => {
    if (categories.length > 0) {
      let excludedCategories = [];
      if (shopType === ShopType.Private) excludedCategories = [...excludedCategoriesForPrivateShop];
      if (shopType === ShopType.Closed) excludedCategories = [...excludedCategories, ...excludedCategoriesForClosedShop];
      if (subscriptionType === subscriptionTypes.Essential) excludedCategories = [...excludedCategories, ...excludedCategoriesForEssentialSubscription];
      if (subscriptionType === subscriptionTypes.Pro) excludedCategories = [...excludedCategories, ...excludedCategoriesForProSubscription];
      const enabledCategories = categories.filter(value => !excludedCategories.includes(value));
      setFieldValue(name, enabledCategories);
    }
  }, [shopType, subscriptionType, name]);

  const handleToggleChanged = (_, newSelected) => {
    const disabledCategories = options.filter(item => !newSelected.includes(item.value)).map(item => item.value);
    let disabledDependant = [...disabledCategories];
    disabledCategories.forEach((category) => {
      categoriesDependencies.forEach(categoriesPair => {
        if (category === categoriesPair.categoryId) {
          disabledDependant = [...disabledDependant, ...categoriesPair.dependantCategories];
        }
      });
    });
    const enabledCategories = options.filter(item => !disabledDependant.includes(item.value)).map(item => item.value);
    setFieldValue(name, enabledCategories);
  };

  return (
    <FormGroup row className="categories">
      <ToogleChipGroup
        name={name}
        value={values[name]}
        onChange={handleToggleChanged}
        color="primary"
      >
        {orderBy(options.filter(o => o.visible !== false), 'value').map(option => (
          <Chip
            key={option.value}
            className={classes.chip}
            label={option.title || option.value}
            clickable
            value={option.value}
            disabled={isExcludedForShopType(option.value) || isExcludedForSubscription(option.value) || disabled || isSubmitting}
          />
        ))}
      </ToogleChipGroup>
    </FormGroup>
  );
}

CategoryListEditor.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string.isRequired,
      visible: PropTypes.bool,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
};

export default memo(CategoryListEditor);
