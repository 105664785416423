import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  header: {
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  statusIcon: {
    marginRight: '8px',
    position: 'relative',
    display: 'inline-flex',
  },
  subTitle: {
    marginLeft: '12px',
    color: grey[600],
  },
  tags: {
    display: 'block',
    margin: theme.spacing(0, 0, 1, 0),
  },
  extra: {
    whiteSpace: 'nowrap',

    '& > *': {
      marginLeft: '12px',
      whiteSpace: 'unset',
    },

    '& > :first-child': {
      marginLeft: 0,
    },
  },
  content: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

function PageHeader({
  title, subTitle, tags, extra, statusIcon, children,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          {statusIcon && (<span className={classes.statusIcon}>{statusIcon}</span>)}
          <Typography variant="h4" className="title">{title}</Typography>
          {subTitle && (<Typography variant="subtitle1" className={classes.subTitle}>{subTitle}</Typography>)}
        </div>
        {extra && <div className={classes.extra}>{extra}</div>}
      </div>
      {tags && <div className={classes.tags}>{tags}</div>}
      <div className={classes.content}>{children}</div>
    </div>
  );
}

function Skeleton(props) {
  return (
    <PageHeader
      title={<MuiSkeleton variant="text" width="300px" />}
      extra={<MuiSkeleton variant="text" width="100px" height={50} />}
      {...props}
    >
      <MuiSkeleton variant="text" width="20%" height={21} />
      <MuiSkeleton variant="text" width="40%" height={21} />
      <MuiSkeleton variant="text" width="30%" height={21} />
    </PageHeader>
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tags: PropTypes.node,
  extra: PropTypes.node,
  statusIcon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

const Component = memo(PageHeader);
Component.Skeleton = Skeleton;

export default Component;
