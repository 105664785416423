/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import valid from 'card-validator';
import { makeSchema } from 'components/form/builder';
import { getValue } from 'util/getValue';
import { SelectEditor, SwitchEditor } from 'components/form/editors';
import { uniquePaymentMethodValidator } from 'behaviour/jobs/validators';
import { isValueExists } from 'util/isValueExists';
import { ExpiryYearEditor, ExpiryMonthEditor } from '../editors';
import {
  NumberWithSpacesRegex, NumberWithoutSpacesRegex, PaymentMethodValidationMessage, PaymentProvider,
} from './constants';

export const schemaGenerator = (jobName, parameters) => makeSchema({
  sections: [{
    title: 'Payment methods',
    description: 'If the field for name is empty or its value is `-`, then tests for this payment method will be skipped.',
    inputs: [{
      name: 'paymentMethod_default',
      alias: 'PaymentMethod.Default.Name',
      label: 'Internal on account payment method name',
      helperText: 'Internal on account payment method will be used during checkout process as default payment method',
      defaultValue: getValue(parameters.paymentMethod_default, 'Autotest internal'),
      validate: Yup.string(),
    }],
    addonPanels: [
      {
        visible: parameters.sanaVersion.includes('SCC'),
        name: 'SanaPay',
        title: 'Sana Pay',
        description: 'Only \'Credit Card\' payment is supported.',
        errorKey: 'paymentMethod_sanaPay',
        checkbox: {
          name: 'paymentMethod_sanaPay_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_sanaPay_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_sanaPay_name',
          alias: 'PaymentMethod.SanaPay.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_sanaPay_name, ''),
          validate: Yup.string().when('paymentMethod_sanaPay_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.SanaPay, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_sanaPay_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_sanaPay_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_sanaPay_creditCardNumber',
              alias: 'PaymentMethod.SanaPay.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_sanaPay_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_sanaPay_checkbox', 'paymentMethod_sanaPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_sanaPay_creditCardExpiryDate',
              alias: 'PaymentMethod.SanaPay.CreditCard.ExpiryDate',
              label: 'Expiry Date',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_sanaPay_creditCardExpiryDate, 'MMYY'),
              validate: Yup.string().when(['paymentMethod_sanaPay_checkbox', 'paymentMethod_sanaPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryDate', '${path} is invalid. Valid format: MMYY', value => valid.expirationDate(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_sanaPay_creditCardCVV',
              alias: 'PaymentMethod.SanaPay.CreditCard.CVV',
              label: 'CVV',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_sanaPay_creditCardCVV, '000'),
              validate: Yup.string().when(['paymentMethod_sanaPay_checkbox', 'paymentMethod_sanaPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
          {
            description: 'Credentials which are used for SanaPay 3DSecure page. Do NOT enter real password here, this password is used for the dummy transaction',
            inputs: [{
              name: 'paymentMethod_sanaPay_3dsUsername',
              alias: 'PaymentMethod.SanaPay.SanaPay3Ds.Username',
              label: '3DS Username',
              defaultValue: getValue(parameters.paymentMethod_sanaPay_3dsUsername, 'User name'),
              validate: Yup.string().when(['paymentMethod_sanaPay_checkbox', 'paymentMethod_sanaPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_sanaPay_3dsPassword',
              alias: 'PaymentMethod.SanaPay.SanaPay3Ds.Password',
              label: '3DS Password',
              defaultValue: getValue(parameters.paymentMethod_sanaPay_3dsPassword, 'Password'),
              validate: Yup.string().when(['paymentMethod_sanaPay_checkbox', 'paymentMethod_sanaPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
              InputProps: {
                type: 'password',
              },
            }],
          },
        ],
      },
      {
        visible: !parameters.sanaVersion.includes('SCC'),
        name: 'Adyen',
        description: 'Supported End point names - select, pay, skipDetails, details.',
        errorKey: 'paymentMethod_adyen',
        checkbox: {
          name: 'paymentMethod_adyen_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_adyen_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_adyen_name',
          alias: 'PaymentMethod.Adyen.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_adyen_name, ''),
          validate: Yup.string().when('paymentMethod_adyen_checkbox', {
            is: true,
            then: Yup.string().required(),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_adyen_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_adyen_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_adyen_methodName',
              alias: 'PaymentMethod.Adyen.MethodName',
              label: 'Method name',
              helperText: 'Method name is required if you use \'Select\' End Point. Method name is name of method on Adyen payment page',
              defaultValue: getValue(parameters.paymentMethod_adyen_methodName, 'Diners Club'),
              validate: Yup.string().when(['paymentMethod_adyen_checkbox', 'paymentMethod_adyen_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Diners Club', 'Discover', 'American Express', 'MasterCard', 'Visa', 'Maestro']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Diners Club' },
                { value: 'Discover' },
                { value: 'American Express' },
                { value: 'MasterCard' },
                { value: 'Visa' },
                { value: 'Maestro' },
              ],
            },
            {
              name: 'paymentMethod_adyen_creditCardNumber',
              alias: 'PaymentMethod.Adyen.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_adyen_creditCardNumber, 'XXXX XXXX XXXX XXXX'),
              validate: Yup.string()
                .when('paymentMethod_adyen_useDefaults', {
                  is: false,
                  then: Yup.string()
                    .test('validNumber', '${path} is invalid. Valid format: XXXX XXXX XXXX XXXX', value => valid.number(value).isValid)
                    .required()
                    .matches(NumberWithSpacesRegex, '${path} should be with whitespaces'),
                }),
            },
            {
              name: 'paymentMethod_adyen_creditCardHolderName',
              alias: 'PaymentMethod.Adyen.CreditCard.HolderName',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_adyen_creditCardHolderName, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_adyen_checkbox', 'paymentMethod_adyen_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_adyen_creditCardExpiryMonth',
              alias: 'PaymentMethod.Adyen.CreditCard.ExpiryMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_adyen_creditCardExpiryMonth, '01'),
              validate: Yup.string().when(['paymentMethod_adyen_checkbox', 'paymentMethod_adyen_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_adyen_creditCardExpiryYear',
              alias: 'PaymentMethod.Adyen.CreditCard.ExpiryYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_adyen_creditCardExpiryYear, '2025'),
              validate: Yup.string().when(['paymentMethod_adyen_checkbox', 'paymentMethod_adyen_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_adyen_creditCardCVV',
              alias: 'PaymentMethod.Adyen.CreditCard.CVV',
              label: 'CVV',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_adyen_creditCardCVV, '000'),
              validate: Yup.string().when(['paymentMethod_adyen_checkbox', 'paymentMethod_adyen_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        visible: parameters.sanaVersion !== 930 && !parameters.sanaVersion.includes('SCC'),
        name: 'AdyenCheckout',
        title: 'Adyen Checkout',
        description: 'Only \'Credit Card\' payment option is supported.',
        errorKey: 'paymentMethod_adyenCheckout',
        checkbox: {
          name: 'paymentMethod_adyenCheckout_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_adyenCheckout_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_adyenCheckout_name',
          alias: 'PaymentMethod.AdyenCheckout.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_adyenCheckout_name, ''),
          validate: Yup.string().when('paymentMethod_adyenCheckout_checkbox', {
            is: true,
            then: Yup.string().required(),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_adyenCheckout_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_adyenCheckout_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_adyenCheckout_creditCardNumber',
              alias: 'PaymentMethod.AdyenCheckout.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_adyenCheckout_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_adyenCheckout_checkbox', 'paymentMethod_adyenCheckout_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_adyenCheckout_creditCardExpiryDate',
              alias: 'PaymentMethod.AdyenCheckout.CreditCard.ExpiryDate',
              label: 'Expiry date',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_adyenCheckout_creditCardExpiryDate, 'MMYY'),
              validate: Yup.string().when(['paymentMethod_adyenCheckout_checkbox', 'paymentMethod_adyenCheckout_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryDate', '${path} is invalid', value => valid.expirationDate(value).isValid)
                  .required()
                  .matches(/^[0-9]{4}$/, '${path} should be in the following format: MMYY'),
              }),
            },
            {
              name: 'paymentMethod_adyenCheckout_creditCardCVV',
              alias: 'PaymentMethod.AdyenCheckout.CreditCard.CVV',
              label: 'CVV',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_adyenCheckout_creditCardCVV, '000'),
              validate: Yup.string().when(['paymentMethod_adyenCheckout_checkbox', 'paymentMethod_adyenCheckout_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_adyenCheckout_creditCardUsername',
              alias: 'PaymentMethod.AdyenCheckout.CreditCard.Username',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_adyenCheckout_creditCardUsername, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_adyenCheckout_checkbox', 'paymentMethod_adyenCheckout_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_adyenCheckout_creditCardPassword',
              alias: 'PaymentMethod.AdyenCheckout.CreditCard.Password',
              label: 'Password',
              defaultValue: getValue(parameters.paymentMethod_adyenCheckout_creditCardPassword, 'Password'),
              validate: Yup.string().when(['paymentMethod_adyenCheckout_checkbox', 'paymentMethod_adyenCheckout_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
              InputProps: {
                type: 'password',
              },
            }],
          },
        ],
      },
      {
        visible: parameters.sanaVersion !== 930,
        name: 'AmazonPay',
        title: 'Amazon Pay',
        errorKey: 'paymentMethod_amazonPay',
        checkbox: {
          name: 'paymentMethod_amazonPay_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_amazonPay_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_amazonPay_name',
          alias: 'PaymentMethod.AmazonPay.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_amazonPay_name, ''),
          validate: Yup.string().when('paymentMethod_amazonPay_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.AmazonPay, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_amazonPay_useDefaults',
          label: 'Use default buyer account data',
          defaultValue: getValue(parameters.paymentMethod_amazonPay_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Buyer account:',
            inputs: [{
              name: 'paymentMethod_amazonPay_buyerAccountEmail',
              alias: 'PaymentMethod.AmazonPay.BuyerAccount.Email',
              label: 'Email',
              defaultValue: getValue(parameters.paymentMethod_amazonPay_buyerAccountEmail, 'test@test.test'),
              validate: Yup.string().when(['paymentMethod_amazonPay_checkbox', 'paymentMethod_amazonPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().email(),
              }),
            },
            {
              name: 'paymentMethod_amazonPay_buyerAccountPassword',
              alias: 'PaymentMethod.AmazonPay.BuyerAccount.Password',
              label: 'Password',
              defaultValue: getValue(parameters.paymentMethod_amazonPay_buyerAccountPassword, 'Password'),
              validate: Yup.string().when(['paymentMethod_amazonPay_checkbox', 'paymentMethod_amazonPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
              InputProps: {
                type: 'password',
              },
            }],
          },
        ],
      },  
      {
        visible: parameters.sanaVersion !== 930,
        name: 'AmazonPayEU',
        title: 'Amazon Pay Europe',
        errorKey: 'paymentMethod_amazonPayEU',
        checkbox: {
          name: 'paymentMethod_amazonPayEU_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_amazonPayEU_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_amazonPayEU_name',
          alias: 'PaymentMethod.AmazonPayEU.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_amazonPayEU_name, ''),
          validate: Yup.string().when('paymentMethod_amazonPayEU_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.AmazonPayEU, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_amazonPayEU_useDefaults',
          label: 'Use default buyer account data',
          defaultValue: getValue(parameters.paymentMethod_amazonPayEU_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Buyer account:',
            inputs: [{
              name: 'paymentMethod_amazonPayEU_buyerAccountEmail',
              alias: 'PaymentMethod.AmazonPayEU.BuyerAccount.Email',
              label: 'Email',
              defaultValue: getValue(parameters.paymentMethod_amazonPayEU_buyerAccountEmail, 'test@test.test'),
              validate: Yup.string().when(['paymentMethod_amazonPayEU_checkbox', 'paymentMethod_amazonPayEU_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().email(),
              }),
            },
            {
              name: 'paymentMethod_amazonPayEU_buyerAccountPassword',
              alias: 'PaymentMethod.AmazonPayEU.BuyerAccount.Password',
              label: 'Password',
              defaultValue: getValue(parameters.paymentMethod_amazonPayEU_buyerAccountPassword, 'Password'),
              validate: Yup.string().when(['paymentMethod_amazonPayEU_checkbox', 'paymentMethod_amazonPayEU_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
              InputProps: {
                type: 'password',
              },
            }],
          },
        ],
      },    
      {
        name: 'AuthorizeNetAcceptHosted',
        title: 'Authorize.Net',
        description: 'BankAccount payment option is not supported.',
        errorKey: 'paymentMethod_authorizeNetAcceptHosted',
        checkbox: {
          name: 'paymentMethod_authorizeNetAcceptHosted_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_authorizeNetAcceptHosted_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_authorizeNetAcceptHosted_name',
          alias: 'PaymentMethod.AuthorizeNetAcceptHosted.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_authorizeNetAcceptHosted_name, ''),
          validate: Yup.string().when('paymentMethod_authorizeNetAcceptHosted_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.AuthorizeNetAcceptHosted, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_authorizeNetAcceptHosted_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_authorizeNetAcceptHosted_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_authorizeNetAcceptHosted_creditCardNumber',
              alias: 'PaymentMethod.AuthorizeNetAcceptHosted.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_authorizeNetAcceptHosted_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_authorizeNetAcceptHosted_checkbox', 'paymentMethod_authorizeNetAcceptHosted_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_authorizeNetAcceptHosted_creditCardExpirationDate',
              alias: 'PaymentMethod.AuthorizeNetAcceptHosted.CreditCard.ExpirationDate',
              label: 'Expiry date',
              defaultValue: getValue(parameters.paymentMethod_authorizeNetAcceptHosted_creditCardExpirationDate, 'MMYY'),
              validate: Yup.string().when(['paymentMethod_authorizeNetAcceptHosted_checkbox', 'paymentMethod_authorizeNetAcceptHosted_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryDate', '${path} is invalid', value => valid.expirationDate(value).isValid)
                  .required()
                  .matches(/^[0-9]{4}$/, '${path} should be in the following format: MMYY'),
              }),
            },
            {
              name: 'paymentMethod_authorizeNetAcceptHosted_creditCardCVV',
              alias: 'PaymentMethod.AuthorizeNetAcceptHosted.CreditCard.CVV',
              label: 'CVV',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_authorizeNetAcceptHosted_creditCardCVV, '000'),
              validate: Yup.string().when(['paymentMethod_authorizeNetAcceptHosted_checkbox', 'paymentMethod_authorizeNetAcceptHosted_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        name: 'Buckaroo',
        errorKey: 'paymentMethod_buckaroo',
        checkbox: {
          name: 'paymentMethod_buckaroo_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_buckaroo_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_buckaroo_name',
          alias: 'PaymentMethod.Buckaroo.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_buckaroo_name, ''),
          validate: Yup.string().when('paymentMethod_buckaroo_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.Buckaroo, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_buckaroo_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_buckaroo_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_buckaroo_creditCardType',
              alias: 'PaymentMethod.Buckaroo.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardType, 'Visa'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Visa', 'MasterCard', 'American Express', 'Bancontact', 'Maestro', 'VPAY', 'PayPal']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Visa' },
                { value: 'MasterCard' },
                { value: 'American Express' },
                { value: 'Bancontact' },
                { value: 'Maestro' },
                { value: 'VPAY' },
                { value: 'PayPal' },
              ],
            },
            {
              name: 'paymentMethod_buckaroo_creditCardCustomerCardName',
              alias: 'PaymentMethod.Buckaroo.CreditCard.CustomerCardName',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardCustomerCardName, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_buckaroo_creditCardNumber',
              alias: 'PaymentMethod.Buckaroo.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_buckaroo_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.Buckaroo.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_buckaroo_creditCardExpiryDateYear',
              alias: 'PaymentMethod.Buckaroo.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardExpiryDateYear, '2025'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_buckaroo_creditCardCVV',
              alias: 'PaymentMethod.Buckaroo.CreditCard.CVV',
              label: 'CVV',
              type: 'number',
              size: 'small',
              helperText: 'Used for Visa',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardCVV, '000'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_buckaroo_creditCardCID',
              alias: 'PaymentMethod.Buckaroo.CreditCard.CID',
              label: 'CID',
              type: 'number',
              size: 'small',
              helperText: 'Used for AmEx',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardCID, '0000'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCID', '${path} must contain 4 digits', value => valid.cvv(value, 4).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_buckaroo_creditCardBuyerEmail',
              alias: 'PaymentMethod.Buckaroo.CreditCard.BuyerEmail',
              label: 'Buyer email',
              helperText: 'Used for PayPal',
              defaultValue: getValue(parameters.paymentMethod_buckaroo_creditCardBuyerEmail, 'test@test.test'),
              validate: Yup.string().when(['paymentMethod_buckaroo_checkbox', 'paymentMethod_buckaroo_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().email(),
              }),
            }],
          },
        ],
      },
      {
        name: 'ChargeLogic',
        title: 'ChargeLogic',
        errorKey: 'paymentMethod_chargeLogic',
        checkbox: {
          name: 'paymentMethod_chargeLogic_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_chargeLogic_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_chargeLogic_name',
          alias: 'PaymentMethod.ChargeLogic.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_chargeLogic_name, ''),
          validate: Yup.string().when('paymentMethod_chargeLogic_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.ChargeLogic, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_chargeLogic_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_chargeLogic_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_chargeLogic_creditCardType',
              alias: 'PaymentMethod.ChargeLogic.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_chargeLogic_creditCardType, 'Visa'),
              validate: Yup.string().when(['paymentMethod_chargeLogic_checkbox', 'paymentMethod_chargeLogic_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_chargeLogic_creditCardNumber',
              alias: 'PaymentMethod.ChargeLogic.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_chargeLogic_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_chargeLogic_checkbox', 'paymentMethod_chargeLogic_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_chargeLogic_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.ChargeLogic.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_chargeLogic_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_chargeLogic_checkbox', 'paymentMethod_chargeLogic_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_chargeLogic_creditCardExpiryDateYear',
              alias: 'PaymentMethod.ChargeLogic.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_chargeLogic_creditCardExpiryDateYear, '25'),
              validate: Yup.string().when(['paymentMethod_chargeLogic_checkbox', 'paymentMethod_chargeLogic_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              lastTwoDigits: true,
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_chargeLogic_creditCardCVC',
              alias: 'PaymentMethod.ChargeLogic.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_chargeLogic_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_chargeLogic_checkbox', 'paymentMethod_chargeLogic_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVC', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_chargeLogic_creditCardHolderName',
              alias: 'PaymentMethod.ChargeLogic.CreditCard.CardholderName',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_chargeLogic_creditCardHolderName, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_chargeLogic_checkbox', 'paymentMethod_chargeLogic_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },      
      {
        name: 'DocData',
        title: 'Docdata',
        errorKey: 'paymentMethod_docData',
        checkbox: {
          name: 'paymentMethod_docData_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_docData_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_docData_name',
          alias: 'PaymentMethod.DocData.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_docData_name, ''),
          validate: Yup.string().when('paymentMethod_docData_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.DocData, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_docData_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_docData_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_docData_creditCardType',
              alias: 'PaymentMethod.DocData.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_docData_creditCardType, 'Visa'),
              validate: Yup.string().when(['paymentMethod_docData_checkbox', 'paymentMethod_docData_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Visa', 'MasterCard']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Visa' },
                { value: 'MasterCard' },
              ],
            },
            {
              name: 'paymentMethod_docData_creditCardNumber',
              alias: 'PaymentMethod.DocData.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_docData_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_docData_checkbox', 'paymentMethod_docData_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_docData_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.DocData.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_docData_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_docData_checkbox', 'paymentMethod_docData_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_docData_creditCardExpiryDateYear',
              alias: 'PaymentMethod.DocData.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_docData_creditCardExpiryDateYear, '25'),
              validate: Yup.string().when(['paymentMethod_docData_checkbox', 'paymentMethod_docData_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              lastTwoDigits: true,
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_docData_creditCardCVC',
              alias: 'PaymentMethod.DocData.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_docData_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_docData_checkbox', 'paymentMethod_docData_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_docData_creditCardHolderName',
              alias: 'PaymentMethod.DocData.CreditCard.CardholderName',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_docData_creditCardHolderName, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_docData_checkbox', 'paymentMethod_docData_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        visible: parameters.sanaVersion !== 930,
        name: 'EBizCharge',
        title: 'EBizCharge',
        description: 'Only \'Credit Card\' payment is supported.',
        errorKey: 'paymentMethod_eBizCharge',
        checkbox: {
          name: 'paymentMethod_eBizCharge_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_eBizCharge_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_eBizCharge_name',
          alias: 'PaymentMethod.EBizCharge.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_eBizCharge_name, ''),
          validate: Yup.string().when('paymentMethod_eBizCharge_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.EBizCharge, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_eBizCharge_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_eBizCharge_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_eBizCharge_creditCardNumber',
              alias: 'PaymentMethod.EBizCharge.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_eBizCharge_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_eBizCharge_checkbox', 'paymentMethod_eBizCharge_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_eBizCharge_creditCardExpirationDate',
              alias: 'PaymentMethod.EBizCharge.CreditCard.ExpirationDate',
              label: 'Expiry date',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_eBizCharge_creditCardExpirationDate, 'MMYY'),
              validate: Yup.string().when(['paymentMethod_eBizCharge_checkbox', 'paymentMethod_eBizCharge_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryDate', '${path} is invalid', value => valid.expirationDate(value).isValid)
                  .required()
                  .matches(/^[0-9]{4}$/, '${path} should be in the following format: MMYY'),
              }),
            },
            {
              name: 'paymentMethod_eBizCharge_creditCardCVV',
              alias: 'PaymentMethod.EBizCharge.CreditCard.CVV',
              label: 'CVV',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_eBizCharge_creditCardCVV, '000'),
              validate: Yup.string().when(['paymentMethod_eBizCharge_checkbox', 'paymentMethod_eBizCharge_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_eBizCharge_creditCardHolder',
              alias: 'PaymentMethod.EBizCharge.CreditCard.CardHolder',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_eBizCharge_creditCardHolder, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_eBizCharge_checkbox', 'paymentMethod_eBizCharge_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        visible: parameters.sanaVersion !== 930,
        name: 'Eway',
        description: 'Only \'Credit Card\' payment is supported.',
        errorKey: 'paymentMethod_eway',
        checkbox: {
          name: 'paymentMethod_eway_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_eway_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_eway_name',
          alias: 'PaymentMethod.Eway.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_eway_name, ''),
          validate: Yup.string().when('paymentMethod_eway_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.Eway, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_eway_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_eway_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_eway_creditCardNumber',
              alias: 'PaymentMethod.Eway.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_eway_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_eway_checkbox', 'paymentMethod_eway_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_eway_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.Eway.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_eway_creditCardExpiryDateMonth, 'Month'),
              validate: Yup.string().when(['paymentMethod_eway_checkbox', 'paymentMethod_eway_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .required()
                  .oneOf(
                    ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    '${path} should be in the following format: Month',
                  ),
              }),
            },
            {
              name: 'paymentMethod_eway_creditCardExpiryDateYear',
              alias: 'PaymentMethod.Eway.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_eway_creditCardExpiryDateYear, '2025'),
              validate: Yup.string().when(['paymentMethod_eway_checkbox', 'paymentMethod_eway_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryDate', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_eway_creditCardCVC',
              alias: 'PaymentMethod.Eway.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_eway_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_eway_checkbox', 'paymentMethod_eway_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVC', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_eway_creditCardHolder',
              alias: 'PaymentMethod.Eway.CreditCard.CardHolder',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_eway_creditCardHolder, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_eway_checkbox', 'paymentMethod_eway_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            }],
          },
          {
            description: 'Password which is used for Eway 3DSecure page. Do NOT enter real password here, this password is used for the dummy transaction',
            inputs: [{
              name: 'paymentMethod_eway_3dsPassword',
              alias: 'PaymentMethod.Eway.Eway3DS.Password',
              label: '3DS Password',
              defaultValue: getValue(parameters.paymentMethod_eway_3dsPassword, ''),
              validate: Yup.string(),
              InputProps: {
                type: 'password',
              },
            }],
          },
        ],
      },
      {
        name: 'Ingenico',
        errorKey: 'paymentMethod_ingenico',
        checkbox: {
          name: 'paymentMethod_ingenico_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_ingenico_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_ingenico_name',
          alias: 'PaymentMethod.Ingenico.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_ingenico_name, ''),
          validate: Yup.string().when('paymentMethod_ingenico_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.Ingenico, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_ingenico_useDefaults',
          label: 'Use default credit card and address data',
          defaultValue: getValue(parameters.paymentMethod_ingenico_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_ingenico_creditCardType',
              alias: 'PaymentMethod.Ingenico.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_ingenico_creditCardType, 'Visa'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Visa', 'MasterCard', 'American Express']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Visa' },
                { value: 'MasterCard' },
                { value: 'American Express' },
              ],
            },
            {
              name: 'paymentMethod_ingenico_creditCardNumber',
              alias: 'PaymentMethod.Ingenico.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_ingenico_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_ingenico_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.Ingenico.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_ingenico_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_ingenico_creditCardExpiryDateYear',
              alias: 'PaymentMethod.Ingenico.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_ingenico_creditCardExpiryDateYear, '2025'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_ingenico_creditCardCVC',
              alias: 'PaymentMethod.Ingenico.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_ingenico_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVC', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
          {
            description: 'Billing address:',
            inputs: [{
              name: 'paymentMethod_ingenico_paymentProvider_billingAddressFirstName',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.BillingAddress.FirstName',
              label: 'First name',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_billingAddressFirstName, 'First name'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_billingAddressLastName',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.BillingAddress.LastName',
              label: 'Last name',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_billingAddressLastName, 'Last name'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_billingAddressAddress',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.BillingAddress.Address',
              label: 'Address',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_billingAddressAddress, 'Address'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_billingAddressHouseNumber',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.BillingAddress.HouseNumber',
              label: 'House number',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_billingAddressHouseNumber, '1'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_billingAddressPhoneNumber',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.BillingAddress.PhoneNumber',
              label: 'Phone number',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_billingAddressPhoneNumber, '000000000'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_billingAddressChamberOfCommerceNumber',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.BillingAddress.ChamberOfCommerceNumber',
              label: 'Chamber of commerce number',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_billingAddressChamberOfCommerceNumber, '000'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_billingAddressSocialSecurityNumber',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.BillingAddress.SocialSecurityNumber',
              label: 'Social security number',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_billingAddressSocialSecurityNumber, '0000000'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            }],
          },
          {
            description: 'Shipping address:',
            inputs: [{
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressDeliveryCompany',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.DeliveryCompany',
              label: 'Delivery company',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressDeliveryCompany, 'Delivery company'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressTaxNumber',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.TaxNumber',
              label: 'Tax number',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressTaxNumber, '000'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressFirstName',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.FirstName',
              label: 'First name',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressFirstName, 'First name'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressLastName',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.LastName',
              label: 'Last name',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressLastName, 'Last name'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressMonthOfBirth',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.MonthOfBirth',
              label: 'Month of birth',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressMonthOfBirth, '01'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressDayOfBirth',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.DayOfBirth',
              label: 'Day of birth',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressDayOfBirth, '01'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressYearOfBirth',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.YearOfBirth',
              label: 'Year of birth',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressYearOfBirth, '1995'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressAddress',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.Address',
              label: 'Address',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressAddress, 'Address'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_ingenico_paymentProvider_shippingAddressHouseNumber',
              alias: 'PaymentMethod.Ingenico.PaymentProvider.ShippingAddress.HouseNumber',
              label: 'House number',
              defaultValue: getValue(parameters.paymentMethod_ingenico_paymentProvider_shippingAddressHouseNumber, '1'),
              validate: Yup.string().when(['paymentMethod_ingenico_checkbox', 'paymentMethod_ingenico_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            }],
          },
        ],
      },
      {
        name: 'KbcPaypage',
        title: 'KBC Paypage',
        errorKey: 'paymentMethod_kbcPaypage',
        checkbox: {
          name: 'paymentMethod_kbcPaypage_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_kbcPaypage_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_kbcPaypage_name',
          alias: 'PaymentMethod.KbcPaypage.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_kbcPaypage_name, ''),
          validate: Yup.string().when('paymentMethod_kbcPaypage_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.KbcPaypage, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_kbcPaypage_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_kbcPaypage_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_kbcPaypage_creditCardType',
              alias: 'PaymentMethod.KbcPaypage.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_kbcPaypage_creditCardType, 'Visa'),
              validate: Yup.string().when(['paymentMethod_kbcPaypage_checkbox', 'paymentMethod_kbcPaypage_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Visa', 'MasterCard', 'American Express', 'Diners Club']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Visa' },
                { value: 'MasterCard' },
                { value: 'American Express' },
                { value: 'Diners Club' },
              ],
            },
            {
              name: 'paymentMethod_kbcPaypage_creditCardCustomerCardName',
              alias: 'PaymentMethod.KbcPaypage.CreditCard.CustomerCardName',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_kbcPaypage_creditCardCustomerCardName, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_kbcPaypage_checkbox', 'paymentMethod_kbcPaypage_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),

            },
            {
              name: 'paymentMethod_kbcPaypage_creditCardNumber',
              alias: 'PaymentMethod.KbcPaypage.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_kbcPaypage_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_kbcPaypage_checkbox', 'paymentMethod_kbcPaypage_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_kbcPaypage_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.KbcPaypage.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_kbcPaypage_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_kbcPaypage_checkbox', 'paymentMethod_kbcPaypage_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_kbcPaypage_creditCardExpiryDateYear',
              alias: 'PaymentMethod.KbcPaypage.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_kbcPaypage_creditCardExpiryDateYear, '2025'),
              validate: Yup.string().when(['paymentMethod_kbcPaypage_checkbox', 'paymentMethod_kbcPaypage_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_kbcPaypage_creditCardCVV',
              alias: 'PaymentMethod.KbcPaypage.CreditCard.CVV',
              label: 'CVV',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_kbcPaypage_creditCardCVV, '000'),
              validate: Yup.string().when(['paymentMethod_kbcPaypage_checkbox', 'paymentMethod_kbcPaypage_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVV', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        name: 'MercadoPago',
        errorKey: 'paymentMethod_mercadoPago',
        checkbox: {
          name: 'paymentMethod_mercadoPago_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_mercadoPago_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_mercadoPago_name',
          alias: 'PaymentMethod.MercadoPago.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_mercadoPago_name, ''),
          validate: Yup.string().when('paymentMethod_mercadoPago_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.MercadoPago, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_mercadoPago_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_mercadoPago_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_mercadoPago_paymentMethod',
              alias: 'PaymentMethod.MercadoPago.PaymentMethod',
              label: 'Payment method',
              helperText: 'Only next payments are supported when hosted page is turned off in Admin',
              defaultValue: getValue(parameters.paymentMethod_mercadoPago_paymentMethod, 'credit_card'),
              validate: Yup.string().when(['paymentMethod_mercadoPago_checkbox', 'paymentMethod_mercadoPago_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['credit_card', 'debit_card', 'prepaid_card']),
              }),
              input: SelectEditor,
              options: [
                { value: 'credit_card' },
                { value: 'debit_card' },
                { value: 'prepaid_card' },
              ],
            },
            {
              name: 'paymentMethod_mercadoPago_creditCardNumber',
              alias: 'PaymentMethod.MercadoPago.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_mercadoPago_creditCardNumber, 'XXXX XXXX XXXX XXXX'),
              validate: Yup.string().when(['paymentMethod_mercadoPago_checkbox', 'paymentMethod_mercadoPago_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXX XXXX XXXX XXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithSpacesRegex, '${path} should be with whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_mercadoPago_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.MercadoPago.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_mercadoPago_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_mercadoPago_checkbox', 'paymentMethod_mercadoPago_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_mercadoPago_creditCardExpiryDateYear',
              alias: 'PaymentMethod.MercadoPago.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_mercadoPago_creditCardExpiryDateYear, '2025'),
              validate: Yup.string().when(['paymentMethod_mercadoPago_checkbox', 'paymentMethod_mercadoPago_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_mercadoPago_creditCardCVC',
              alias: 'PaymentMethod.MercadoPago.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_mercadoPago_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_mercadoPago_checkbox', 'paymentMethod_mercadoPago_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVC', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_mercadoPago_creditCardHolder',
              alias: 'PaymentMethod.MercadoPago.CreditCard.CardHolder',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_mercadoPago_creditCardHolder, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_mercadoPago_checkbox', 'paymentMethod_mercadoPago_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        visible: parameters.sanaVersion !== 930,
        name: 'Mollie',
        description: 'Only \'Credit Card\' payment is supported.',
        errorKey: 'paymentMethod_mollie',
        checkbox: {
          name: 'paymentMethod_mollie_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_mollie_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_mollie_name',
          alias: 'PaymentMethod.Mollie.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_mollie_name, ''),
          validate: Yup.string().when('paymentMethod_mollie_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.Mollie, jobName, parameters.sanaVersion)),
          }),
        },
      },
      {
        visible: parameters.sanaVersion !== 930,
        name: 'MultiSafepay',
        title: 'MultiSafepay',
        description: 'Only \'Credit Card\' payment is supported.',
        errorKey: 'paymentMethod_multiSafepay',
        checkbox: {
          name: 'paymentMethod_multiSafepay_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_multiSafepay_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_multiSafepay_name',
          alias: 'PaymentMethod.MultiSafepay.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_multiSafepay_name, ''),
          validate: Yup.string().when('paymentMethod_multiSafepay_checkbox', {
            is: true,
            then: Yup.string().required(),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_multiSafepay_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_multiSafepay_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_multiSafepay_creditCardType',
              alias: 'PaymentMethod.MultiSafepay.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_multiSafepay_creditCardType, 'Visa'),
              validate: Yup.string().when(['paymentMethod_multiSafepay_checkbox', 'paymentMethod_multiSafepay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Visa', 'MasterCard', 'Amex', 'Maestro', 'Bancontact']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Visa' },
                { value: 'MasterCard' },
                { value: 'Amex' },
                { value: 'Maestro' },
                { value: 'Bancontact' },
              ],
            },
            {
              name: 'paymentMethod_multiSafepay_creditCardNumber',
              alias: 'PaymentMethod.MultiSafepay.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_multiSafepay_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_multiSafepay_checkbox', 'paymentMethod_multiSafepay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_multiSafepay_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.MultiSafepay.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_multiSafepay_creditCardExpiryDateMonth, '01 - Jan'),
              validate: Yup.string().when(['paymentMethod_multiSafepay_checkbox', 'paymentMethod_multiSafepay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .required()
                  .matches(/^[0-9]{2}\s-\s[A-Za-z]{3}$/, '${path} should be in the following format: 01 - Jan'),
              }),
            },
            {
              name: 'paymentMethod_multiSafepay_creditCardExpiryDateYear',
              alias: 'PaymentMethod.MultiSafepay.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_multiSafepay_creditCardExpiryDateYear, '2025'),
              validate: Yup.string().when(['paymentMethod_multiSafepay_checkbox', 'paymentMethod_multiSafepay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_multiSafepay_creditCardCVC',
              alias: 'PaymentMethod.MultiSafepay.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_multiSafepay_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_multiSafepay_checkbox', 'paymentMethod_multiSafepay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVC', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_multiSafepay_creditCardHolder',
              alias: 'PaymentMethod.MultiSafepay.CreditCard.CardHolder',
              label: 'Cardholder name',
              defaultValue: getValue(parameters.paymentMethod_multiSafepay_creditCardHolder, 'Cardholder name'),
              validate: Yup.string().when(['paymentMethod_multiSafepay_checkbox', 'paymentMethod_multiSafepay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCardholderName', '${path} is invalid', value => valid.cardholderName(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        name: 'SagePay',
        title: 'Opayo (Formerly Sage Pay)',
        description: 'Only Credit card payment type is supported.',
        errorKey: 'paymentMethod_sagePay',
        checkbox: {
          name: 'paymentMethod_sagePay_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_sagePay_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_sagePay_name',
          alias: 'PaymentMethod.SagePay.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_sagePay_name, ''),
          validate: Yup.string().when('paymentMethod_sagePay_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.SagePay, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_sagePay_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_sagePay_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_sagePay_creditCardType',
              alias: 'PaymentMethod.SagePay.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_sagePay_creditCardType, 'Visa'),
              validate: Yup.string().when(['paymentMethod_sagePay_checkbox', 'paymentMethod_sagePay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Visa', 'Visa Debit / Delta', 'Visa Electron', 'MasterCard', 'Debit MasterCard']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Visa' },
                { value: 'Visa Debit / Delta' },
                { value: 'Visa Electron' },
                { value: 'MasterCard' },
                { value: 'Debit MasterCard' },
              ],
            },
            {
              name: 'paymentMethod_sagePay_creditCardNumber',
              alias: 'PaymentMethod.SagePay.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_sagePay_creditCardNumber, 'XXXX XXXX XXXX XXXX'),
              validate: Yup.string().when(['paymentMethod_sagePay_checkbox', 'paymentMethod_sagePay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXX XXXX XXXX XXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithSpacesRegex, '${path} be with whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_sagePay_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.SagePay.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_sagePay_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_sagePay_checkbox', 'paymentMethod_sagePay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_sagePay_creditCardExpiryDateYear',
              alias: 'PaymentMethod.SagePay.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_sagePay_creditCardExpiryDateYear, '25'),
              validate: Yup.string().when(['paymentMethod_sagePay_checkbox', 'paymentMethod_sagePay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              lastTwoDigits: true,
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_sagePay_creditCardCVC',
              alias: 'PaymentMethod.SagePay.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_sagePay_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_sagePay_checkbox', 'paymentMethod_sagePay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVC', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
      {
        name: 'PayFabric',
        errorKey: 'paymentMethod_payFabric',
        checkbox: {
          name: 'paymentMethod_payFabric_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_payFabric_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_payFabric_name',
          alias: 'PaymentMethod.PayFabric.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_payFabric_name, ''),
          validate: Yup.string().when('paymentMethod_payFabric_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.PayFabric, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_payFabric_useDefaults',
          label: 'Use default customer, credit card and ECheck data',
          defaultValue: getValue(parameters.paymentMethod_payFabric_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Customer data:',
            inputs: [{
              name: 'paymentMethod_payFabric_firstName',
              alias: 'PaymentMethod.PayFabric.FirstName',
              label: 'First name',
              defaultValue: getValue(parameters.paymentMethod_payFabric_firstName, 'First name'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            },
            {
              name: 'paymentMethod_payFabric_lastName',
              alias: 'PaymentMethod.PayFabric.LastName',
              label: 'Last name',
              defaultValue: getValue(parameters.paymentMethod_payFabric_lastName, 'Last name'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            }],
          },
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_payFabric_creditCardNumber',
              alias: 'PaymentMethod.PayFabric.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_payFabric_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_payFabric_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.PayFabric.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_payFabric_creditCardExpiryDateMonth, '01'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_payFabric_creditCardExpiryDateYear',
              alias: 'PaymentMethod.PayFabric.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_payFabric_creditCardExpiryDateYear, '25'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              lastTwoDigits: true,
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_payFabric_creditCardCVC',
              alias: 'PaymentMethod.PayFabric.CreditCard.CVC',
              label: 'CVC',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_payFabric_creditCardCVC, '000'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCVC', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
          {
            description: 'ECheck:',
            inputs: [{
              name: 'paymentMethod_payFabric_eCheckAccNumber',
              alias: 'PaymentMethod.PayFabric.ECheck.AccNumber',
              label: 'AccNumber',
              defaultValue: getValue(parameters.paymentMethod_payFabric_eCheckAccNumber, '0000000000'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validAccNumber', '${path} must contain 10 digits', value => valid.cvv(value, 10).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_payFabric_eCheckABA',
              alias: 'PaymentMethod.PayFabric.ECheck.ABA',
              label: 'ABA',
              defaultValue: getValue(parameters.paymentMethod_payFabric_eCheckABA, '000000000'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validABA', '${path} must contain 9 digits', value => valid.cvv(value, 9).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_payFabric_eCheckCheckNumber',
              alias: 'PaymentMethod.PayFabric.ECheck.CheckNumber',
              label: 'Check number',
              defaultValue: getValue(parameters.paymentMethod_payFabric_eCheckCheckNumber, '000'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validCheckNumber', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            },
            {
              name: 'paymentMethod_payFabric_eCheckDriverLicense',
              alias: 'PaymentMethod.PayFabric.ECheck.DriverLicense',
              label: 'Driver license',
              defaultValue: getValue(parameters.paymentMethod_payFabric_eCheckDriverLicense, 'Driver license'),
              validate: Yup.string().when(['paymentMethod_payFabric_checkbox', 'paymentMethod_payFabric_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
            }],
          },
        ],
      },
      {
        name: 'PayPalCheckout',
        title: 'PayPal Checkout',
        errorKey: 'paymentMethod_payPalCheckout',
        checkbox: {
          name: 'paymentMethod_payPalCheckout_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_payPalCheckout_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_payPalCheckout_name',
          alias: 'PaymentMethod.PayPalCheckout.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_payPalCheckout_name, ''),
          validate: Yup.string().when('paymentMethod_payPalCheckout_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.PayPalCheckout, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_payPalCheckout_useDefaults',
          label: 'Use default buyer account data',
          defaultValue: getValue(parameters.paymentMethod_payPalCheckout_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Buyer account:',
            inputs: [{
              name: 'paymentMethod_payPalCheckout_buyerAccountEmail',
              alias: 'PaymentMethod.PayPalCheckout.BuyerAccount.Email',
              label: 'Email',
              defaultValue: getValue(parameters.paymentMethod_payPalCheckout_buyerAccountEmail, 'test@test.test'),
              validate: Yup.string().when(['paymentMethod_payPalCheckout_checkbox', 'paymentMethod_payPalCheckout_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().email(),
              }),
            },
            {
              name: 'paymentMethod_payPalCheckout_buyerAccountPassword',
              alias: 'PaymentMethod.PayPalCheckout.BuyerAccount.Password',
              label: 'Password',
              defaultValue: getValue(parameters.paymentMethod_payPalCheckout_buyerAccountPassword, 'Password'),
              validate: Yup.string().when(['paymentMethod_payPalCheckout_checkbox', 'paymentMethod_payPalCheckout_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required(),
              }),
              InputProps: {
                type: 'password',
              },
            }],
          },
        ],
      },
      {
        name: 'WorldPay',
        errorKey: 'paymentMethod_worldPay',
        checkbox: {
          name: 'paymentMethod_worldPay_checkbox',
          defaultValue: isValueExists(parameters.paymentMethod_worldPay_name),
          validate: Yup.bool(),
        },
        nameInput: {
          name: 'paymentMethod_worldPay_name',
          alias: 'PaymentMethod.WorldPay.Name',
          label: 'Name',
          defaultValue: getValue(parameters.paymentMethod_worldPay_name, ''),
          validate: Yup.string().when('paymentMethod_worldPay_checkbox', {
            is: true,
            then: Yup.string().required()
              .test('uniquePaymentMethod', PaymentMethodValidationMessage, async value => uniquePaymentMethodValidator(value, PaymentProvider.WorldPay, jobName, parameters.sanaVersion)),
          }),
        },
        useDefaults: {
          name: 'paymentMethod_worldPay_useDefaults',
          label: 'Use default credit card data',
          defaultValue: getValue(parameters.paymentMethod_worldPay_useDefaults, true),
          validate: Yup.bool(),
          input: SwitchEditor,
        },
        sections: [
          {
            description: 'Credit card:',
            inputs: [{
              name: 'paymentMethod_worldPay_creditCardType',
              alias: 'PaymentMethod.WorldPay.CreditCard.CardType',
              label: 'Card type',
              defaultValue: getValue(parameters.paymentMethod_worldPay_creditCardType, 'Diners'),
              validate: Yup.string().when(['paymentMethod_worldPay_checkbox', 'paymentMethod_worldPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string().required().oneOf(['Diners', 'Visa', 'Mastercard', 'Amex', 'JBC']),
              }),
              input: SelectEditor,
              options: [
                { value: 'Diners' },
                { value: 'Visa' },
                { value: 'Mastercard' },
                { value: 'Amex' },
                { value: 'JBC' },
              ],
            },
            {
              name: 'paymentMethod_worldPay_creditCardNumber',
              alias: 'PaymentMethod.WorldPay.CreditCard.Number',
              label: 'Number',
              defaultValue: getValue(parameters.paymentMethod_worldPay_creditCardNumber, 'XXXXXXXXXXXXXXXX'),
              validate: Yup.string().when(['paymentMethod_worldPay_checkbox', 'paymentMethod_worldPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validNumber', '${path} is invalid. Valid format: XXXXXXXXXXXXXXXX', value => valid.number(value).isValid)
                  .required()
                  .matches(NumberWithoutSpacesRegex, '${path} should be without whitespaces'),
              }),
            },
            {
              name: 'paymentMethod_worldPay_creditCardExpiryDateMonth',
              alias: 'PaymentMethod.WorldPay.CreditCard.ExpiryDateMonth',
              label: 'Expiry month',
              defaultValue: getValue(parameters.paymentMethod_worldPay_creditCardExpiryDateMonth, '1'),
              validate: Yup.string().when(['paymentMethod_worldPay_checkbox', 'paymentMethod_worldPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryMonth', '${path} is invalid', value => valid.expirationMonth(value).isValid)
                  .required(),
              }),
              numberWithZero: false,
              input: ExpiryMonthEditor,
            },
            {
              name: 'paymentMethod_worldPay_creditCardExpiryDateYear',
              alias: 'PaymentMethod.WorldPay.CreditCard.ExpiryDateYear',
              label: 'Expiry year',
              defaultValue: getValue(parameters.paymentMethod_worldPay_creditCardExpiryDateYear, '2025'),
              validate: Yup.string().when(['paymentMethod_worldPay_checkbox', 'paymentMethod_worldPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validExpiryYear', '${path} is invalid', value => valid.expirationYear(value).isValid)
                  .required(),
              }),
              input: ExpiryYearEditor,
            },
            {
              name: 'paymentMethod_worldPay_creditCardSecurityCode',
              alias: 'PaymentMethod.WorldPay.CreditCard.SecurityCode',
              label: 'Security code',
              type: 'number',
              size: 'small',
              defaultValue: getValue(parameters.paymentMethod_worldPay_creditCardSecurityCode, '000'),
              validate: Yup.string().when(['paymentMethod_worldPay_checkbox', 'paymentMethod_worldPay_useDefaults'], {
                is: (isAddonChecked, isUseDefaults) => isAddonChecked && !isUseDefaults,
                then: Yup.string()
                  .test('validSecurityCode', '${path} must contain 3 digits', value => valid.cvv(value).isValid)
                  .required(),
              }),
            }],
          },
        ],
      },
    ],
  }],
});
