import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { Select } from 'components/form';

function SelectEditor({ options, ...props }) {
  return (
    <Field
      {...props}
      component={Select}
    >
      {options.map(({ title, value }) => (
        <MuiMenuItem value={value} key={value}>
          {title || value}
        </MuiMenuItem>
      ))}
    </Field>
  );
}

SelectEditor.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

export default memo(SelectEditor);
