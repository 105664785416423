import { useAuthentication } from 'behaviour/authentication';
import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient, from } from '@apollo/client';
import {
  authLink, httpLink, createAuthError, temporaryNotAvailableError,
} from 'util/apollo';
import { createGetAuthToken } from 'util/auth';
import { useNotifications } from 'behaviour/notifications';
import { useHistory } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';

function withAuthentication(Component) {
  function Wrapper(props) {
    const { instance, account, loading } = useAuthentication();
    const apollo = useApolloClient();
    const [initialized, setInitialized] = useState(false);
    const { addNotification } = useNotifications();
    const history = useHistory();

    const redirectTo = useCallback((path) => {
      history.push(path);
    }, [history]);

    useEffect(() => {
      if (instance) {
        const getToken = createGetAuthToken(account, instance);
        const handleError = ({ anyMutation, loginPath }) => {
          addNotification({
            key: 'sessionExpired',
            message: 'Your session has expired.',
            options: {
              variant: 'info',
              autoHideDuration: 5000,
            },
          });
          if (!anyMutation) {
            redirectTo(loginPath);
          }
        };

        const authError = createAuthError(getToken, handleError);
        const link = from([
          temporaryNotAvailableError,
          authError,
          authLink,
          httpLink,
        ]);

        apollo.setLink(link);
      }
      setInitialized(true);
    }, [account, instance, apollo, redirectTo, addNotification]);

    if (!initialized || loading !== InteractionStatus.None) { return null; }

    return <Component {...props} />;
  }

  Wrapper.displayName = `withAuthentication(${Component.displayName || Component.name})`;

  return Wrapper;
}

export default withAuthentication;
