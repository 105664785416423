import React, {
  useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FileCopyIcon from '@material-ui/icons/FileCopyTwoTone';
import { Button, Tooltip } from '@material-ui/core';
import { CopyJobDialog } from '../dialogs';

function DuplicateActionButton({
  name, displayName = name, className = '', disabled = false, disabledReason = '',
}) {
  const [copyJobDialogOpen, setCopyJobDialogOpen] = useState(false);

  const handleCopyButtonClick = (e) => {
    e.preventDefault();
    setCopyJobDialogOpen(true);
  };

  return (
    <>
      <CopyJobDialog
        name={name}
        displayName={displayName}
        open={copyJobDialogOpen}
        setOpen={setCopyJobDialogOpen}
      />
      <Tooltip title={(disabled && disabledReason) || ''}>
        <span>
          <Button
            variant="text"
            className={clsx(className, 'copy')}
            onClick={e => handleCopyButtonClick(e)}
            startIcon={<FileCopyIcon fontSize="small" />}
            disabled={disabled}
          >
            Duplicate
          </Button>
        </span>
      </Tooltip>
    </>
  );
}

DuplicateActionButton.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
};

export default memo(DuplicateActionButton);
