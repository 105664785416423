import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route } from 'react-router';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Link from './Link';

const breadcrumbNameMap = {
  projects: 'Projects',
  createProject: 'Create project',
  testData: 'Test data',
  configuration: 'Configuration',
  buildTrigger: 'Scheduling',
  'knowledge-base': 'Knowledge base',
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumbs: {
    color: 'inherit',
  },
  breadcrumbItem: {
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 'inherit',
    padding: '2px 8px',
    color: 'inherit',
  },
}));

function pathTitle(current) {
  const title = breadcrumbNameMap[current];

  if (title) { return title; }

  return current;
}

function Breadcrumbs({ homeTitle = 'Home', ...props }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      <Route>
        {({ location }) => {
          const pathnames = location.pathname.split('/').filter(x => x);

          return (
            <MuiBreadcrumbs
              className={classes.breadcrumbs}
              aria-label="Breadcrumb"
            >
              <MuiButton className={classes.breadcrumbItem} component={Link} to="/" variant="text">
                {homeTitle}
              </MuiButton>
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return !last && (
                  <MuiButton className={classes.breadcrumbItem} component={Link} variant="text" to={to} key={to}>
                    {pathTitle(value)}
                  </MuiButton>
                );
              })}
            </MuiBreadcrumbs>
          );
        }}
      </Route>
    </Box>
  );
}

Breadcrumbs.propTypes = {
  homeTitle: PropTypes.string,
};

export default React.memo(Breadcrumbs);
