import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'components/PageHeader';
import { gql } from '@apollo/client';
import FavoriteButton from '../FavoriteButton';
import JobBadges, { jobBadgesFragment } from './JobBadges';
import JobActions, { jobActionsFragment } from './JobActions';
import JobSummary, { jobSummaryFragment } from './JobSummary';
import { BridgeNotInstalledAlert, WebshopUnavailableAlert } from 'components/alerts';
import { useBridgeAvailability, useWebshopAvailability } from 'behaviour/jobs';

export const jobHeaderFragment = gql`
  fragment jobHeaderFragment on Job {
    name
    displayName
    ...jobActionsFragment
    ...jobBadgesFragment
    ...jobSummaryFragment
  }
  ${jobActionsFragment}
  ${jobBadgesFragment}
  ${jobSummaryFragment}
`;

function JobHeader({ job }) {
  const { isBridgeAvailable } = useBridgeAvailability({
    jobName: job.name,
    sanaVersion: job.packageVersion,
  });

  const { isWebshopAvailable } = useWebshopAvailability({
    jobName: job.name,
    sanaVersion: job.packageVersion
  });

  if (!job) { return null; }

  return (
    <PageHeader
      title={job.displayName || job.name}
      subTitle={(
        <FavoriteButton
          jobName={job.displayName}
        />
      )}
      tags={(
        <Fragment>
          <JobBadges {...job} />
          <JobActions key="JobActions" {...job} />
        </Fragment>
      )}
    >
      <JobSummary {...job} />
      {isBridgeAvailable === false && <BridgeNotInstalledAlert />}
      {isWebshopAvailable === false && <WebshopUnavailableAlert />}
    </PageHeader>
  );
}

function Skeleton() {
  return (
    <PageHeader.Skeleton />
  );
}

JobHeader.propTypes = {
  job: PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string.isRequired,
    buildTrigger: PropTypes.string,
    lastBuild: PropTypes.shape({
      completed: PropTypes.string,
    }),
    packageVersion: PropTypes.string,
  }).isRequired,
};

const Component = memo(JobHeader);
Component.Skeleton = Skeleton;

export default Component;
