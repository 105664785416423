import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext, Field } from 'formik';
import {
  Chip, TextField, makeStyles,
} from '@material-ui/core';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '16px 0 8px',
    width: '100%',
  },
  label: {
    alignItems: 'flex-start',
    '& .MuiFormHelperText-root': {
      margin: '0',
      marginTop: '8px',
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
  chip: {
    margin: theme.spacing(0.2),
  },
  textField: {
    width: '70%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function TagsEditor({
  name, label = '', disabled = false, ...props
}) {
  const classes = useStyles();

  const { values, isSubmitting } = useFormikContext();

  const [newTag, setNewTag] = useState('');
  const handleChange = (e) => {
    setNewTag(e.target.value);
  };

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <>
          <MuiFormControlLabel
            label={label}
            labelPlacement="top"
            className={clsx(
              classes.root,
              classes.label,
            )}
            control={(
              <TextField
                {...props}
                className={classes.textField}
                value={newTag}
                name="newTag"
                disabled={disabled || isSubmitting}
                onChange={handleChange}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    arrayHelpers.push(newTag);
                    setNewTag('');
                  }
                }}
              />
            )}
          />
          <div className="tags">
            {values[name] && values[name].map((tag, index) => (
              <Field
                key={tag}
                className={`${classes.chip} tag`}
                name={`name.${index}`}
                label={tag}
                onDelete={() => arrayHelpers.remove(index)}
                component={Chip}
                disabled={disabled || isSubmitting}
                color="primary"
              />
            ))}
          </div>
        </>
      )}
    />
  );
}

TagsEditor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(TagsEditor);
