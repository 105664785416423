import React from 'react';
import { Toolbar, Button } from '@material-ui/core';
import Link from 'components/Link';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';

function JobListToolbar() {
  return (
    <Toolbar variant="dense">
      <Link to="/createProject" underline="none">
        <Button startIcon={<AddCircleOutlineTwoToneIcon />} className="createJob">
          Create project
        </Button>
      </Link>
    </Toolbar>
  );
}

export default JobListToolbar;
