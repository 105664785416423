import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paper, makeStyles } from '@material-ui/core';
import DataGrid, { makeColumns, useFetchMore } from 'components/dataGrid';
import IndexingRun, { indexingRunFragment } from './IndexingRun';
import { gql } from '@apollo/client';
import makerLaunch from 'asserts/maker_launch.svg';
import IllustratedMessage from 'components/IllustratedMessage';

export const indexingRunListFragment = gql`
  fragment indexingRunListFragment on IndexingRun {
    id,
    ...indexingRunFragment
  },
  ${indexingRunFragment}
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  }
}));

const columns = makeColumns([
  { id: 'status', label: 'Status', },
  { id: 'number', label: 'Run' },
  { id: 'duration', label: 'Duration' },
  { id: 'completed', label: 'Completed' },
  { id: '' }
]);

function IndexingRunList({ runs = [], loading = false }) {
  const classes = useStyles();
  const { pagedItems, totalCount, fetchMore } = useFetchMore(runs);

  return (
    <Paper className={classes.root}>
      {!loading && runs.length === 0
        ? (
          <IllustratedMessage
            imageSrc={makerLaunch}
            title="There are no test data indexing runs yet"
          />
        )
        : (
          <DataGrid
            className="indexingRunsTable"
            columns={columns}
            fetchMore={fetchMore}
            totalCount={totalCount}
            items={pagedItems}
            loading={loading}
            renderRow={({ data: run }) => (
              <IndexingRun
                key={run.id}
                run={run}
              />
            )}
            defaultSortProperty="number"
            defaultSortAscending={false}
            pagination={false}
          />
        )
      }
    </Paper>
  );
}

IndexingRunList.propTypes = {
  runs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  loading: PropTypes.bool.isRequired
};

export default memo(IndexingRunList);
