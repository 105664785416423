import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import {
  BuildTriggerForm, GeneralForm, TestDataConfigurationForm,
} from 'components/job/configuration';
import { makeStyles } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useUpdateJobConfigurationMutation } from 'behaviour/jobs';
import { runIndexingButtonFragment } from 'components/testDataIndexing/RunIndexingButton';
import { indexingRunListFragment } from 'components/testDataIndexing/IndexingRunList';

const useStyles = makeStyles(theme => ({
  heading: {
    paddingLeft: theme.spacing(1),
  },
  box: {
    padding: theme.spacing(1, 2),
  },
}));

export const jobConfigurationFormFragment = gql`
  fragment jobConfigurationFormFragment on JobConfiguration {
    versionToken
    parameters {
      name,
      type,
      value
    },
    isParameterized,
    nonBuildableReason,
    buildPeriodically,
    timerTrigger,
    job {
      name,
      indexingTask {
        ...runIndexingButtonFragment
        runs {
          id
          ...indexingRunListFragment          
        }
      }
    }
  }
  ${runIndexingButtonFragment}
  ${indexingRunListFragment}
`;

function JobConfigurationForm({
  jobName,
  configuration,
  ...props
}) {
  const classes = useStyles();
  const updateConfiguration = useUpdateJobConfigurationMutation();
  const handleUpdateConfiguration = useCallback(config => updateConfiguration({
    jobName,
    configuration: {
      ...config,
    },
  }), [jobName, updateConfiguration]);

  return (
    <div className={classes.box}>
      <Switch>
        <Route
          path="/projects/:jobName/settings"
          render={() => (
            <GeneralForm
              jobName={jobName}
              configuration={configuration}
              updateConfiguration={handleUpdateConfiguration}
              {...props}
            />
          )}
        />
        <Route
          path="/projects/:jobName/scheduling"
          exact
          render={() => (
            <BuildTriggerForm
              jobName={jobName}
              configuration={configuration}
              updateConfiguration={handleUpdateConfiguration}
              {...props}
            />
          )}
        />
        <TestDataConfigurationForm
          jobName={jobName}
          configuration={configuration}
          updateConfiguration={handleUpdateConfiguration}
          {...props}
        />
      </Switch>
    </div>
  );
}


JobConfigurationForm.propTypes = {
  jobName: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    versionToken: PropTypes.string.isRequired,
  }),
};

export default JobConfigurationForm;
