import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormSkeleton } from 'components/form';

const useStyles = makeStyles(theme => ({
  heading: {
    paddingLeft: theme.spacing(1),
  },
  box: {
    padding: theme.spacing(2),
  },
}));

function JobConfigurationSkeleton() {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <FormSkeleton inputs={3} />
    </div>
  );
}

export default memo(JobConfigurationSkeleton);
