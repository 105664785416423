export default {
  readJson: (key) => {
    const value = localStorage.getItem(key);
    if (!value) { return value; }

    return JSON.parse(value);
  },

  writeJson: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
};
