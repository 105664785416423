import React, {
  useState, useRef, useLayoutEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useEventListener from 'util/react/hooks/useEventListener';

function Resizer({ children, className = '' }) {
  const boxRef = useRef();
  const [width, setWidth] = useState(1170);
  const resizeHandler = useCallback(() => {
    if (boxRef.current) {
      setWidth(boxRef.current.offsetWidth - 40);
    }
  }, [boxRef]);

  useEventListener('resize', resizeHandler);
  useLayoutEffect(resizeHandler, [boxRef]);
  return (
    <Box className={className} ref={boxRef}>
      {children({ width })}
    </Box>
  );
}

Resizer.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Resizer;
