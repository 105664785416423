import type { Notification } from './types';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR' as const;
export const enqueueSnackbar = ({ key, message, options }: Notification) => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: key || new Date().getTime() + Math.random(),
    message,
    options,
  },
});

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR' as const;
export const closeSnackbar = (key: string | number) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR' as const;
export const removeSnackbar = (key: string | number) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export type NotificationsAction = ReturnType<
  | typeof enqueueSnackbar
  | typeof closeSnackbar
  | typeof removeSnackbar
>;
