import React from 'react';
import Warning from 'components/Warning';

function AutocompleteAlert() {
  return (
    <Warning>
      Autocomplete functionality is available for
      {' '}
      <strong>Sana Commerce Cloud</strong>
      {' '}
      and higher
      .
    </Warning>
  );
}

export default AutocompleteAlert;
