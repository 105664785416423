import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Box, Grid, makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  imageBox: {
    margin: '0 auto',
  },
  imageBoxPaddings: {
    padding: theme.spacing(3, 5),
  },
  image: {
    maxWidth: '100%',
  },
  rightContainer: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  },
  rightContent: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

function IllustratedMessage({
  title, subTitle, imageSrc, imageTitle, actionBox, disablePaddings = false, className = '', ...props
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={clsx(classes.root, className, { [classes.imageBoxPaddings]: !disablePaddings })}
      {...props}
    >
      <Grid container item justifyContent="flex-end" md className={classes.imageBox}>
        <img src={imageSrc} alt={imageTitle || title} className={classes.image} />
      </Grid>
      <Grid container item alignContent="center" md className={classes.rightContainer}>
        <Grid className={classes.rightContent}>
          {title && <Typography variant="h5">{title}</Typography>}
          {subTitle && <Typography>{subTitle}</Typography>}
          {actionBox && (
            <Box paddingTop={2}>
              {actionBox}
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

IllustratedMessage.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  imageSrc: PropTypes.string,
  imageTitle: PropTypes.string,
  actionBox: PropTypes.node,
  disablePaddings: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(IllustratedMessage);
