import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-timeago';
import Tooltip from 'components/Tooltip';

const dateTimeFormat = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: 'numeric',
  hour12: true,
});

// eslint-disable-next-line react/prop-types
function timeRenderer({ title, children }) {
  const date = new Date(title);

  return (
    <Tooltip title={`${dateTimeFormat.format(date)} UTC`}>
      <span>
        {children}
      </span>
    </Tooltip>
  );
}

function TimeAgo({ date, ...props }) {
  if (!date) return null;

  return <ReactTimeAgo date={date} {...props} component={timeRenderer} />;
}

TimeAgo.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

export default memo(TimeAgo);
