import React from 'react';
import { useParams } from 'react-router-dom';
import { JobDetails, jobDetailsFragment } from 'components/job';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import Page404 from 'pages/Page404';
import Page500 from 'pages/Page500';
import { isNetworkError } from 'util/apollo';

const loadJobQuery = gql`
  query loadJob($name: String!) {
    job (name: $name) {
      name
      ...jobDetailsFragment
    }
  }
  ${jobDetailsFragment}
`;

function JobPage() {
  const { jobName } = useParams();
  const { data, loading, error } = useQuery(loadJobQuery, {
    variables: {
      name: jobName,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const job = get(data, 'job');

  if (error && isNetworkError(error)) {
    return <Page500 error={error} />;
  }

  if (!loading && !job) {
    return <Page404 />;
  }

  return (
    <JobDetails jobName={jobName} job={job} loading={loading} />
  );
}

export default JobPage;
