import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  Select,
  MenuItem,
  makeStyles,
  FormGroup,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),

    '&:last-child': {
      marginBottom: theme.spacing(1),
    },
  },
  select: {
    width: '100px',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

function getDayOfTheWeek(days) {
  const result = [];
  if (days.Monday) result.push(1);
  if (days.Tuesday) result.push(2);
  if (days.Wednesday) result.push(3);
  if (days.Thursday) result.push(4);
  if (days.Friday) result.push(5);
  if (days.Saturday) result.push(6);
  if (days.Sunday) result.push(7);

  if (result.length === 0) return '*';
  return result.join(',');
}

function CronEditor({
  name, helperText = '', disabled = false,
}) {
  const classes = useStyles();
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  const [minute, setMinute] = React.useState(() => {
    const result = values[name].split(' ')[0];
    return result[0] === '0' && result.length === 2 ? result.replace('0', '') : result;
  });
  const handleMinuteChange = (event) => {
    setMinute(event.target.value);
  };

  const [hour, setHour] = React.useState(() => {
    const result = values[name].split(' ')[1];
    return result[0] === '0' && result.length === 2 ? result.replace('0', '') : result;
  });
  const handleHourChange = (event) => {
    setHour(event.target.value);
  };

  const [dayOfTheWeek, setDayOfTheWeek] = React.useState(() => {
    const days = values[name].split(' ')[4];
    return {
      Monday: days.includes('1'),
      Tuesday: days.includes('2'),
      Wednesday: days.includes('3'),
      Thursday: days.includes('4'),
      Friday: days.includes('5'),
      Saturday: days.includes('6'),
      Sunday: days.includes('7'),
    };
  });
  const handleDayOfTheWeekChange = (event) => {
    setDayOfTheWeek({ ...dayOfTheWeek, [event.target.name]: event.target.checked });
  };
  const {
    Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday,
  } = dayOfTheWeek;

  useEffect(() => {
    const array = values[name].split(' ');
    array[0] = minute;
    array[1] = hour;
    array[4] = getDayOfTheWeek(dayOfTheWeek);
    setFieldValue(name, array.join(' '));
  }, [minute, hour, dayOfTheWeek, name, setFieldValue, values]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl component="fieldset" disabled={disabled || isSubmitting} className={classes.formControl}>
            <FormLabel component="legend">Days of week:</FormLabel>
            <FormGroup row>
              <FormControlLabel control={<Checkbox checked={Monday} name="Monday" onChange={handleDayOfTheWeekChange} />} label="Monday" />
              <FormControlLabel control={<Checkbox checked={Tuesday} name="Tuesday" onChange={handleDayOfTheWeekChange} />} label="Tuesday" />
              <FormControlLabel control={<Checkbox checked={Wednesday} name="Wednesday" onChange={handleDayOfTheWeekChange} />} label="Wednesday" />
              <FormControlLabel control={<Checkbox checked={Thursday} name="Thursday" onChange={handleDayOfTheWeekChange} />} label="Thursday" />
              <FormControlLabel control={<Checkbox checked={Friday} name="Friday" onChange={handleDayOfTheWeekChange} />} label="Friday" />
              <FormControlLabel control={<Checkbox checked={Saturday} name="Saturday" onChange={handleDayOfTheWeekChange} />} label="Saturday" />
              <FormControlLabel control={<Checkbox checked={Sunday} name="Sunday" onChange={handleDayOfTheWeekChange} />} label="Sunday" />
            </FormGroup>
          </FormControl>
        </Grid>
        <FormGroup component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Start time:</FormLabel>
          <div>
            <Select
              value={hour}
              onChange={handleHourChange}
              disabled={disabled || isSubmitting}
              className={classes.select}
              variant="outlined"
            >
              <MenuItem value="*">Any</MenuItem>
              {[...Array(24).keys()].map(value => <MenuItem key={value} value={value}>{value.toString().length === 1 ? `0${value}` : value}</MenuItem>)}
            </Select>
            <Select
              value={minute}
              onChange={handleMinuteChange}
              disabled={disabled || isSubmitting}
              className={classes.select}
              variant="outlined"
            >
              <MenuItem value="*">Any</MenuItem>
              <MenuItem value="H">H</MenuItem>
              {[...Array(59).keys()].map(value => <MenuItem key={value} value={value}>{value.toString().length === 1 ? `0${value}` : value}</MenuItem>)}
            </Select>
          </div>
        </FormGroup>
      </Grid>
      <FormHelperText>
        {typeof helperText === 'function' ? helperText(values[name]) : helperText}
      </FormHelperText>
    </>
  );
}

CronEditor.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
};

export default CronEditor;
