import { ADD_RECENT_VIEWED, RecentViewedAction } from './actions';

export default function reducer(
  state = [] as string[],
  action: RecentViewedAction
): string[] {
  switch (action.type) {
    case ADD_RECENT_VIEWED: {
      if (state.some((item) => item === action.payload.jobName)) {
        return state;
      }

      const array = [...state];
      if (array.length >= 3) {
        array.shift();
      }
      array.push(action.payload.jobName);

      return array;
    }
    default:
      return state;
  }
}
