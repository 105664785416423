/* eslint-disable max-len */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles, Grid, AppBar } from '@material-ui/core';
import { RoutingTabs } from 'components/tabs';
import get from 'lodash/get';
import { isCategoryExcluded } from 'behaviour/jobs/validators';
import {
  CustomersForm, ProductsForm, ShippingMethodsForm, PaymentMethodsForm, DiscountCodesForm, NewCustomerDataForm,
} from '.';
import { trimJsonDataValues } from 'util/trimJsonDataValues';
import { TestCategory } from '../general';

const useStyles = makeStyles(theme => ({
  tab: {
    textAlign: 'right',
    '& .MuiTab-wrapper': {
      [theme.breakpoints.up('md')]: {
        alignItems: 'unset',
      },
    },
  },
  leftContent: {
    maxWidth: '100%',
  },
  mainContent: {
    width: '100%',
  },
  tabContent: {
    padding: theme.spacing(1),
  },
}));

const createTabs = (jobName, parameters) => [
  {
    to: `/projects/${jobName}/testData/customers`,
    label: 'Customers',
    value: 'customers',
  },
  {
    to: `/projects/${jobName}/testData/products`,
    label: 'Products',
    value: 'products',
  },
  {
    to: `/projects/${jobName}/testData/shippingMethods`,
    label: 'Shipping methods',
    value: 'shippingMethods',
    disabled: isCategoryExcluded(parameters, [TestCategory.ShippingMethod]),
  },
  {
    to: `/projects/${jobName}/testData/paymentMethods`,
    label: 'Payment methods',
    value: 'paymentMethods',
    disabled: isCategoryExcluded(parameters, [TestCategory.PaymentMethod]),
  },
  {
    to: `/projects/${jobName}/testData/discountCodes`,
    label: 'Discount codes',
    value: 'discounts',
    disabled: isCategoryExcluded(parameters, [TestCategory.Promotions]),
  },
  {
    to: `/projects/${jobName}/testData/newCustomerData`,
    label: 'New customer account data',
    value: 'newCustomerData',
  },
];

function TestDataConfigurationForm({
  jobName,
  configuration,
  updateConfiguration,
  ...props
}) {
  const classes = useStyles();
  const parameters = get(configuration, 'parameters.Parameters.value') || {};
  const testData = get(configuration, 'parameters.TestData.value') || {};
  const tabs = useMemo(() => createTabs(jobName, parameters), [jobName, parameters]);

  const handleSubmit = useCallback(({ values, aliases }, { setSubmitting, resetForm }) => {
    const mergedParameters = { ...parameters, ...values };
    const mergedTestData = { ...testData, ...aliases };

    updateConfiguration({
      versionToken: configuration.versionToken,
      parameters: [{
        name: 'TestData',
        value: JSON.stringify(trimJsonDataValues(mergedTestData)),
        type: 'MULTILINE',
      },
      {
        name: 'Parameters',
        value: JSON.stringify(trimJsonDataValues(mergedParameters)),
        type: 'MULTILINE',
      }],
    }).then(() => {
      setSubmitting(false);
      resetForm({ values });
    });
  }, [parameters, configuration, updateConfiguration, testData]);

  return (
    <Grid container spacing={1}>
      <Grid item md={2} className={classes.leftContent}>
        <AppBar position="sticky" color="transparent" style={{ top: '1%' }} elevation={0}>
          <RoutingTabs tabs={tabs} orientation="vertical" className={classes.tab} />
        </AppBar>
      </Grid>
      <Grid item md={10} className={classes.mainContent}>
        <div className={classes.tabContent}>
          <Switch>
            <Redirect from="/projects/:jobName/testData" to="/projects/:jobName/testData/customers" exact />
            <Route
              path="/projects/:jobName/testData/customers"
              render={() => (
                <CustomersForm
                  configuration={configuration}
                  handleSubmit={handleSubmit}
                  jobName={jobName}
                  {...props}
                />
              )}
            />
            <Route
              path="/projects/:jobName/testData/products"
              render={() => (
                <ProductsForm
                  configuration={configuration}
                  handleSubmit={handleSubmit}
                  jobName={jobName}
                  {...props}
                />
              )}
            />
            <Route
              path="/projects/:jobName/testData/shippingMethods"
              render={() => (
                <ShippingMethodsForm
                  configuration={configuration}
                  handleSubmit={handleSubmit}
                  jobName={jobName}
                  {...props}
                />
              )}
            />
            <Route
              path="/projects/:jobName/testData/paymentMethods"
              render={() => (
                <PaymentMethodsForm
                  configuration={configuration}
                  handleSubmit={handleSubmit}
                  jobName={jobName}
                  {...props}
                />
              )}
            />
            <Route
              path="/projects/:jobName/testData/discountCodes"
              render={() => (
                <DiscountCodesForm
                  configuration={configuration}
                  handleSubmit={handleSubmit}
                  {...props}
                />
              )}
            />
            <Route
              path="/projects/:jobName/testData/newCustomerData"
              render={() => (
                <NewCustomerDataForm
                  configuration={configuration}
                  handleSubmit={handleSubmit}
                  {...props}
                />
              )}
            />
          </Switch>
        </div>
      </Grid>
    </Grid>
  );
}

TestDataConfigurationForm.propTypes = {
  jobName: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    versionToken: PropTypes.string.isRequired,
  }),
  updateConfiguration: PropTypes.func.isRequired,
};

export default TestDataConfigurationForm;
