import {
  InteractionRequiredAuthError, BrowserAuthError,
  // eslint-disable-next-line no-unused-vars
  PublicClientApplication, AuthenticationResult, AccountInfo, AuthError,
} from '@azure/msal-browser';
import { wait } from '../index';

const loginRequest = {
  scopes: [
    'api://94766746-a5b4-4dd4-8d74-718aaf7108a9/access_as_user',
  ],
  resourceRequestUri: 'https://graph.windows.net/',
};

/**
 * @type {Promise<AuthenticationResult>}
 */
let authPromise = null;
let inProgress = false;

/**
 * Acquires a token
 *
 * @param {AccountInfo} account
 * @param {PublicClientApplication} instance
 * @returns {Promise<AuthenticationResult>}
 */
export function acquireTokenSilent(account, instance) {
  if (inProgress) {
    if (authPromise) { return authPromise; }

    return wait(500).then(() => authPromise);
  }

  if (!account) {
    inProgress = true;
    authPromise = instance.acquireTokenRedirect(loginRequest);

    return authPromise;
  }

  inProgress = true;
  authPromise = instance.acquireTokenSilent({
    ...loginRequest,
    account,
  }).catch(async (error) => {
    if (error instanceof InteractionRequiredAuthError ||
        error instanceof BrowserAuthError ||
        error instanceof AuthError) {
      return instance.acquireTokenRedirect(loginRequest);
    }

    return Promise.reject();
  }).finally(() => {
    authPromise = null;
    inProgress = false;
  });

  return authPromise;
}
