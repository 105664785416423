import React, { memo, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import {
  useParams, Redirect,
} from 'react-router-dom';
import { AsideMenu, Article, useContentPages } from 'components/content';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

function KnowledgeBasePage() {
  const classes = useStyles();
  const { path } = useParams();
  const {
    articles, article, loading, loadArticle,
  } = useContentPages('docs');

  useEffect(() => {
    if (path) {
      loadArticle(path);
    }
  }, [path, loadArticle]);

  if (!path && articles.length) {
    return (<Redirect to={`/knowledge-base/${articles[0].url}`} />);
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item lg={9}>
        <Article
          {...article}
          loading={loading}
        />
      </Grid>
      <Grid item lg={3}>
        <AsideMenu
          loading={loading}
          links={articles.map(page => ({
            title: page.title,
            to: `/knowledge-base/${page.url}`,
          }))}
        />
      </Grid>
    </Grid>
  );
}

export default memo(KnowledgeBasePage);
