import { useApolloClient, gql } from '@apollo/client';
import { useCallback } from 'react';

const jobConfigurationFormFragment = gql`
  fragment jobConfigurationFormFragment on JobConfiguration {
    versionToken
    parameters {
      name,
      type,
      value
    },
    isParameterized,
    nonBuildableReason,
    buildPeriodically,
    timerTrigger
  }
`;

const loadJobConfigurationQuery = gql`
query loadJobConfiguration($name:String!) {
    job(name:$name) {
      name,
      configuration {
        ...jobConfigurationFormFragment
      }
    }
  }
  ${jobConfigurationFormFragment}
`;

export default function useLoadJobConfiguration() {
  const apolloClient = useApolloClient();

  return useCallback(jobName => apolloClient.query({
    query: loadJobConfigurationQuery,
    variables: {
      name: jobName,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  }), [apolloClient]);
}
