import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
  },
  disableGutters: {
    margin: 0,
  },
}));

function ButtonSkeleton({ width = 140, disableGutters = false }) {
  const classes = useStyles();
  return (
    <Skeleton
      className={clsx(classes.root, {
        [classes.disableGutters]: disableGutters,
      })}
      variant="rect"
      height={36}
      width={width}
    />
  );
}

export default ButtonSkeleton;

ButtonSkeleton.propTypes = {
  width: PropTypes.number,
  disableGutters: PropTypes.bool,
};
